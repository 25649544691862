import React from 'react';
import { Form, Button } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import InputFormNumber from '../../components/antd/InputFormNumber';
import MyRow from '../../components/MyRow';
import InputFormText from '../../components/antd/InputFormText';
import getText from '../../lang';

const OrderItemProduct = (props) => {
  const { onChange } = props;

  return (
    <Form.List name="items">
      {(fields, { add, remove }) => {
        return (
          <div
            className="order-item-price-list-wrapper"
            style={{ width: '100%', marginTop: 40 }}
          >
            <h5>{getText('WORD_ITEMS_LIST')}</h5>
            {fields.map((field, index) => (
              <div className="order-list-item" key={field.key}>
                <MyRow key={index} className="add-price-list-row" withoutTitle>
                  <InputFormText
                    label={index === 0 ? getText('WORD_PRODUCT_NUMBER') : ''}
                    placeholder={getText('WORD_PRODUCT_NUMBER')}
                    name={[field.name, 'itemNumber']}
                    minLength={3}
                  />
                  <InputFormText
                    label={index === 0 ? getText('WORD_PRODUCT_NAME') : ''}
                    placeholder={getText('WORD_PRODUCT_NAME')}
                    name={[field.name, 'itemName']}
                    minLength={3}
                    required
                  />

                  <InputFormNumber
                    name={[field.name, 'itemQuantity']}
                    label={index === 0 ? getText('WORD_QUANTITY') : ''}
                    placeholder={getText('WORD_QUANTITY')}
                    onChange={onChange}
                    className="formNumber"
                    required
                  />
                  <InputFormText
                    label={index === 0 ? getText('WORD_PRODUCT_UNIT') : ''}
                    placeholder={getText('WORD_PRODUCT_UNIT')}
                    name={[field.name, 'itemUnit']}
                    minLength={2}
                    required
                  />

                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ marginLeft: 8, marginBottom: 30 }}
                    onClick={() => {
                      remove(field.name);
                      onChange && onChange();
                    }}
                  />
                </MyRow>
                <MyRow withoutTitle>
                  <InputFormNumber
                    name={[field.name, 'itemPrice']}
                    label={index === 0 ? getText('WORD_PRODUCT_PRICE') : ''}
                    placeholder={getText('WORD_PRODUCT_PRICE')}
                    onChange={onChange}
                    className="formNumber"
                  />
                  <InputFormNumber
                    name={[field.name, 'sizeWeight']}
                    label={index === 0 ? getText('WORD_KG') : ''}
                    placeholder={getText('WORD_KG')}
                    min={0}
                    required
                    onChange={onChange}
                    className="formNumber"
                  />
                  <InputFormNumber
                    name={[field.name, 'sizeM3']}
                    label={index === 0 ? getText('WORD_M3_WITH_3') : ''}
                    placeholder={getText('WORD_M3_WITH_3')}
                    min={0}
                    required
                    onChange={onChange}
                    className="formNumber"
                  />
                </MyRow>
              </div>
            ))}
            <Button
              onClick={() => {
                add({ sizeWeight: 0, sizeM3: 0 });
              }}
              style={{ width: '100%', marginBottom: 5 }}
            >
              <PlusCircleOutlined /> {getText('WORD_ADD_PRODUCT')}
            </Button>
          </div>
        );
      }}
    </Form.List>
  );
};

export default OrderItemProduct;
