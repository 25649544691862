import React, { useEffect, useState } from 'react';
import getText from '../../../lang';
import DriverMonitoringActions from '../../../actions/DriverMonitoringActions';
import MyListGrid from '../../../components/antd/MyListGrid';
import MyRow from '../../../components/MyRow';
import { RightOutlined } from '@ant-design/icons';
import TruckIcon from '../../../icons/svg/TruckIcon';
import ApiStorage from '../../../reducers/ApiStorage';
import { mainConnect } from '../../../utils/MySocket';
import './DriverPackingStatusMonitorPageStyle.scss';

const DriverPackingStatusMonitorPage = (props) => {
  const [readyList, setReadyList] = useState([]);
  const [packingList, setPackingList] = useState([]);

  useEffect(() => {
    const fill = async () => {
      let result = await DriverMonitoringActions.fillMonitoringList();
      if (result.success) {
        setReadyList(result.data.ready);
        setPackingList(result.data.packing);
      }
    };
    mainConnect();
    ApiStorage.setPageSocketMonitoring((val) => {
      setReadyList(val.ready);
      setPackingList(val.packing);
    });
    fill();
  }, []);

  return (
    <div className="driver-packing-status-monitor-page-wrapper">
      <div className="pending-section">
        <h6 className="my-section-title">{getText('WORD_IN_PROGRESS')}</h6>
        <MyListGrid
          className="ready-list"
          dataSource={packingList}
          pagination={false}
          renderItem={(packingListItem) => {
            return (
              <MyRow withoutTitle className="list-item-wrapper">
                <TruckIcon color="#ff9300" width="35px" height="35px" />
                <div className="list-item-transport-number">
                  {packingListItem.transportNumber}
                </div>
              </MyRow>
            );
          }}
        />
      </div>
      <div className="ready-for-delevery-section">
        <h6 className="my-section-title">
          {getText('WORD_READY_FOR_DELEVERY')}
        </h6>
        <MyListGrid
          className="ready-list"
          dataSource={readyList}
          pagination={false}
          renderItem={(readyListItem, index) => {
            return (
              <MyRow withoutTitle className="list-item-wrapper">
                <TruckIcon color="#0076ff" width="35px" height="35px" />
                <div className="list-item-transport-number">
                  {readyListItem.transportNumber}
                </div>
                <RightOutlined fill="blue" />
                <div className="list-item-gate">
                  {readyListItem.driverPackingStatusNote}
                </div>
              </MyRow>
            );
          }}
        />
      </div>
    </div>
  );
};

export default DriverPackingStatusMonitorPage;
