import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.518"
    height="19.518"
    viewBox="0 0 19.518 19.518"
  >
    <g id="noun_Plus_12862" transform="translate(19.518 9.759) rotate(135)">
      <path
        id="Path_880"
        data-name="Path 880"
        d="M17.574,10.827h-4.6v-4.6A1.161,1.161,0,0,0,11.9,5a1.161,1.161,0,0,0-1.073,1.227v4.6h-4.6A1.161,1.161,0,0,0,5,11.9a1.161,1.161,0,0,0,1.227,1.073h4.6v4.6A1.161,1.161,0,0,0,11.9,18.8a1.161,1.161,0,0,0,1.073-1.227v-4.6h4.6A1.161,1.161,0,0,0,18.8,11.9,1.161,1.161,0,0,0,17.574,10.827Z"
        transform="translate(-5 -5)"
        fill="#f0f0f0"
      />
    </g>
  </svg>
);

const CloseIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default CloseIcon;
