import React from 'react';
import { Menu, Layout, Drawer, Badge } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import LogoFull from '../../../icons/svg/LogoFull';
import DashboardIcon from '../../../icons/svg/DashboardIcon';
import OrderIcon from '../../../icons/svg/OrderIcon';
import AddressesIcon from '../../../icons/svg/AddressesIcon';
import SettingsIcon from '../../../icons/svg/SettingsIcon';
import LogoSmall from '../../../icons/svg/LogoSmall';
import ManifestIcon from '../../../icons/svg/ManifestIcon';
import TruckIcon from '../../../icons/svg/TruckIcon';
import StarIcon from '../../../icons/svg/StarIcon';
import UserActions from '../../../actions/UserActions';
import getText from '../../../lang';
import MenuProfileSettings from './MenuProfileSettings';
import { useSelector } from 'react-redux';
import useWindowSize from '../../../utils/useWindowSize';
import './sidebarStyle.scss';
import './styleMobile.scss';

const SideBar = (props) => {
  const { onToggle, onCloseToggle } = props;

  const lang = useSelector((state) => {
    return state.AuthReducer.currentUser?.lang;
  });

  const idRole = useSelector((state) => {
    return state.AuthReducer.currentUser?.idRole;
  });

  const totalActiveOrdersCount = useSelector((state) => {
    return state.AuthReducer.totalActiveOrdersCount;
  });

  const menuOpen = useSelector((state) => {
    return state.AuthReducer.menuOpen;
  });

  const location = useLocation();
  const { isMobile } = useWindowSize();

  const RouteSubMenuGroup = {
    '/dashboard': ['dashboard'],

    '/driver/list': ['driverMenu'],

    '/home': ['ordersSub', 'orders'],
    '/orders/edit': ['ordersSub', 'orders'],
    '/orders/create': ['ordersSub', 'orders'],
    '/orders/view': ['ordersSub', 'orders'],
    '/orders/list': ['ordersSub', 'orders'],

    '/manifest/list': ['ordersSub', 'manifests'],
    '/manifest/create': ['ordersSub', 'manifests'],
    '/manifest/edit': ['ordersSub', 'manifests'],
    '/manifest/view': ['ordersSub', 'manifests'],
    '/assign': ['ordersSub', 'manifests'],

    '/customer/create': ['customer'],
    '/customer/edit': ['customer'],
    '/customer/list': ['customer'],

    '/analytics': ['analytics'],

    '/users/list': ['settingsSub', 'users'],
    '/users/edit': ['settingsSub', 'users'],
    '/users/view': ['settingsSub', 'users'],
    '/users/create': ['settingsSub', 'users'],

    '/services/list': ['settingsSub', 'services'],
    '/services/edit/': ['settingsSub', 'services'],
    '/services/create': ['settingsSub', 'services'],

    '/reasons/list': ['settingsSub', 'reasons'],
    '/reasons/edit/': ['settingsSub', 'reasons'],
    '/reasons/create': ['settingsSub', 'reasons'],

    '/device/list': ['settingsSub', 'device'],
    '/device/create': ['settingsSub', 'device'],
    '/device/edit': ['settingsSub', 'device'],

    '/settings': ['settingsSub', 'settings'],
    '/notifications': ['settingsSub', 'notifications'],
    '/apicode/list': ['settingsSub', 'apicode'],
    '/apicode/create': ['settingsSub', 'apicode'],
    '/apicode/edit': ['settingsSub', 'apicode'],
    '/polygons': ['settingsSub', 'polygons'],
    '/branch': ['settingsSub', 'branch'],
    '/sms_history/list': ['settingsSub', 'sms'],

    '/rating/list': ['ratingList'],

    '/account_settings': [],
  };

  const selectedRoute = () => {
    let pat = location.pathname;
    let urlLang = `/${lang}`;
    if (pat.startsWith(urlLang)) {
      pat = pat.replace(urlLang, '');
    }
    let selecPath = pat;
    for (const [key, value] of Object.entries(RouteSubMenuGroup)) {
      if (pat.startsWith(key)) {
        selecPath = value;
      }
    }
    return selecPath;
  };

  const isSuperAdmin = idRole === UserActions.ROLE.SUPERADMIN;

  const items = [
    {
      label: (
        <Link to={`/${lang}/orders/list`}>
          {getText('WORD_SIDEBAR_ORDERS')}
        </Link>
      ),
      key: 'orders',
      onClick: () => {
        // ApiStorage.setFilterTransaction({});
      },
      icon: (
        <Badge
          className={`anticon ${menuOpen ? 'anticon-menuopen' : ''}`}
          count={totalActiveOrdersCount}
          overflowCount={9999}
        >
          <OrderIcon color="#fff" />
        </Badge>
      ),
    },
    {
      label: (
        <Link to={`/${lang}/manifest/list`}>{getText('WORD_FREIGHTS')}</Link>
      ),
      key: 'manifests',
      icon: <ManifestIcon color="#fff" />,
      onClick: () => {
        // ApiStorage.setFilterManifest({});
      },
    },
    {
      label: (
        <Link to={{ pathname: `/${lang}/driver/list` }}>
          {getText('WORD_DRIVERS')}
        </Link>
      ),
      key: 'driverMenu',
      icon: <TruckIcon color="#fff" />,
    },
    {
      label: (
        <Link to={`/${lang}/customer/list`}>
          {getText('WORD_SIDEBAR_CUSTOMERS')}
        </Link>
      ),
      key: 'customer',
      icon: <AddressesIcon />,
    },
    {
      label: (
        <Link to={`/${lang}/rating/list`}>
          {getText('WORD_SIDEBAR_RATING')}
        </Link>
      ),
      key: 'ratingList',
      icon: <StarIcon />,
    },
    {
      label: getText('WORD_SIDEBAR_SETTINGS'),
      key: 'settingsSub',
      icon: <SettingsIcon />,
      children: [
        {
          label: (
            <Link to={`/${lang}/settings`}>
              {getText('WORD_COMPANY_SETTINGS_TITLE')}
            </Link>
          ),
          key: 'settings',
          hidden: !isSuperAdmin,
        },
        {
          label: (
            <Link to={`/${lang}/notifications`}>
              {getText('WORD_NOTIFICATIONS')}
            </Link>
          ),
          key: 'notifications',
          hidden: !isSuperAdmin,
        },
        {
          label: (
            <Link to={{ pathname: `/${lang}/users/list` }}>
              {getText('WORD_SIDEBAR_TEAM')}
            </Link>
          ),
          key: 'users',
          hidden: !isSuperAdmin,
        },
        {
          label: (
            <Link to={`/${lang}/services/list`}>
              {getText('WORD_SIDEBAR_SERVICES')}
            </Link>
          ),
          key: 'services',
          hidden: !isSuperAdmin,
        },
        {
          label: (
            <Link to={`/${lang}/reasons/list`}>
              {getText('WORD_SIDEBAR_REASONS')}
            </Link>
          ),
          key: 'reasons',
          hidden: !isSuperAdmin,
        },

        {
          label: (
            <Link to={`/${lang}/device/list`}>{getText('WORD_DEVICES')}</Link>
          ),
          key: 'device',
        },
        {
          label: (
            <Link to={`/${lang}/apicode/list`}>{getText('WORD_API_CODE')}</Link>
          ),
          key: 'apicode',
        },
        {
          label: (
            <Link to={`/${lang}/polygons/list`}>
              {getText('WORD_BUSINESS_ZONES')}
            </Link>
          ),
          key: 'polygons',
        },
        {
          label: (
            <Link to={`/${lang}/branch/list`}>{getText('WORD_BRANCH')}</Link>
          ),
          key: 'branch',
        },
        {
          label: (
            <Link to={`/${lang}/sms_history/list`}>
              {getText('WORD_SMS_HISTORY')}
            </Link>
          ),
          key: 'sms',
        },
      ],
    },
  ];

  if (
    idRole === UserActions.ROLE.SUPERADMIN ||
    idRole === UserActions.ROLE.ADMIN
  ) {
    items.unshift({
      label: (
        <Link to={`/${lang}/dashboard`}>
          {getText('WORD_SIDEBAR_DASHBOARD')}
        </Link>
      ),
      key: 'dashboard',
      icon: <DashboardIcon />,
    });
  }

  const Menumm = (props) => {
    return (
      <>
        <div className="logo" onClick={onToggle}>
          {menuOpen ? <LogoFull /> : <LogoSmall />}
        </div>
        <Menu
          theme="light"
          mode={isMobile ? 'inline' : 'vertical'}
          onSelect={() => {
            isMobile && onCloseToggle && onCloseToggle();
          }}
          selectedKeys={selectedRoute()}
          items={items}
        />
        <MenuProfileSettings />
      </>
    );
  };

  return isMobile ? (
    <Drawer
      className="ant-drawer-left-menu-mobile"
      placement={'left'}
      closable={false}
      onClose={onCloseToggle}
      open={menuOpen}
      key={'left'}
      width={'70%'}
    >
      <Menumm isDrawer {...props} />
    </Drawer>
  ) : (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={!menuOpen}
      width={220}
      collapsedWidth={70}
    >
      <Menumm {...props} />
    </Layout.Sider>
  );
};

export default SideBar;
