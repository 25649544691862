import React from 'react';
import { Timeline } from 'antd';
import getText from '../../lang';
import { formatDateTime } from '../../utils';
import MyRow from '../../components/MyRow';
import ManifestActions from '../../actions/ManifestActions';
import OrderGreenDone from '../../icons/svg/OrderGreenDone';
import moment from 'moment';
import UtilNumber from '../../utils/utilNumber';

const ManifestTimeLine = (props) => {
  const { list, item } = props;

  const startDate = formatDateTime(item.startDate);
  const finishDate = formatDateTime(item.finishDate);

  const diff = moment(item.startDate).diff(moment(item.finishDate));
  const duration = UtilNumber.formatDouble(moment.duration(diff).asHours());

  return (
    <div
      className="manifest-timeline-wrapper"
      style={{ height: 320, margin: '0 20px 20px 35px' }}
    >
      <div className="manifest-timeline">
        <div className="timeline-header">{getText('WORD_TIMELINE')}</div>
        <Timeline
          items={
            list &&
            list.map((tim, index) => {
              const timelineItemClassName =
                tim.status === ManifestActions.STATUS.FINISHED
                  ? 'timeline-done'
                  : 'timeline-progressing';
              return {
                key: index,
                children: (
                  <div
                    key={index}
                    className={`timeline-item ${timelineItemClassName}`}
                    dot={
                      tim.status === ManifestActions.STATUS.FINISHED ? (
                        <OrderGreenDone />
                      ) : null
                    }
                  >
                    <div>{`#${tim?.apiOrderKey || tim?.orderNumber}`}</div>
                    <MyRow withoutTitle>
                      <div>{formatDateTime(tim.startDate)}</div>
                      <div>{formatDateTime(tim.finishDate)}</div>
                    </MyRow>
                  </div>
                ),
              };
            })
          }
        />
      </div>
      <div className="end-start-date">
        <div>
          {startDate}
          {finishDate}
        </div>
        {item.startDate && item.finishDate && (
          <span>
            {duration}
            {getText('WORD_HR')}
          </span>
        )}
      </div>
    </div>
  );
};

export default ManifestTimeLine;
