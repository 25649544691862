import { SELECT_UNSELECT_NONE } from '../utils';
import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import getText from '../lang';
import ApiStorage from '../reducers/ApiStorage';

const URL_LIST = '/service_transport_prices/get_all';

const fs = {
  getFullList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result.success ? result.data : [];
  },
};

const loc = {
  PRICEMETHOD: {
    FIX: 1,
    BYDISTANCE: 2,
  },
  STATUS: {
    ACTIVE: 1,
    BLOCKED: 2,
  },
  getFullListOptions: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SERVICE'),
    }
  ) => {
    let items = await fs.getFullList();
    let user = ApiStorage.getUser();
    let symbol = user ? user.clientCurrencySymbol : '';

    const options = items.map((obj) => {
      return {
        value: obj.idServiceTransportPrices,
        label: `${obj.serviceName} / ${obj.transportName} / ${
          obj.priceMethod ===
          ServiceTransportPriceActions.PRICEMETHOD.BYDISTANCE
            ? `${obj.minKm}km/${obj.minPriceKm}${symbol}/${obj.oneKmPrice}${symbol}`
            : obj.price
        }`,
        ...obj,
      };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getFullListOptionsAll: async () => {
    return await loc.getFullListOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL_SERVICES'),
    });
  },
  getPriceMetodeOptions: (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT_TYPE'),
    }
  ) => {
    const options = [
      { label: getText('WORD_PRICE_FIXED_PRICE'), value: loc.PRICEMETHOD.FIX },
      {
        label: getText('WORD_PRICE_BY_DISTANCE'),
        value: loc.PRICEMETHOD.BYDISTANCE,
      },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getPriceMetodeOptionsAll: () => {
    return loc.getPriceMetodeOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL'),
    });
  },
  getFullListOptionsActive: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL_SERVICES'),
    }
  ) => {
    let items = await fs.getFullList();
    let user = ApiStorage.getUser();
    let symbol = user?.clientCurrencySymbol || '';

    const options = items
      .filter((oo) => oo.serviceStatus === loc.STATUS.ACTIVE)
      .map((obj) => {
        return {
          value: obj.idServiceTransportPrices,
          label: `${obj.serviceName} / ${
            obj.priceMethod ===
            ServiceTransportPriceActions.PRICEMETHOD.BYDISTANCE
              ? `${obj.minKm}km/${obj.minPriceKm}${symbol}/${obj.oneKmPrice}${symbol}`
              : obj.price
          }`,
          ...obj,
        };
      });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
};

const ServiceTransportPriceActions = Object.assign(fs, loc);

export default ServiceTransportPriceActions;
