import React from 'react';

const OrderProductsSection = (props) => {
  const { item, clientCurrencySymbol } = props;

  return item?.items
    ? item.items.map((oo, index) => {
        return (
          <div className="product-row" key={index + item?.idOrder}>
            <div className="row-first-item">
              <div className="name">{oo.itemName}</div>
              <div className="store">{oo.store}</div>
            </div>

            <div className="row-first-item">
              {oo.itemQuantity}
              <span className="item-unit">{oo.itemUnit}</span>
            </div>
            <div className="row-second-item">
              {oo.itemPrice}
              <span className="currency-symbol">{clientCurrencySymbol}</span>
            </div>
          </div>
        );
      })
    : null;
};

export default OrderProductsSection;
