import React from 'react';
import { Form, Input } from 'antd';
import getText from '../../lang';

const InputFormEmail = (props) => {
  const {
    label,
    name,
    hasFeedback,
    required,
    message,
    type,
    minLength,
    placeholder,
    allowClear,
    disabled,
    autoComplete,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: getText(message),
          type: type,
        },
        {
          min: minLength,
          message: `${
            label ? label : placeholder
          } must be minimum ${minLength} characters.`,
        },
      ]}
    >
      <Input
        type={type}
        placeholder={label ? label : placeholder}
        allowClear={allowClear}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </Form.Item>
  );
};

InputFormEmail.defaultProps = {
  name: 'email',
  placeholder: 'Email',
  message: 'WORD_PLEASE_INPUT_EMAIL',
  required: true,
  hasFeedback: true,
  allowClear: true,
  minLength: 9,
  type: 'email',
  autoComplete: 'new-email',
};

export default InputFormEmail;
