import React, { forwardRef } from 'react';
import { YMaps, Map } from 'react-yandex-maps';

const MyMap = forwardRef((props, ref) => {
  const { width, height, onLoadMap, children } = props;

  return (
    <YMaps
      enterprise
      query={{
        lang: 'en_RU',
        apikey: process.env.REACT_APP_YANDEX_MAP_APIKEY,
      }}
    >
      <Map
        width={width || '100%'}
        height={height || 700}
        defaultState={{
          center: [40.1772, 44.50349],
          zoom: 8,
        }}
        onLoad={onLoadMap}
        instanceRef={ref}
      >
        {children}
      </Map>
    </YMaps>
  );
});

export default MyMap;
