import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import MyMap from '../../components/MyMap';
import { useParams } from 'react-router-dom';
import ManifestActions from '../../actions/ManifestActions';
import OrderActions from '../../actions/OrderActions';
import ManifestCard from './ManifestCard';
import { notifyError } from '../../components/notify';
import MainPage from '../../components/MainPage';
import getText from '../../lang';
import ManifestTimeLine from './ManifestTimeLine';
import moment from 'moment';
import ManifestTabs from './ManifestTabs';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';

const ManifestView = (props) => {
  const navigateApi = useNavigateApi();
  const { isMobile } = useWindowSize();

  let { _id } = useParams();
  const [item, setItem] = useState({});

  const [listDataPending, setListDataPending] = useState([]);
  const [listDataArchive, setListDataArchive] = useState([]);
  const [listTimeLine, setlistTimeLine] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const fill = async (idManifest) => {
      setLoadingData(true);
      let fetchParams = {
        page: 0,
        perPage: 1000,
        sort: {},
        filter: {
          idManifest: idManifest,
        },
        q: '',
      };
      let result = await OrderActions.fillPaginationList(fetchParams);

      if (result.success) {
        let listPending = [];
        let listArchive = [];

        for (const order of result.data) {
          if (order.status === OrderActions.STATUS.FINISHED) {
            listArchive.push(order);
          } else {
            listPending.push(order);
          }
        }
        setListDataPending(listPending);
        setListDataArchive(listArchive);

        result.data.sort((a, b) => {
          if (a.startDate > b.startDate) {
            return 1;
          }
          if (a.startDate < b.startDate) {
            return -1;
          }
          return 0;
        });
        setlistTimeLine(result.data);
      }
      setLoadingData(false);
    };
    const getObj = async () => {
      if (_id) {
        let resultGet = await ManifestActions.getOneByidKey(_id);
        if (resultGet.success) {
          resultGet.data.startDate = moment().add(1, 'days');
          resultGet.data.finishDate = moment();
          setItem(resultGet.data);
          fill(resultGet.data.idManifest);
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('manifest/list');
        }
      }
    };
    getObj();
  }, [_id, navigateApi]);

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <div
        style={isMobile ? { marginTop: 0 } : { marginTop: 20 }}
        className="user-list-card-with-map"
      >
        <ManifestCard item={item} disableView={true} />
        <ManifestTimeLine list={listTimeLine} item={item} />
        <div className="user-view-map">
          <MyMap height={300} />
        </div>
      </div>
      <MainPage title={getText('WORD_ACTIVITY')}>
        <div className="user-view-activity-wrapper">
          <ManifestTabs
            listDataPending={listDataPending}
            listDataArchive={listDataArchive}
            loadingData={loadingData}
          />
        </div>
      </MainPage>
    </>
  );
};

export default ManifestView;
