import React, { useState } from 'react';
import MyRow from '../../components/MyRow';
import ManifestIcon from '../../icons/svg/ManifestIcon';
import EditIcon from '../../icons/svg/EditIcon';
import { formatDateTime } from '../../utils';
import ManifestActions from '../../actions/ManifestActions';
import CalButton from '../../components/CalButton';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import MyTextWithBadge from '../../components/myTextWithBadge/MyTextWithBadge';
import getText from '../../lang';
import UtilNumber from '../../utils/utilNumber';
import DrawerEdit from './DrawerEdit';
import { Space, Tooltip } from 'antd';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import './ManifestCard.scss';

const ManifestCard = (props) => {
  const { item } = props;

  const navigateApi = useNavigateApi();

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const RenderDate = () => {
    return (
      <div className="date-div">
        <Tooltip
          placement={'bottom'}
          title={
            item.status === ManifestActions.STATUS.FINISHED
              ? getText('WORD_END_DATE')
              : getText('WORD_CREATE_DATE')
          }
        >
          {formatDateTime(
            item.status === ManifestActions.STATUS.FINISHED
              ? item.finishDate
              : item.createDate
          )}
        </Tooltip>
      </div>
    );
  };

  const RenderRow = ({ label, value, border }) => {
    return (
      <div className={`manifest-row-wrapper ${border ? 'my-left-border' : ''}`}>
        <div className="manifest-first-item">{label}</div>
        <div className="manifest-second-item">{'' + value}</div>
      </div>
    );
  };

  const getNegativeOrPositive = (size, transportSize) => {
    const number = parseInt(UtilNumber.formatDouble(size - transportSize));
    if (number > 0) {
      return 'positive';
    } else if (number < 0) {
      return 'negative';
    } else {
      return '';
    }
  };
  const showDrawer = () => {
    setVisibleDrawer(true);
  };
  const closeDrawer = () => {
    setVisibleDrawer(false);
  };

  return (
    <>
      <div
        className="one-manifest-block-wrapper some-manifest-infos"
        onClick={(e) => {
          e.stopPropagation();
          navigateApi('manifest/view/' + item.idKey);
        }}
      >
        <MyRow withoutTitle bottomZero className="my-row-for-padding">
          <div className="order-number-with-icon">
            <MyRow withoutTitle>
              <ManifestIcon />
              <Space>
                <div className="number-div">{item.manifestName}</div>
                <div className="number-div">{item.idManifest}</div>
              </Space>
            </MyRow>
            {item.status !== ManifestActions.STATUS.FINISHED && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.status === ManifestActions.STATUS.ACTIVE) {
                    navigateApi('assign/' + item.idKey);
                  } else {
                    e.persist();
                    e.stopPropagation();
                    showDrawer();
                  }
                }}
                className="edit-icon"
              >
                <EditIcon />
              </div>
            )}
          </div>
          <RenderDate />
        </MyRow>
        <div className="divider-line" />
        <MyRow withoutTitle bottomZero className="manifest-row-padding">
          <div className="manifest-first-item">{getText('WORD_KG')}</div>
          <div className="manifest-second-item">
            {UtilNumber.formatDouble(item.sizeKg)} /{' '}
            {UtilNumber.formatDouble(item.transportKg)}
            <span
              className={getNegativeOrPositive(item.sizeKg, item.transportKg)}
            >
              {UtilNumber.formatDouble(item.sizeKg - item.transportKg)}
            </span>
          </div>
        </MyRow>
        <div className="divider-line" />
        <MyRow withoutTitle bottomZero className="manifest-row-padding">
          <div className="manifest-first-item">
            <MyTextWithBadge mainText={getText('WORD_M3')} topSymbol={3} />
          </div>
          <div className="manifest-second-item">
            {UtilNumber.formatDouble(item.sizeM3)} /{' '}
            {UtilNumber.formatDouble(item.transportM3)}
            <span
              className={getNegativeOrPositive(item.sizeM3, item.transportM3)}
            >
              {UtilNumber.formatDouble(item.sizeM3 - item.transportM3)}
            </span>
          </div>
        </MyRow>
        <div className="divider-line" />
        <div className="manifest-block-wrapper">
          <RenderRow
            label={getText('WORD_ORDERS')}
            // value={item?.orderTotalCount}
            value={Math.abs(
              (item?.orderActiveCount || 0) +
                (item?.orderInprogressCount || 0) +
                (item?.orderFinishCount || 0)
            )}
            border
          />
          <RenderRow
            label={getText('WORD_PENDING')}
            value={Math.abs(
              item?.orderActiveCount || 0 + item?.orderInprogressCount || 0
            )}
            border
          />
          <RenderRow
            label={getText('WORD_COMPLETED')}
            value={item?.orderFinishCount}
            border
          />
          <RenderRow
            label={getText('WORD_STATUS')}
            value={ManifestActions.getStatusLabel(item.status)}
          />
        </div>
        <div className="divider-line" />
        <MyRow withoutTitle bottomZero className="callbtn-with-avatar">
          <MyRow withoutTitle bottomZero className="avatar-name">
            <ProfileAvatar icon={item?.driverIcon} value={item?.driverName} />
            <div className="number-div">{item?.driverName || '---'}</div>
          </MyRow>
          <CalButton
            phoneNumber={item?.driverPhoneCode + '' + item?.driverPhoneNumber}
          />
        </MyRow>
      </div>
      <DrawerEdit
        visible={visibleDrawer}
        setVisible={setVisibleDrawer}
        idKey={item.idKey}
        onClose={closeDrawer}
      />
    </>
  );
};

export default ManifestCard;
