import React from 'react';
import MyPanelFooter from '../../MyPanelFooter';
import { Modal } from 'antd';
import LoadingOutlinedApp from '../LoadingOutlinedApp';
import './MyModal.scss';

const MyModal = (props) => {
  const {
    visible,
    onTrrigerClick,
    trigger,
    className,
    title,
    width,
    bodyStyle,
    maskStyle,
    forceRender,
    okText,
    cancelText,
    confirmLoading,
    onCancel,
    onClick,
    onOk,
    destroyOnClose,
    loading,
    onCancelBtn,
    saving,
    cancelBtnText,
    okBtnText,
    children,
  } = props;

  return (
    <>
      <div
        className={`${visible ? 'modal-btn-active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          e.persist();
          e.stopPropagation();
          onTrrigerClick && onTrrigerClick(e);
        }}
      >
        {trigger ? trigger : 'Open Modal'}
      </div>
      <Modal
        className={`my-modal ${className || ''}`}
        title={title}
        open={visible}
        width={width}
        bodyStyle={bodyStyle}
        maskStyle={maskStyle}
        centered
        forceRender={forceRender}
        okText={okText}
        cancelText={cancelText}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        onClick={onClick}
        onOk={onOk}
        destroyOnClose={destroyOnClose}
        footer={
          loading ? (
            ''
          ) : (
            <MyPanelFooter
              onCancelClick={onCancelBtn || onCancel}
              onPrimaryClick={onOk}
              loading={saving}
              labelCancel={cancelBtnText}
              labelPrimary={okBtnText}
            />
          )
        }
      >
        {loading ? (
          <LoadingOutlinedApp />
        ) : (
          <div className="modal-content-wrapper">{children}</div>
        )}
      </Modal>
    </>
  );
};

MyModal.defaultProps = {
  width: 500,
};

export default MyModal;
