import React, { useCallback, useEffect, useRef, useState } from 'react';
import MyMap from '../../components/MyMap';
import { notifyError } from '../../components/notify';
import DashboardLeft from './DashboardLeft';
import DashboardRight from './DashboardRight';
import DashboardActions from '../../actions/DashboardActions';
import ApiStorage from '../../reducers/ApiStorage';
import { Drawer } from 'antd';
import CloseIcon from '../../icons/svg/CloseIcon';
import MainPage from '../../components/MainPage';
import useWindowSize from '../../utils/useWindowSize';
import HeaderSpace from '../../components/HeaderSpace';
import UtilObject from '../../utils/UtilObject';

let MyYmaps;

const Dashboard = (props) => {
  const refContainer = useRef(null);
  const refLeft = useRef(null);

  const { isMobile } = useWindowSize();

  const [selectedItemActive, setSelectedItemActive] = useState({});

  const [listDriverLocations, setListDriverLocations] = useState([]);
  const [listMarkers, setListMarkers] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [visible, setVisible] = useState(false);
  const [visibleMap, setVisibleMap] = useState(false);

  const createMarker = useCallback((driver) => {
    if (!MyYmaps) return;
    const MyMapRef = refContainer.current;
    let color = driver.orderInprogressCount > 0 ? 'orange' : 'blue';
    let hint = driver.orderInprogressCount > 0 ? 'online' : 'Available';

    var m = new MyYmaps.Placemark(
      [driver.lastLat, driver.lastLng],
      {
        balloonContent: `${driver.transportTypeName}
                    ${driver.transportNumber ? driver.transportNumber : ''}
                    ${
                      driver.transportDescription
                        ? driver.transportDescription
                        : ''
                    }`,
        clusterCaption: 'Another one placemark',
        hintContent: hint,
        iconContent: `${driver.fullName}`,
      },
      {
        preset: `islands#${color}StretchyIcon`,
        balloonCloseButton: true,
        hideIconOnBalloonOpen: false,
      }
    );
    MyMapRef.geoObjects.add(m);
    setListMarkers((o) => {
      o[`Marker_${driver.idUser}`] = m;
      return { ...o };
    });
  }, []);

  useEffect(() => {
    const fill = async () => {
      setIsLoading(true);
      let resultUsers =
        await DashboardActions.getDriversCurrentLocation_Dashboard();
      setIsLoading(false);
      if (resultUsers.success) {
        setListDriverLocations(resultUsers.data);
      } else {
        notifyError(resultUsers.errMsg);
      }
    };
    fill();
  }, []);

  useEffect(() => {
    ApiStorage.setPageSocket((msg) => {
      const obj = msg.body;
      const typ = msg.type;
      if (typ === 'DRIVERINFO') {
        if (obj.idUser) {
          if (refLeft.current) {
            refLeft.current.refreshUser(obj);
          }
          const MyMapRef = refContainer.current;
          if (MyMapRef && listMarkers[`Marker_${obj.idUser}`]) {
            MyMapRef.geoObjects?.remove(listMarkers[`Marker_${obj.idUser}`]);
          }
          if (obj.driverIsOnline) {
            createMarker(obj);
          }
        }
      }
    });
  }, [listMarkers, visibleMap, createMarker]);

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      MyYmaps = ymaps;
      addAllInMap();
    });
  };

  const addAllInMap = () => {
    const MyMapRef = refContainer.current;
    if (MyMapRef && MyMapRef.geoObjects) {
      MyMapRef.geoObjects?.removeAll();
      listDriverLocations.forEach((driver) => {
        createMarker(driver);
      });
      let myResult = MyYmaps.geoQuery(MyMapRef.geoObjects);
      if (myResult.getBounds()) {
        MyMapRef.setBounds(myResult.getBounds(), {
          checkZoomRange: true,
          zoomMargin: 40,
        });
      }
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MainPage bottomZero>
        {isLoading ? null : (
          <div className="three-dashboard-block">
            <DashboardLeft
              ref={refLeft}
              selectedItemActive={selectedItemActive}
              onChange={(idKey, user) => {
                if (idKey) {
                  setSelectedItemActive(user);
                  setVisible(true);
                }
              }}
              onClickOpenMap={() => setVisibleMap(true)}
            />
            <div className="dashboard-map-wrapper">
              {isMobile ? (
                <Drawer
                  placement="right"
                  closable={false}
                  onClose={() => setVisibleMap(false)}
                  open={visibleMap}
                  className="map-dashboard-drawer"
                  width={'100%'}
                >
                  <div
                    className="close-icon-map-drawer"
                    onClick={() => setVisibleMap(false)}
                  >
                    <CloseIcon />
                  </div>
                  <MyMap
                    ref={refContainer}
                    onLoadMap={onLoadMap}
                    height={'95%'}
                  />
                </Drawer>
              ) : (
                <MyMap ref={refContainer} onLoadMap={onLoadMap} />
              )}
            </div>

            {isMobile ? (
              <Drawer
                placement="right"
                closable={false}
                onClose={() => {
                  setSelectedItemActive({});
                  setVisible(false);
                }}
                open={visible}
                width={320}
                className="dashboard-right-content-drawer"
              >
                {selectedItemActive && (
                  <DashboardRight
                    selectedDriverKey={selectedItemActive}
                    onClose={() => {
                      setSelectedItemActive({});
                      setVisible(false);
                    }}
                  />
                )}
              </Drawer>
            ) : (
              selectedItemActive &&
              !UtilObject.isEmpty(selectedItemActive) && (
                <DashboardRight
                  selectedDriverKey={selectedItemActive}
                  onClose={() => {
                    setSelectedItemActive({});
                  }}
                />
              )
            )}
          </div>
        )}
      </MainPage>
    </>
  );
};

export default Dashboard;
