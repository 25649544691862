import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import InputSearch from '../../components/antd/InputSearch';
import HeaderSpace from '../../components/HeaderSpace';
import MyFilterApply from '../../components/MyFilterApply';
import FilterIcon from '../../icons/svg/FilterIcon';
import MyDatePickerRange from '../../components/antd/MyDatePickerRange';
import ManifestsListPanel from './ManifestsListPanel';
import getText from '../../lang';
import SelectUserDrivers from '../../components/selects/SelectUserDrivers';
import useWindowSize from '../../utils/useWindowSize';
import MyCarousel from '../../components/myCarousel/MyCarousel';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import { PlusOutlined } from '@ant-design/icons';
import ApiStorage from '../../reducers/ApiStorage';
import { useSelector } from 'react-redux';
import utilDate from '../../utils/utilDate';
import './ManifestList.scss';

const ManifestList = (props) => {
  const { isMobile } = useWindowSize();
  const navigateApi = useNavigateApi();

  const filter = useSelector((state) => {
    const sta = state.AuthReducer.filterManifest;
    if (sta.dateRange) {
      sta.dateRange = [
        utilDate.getDate(sta.dateRange[0]),
        utilDate.getDate(sta.dateRange[1]),
      ];
      if (sta.fromDate) {
        sta.fromDate = utilDate.getDate(sta.fromDate);
      }
      if (sta.toDate) {
        sta.toDate = utilDate.getDate(sta.toDate);
      }
    }
    return sta;
  });

  // const [selectedDrivers, setSelectedDrivers] = useState(filter?.idUserDriver);
  // const [selectedDateRange, setSelectedDateRange] = useState(filter?.dateRange);
  // const [searchName, setSearchName] = useState('');
  const [visible, setVisible] = useState(false);
  const [showArchiveSection, setshowArchiveSection] = useState(false);

  const setFilter = (fil) => {
    const newFilter = Object.assign({}, filter, fil);
    ApiStorage.setFilterManifest(newFilter);
  };

  // const setFilterClear = () => {
  //   ApiStorage.setFilterManifest({});
  // };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const RenderFilter = () => {
    return (
      <>
        <MyDatePickerRange
          value={filter?.dateRange}
          defaultDateRange={[]}
          onChange={(e) => {
            setFilter({
              dateRange: e,
              fromDate: e && e.length ? e[0] : '',
              toDate: e && e.length ? e[1] : '',
            });
          }}
        />
        <SelectUserDrivers
          value={filter?.idUserDriver}
          withAll
          onChange={(e) => {
            setFilter({ idUserDriver: e });
          }}
        />
      </>
    );
  };

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH_PRESS_ENTER')}
          defaultValue={filter?.searchName}
          onPressEnter={(v) => setFilter({ searchName: v })}
          onClear={() => setFilter({ searchName: '' })}
        />

        {isMobile ? (
          <>
            <Button
              className="filter-button"
              type="filter"
              onClick={showDrawer}
            >
              <FilterIcon />
            </Button>
            <Drawer
              title={
                <MyFilterApply
                  onCancelClick={onClose}
                  onClick={onClose}
                  labelCancel={getText('WORD_CANCEL')}
                  labelPrimary={getText('WORD_FILTER_APPLY')}
                />
              }
              placement={'right'}
              closable={false}
              onClose={onClose}
              open={visible}
              key={'right'}
              width={300}
            >
              <RenderFilter />
            </Drawer>
          </>
        ) : (
          <RenderFilter />
        )}
        <Button
          type="primary"
          className="add-freight-btn"
          onClick={() => {
            // ApiStorage.setFilterAssign({});
            navigateApi('manifest/create');
          }}
        >
          {isMobile ? (
            <PlusOutlined />
          ) : (
            <span>{getText('WORD_ADD_FREIGHT')}</span>
          )}
        </Button>
      </HeaderSpace>
      <div className="my-manifest-lists-main-page">
        {isMobile ? (
          <MyCarousel
            infinite={true}
            items={[
              <ManifestsListPanel
                title={getText('WORD_PENDING_WAITING')}
                listType="PENDINGWAITING"
              />,
              <ManifestsListPanel
                title={getText('WORD_STARTED_ON_WAY')}
                listType="STARTEDONWAY"
              />,
              <ManifestsListPanel
                title={getText('WORD_DONE_ARCHIVE')}
                listType="DONEARCHIVE"
              />,
            ]}
          />
        ) : (
          <>
            <ManifestsListPanel
              title={getText('WORD_PENDING_WAITING')}
              listType="PENDINGWAITING"
            />
            {/* <ManifestsListPanel
              title={getText('WORD_PENDING')}
              listType="PENDING"
            />
            <ManifestsListPanel
              title={getText('WORD_WAITING')}
              listType="WAITING"
            /> */}
            <ManifestsListPanel
              title={getText('WORD_STARTED_ON_WAY')}
              listType="STARTEDONWAY"
            />

            {showArchiveSection ? (
              <ManifestsListPanel
                title={getText('WORD_DONE_ARCHIVE')}
                listType="DONEARCHIVE"
              />
            ) : (
              <div className="manifest-show-archive-section">
                <Button
                  className="add-freight-btn"
                  onClick={() => setshowArchiveSection((ov) => !ov)}
                >
                  Show archive
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ManifestList;
