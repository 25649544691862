import getText from '../lang';
import { SELECT_UNSELECT_NONE } from '../utils';
import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_SAVE = '/branchs/save';
const URL_GET_ONE = '/branchs/get_one';
const URL_GET_ALL = '/branchs/get_all';
const URL_PAGINATION_LIST = '/branchs/list';

const fs = {
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  save: async (values, idService = false) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idService) {
      result = await fetchFromUrlPOSTAsync(
        URL_SAVE + '/' + idService,
        urlParams
      );
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
  getList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_GET_ALL, urlParams);
    return result.success ? result.data : [];
  },
};

const loc = {
  getFullListOptionsAll: () => {
    return loc.getFullListOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_ALL_BRANCHES'),
    });
  },
  getFullListOptions: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_BRANCHES'),
    }
  ) => {
    let items = await fs.getList();
    const options = items.map((obj) => {
      return {
        value: obj.idBranch,
        label: obj.branchName,
        ...obj,
      };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
};

const BranchActions = Object.assign(fs, loc);

export default BranchActions;
