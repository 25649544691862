import { Button } from 'antd';
import React, { useState } from 'react';
import getText from '../../lang';
import { FileExcelOutlined } from '@ant-design/icons';
import SmsHistoryActions from '../../actions/SmsHistoryActions';

const SmsExportExcel = (props) => {
  const { filter, search } = props;

  const [loading, setloading] = useState(false);

  const onFinish = async () => {
    setloading(true);
    const localFilter = Object.assign({ page: 0, perPage: 10000 }, filter);
    let fetchParams = {
      page: localFilter?.page,
      perPage: localFilter?.perPage,
      sort: { createDate: 'DESC' },
      filter: filter,
      q: search || '',
    };
    let data = await SmsHistoryActions.listExcelFile(fetchParams);
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([data]));
    a.download = 'exported_sms_data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setloading(false);
  };

  return (
    <Button
      className="filter-button"
      icon={<FileExcelOutlined />}
      loading={loading}
      onClick={onFinish}
      type="filter"
    >
      {getText('WORD_EXPORT')}
    </Button>
  );
};

export default SmsExportExcel;
