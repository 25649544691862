import getText from './lang';

// function getRandomInt(min,max) {
//     return Math.floor(Math.random() * Math.floor((max - min) + min));
// }

import { SELECT_UNSELECT_NONE } from './utils';

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const Data = {
  // fillServiceList: async (page, perPage, filter = {}) => {
  fillServiceList: async (params = {}) => {
    let list = [];
    for (let i = 1; i <= 500; i++) {
      list.push({
        idService: i,
        name: 'Service ' + i,
        isActive: Boolean(getRandomInt(0, 2)),
        type: getRandomInt(1, 3),
        price: 10 * i,
        description: 'test Disc' + i,
      });
    }
    for (var key in params.filter) {
      if (
        params.filter[key] === SELECT_UNSELECT_NONE ||
        params.filter[key] === ''
      ) {
        delete params.filter[key];
      }
    }
    // let listF = list.filter((item) => {
    //     for (var key in params.filter) {
    //         if (key === 'name' && item.name.indexOf(params.filter.name) === -1) {
    //             return false;
    //         } else if (key !== 'name' && item[key] !== params.filter[key]) {
    //             return false;
    //         }
    //     }
    //     return true;
    // })
    // listF = listF.slice(params.perPage * (params.page+1), params.perPage * (params.page+1) + params.perPage);
    return {
      success: true,
      totalCount: list.length,
      // data: listF,
      data: list,
    };
  },
  fillUsersList: async (page, perPage, filter = {}) => {
    let list = [];
    for (let i = 1; i <= 200; i++) {
      list.push({
        idUser: i,
        roleName: 'Driver',
        fullName: 'Julie Cooper ' + i,
        isActive: Boolean(getRandomInt(0, 2)),
        transportType: getRandomInt(1, 4),
        transportTypeName: 'Car',
        transportID: '23424',
        phoneNumber: '094985051',
        email: 'nazariankhatchig@gmail.com',
      });
    }
    list[0].icon =
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80';
    list[1].icon = list[0].icon;
    // for (var key in filter) {
    //     if (filter[key] === SELECT_UNSELECT_NONE || filter[key] === '') {
    //         delete filter[key];
    //     }
    // }
    // let listF = list.filter((item) => {
    //     for (var key in filter) {
    //         if (key === 'name' && item.name.indexOf(filter.name) === -1) {
    //             return false;
    //         } else if (key !== 'name' && item[key] !== filter[key]) {
    //             return false;
    //         }
    //     }
    //     return true;
    // });

    // listF = listF.slice(perPage * (page - 1), perPage * (page - 1) + perPage);
    return {
      success: true,
      totalCount: list.length,
      data: list,
    };
  },
  fillUsersViewActivityList: async (filter = {}) => {
    let list = [];
    for (let i = 1; i <= 20; i++) {
      list.push({
        idUser: i,
        orderNumber: '#3867' + i,
        pickupName: 'Ave flower ' + i,
        dropoffName: 'Nicole Alinsiony ' + i,
        address: 'Vagharsh Vagharshyan Street, 12',
        date: '23/07/2020 19:11',
        service: 'Standart delivry',
      });
    }
    list[0].icon =
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80';
    list[1].icon = list[0].icon;
    for (var key in filter) {
      if (filter[key] === SELECT_UNSELECT_NONE || filter[key] === '') {
        delete filter[key];
      }
    }
    let listF = list.filter((item) => {
      for (var key in filter) {
        if (key === 'name' && item.name.indexOf(filter.name) === -1) {
          return false;
        } else if (key !== 'name' && item[key] !== filter[key]) {
          return false;
        }
      }
      return true;
    });

    // listF = listF.slice(perPage * (page - 1), perPage * (page - 1) + perPage);
    return {
      success: true,
      totalCount: list.length,
      data: listF,
    };
  },
  fillAddressList: async (page, perPage, filter = {}) => {
    let list = [];
    for (let i = 1; i <= 200; i++) {
      list.push({
        idAddress: i,
        fullName: 'Julie Cooper ' + i,
        email: 'nazariankhatchig@gmail.com',
        phoneNumber: '094985051',
        address: 'Armenia, Yerevan, Vagharsh Vagharshyan Street, 12',
      });
    }
    list[0].icon =
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80';
    list[1].icon = list[0].icon;
    for (var key in filter) {
      if (filter[key] === SELECT_UNSELECT_NONE || filter[key] === '') {
        delete filter[key];
      }
    }
    let listF = list.filter((item) => {
      for (var key in filter) {
        if (key === 'name' && item.name.indexOf(filter.name) === -1) {
          return false;
        } else if (key !== 'name' && item[key] !== filter[key]) {
          return false;
        }
      }
      return true;
    });

    listF = listF.slice(perPage * (page - 1), perPage * (page - 1) + perPage);
    return {
      success: true,
      totalCount: list.length,
      data: listF,
    };
  },
  languageOptions: () => {
    return [
      { label: 'English', value: 'en' },
      { label: getText('WORD_ARMENIAN'), value: 'hy' },
    ];
  },
  // userRoleOptions: (first = 'Select') => {
  //     return [
  //         { label: `${first} roles`, value: SELECT_UNSELECT_NONE },
  //         { label: "Super Admin", value: 1 },
  //         { label: "Admin", value: 2},
  //         { label: "Operator", value: 3 },
  //         { label: "Driver", value: 4 },
  //     ]
  // },
  // userTransportTypeOptions: (first = 'Select') => {
  //     return [
  //         { label: `${first} transport type`, value: SELECT_UNSELECT_NONE },
  //         { label: "Car", value: 1 },
  //         { label: "Walk", value: 2 },
  //         { label: "Bicikle", value: 3 },
  //         { label: "Track", value: 4 },
  //     ]
  // },
  servicesOptions: (first = 'Select') => {
    return [
      { label: `${first} service`, value: SELECT_UNSELECT_NONE },
      { label: 'One Way', value: 1 },
      { label: 'Two Way', value: 2 },
    ];
  },
  policyOptions: (first = 'Select') => {
    return [
      { label: `${first} Policy`, value: SELECT_UNSELECT_NONE },
      { label: 'admin', value: 1 },
    ];
  },
  // countryOptions: (first = 'Select') => {
  //     return [
  //         { label: `${first} service`, value: SELECT_UNSELECT_NONE },
  //         { label: 'Armenia', value: 1 },
  //         { label: 'Georgia', value: 2 },
  //     ]
  // },
  // currencyOptions: (first = 'Select') => {
  //     return [
  //         { label: `${first} service`, value: SELECT_UNSELECT_NONE },
  //         { label: 'AMD', value: 1 },
  //         { label: '$', value: 2 },
  //     ]
  // },
};

export default Data;
