import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import InputFormEmail from '../../components/antd/InputFormEmail';
import SelectForm from '../../components/antd/SelectForm';
import MyPanelFooter from '../../components/MyPanelFooter';
import Data from '../../Data';
import InputFormText from '../../components/antd/InputFormText';
import { Form } from 'antd';
import InputFormPhoneNumber from '../../components/antd/InputFormPhoneNumber';
// import CountryActions from '../../actions/CountryActions';
import UserActions from '../../actions/UserActions';
import { notifySuccess, notifyError } from '../../components/notify';
import TransportTypeActions from '../../actions/TransportTypeActions';
import getText from '../../lang';
import MyRow from '../../components/MyRow';
import InputFormNumber from '../../components/antd/InputFormNumber';
import ProfileAvatarFormS3 from '../../components/antd/ProfileAvatarFormS3';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import { useSelector } from 'react-redux';
import useWindowSize from '../../utils/useWindowSize';

const AccountSettings = (props) => {
  const [form] = Form.useForm();
  const navigateApi = useNavigateApi();
  const { isMobile } = useWindowSize();

  const currentUser = useSelector((state) => {
    return state.AuthReducer?.currentUser;
  });

  // const [listCountries, setListCountries] = useState([]);
  const [listTransportTypes, setListTransportTypes] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    function fill() {
      // setListCountries(CountryActions.getFullListOptions());
      setListTransportTypes(TransportTypeActions.getFullListOptions());
    }
    fill();
  }, []);

  // useEffect(() => {
  //   async function fill() {
  //     let cnt = listCountries.find((country) => {
  //       return currentUser && country.phoneCode === currentUser.phoneCode;
  //     });
  //     setSelectedCountry(cnt);
  //   }
  //   fill();
  // }, [listCountries, currentUser]);

  const onFinish = async (values) => {
    setSaving(true);
    let result = await UserActions.saveAccountSetting(values);
    setSaving(false);
    if (result.success) {
      notifySuccess(getText('WORD_CHANGED_PROFILE_SETTINGS_SUCCESS'));
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
    setSaving(false);
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock {...props} title={getText('WORD_ACCOUNT_SETTINGS_TITLE')}>
        <MyForm
          form={form}
          name="accountSettingsForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          withoutMarginLeft
          initialValues={
            currentUser
              ? {
                  icon: currentUser.icon,
                  fullName: currentUser.fullName,
                  email: currentUser.email,
                  login: currentUser.login,
                  phoneCode: currentUser.phoneCode,
                  phoneNumber: currentUser.phoneNumber,
                  lang: currentUser.lang,
                  idTransportType: currentUser.idTransportType,
                  transportNumber: currentUser.transportNumber,
                  transportDescription: currentUser.transportDescription,
                  transportKg: currentUser.transportKg,
                  transportM3: currentUser.transportM3,
                }
              : {
                  transportKg: 0,
                  transportM3: 0,
                }
          }
        >
          <div className="user-edit-icon-title">
            <ProfileAvatarFormS3
              name="icon"
              label={getText('WORD_AVATAR')}
              imageUrl={form.getFieldValue('icon') || ''}
              value={currentUser?.fullName || ''}
              onChange={(img) => {
                form.setFieldsValue({ icon: img });
              }}
              onClear={() => {
                form.setFieldsValue({ icon: '' });
              }}
            />
          </div>
          <p style={{ marginLeft: 6 }}>{currentUser?.roleName || ''}</p>
          <InputFormText
            name="fullName"
            label={getText('WORD_USER_NAME')}
            minLength={3}
            required
          />
          <InputFormEmail
            name="email"
            label={getText('WORD_EMAIL')}
            disabled={true}
            required={false}
          />
          <InputFormText
            name="login"
            label={getText('WORD_USER_LOGIN')}
            required
          />
          <InputFormPhoneNumber
            name="phoneNumber"
            nameCode="phoneCode"
            label={getText('WORD_TEL')}
            length={8}
            disabledCode={true}
            // length={selectedCountry ? selectedCountry.phoneCodeLength : 15}
            // options={listCountries?.map((it) => {
            //   return { label: it.phoneCode, value: it.phoneCode };
            // })}
            // onChangeCode={(codeItem) => {
            //   let country = listCountries.find((countery) => {
            //     return countery.phoneCode === codeItem.value;
            //   });
            //   if (country) {
            //     setSelectedCountry(country);
            //     form.validateFields(['phoneNumber'], { force: true });
            //   }
            // }}
            required
          />
          <SelectForm
            label={getText('WORD_LANGUAGE')}
            name="lang"
            options={Data.languageOptions()}
            showSearch={true}
            required
          />
          {currentUser && currentUser.idRole === UserActions.ROLE.DRIVER && (
            <>
              <SelectForm
                label={getText('WORD_TRANSPORT_TYPE')}
                name="idTransportType"
                options={listTransportTypes}
                showSearch={true}
                required
              />
              <InputFormText
                label={getText('WORD_TRANSPORT_ID')}
                name="transportNumber"
              />
              <InputFormText
                label={getText('WORD_TRANSPORT')}
                name="transportDescription"
              />
            </>
          )}
          <MyRow>
            <InputFormNumber
              name="transportKg"
              label={getText('WORD_AMOUNT_DELEVERY')}
              min={0}
              required
              hidden={
                currentUser && currentUser.idRole !== UserActions.ROLE.DRIVER
              }
            />
            <InputFormNumber
              name="transportM3"
              label={getText('WORD_AMOUNT_DELEVERY')}
              min={0}
              required
              hidden={
                currentUser && currentUser.idRole !== UserActions.ROLE.DRIVER
              }
            />
          </MyRow>
          <div
            onClick={() => {
              navigateApi('change_password');
            }}
            className="link-path"
          >
            {getText('WORD_CHANGE_PASSWORD')}
          </div>
          <MyPanelFooter
            formName={'accountSettingsForm'}
            hideCancel
            labelPrimary={getText('WORD_SAVE')}
            labelCancel={getText('WORD_CANCEL')}
            loading={saving}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default AccountSettings;
