import React, { useEffect, useState } from 'react';
import SelectForm from '../antd/SelectForm';
import getText from '../../lang';

const SelectSmsStatus = (props) => {
  const {
    label,
    name,
    showSearch,
    required,
    onChangeItem,
    onChange,
    hasFeedback,
  } = props;

  const [listData, setListData] = useState([]);

  useEffect(() => {
    let fill = () => {
      let result = [
        { label: getText('WORD_SELECT_ALL'), value: '' },
        { label: getText('WORD_FAILED'), value: '0' },
        { label: getText('WORD_SUCCESS'), value: '1' },
      ];
      setListData(result);
    };
    fill();
  }, []);

  return (
    <SelectForm
      className="selectBranch"
      label={label}
      name={name}
      options={listData}
      showSearch={showSearch}
      required={required}
      onChangeItem={onChangeItem}
      onChange={onChange}
      hasFeedback={hasFeedback}
    />
  );
};

export default SelectSmsStatus;
