import { SELECT_UNSELECT_NONE } from '../utils';
import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { fetchFromUrlGETExternal } from '../utils/UrlHelperExternal';
import getText from '../lang';

const URL_SAVE = '/polygons/save';
const URL_GET_ONE = '/polygons/get_one';
const URL_PAGINATION_LIST = '/polygons/list';
const URL_DELETE = '/polygons/delete';
const URL_LIST = '/polygons/get_all';

const fs = {
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  save: async (values, idKey = false) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idKey) {
      result = await fetchFromUrlPOSTAsync(URL_SAVE + '/' + idKey, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  getFullList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  delete: async (idKay) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_DELETE + '/' + idKay,
      urlParams
    );
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
  findAllFromExtrnal: async (search) => {
    let result = await fetchFromUrlGETExternal(
      `https://nominatim.openstreetmap.org/search.php?q=${search}&polygon_geojson=1&format=json`
    );
    if (result.success) {
      let data = result.data.find((it) => {
        return it.geojson.type === 'Polygon' && it.class === 'boundary';
      });
      if (data) {
        data = {
          class: data.class,
          display_name: data.display_name,
          coordinates: data.geojson?.coordinates[0].map((it) => {
            return [it[1], it[0]];
          }),
        };
      }
      return { success: Boolean(data), data: data };
    }
    return { success: false };
  },
};

const loc = {
  STATUS: {
    ACTIVE: 1,
  },
  getOptions: async () => {
    let list = [];
    let result = await fs.getFullList();
    if (result.success) {
      list = result.data.map((it) => {
        return { label: it.name, value: it.idPolygon };
      });
      list.unshift({
        label: getText('WORD_ALL_BUSINESS_ZONES'),
        // label: getText('WORD_ALL'),
        // !old way in case need to change ^
        value: SELECT_UNSELECT_NONE,
      });
      list.push({ label: getText('WORD_OTHER'), value: -1 });
    }
    return list;
  },
};

const PolygonsActions = Object.assign(fs, loc);

export default PolygonsActions;
