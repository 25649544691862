import React from 'react';
import MyRow from '../MyRow';
import getText from '../../lang';
import './style.scss';

const MyFilterApply = (props) => {
  const { onCancelClick, labelCancel, onApplyClick, labelPrimary } = props;

  return (
    <MyRow className="my-filter-apply-texts">
      <div onClick={onCancelClick} className="title">
        {labelCancel}
      </div>
      <div className="title" onClick={onApplyClick}>
        {labelPrimary}
      </div>
    </MyRow>
  );
};

MyFilterApply.defaultProps = {
  labelPrimary: getText('WORD_RESET'),
  labelCancel: getText('WORD_CANCEL'),
};

export default MyFilterApply;
