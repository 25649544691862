import React, { useEffect, useState } from 'react';
import UserActions from '../../actions/UserActions';
import { SELECT_UNSELECT_NONE, TABLE_FETCH_PAGE_SIZE } from '../../utils';
import getText from '../../lang';
import MainPage from '../../components/MainPage';
import MyGrid from '../../components/antd/MyGrid';
import MySelect from '../../components/antd/MySelect';
import HeaderSpace from '../../components/HeaderSpace';
import MyFilterApply from '../../components/MyFilterApply';
import InputSearch from '../../components/antd/InputSearch';
import SelectUserRole from '../../components/selects/SelectUserRole';
import SelectUserTransportType from '../../components/selects/SelectUserTransportType';
import { notifyError } from '../../components/notify';
import UserListCard from './userListCard/UserListCard';
import FilterIcon from '../../icons/svg/FilterIcon';
import { Button, Drawer } from 'antd';
import useIsMounted from '@utilityjs/use-is-mounted';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import { PlusOutlined } from '@ant-design/icons';
import './style.scss';

const UserList = (props) => {
  const isMounted = useIsMounted();
  const navigateApi = useNavigateApi();

  const { isForMonitoring } = props;

  const { isMobile } = useWindowSize();

  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedTransportType, setSelectedTransportType] =
    useState(SELECT_UNSELECT_NONE);
  const [selectedStatus, setSelectedStatus] = useState(SELECT_UNSELECT_NONE);
  const [idRole, setidRole] = useState(SELECT_UNSELECT_NONE);
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [visibleFilter, setVisibleFilter] = useState(false);

  useEffect(() => {
    const fillDataFS = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {},
        filter: {
          status: selectedStatus,
          idTransportType: selectedTransportType,
          idRole: (isForMonitoring && UserActions.ROLE.DRIVER) || idRole,
        },
        q: searchName || '',
      };

      let result = await UserActions.fillPaginationList(fetchParams);
      if (isMounted()) {
        if (result.success) {
          setListData(result.data);
          setTotalCount(result.totalRows);
        } else {
          notifyError(result.errMsg);
        }
        setLoadingData(false);
      }
    };
    fillDataFS();
  }, [
    isForMonitoring,
    isMounted,
    selectedTransportType,
    selectedStatus,
    idRole,
    page,
    perPage,
    searchName,
  ]);

  const showDrawer = () => {
    setVisibleFilter(true);
  };

  const onClose = () => {
    setVisibleFilter(false);
  };

  const RenderFilter = () => {
    return (
      <>
        {!isForMonitoring && (
          <SelectUserRole
            name="selectRole"
            value={idRole}
            onChange={setidRole}
            withAll
          />
        )}
        <SelectUserTransportType
          name="selectTransportType"
          value={selectedTransportType}
          showSearch={false}
          onChange={(e) => setSelectedTransportType(e)}
          withAll
        />
        <MySelect
          name="selectStatus"
          defaultValue={SELECT_UNSELECT_NONE}
          options={UserActions.getStatusOptionsAll()}
          value={selectedStatus}
          showSearch={false}
          onChange={(e) => setSelectedStatus(e)}
          placeholder={getText('WORD_SELECT_ALL_STATUSES')}
        />
      </>
    );
  };

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onChange={(v) => setSearchName(v)}
          onClear={() => setSearchName('')}
        />
        {isMobile ? (
          <>
            <Button
              icon={<FilterIcon />}
              className="filter-button"
              type="filter"
              onClick={showDrawer}
            >
              {getText('WORD_FILTERS')}
            </Button>
            <Drawer
              title={
                <MyFilterApply
                  onCancelClick={onClose}
                  onClick={onClose}
                  labelCancel={getText('WORD_CANCEL')}
                  labelPrimary={getText('WORD_FILTER_APPLY')}
                />
              }
              placement={'right'}
              closable={false}
              onClose={onClose}
              open={visibleFilter}
              key={'right'}
              width={300}
            >
              <RenderFilter />
            </Drawer>
          </>
        ) : (
          <RenderFilter />
        )}
        {!isForMonitoring && (
          <Button
            type="primary"
            onClick={() => {
              navigateApi('users/create');
            }}
          >
            {isMobile ? (
              <PlusOutlined />
            ) : (
              <span>{getText('WORD_ADD_USER')}</span>
            )}
          </Button>
        )}
      </HeaderSpace>
      <MainPage
        title={
          isForMonitoring
            ? getText('WORD_DRIVERS')
            : getText('WORD_TEAM_LIST_TITLE')
        }
        topZero
      >
        <MyGrid
          loading={loadingData}
          data={listData}
          pagination={true}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          // style={{ position: 'relative', bottom: 30 }}
          renderItem={(item, index) => {
            return <UserListCard key={index} item={item} {...props} />;
          }}
        />
      </MainPage>
    </>
  );
};

export default UserList;
