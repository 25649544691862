import React, { useEffect, useState } from 'react';
import PolygonsActions from '../../actions/PolygonsActions';
import getText from '../../lang';
import MySelect from '../antd/MySelect';

const SelectPolygon = (props) => {
  const { withAll } = props;

  const [listData, setListData] = useState([]);

  useEffect(() => {
    const fill = async () => {
      let options = await PolygonsActions.getOptions();
      setListData(options);
    };
    fill();
  }, []);

  return (
    <MySelect
      {...props}
      options={listData}
      placeholder={
        withAll
          ? getText('WORD_ALL_BUSINESS_ZONES')
          : getText('WORD_SELECT_BUSINESS_ZONE')
      }
      name="idPolygon"
    />
  );
};

export default SelectPolygon;
