import React from 'react';
import { DatePicker } from 'antd';
import './style.scss';

const InputDateTimePicker = (props) => {
  const {
    value,
    label,
    placeholder,
    format,
    allowClear,
    disabledDate,
    onChange,
    onOk,
  } = props;

  return (
    <DatePicker
      className="input-time-picker"
      value={value}
      placeholder={label ? label : placeholder}
      format={format}
      allowClear={allowClear}
      disabledDate={disabledDate}
      onChange={onChange}
      onOk={onOk}
      showSecond={false}
      showTime
    />
  );
};

InputDateTimePicker.defaultProps = {
  placeholder: 'Select Date Time',
  allowClear: true,
  format: 'DD/MM/YYYY hh:mm',
};

export default InputDateTimePicker;
