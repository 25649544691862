import React from 'react';
import { Button, Result } from 'antd';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const Error401Page = () => {
  const navigateApi = useNavigateApi();
  return (
    <Result
      status="500"
      title="Oops"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => navigateApi(-1)}>
          Back Home
        </Button>
      }
    />
  );
};

export default Error401Page;
