import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import UserListCard from './userListCard/UserListCard';
import MyMap from '../../components/MyMap';
import UserViewListCard from './UserViewListCard';
import DraggableList from '../../components/antd/DraggableList';
import { useParams } from 'react-router-dom';
import UserActions from '../../actions/UserActions';
import OrderActions from '../../actions/OrderActions';
import { notifyError } from '../../components/notify';
import MainPage from '../../components/MainPage';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import { useSelector } from 'react-redux';

const UserView = (props) => {
  let { _id } = useParams();
  const navigateApi = useNavigateApi();
  const { isMobile, isDesktop } = useWindowSize();
  const [item, setItem] = useState({});

  const menuOpen = useSelector((state) => state.AuthReducer?.menuOpen);

  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('Pending');

  useEffect(() => {
    const fill = async () => {
      let fetchParams = {
        page: 0,
        perPage: 100,
        sort: {},
        filter: {
          status:
            selectedStatus === 'Pending'
              ? OrderActions.STATUS.ACTIVE
              : OrderActions.STATUS.FINISHED,
          idUserDriver: item.idUser,
        },
        q: '',
      };
      setLoadingData(true);
      let result = await OrderActions.fillPaginationList(fetchParams);
      setLoadingData(false);
      if (result.success) {
        setListData(result.data);
      }
    };
    setTimeout(() => {
      fill();
    }, 500);
  }, [item, selectedStatus]);

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await UserActions.getOneByidKey(_id);
        if (resultGet.success) {
          setItem(resultGet.data);
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('users/list');
        }
      }
    };
    getObj();
  }, [_id, navigateApi]);

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <div
        style={isMobile ? { marginTop: '0' } : { marginTop: '20px' }}
        className="user-list-card-with-map"
      >
        <UserListCard item={item} disableView={true} userView />
        <div
          className={`user-view-map-wrapper ${
            menuOpen && isDesktop ? 'menu-open-for-map' : ''
          }`}
        >
          <MyMap height={195} />
        </div>
      </div>
      <MainPage title={getText('WORD_ACTIVITY')} page>
        <div className="user-view-activity-wrapper">
          <div className="user-view-activity-header">
            <div
              className={
                'user-view-activity-header-item ' +
                (selectedStatus === 'Pending' && 'active')
              }
              onClick={() => {
                setSelectedStatus('Pending');
              }}
            >
              {getText('WORD_PENDING')}
            </div>
            <div
              className={
                'user-view-activity-header-item ' +
                (selectedStatus === 'Archive' && 'active')
              }
              onClick={() => {
                setSelectedStatus('Archive');
              }}
            >
              {getText('WORD_ARCHIVE')}
            </div>
          </div>
          <DraggableList
            data={listData}
            loading={loadingData}
            renderItem={(item, index) => {
              return <UserViewListCard key={index} item={item} />;
            }}
          />
        </div>
      </MainPage>
    </>
  );
};

export default UserView;
