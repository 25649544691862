import React, { useState } from 'react';
import { TwitterPicker } from 'react-color';
import { Form } from 'antd';

import './style.scss';
const MyColorPickerForm = (props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const { name, label, required, message, type, onChange } = props;

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: required, message: message, type: type }]}
      valuePropName="color"
      getValueFromEvent={(e) => {
        return Boolean(e && e.hex) ? e.hex : '';
      }}
    >
      {displayColorPicker ? (
        <TwitterPicker
          style={{ position: 'absolute', zIndex: '2' }}
          onChangeComplete={(e) => {
            onChange && onChange(e);
            setDisplayColorPicker(false);
          }}
        />
      ) : (
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const value = getFieldValue(name);
            return (
              <div
                className="divSelectedcolor"
                style={{
                  backgroundColor: value && value,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDisplayColorPicker(!displayColorPicker);
                }}
              ></div>
            );
          }}
        </Form.Item>
      )}
    </Form.Item>
  );
};

MyColorPickerForm.defaultProps = {
  name: 'colorPicker',
  placeholder: 'colorPicker',
  message: 'Please Select Color',
  required: true,
  hasFeedback: true,
  allowClear: false,
};

export default MyColorPickerForm;
