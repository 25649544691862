import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.004"
    height="16"
    viewBox="0 0 16.004 16"
  >
    <g id="noun_Settings_2814372" transform="translate(-9 -9)" opacity="0.8">
      <g id="Group_6923" data-name="Group 6923" transform="translate(9 9)">
        <path
          id="Path_5675"
          data-name="Path 5675"
          d="M24.356,15.828h-.62a.642.642,0,0,1-.618-.48,6.289,6.289,0,0,0-.626-1.512.64.64,0,0,1,.1-.776l.437-.437a.64.64,0,0,0,0-.905l-.751-.751a.64.64,0,0,0-.905,0l-.437.437a.64.64,0,0,1-.776.1,6.322,6.322,0,0,0-1.512-.626.637.637,0,0,1-.476-.616v-.62A.64.64,0,0,0,17.529,9H16.467a.64.64,0,0,0-.64.64v.62a.642.642,0,0,1-.48.618,6.289,6.289,0,0,0-1.512.626.64.64,0,0,1-.776-.1l-.437-.437a.64.64,0,0,0-.905,0l-.751.751a.64.64,0,0,0,0,.905l.437.437a.64.64,0,0,1,.1.776,6.322,6.322,0,0,0-.626,1.512.637.637,0,0,1-.616.476h-.62a.64.64,0,0,0-.638.64v1.061a.64.64,0,0,0,.64.64h.62a.642.642,0,0,1,.618.48A6.289,6.289,0,0,0,11.5,20.16a.64.64,0,0,1-.1.776l-.437.437a.64.64,0,0,0,0,.905l.751.751a.64.64,0,0,0,.905,0l.437-.437a.64.64,0,0,1,.776-.1,6.322,6.322,0,0,0,1.512.626.642.642,0,0,1,.48.618v.62a.64.64,0,0,0,.64.64h1.061a.64.64,0,0,0,.64-.64v-.62a.642.642,0,0,1,.48-.618,6.289,6.289,0,0,0,1.512-.626.64.64,0,0,1,.776.1l.437.437a.64.64,0,0,0,.905,0l.751-.751a.64.64,0,0,0,0-.905l-.437-.437a.64.64,0,0,1-.1-.776,6.322,6.322,0,0,0,.626-1.512.642.642,0,0,1,.618-.48h.62a.64.64,0,0,0,.64-.64V16.467A.652.652,0,0,0,24.356,15.828ZM17,21.68A4.682,4.682,0,1,1,21.68,17,4.683,4.683,0,0,1,17,21.68Z"
          transform="translate(-9 -9)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const SettingsIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default SettingsIcon;
