import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlPOSTAsyncFile,
} from '../utils/UrlHelper';

const URL_PAGINATION_LIST = '/reviews/list';
const URL_LIST_EXCEL = '/reviews/list-excel';

const fs = {
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
  listExcelFile: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    return await fetchFromUrlPOSTAsyncFile(URL_LIST_EXCEL, urlParams);
  },
};

const loc = {};

const RatingActions = Object.assign(fs, loc);

export default RatingActions;
