import React from 'react';
import getText from '../../../lang';
import { formatPhoneNumber } from '../../../utils';
import MyRow from '../../../components/MyRow';
import MyField from '../../../components/MyField';
import UserActions from '../../../actions/UserActions';
import ProfileAvatar from '../../../components/antd/ProfileAvatar';
import EditIcon from '../../../icons/svg/EditIcon';
import useNavigateApi from '../../../AppRoute/useNavigateApi';
import './style.scss';

const UserListCard = (props) => {
  const navigateApi = useNavigateApi();

  const { disableView, item, isForMonitoring } = props;

  return (
    <div className="my-card users-list-card team" style={{ cursor: 'pointer' }}>
      <div
        onClick={(e) => {
          if (!disableView) {
            e.stopPropagation();
            navigateApi('users/view/' + item.idKey);
          }
        }}
      >
        <MyRow className="avatar-name">
          <ProfileAvatar icon={item?.icon} value={item?.fullName} />
          <div className="user-card-name-type-wrapper">
            <div className="transport-type-name-div">
              {item?.roleName && getText(item?.roleName)}
            </div>
            <div className="with-edit-button">
              <div className="user-name-div">{item?.fullName}</div>
              {Boolean(item?.idRole !== UserActions.ROLE.SUPERADMIN) &&
                !isForMonitoring && (
                  <div
                    className="edit-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigateApi('users/edit/' + item?.idKey);
                    }}
                  >
                    <EditIcon />
                  </div>
                )}
            </div>
          </div>
        </MyRow>
        <MyField
          fieldValuesList={[
            {
              label: getText('WORD_TEL'),
              rightItem: formatPhoneNumber(item?.phoneCode, item?.phoneNumber),
            },
            {
              label: getText('WORD_TRANSPORT'),
              rightItem:
                item?.transportTypeName || item?.transportNumber
                  ? item?.transportTypeName + ' | ' + item?.transportNumber
                  : '',
            },
          ]}
        />
      </div>
    </div>
  );
};

UserListCard.defaultProps = {
  item: {},
};

export default UserListCard;
