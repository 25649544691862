import ApiStorage from '../reducers/ApiStorage';
import { DEFAULT_LANGUAGE } from '../utils';

export const LANGS = ['en', 'hy'];

export default function getText(textKey, returnSame) {
  let userLang = ApiStorage.getLanguage();
  if (!userLang || !LANGS.includes(userLang)) {
    userLang = DEFAULT_LANGUAGE;
  }
  const langData = module.require(`./${userLang}`).default;
  let str = langData[textKey];
  return str ? str : returnSame ? textKey : `<${textKey}>`;
}
