import React, { useEffect, useState } from 'react';
import getText from '../../lang';
import RatingActions from '../../actions/RatingActions';
import { TABLE_FETCH_PAGE_SIZE } from '../../utils';
import HeaderSpace from '../../components/HeaderSpace';
import InputSearch from '../../components/antd/InputSearch';
import MainPage from '../../components/MainPage';
import RatingListFilterDrawer from './RatingListFilterDrawer';
import RatingListCard from './RatingListCard';
import FilterIcon from '../../icons/svg/FilterIcon';
import { Button, Space, Statistic } from 'antd';
import MyListSection from '../../components/myList/MyListSection';
import RatingExportExcel from './RatingExportExcel';
import utilDate from '../../utils/utilDate';
import { MehOutlined } from '@ant-design/icons';
import './style.scss';

const RatingList = (props) => {
  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [perPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [filterData, setFilterData] = useState({
    fromDate: utilDate.getThisMonthStart(),
    toDate: utilDate.getThisMonthEnd(),
    fromRating: 0,
    toRating: 5,
    idUserDriver: undefined,
  });
  const [visibleDrawer, setVisibleDrawer] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: { createDate: 'DESC' },
        filter: filterData,
        q: searchName || '',
      };
      let result = await RatingActions.fillPaginationList(fetchParams);
      console.log('result = ', result);
      if (result.success) {
        setTotalCount(result.totalRows);
        if (page > 0) {
          setListData((ov) => {
            let list = ov.concat(result.data);
            return list;
          });
        } else {
          setListData(result.data);
        }
      }
      setLoadingData(false);
    };
    fill();
  }, [searchName, page, perPage, filterData]);

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const handleLoadMore = async () => {
    if (totalCount !== listData.length && !loadingData) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onChange={(v) => setSearchName(v)}
        />
        <Button
          className="filter-button"
          icon={<FilterIcon />}
          onClick={showDrawer}
          type="filter"
        >
          {getText('WORD_FILTERS')}
        </Button>
        <RatingListFilterDrawer
          showDrawer={showDrawer}
          onCloseDrawer={onCloseDrawer}
          visibleDrawer={visibleDrawer}
          onChangeFilterData={(fil) => {
            setPage(0);
            setFilterData(fil);
          }}
          filter={filterData}
        />
        <RatingExportExcel search={searchName} filter={filterData} />
      </HeaderSpace>
      <MainPage
        title={
          <Space>
            {getText('WORD_ORDER_RATINGS')}
            <Statistic value={totalCount} />
          </Space>
        }
        topZero
      >
        <MyListSection
          loading={loadingData}
          totalCount={totalCount}
          dataSource={listData}
          onLoadMore={handleLoadMore}
          renderItem={(item, index) => {
            return (
              <RatingListCard
                {...props}
                key={JSON.stringify(item)}
                item={item}
              />
            );
          }}
        />
      </MainPage>
    </>
  );
};

export default RatingList;
