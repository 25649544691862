import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthReducer from '../reducers/AuthReducer';

const reducers = combineReducers({
  AuthReducer,
});

const persistConfig = {
  key: 'root3',
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
