import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserActions from '../actions/UserActions';
import { notifySuccess, notifyError } from '../components/notify';
import useNavigateApi from '../AppRoute/useNavigateApi';

const UserEmailConfirm = (props) => {
  let { _id } = useParams();
  const navigateApi = useNavigateApi();

  useEffect(() => {
    function check() {
      setTimeout(async () => {
        let resultConfirm = await UserActions.confirmEmail(_id);
        if (resultConfirm.success) {
          notifySuccess('Success Activated Your Account');
          if (resultConfirm.data.magicToken) {
            let result = await UserActions.magicLinkLogin(
              resultConfirm.data.magicToken
            );
            if (result.success) {
              navigateApi('home');
            }
          }
        } else {
          notifyError(resultConfirm.errMsg);
          navigateApi('login');
        }
      }, 1500);
    }
    check();
  }, [_id, navigateApi]);

  return (
    <div className="login-page-wrapper">
      <img
        height="300px"
        alt="Loading"
        src="https://i.pinimg.com/originals/45/12/4d/45124d126d0f0b6d8f5c4d635d466246.gif"
      />
    </div>
  );
};

export default UserEmailConfirm;
