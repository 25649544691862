import React from 'react';
import useWindowSize from '../../utils/useWindowSize';
import './style.scss';
import './styleResponsive.scss';

const MainPage = (props) => {
  const { isMobile } = useWindowSize();

  const { className, topZero, title, children, bottomZero } = props;

  return (
    <div
      className={`main-page ${className || ''}`}
      style={isMobile ? { paddingTop: 0 } : { paddingTop: topZero ? 0 : 20 }}
    >
      {title && (
        <h6
          className="my-block-title"
          style={{ marginBottom: bottomZero ? 0 : 15 }}
        >
          {title}
        </h6>
      )}
      {children}
    </div>
  );
};

export default MainPage;
