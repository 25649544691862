import React from 'react';
import getText from '../../lang';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

const InputPassword = (props) => {
  const {
    label,
    name,
    hasFeedback,
    required,
    message,
    placeholder,
    value,
    onChange,
    allowClear,
    autoComplete,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[{ required: required, message: getText(message) }]}
    >
      <Input.Password
        placeholder={label ? label : placeholder}
        prefix={<LockOutlined />}
        value={value}
        onChange={onChange}
        allowClear={allowClear}
        autoComplete={autoComplete}
      />
    </Form.Item>
  );
};

InputPassword.defaultProps = {
  name: 'password',
  placeholder: 'Password',
  message: 'WORD_PLEASE_INPUT_PASSWORD',
  required: true,
  hasFeedback: true,
  allowClear: true,
  autoComplete: 'on',
};

export default InputPassword;
