import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      id="Search_Icon"
      data-name="Search Icon"
      d="M11.113,9.826a5.93,5.93,0,0,0,1.225-3.652A6.142,6.142,0,0,0,6.213,0,6.217,6.217,0,0,0,0,6.174a6.217,6.217,0,0,0,6.213,6.174A5.833,5.833,0,0,0,9.888,11.13l2.625,2.609a.849.849,0,0,0,1.225,0,.837.837,0,0,0,0-1.217Zm-4.9.7A4.394,4.394,0,0,1,1.75,6.174a4.463,4.463,0,0,1,8.925,0A4.394,4.394,0,0,1,6.213,10.522Z"
      fill="#474657"
    />
  </svg>
);

const SearchIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default SearchIcon;
