import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import BranchActions from '../../actions/BranchActions';
import InputSearch from '../../components/antd/InputSearch';
import MyGrid from '../../components/antd/MyGrid';
import HeaderSpace from '../../components/HeaderSpace';
import MainPage from '../../components/MainPage';
import getText from '../../lang';
import { SELECT_UNSELECT_NONE, TABLE_FETCH_PAGE_SIZE } from '../../utils';
import BranchListCard from './BranchListCard';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const BranchList = (props) => {
  const navigateApi = useNavigateApi();

  const [searchName, setSearchName] = useState('');
  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedStatus] = useState(SELECT_UNSELECT_NONE);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {},
        filter: {
          status: selectedStatus,
        },
        q: searchName || '',
      };
      let result = await BranchActions.fillPaginationList(fetchParams);
      if (result.success) {
        setListData(result.data);
        setTotalCount(result.totalRows);
      }
      setLoadingData(false);
    };
    fill();
  }, [selectedStatus, searchName, page, perPage]);

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onChange={(v) => setSearchName(v)}
        />
        <Button
          type="primary"
          onClick={() => {
            navigateApi('branch/create');
          }}
        >
          {getText('WORD_ADD_BRANCH')}
        </Button>
      </HeaderSpace>
      <MainPage title={getText('WORD_BRANCH')} topZero>
        <MyGrid
          loading={loadingData}
          // style={{ height: 'calc(100% - 20px)' }}
          data={listData}
          pagination={true}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          renderItem={(item) => {
            return <BranchListCard item={item} />;
          }}
        />
      </MainPage>
    </>
  );
};

export default BranchList;
