import md5 from 'md5';
import ApiStorage from '../reducers/ApiStorage';
import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';
import UtilDate from '../utils/utilDate';
import getText from '../lang';
import ApiLocalStorage from '../reducers/ApiLocalStorage';

const URL_LOGIN = '/users/login';
// const URL_Register = '/users/register';
const URL_ForgotPassword = '/users/forgot_password';
const URL_Logout = '/users/logout';
const URL_ConfirmEmail = '/users/confirm';
const URL_ChangePassword = '/users/change_password';
const URL_MagicLogin = '/users/magic_login';
const URL_ChangeAccountSettings = '/users/account_settings';
const URL_SAVE = '/users/save';
const URL_PAGINATION_LIST = '/users/list';
const URL_PAGINATION_LIST_DRIVERS_WITH_MANIFEST =
  '/users/drivers/list_with_manifests';
const URL_GET_ONE = '/users/get_one';
const URL_GET_ONE_BY_ID = '/users/get_one_byId';
const URL_GET_DRIVER_ALL = '/users/drivers/get_all';
const URL_CHANGE_DRIVER_PACKING_STATUS = '/users/driver/change_packing_status';
const URL_TOKENS_GET_PACKING = '/tokens/get_packing';

const URL_TEST = '/users/test';

const fs = {
  // register: async (saveData) => {
  //   saveData.password = md5(saveData.password);
  //   const urlParams = [{ key: 'saveData', value: saveData }];
  //   const result = await fetchFromUrlPOSTAsync(URL_Register, urlParams);
  //   return result;
  // },
  confirmEmail: async (id) => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(
      URL_ConfirmEmail + '/' + id,
      urlParams
    );
    return result;
  },
  forgotPasswordChange: async (id, password) => {
    const urlParams = [
      {
        key: 'saveData',
        value: {
          password: md5(password),
        },
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_ForgotPassword + '/' + id,
      urlParams
    );
    return result;
  },
  changeDriverPackingStatus: async (data, idKey) => {
    const urlParams = [
      {
        key: 'saveData',
        value: data,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_CHANGE_DRIVER_PACKING_STATUS + '/' + idKey,
      urlParams
    );
    return result;
  },
  getPackingToken: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(
      URL_TOKENS_GET_PACKING,
      urlParams
    );
    return result;
  },
  magicLinkLogin: async (magicToken) => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(
      URL_MagicLogin + '/' + magicToken,
      urlParams
    );
    if (result.success) {
      await ApiStorage.setUser(result.data);
    }
    return result;
  },
  login: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LOGIN, urlParams);
    if (result.success) {
      await ApiStorage.setUser(result.data);
    }
    return result;
  },
  forgotPasswordRequest: async (email) => {
    const urlParams = [{ key: 'email', value: email }];
    const result = await fetchFromUrlPOSTAsync(URL_ForgotPassword, urlParams);
    return result;
  },
  logout: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_Logout, urlParams);
    // if (result.success) {
    // }
    // await ApiStorage.setUser(null);
    ApiLocalStorage.removeItem(ApiLocalStorage.TEMP_REMEMBER_ME);
    return result;
  },
  test: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_TEST, urlParams);
    await ApiStorage.setUser(null);
    return result;
  },
  changePassword: async (values) => {
    const urlParams = [
      {
        key: 'saveData',
        value: {
          currentPassword: md5(values.currentPassword),
          newPassword: md5(values.newPassword),
        },
      },
    ];
    const result = await fetchFromUrlPOSTAsync(URL_ChangePassword, urlParams);
    return result;
  },
  saveAccountSetting: async (values) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_ChangeAccountSettings,
      urlParams
    );
    if (result.success) {
      await ApiStorage.setUser(result.data);
    }
    return result;
  },
  saveUser: async (values, idKey = false) => {
    if (values.hasOwnProperty('password')) {
      if (values.password) {
        values.password = md5(values.password);
      }
    }
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idKey) {
      result = await fetchFromUrlPOSTAsync(URL_SAVE + '/' + idKey, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  getOneByid: async (id) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE_BY_ID + '/' + id,
      urlParams
    );
    return result;
  },
  getDriversList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_GET_DRIVER_ALL, urlParams);
    return result.success ? result.data : [];
  },
  getDriversListResult: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_GET_DRIVER_ALL, urlParams);
    return result;
  },
  fillPaginationListDriverWithManifest: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(
      URL_PAGINATION_LIST_DRIVERS_WITH_MANIFEST,
      urlParams
    );
    return result;
  },
};

const loc = {
  ROLE: {
    SUPERADMIN: 1,
    ADMIN: 2,
    OPERATOR: 3,
    DRIVER: 4,
  },
  STATUS: {
    ACTIVE: 1,
    BLOCKED: 2,
  },
  DRIVERPACKINGSTATUS: {
    PENDING: 1,
    PACKING: 2,
    READY: 3,
  },

  getStatusOptions: (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT_STATUS'),
    }
  ) => {
    const options = [
      { label: getText('WORD_STATUS_ACTIVE'), value: loc.STATUS.ACTIVE },
      { label: getText('WORD_STATUS_INACTIVE'), value: loc.STATUS.BLOCKED },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusOptionsAll: () => {
    return loc.getStatusOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL_STATUSES'),
    });
  },
  getFullListDriversOptionsAll: () => {
    return loc.getFullListDriversOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL_DRIVERS'),
    });
  },
  getFullListDriversOptions: async (firstOption) => {
    let items = await fs.getDriversList();
    const options = items.map((obj) => {
      return {
        value: obj.idUser,
        label: `${obj.fullName} / ${
          obj.transportNumber || obj.transportTypeName
        }`,
        ...obj,
      };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getFullListDriversPackingStatusOptions: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT_DRIVER'),
    }
  ) => {
    let options = [
      { label: getText('WORD_PENDING_STATUS'), value: 1 },
      { label: getText('WORD_IN_PROGRESS'), value: 2 },
      { label: getText('WORD_READY'), value: 3 },
    ];
    return options;
  },
  rangeConst: {
    TODAY: 'Today',
  },
  getRangeOptions: () => {
    return [
      { value: '', label: getText('WORD_ALL') },
      { value: UserActions.rangeConst.TODAY, label: getText('WORD_TODAY') },
    ];
  },

  getRangeOptionsValue: (selectedDateRangeOption) => {
    switch (selectedDateRangeOption) {
      case UserActions.rangeConst.TODAY:
        return {
          from: UtilDate.getDateToday(),
          to: UtilDate.getDateToday(),
        };
      default:
        return {
          from: UtilDate.getDateToday(),
          to: UtilDate.getDateToday(),
        };
    }
  },
};

const UserActions = Object.assign(fs, loc);

export default UserActions;
