import { Tabs } from 'antd';
import React from 'react';
import DraggableList from '../../components/antd/DraggableList';
import UserViewListCard from '../users/UserViewListCard';
import getText from '../../lang';

const ManifestTabs = (props) => {
  const { listDataPending, listDataArchive, loadingData } = props;
  const items = [
    {
      key: '1',
      label: getText('WORD_PENDING'),
      children: [
        <DraggableList
          key="3"
          data={listDataPending}
          loading={loadingData}
          renderItem={(item, index) => {
            return <UserViewListCard key={index} item={item} />;
          }}
        />,
      ],
    },
    {
      key: '2',
      label: getText('WORD_ARCHIVE'),
      children: [
        <DraggableList
          key="4"
          data={listDataArchive}
          loading={loadingData}
          renderItem={(item, index) => {
            return <UserViewListCard key={index} item={item} />;
          }}
        />,
      ],
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} />;
};

export default ManifestTabs;
