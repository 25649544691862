import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const MyCarousel = ({ items, infinite }) => {
  return (
    <AliceCarousel
      infinite={infinite}
      mouseTracking
      items={items}
      disableDotsControls={true}
      disableButtonsControls={true}
    />
  );
};

export default MyCarousel;
