import React, { forwardRef } from 'react';
import MyRow from '../MyRow';
import InfiniteScrollList from '../InfiniteScrollList/InfiniteScrollList';
import './myListSection.scss';

const MyListSection = forwardRef((props, ref) => {
  const {
    title,
    totalCount,
    dataSource,
    onLoadMore,
    loading,
    id,
    renderItem,
    className,
    height,
    style,
    extra,
  } = props;

  return (
    <div className={`my-list-section-wrapper ${className}`} style={style}>
      {title && totalCount ? (
        <MyRow withoutTitle className="list-title">
          <div>{title}</div>
          <div>{extra}</div>
          <div className="count-value">{totalCount}</div>
        </MyRow>
      ) : null}
      <InfiniteScrollList
        dataSource={dataSource}
        dataLength={totalCount}
        onLoadMore={onLoadMore}
        propHeight={height}
        id={id}
        loading={loading}
        renderItem={renderItem}
      />
    </div>
  );
});

MyListSection.defaultProps = {
  title: '',
  totalCount: 0,
  dataSource: [],
};

export default MyListSection;
