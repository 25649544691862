import React, { useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import getText from '../../lang';

const DeleteButton = (props) => {
  const { title, onConfirm, color } = props;

  const [visible, setVisible] = useState(false);

  const cancel = (e) => {
    e.stopPropagation();
    setVisible(false);
    return false;
  };

  const showPopconfirm = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  return (
    <Popconfirm
      title={title ? title : getText('ARE_YOU_SURE_TO_DELETE_THIS_ITEM')}
      okText={getText('WORD_YES')}
      cancelText={getText('WORD_NO')}
      onCancel={cancel}
      open={visible}
      onConfirm={(e) => {
        cancel(e);
        onConfirm && onConfirm();
      }}
      icon={<DeleteFilled style={{ color: color }} />}
    >
      <div className="delete-btn" onClick={showPopconfirm}>
        <DeleteFilled style={{ color: color }} />
      </div>
    </Popconfirm>
  );
};

export default DeleteButton;
