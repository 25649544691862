import React, { memo, useEffect, useState } from 'react';
import ManifestCard from './ManifestCard';
import { notifyError } from '../../components/notify';
import ManifestActions from '../../actions/ManifestActions';
import MyListSection from '../../components/myList/MyListSection';
import { useSelector } from 'react-redux';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import './style.scss';
import utilDate from '../../utils/utilDate';
// import { Segmented } from 'antd';

const ManifestsListPanel = (props) => {
  const { listType, title } = props;

  const filter = useSelector((state) => {
    const sta = state.AuthReducer.filterManifest;
    if (sta.dateRange) {
      sta.dateRange = [
        utilDate.getDate(sta.dateRange[0]),
        utilDate.getDate(sta.dateRange[1]),
      ];
      if (sta.fromDate) {
        sta.fromDate = utilDate.getDate(sta.fromDate);
      }
      if (sta.toDate) {
        sta.toDate = utilDate.getDate(sta.toDate);
      }
    }
    return sta;
  });

  const isMounted = useIsMounted();

  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [localFilter, setlocalFilter] = useState(null);
  // const [perPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setLoadingData(true);
    setlocalFilter(
      Object.assign(
        {},
        {
          page: 0,
        },
        filter
      )
    );
  }, [filter, listType]);

  useEffect(() => {
    const fill = async () => {
      if (localFilter) {
        let fetchParams = {
          page: localFilter?.page || 0,
          perPage: 10,
          sort: {
            'M.createDate': 'ASC',
          },
          filter: {
            idUserDriver: localFilter?.idUserDriver,
            fromDate: localFilter?.dateRange ? localFilter.dateRange[0] : '',
            toDate: localFilter?.dateRange ? localFilter?.dateRange[1] : '',
          },
          q: localFilter?.searchName || '',
        };
        if (listType === 'PENDING') {
          fetchParams.filter.statusList = [ManifestActions.STATUS.ACTIVE];
        } else if (listType === 'WAITING') {
          fetchParams.filter.statusList = [
            ManifestActions.STATUS.ACTIVE_AND_SEND,
          ];
        } else if (listType === 'PENDINGWAITING') {
          fetchParams.filter.statusList = [
            ManifestActions.STATUS.ACTIVE,
            ManifestActions.STATUS.ACTIVE_AND_SEND,
          ];
        } else if (listType === 'STARTEDONWAY') {
          fetchParams.filter.status = ManifestActions.STATUS.INPROGRESS;
        } else if (listType === 'DONEARCHIVE') {
          fetchParams.filter.status = ManifestActions.STATUS.FINISHED;
          fetchParams.sort = {
            // 'M.createDate': 'DESC',
            'M.finishDate': 'DESC',
          };
          fetchParams.perPage = 5;
        }
        let result = await ManifestActions.fillPaginationList(fetchParams);
        if (isMounted()) {
          if (result.success) {
            if (localFilter?.page > 0) {
              setListData((searches) => {
                return searches.concat(result.data);
              });
            } else {
              setListData(result.data);
            }
            setTotalCount(result.totalRows);
          } else {
            notifyError(result.errMsg);
          }
          setLoadingData(false);
        }
      }
    };
    fill();
  }, [localFilter, isMounted, listType]);

  const handleLoadMore = async () => {
    if (totalCount !== listData.length && !loadingData) {
      // setPage(page + 1);
      setlocalFilter(
        Object.assign({}, { page: (localFilter.page || 0) + 1 }, filter)
      );
    }
  };

  return (
    <MyListSection
      title={title}
      // extra={
      //   listType === 'PENDINGWAITING' ? (
      //     <Segmented
      //       options={['All', 'Active', 'Send']}
      //       onChange={(value) => {
      //         console.log(value); // string
      //       }}
      //     />
      //   ) : null
      // }
      dataSource={listData}
      totalCount={totalCount}
      onLoadMore={handleLoadMore}
      loading={loadingData}
      id={listType}
      renderItem={(item, index) => <ManifestCard key={index} item={item} />}
    />
  );
};

ManifestsListPanel.defaultProps = {
  title: '',
};

export default memo(ManifestsListPanel);
