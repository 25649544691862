import React, { memo, useEffect, useState } from 'react';
import UserActions from '../../actions/UserActions';
import MySelect from '../antd/MySelect';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import getText from '../../lang';

const SelectUserDrivers = (props) => {
  const { withClear, withAll } = props;

  const [listData, setListData] = useState([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    let fill = async () => {
      let result;
      if (withAll) {
        result = await UserActions.getFullListDriversOptionsAll();
      } else {
        result = await UserActions.getFullListDriversOptions();
      }
      if (withClear) {
        result.push({ label: 'Unassign', value: 0 });
      }
      if (isMounted()) {
        setListData(result);
      }
    };
    fill();
  }, [isMounted, withClear, withAll]);

  return (
    <MySelect
      {...props}
      options={listData}
      placeholder={
        withAll
          ? getText('WORD_SELECT_ALL_DRIVERS')
          : getText('WORD_SELECT_DRIVER')
      }
    />
  );
};

export default memo(SelectUserDrivers);
