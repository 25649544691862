import React from 'react';
import CalButton from '../../components/CalButton';
import MyRow from '../../components/MyRow';
import getText from '../../lang';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import { memo } from 'react';

const OrderViewContactCard = (props) => {
  const { phoneNumber, fullName, icon, type, style } = props;

  return (
    <div className="order-view-customer-driver-wrapper" style={style}>
      <MyRow bottomZero className="order-view-user-wrapper">
        <MyRow>
          <ProfileAvatar icon={icon} value={fullName} />
          <div className="order-card-name-type-wrapper">
            <div className="transport-type-name-div">{type}</div>
            <div className="user-name-div">{fullName}</div>
          </div>
        </MyRow>
        {phoneNumber ? <CalButton phoneNumber={phoneNumber} /> : ''}
      </MyRow>
      <MyRow bottomZero className="customer-or-driver-info">
        <div className="first-item">
          {phoneNumber ? getText('WORD_PHONE') : ''}
        </div>
        <div className="second-item">{phoneNumber}</div>
      </MyRow>
    </div>
  );
};

export default memo(OrderViewContactCard);
