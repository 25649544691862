import React, { useEffect, useState } from 'react';
import RolesActions from '../../actions/RolesActions';
import MySelect from '../antd/MySelect';
import getText from '../../lang';

const SelectUserRole = (props) => {
  const { withAll } = props;

  const [listData, setListData] = useState([]);

  useEffect(() => {
    let fill = async () => {
      let listRoles;
      if (withAll) {
        listRoles = await RolesActions.getFullListOptionsAll();
      } else {
        listRoles = await RolesActions.getFullListOptions();
      }
      setListData(listRoles);
    };
    fill();
  }, [withAll]);

  return (
    <MySelect
      {...props}
      options={listData}
      placeholder={
        withAll ? getText('WORD_SELECT_ALL_ROLES') : getText('WORD_USER_ROLE')
      }
    />
  );
};

export default SelectUserRole;
