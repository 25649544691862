import React, { useEffect, useState } from 'react';
import getText from '../../lang';
import { formatPhoneNumber } from '../../utils';
import MyRow from '../../components/MyRow';
import MyField from '../../components/MyField';
import UserActions from '../../actions/UserActions';
import MySelect from '../../components/antd/MySelect';
import InputText from '../../components/antd/InputText';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import { notifyError, notifySuccess } from '../../components/notify';
import ManifestIcon from '../../icons/svg/ManifestIcon';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import './style.scss';

const UserListDriverMonitorStatusChangeCard = (props) => {
  const navigateApi = useNavigateApi();

  const [driverPackingStatusNote, setDriverPackingStatusNote] = useState('');

  const [listOptionsStatus, setlistOptionsStatus] = useState([]);
  const [selectedDriverPackingStatusM, setselectedDriverPackingStatusM] =
    useState('');

  const { item, disableView } = props;

  useEffect(() => {
    let list = [];
    list.push({
      label: getText('WORD_PENDING_STATUS'),
      value: UserActions.DRIVERPACKINGSTATUS.PENDING,
    });
    list.push(
      ...item.manifests.map((oo) => {
        return { label: oo.manifestName, value: -oo.driverPackingIdManifest };
      })
    );
    setlistOptionsStatus(list);

    if (item) {
      setDriverPackingStatusNote(item.driverPackingStatusNote);
      if (item.driverPackingIdManifest) {
        setselectedDriverPackingStatusM(-item.driverPackingIdManifest);
      } else {
        setselectedDriverPackingStatusM(
          UserActions.DRIVERPACKINGSTATUS.PENDING
        );
      }
    }
  }, [item]);

  const handleChangeDriverPackingStatus = async (status, note, idManifest) => {
    const objSend = {
      driverPackingStatus: status,
      driverPackingStatusNote: note,
      driverPackingIdManifest: Math.abs(idManifest),
    };
    let result = await UserActions.changeDriverPackingStatus(
      objSend,
      item.idKey
    );
    if (result.success) {
      notifySuccess(getText('WORD_STATUS_CHANGED_SUCCESSFULLY'));
    } else {
      notifyError(result.errMsg);
    }
  };

  const handle_change_status = async (val) => {
    setselectedDriverPackingStatusM(val);
    setDriverPackingStatusNote('');
    if (val !== UserActions.DRIVERPACKINGSTATUS.PENDING) {
      handleChangeDriverPackingStatus(
        UserActions.DRIVERPACKINGSTATUS.PACKING,
        '',
        val
      );
    } else {
      handleChangeDriverPackingStatus(
        UserActions.DRIVERPACKINGSTATUS.PENDING,
        '',
        ''
      );
    }
  };

  return (
    <div
      className="my-card users-list-card driver"
      style={{ cursor: 'pointer' }}
    >
      <div
        onClick={(e) => {
          if (!disableView) {
            e.stopPropagation();
            navigateApi('users/view/' + item.idKey);
          }
        }}
      >
        <MyRow className="avatar-name">
          <ProfileAvatar icon={item.icon} value={item.fullName} />
          <div className="user-card-name-type-wrapper">
            <div className="transport-type-name-div">{item.roleName}</div>
            <div className="with-edit-buttin">
              <div className="user-name-div">{item.fullName}</div>
            </div>
          </div>
          <div
            className="manifest-icon"
            onClick={(e) => {
              e.stopPropagation();
              navigateApi('manifest/create', '', { driver: item });
            }}
          >
            <Tooltip
              placement="top"
              title={getText('WORD_CHANGE_DRIVER_FREIGHT')}
            >
              <Button ghost className="button-trigger">
                <ManifestIcon />
              </Button>
            </Tooltip>
          </div>
        </MyRow>
        <MyField
          fieldValuesList={[
            {
              label: getText('WORD_TEL'),
              rightItem: formatPhoneNumber(item.phoneCode, item.phoneNumber),
            },
            {
              label: getText('WORD_TRANSPORT'),
              rightItem: item.transportTypeName + ' | ' + item.transportNumber,
            },
          ]}
        />
      </div>
      <MyRow className="div-label-value-wrapper">
        <p className="label-title">{getText('WORD_SHIPMENT')}</p>
        <div className="label-value">
          <MySelect
            name="selectStatus"
            options={listOptionsStatus}
            value={selectedDriverPackingStatusM}
            showSearch={false}
            onChange={handle_change_status}
          />
        </div>
      </MyRow>

      <MyRow className="div-label-value-wrapper">
        <p className="label-title">{getText('WORD_GATE')}</p>
        <div className="label-value-with-button">
          <InputText
            className="gate-input"
            value={driverPackingStatusNote}
            disabled={
              selectedDriverPackingStatusM ===
              UserActions.DRIVERPACKINGSTATUS.PENDING
            }
            onChange={(e) => {
              setDriverPackingStatusNote(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <Button
            type="primary"
            className="input-right-btn"
            disabled={
              selectedDriverPackingStatusM ===
              UserActions.DRIVERPACKINGSTATUS.PENDING
            }
            onClick={(e) => {
              e.stopPropagation();
              if (driverPackingStatusNote) {
                handleChangeDriverPackingStatus(
                  UserActions.DRIVERPACKINGSTATUS.READY,
                  driverPackingStatusNote,
                  selectedDriverPackingStatusM
                );
              } else {
                notifyError(getText('WORD_PLEASE_INPUT_GATE'));
              }
            }}
          >
            <CheckOutlined />
          </Button>
        </div>
      </MyRow>
    </div>
  );
};

UserListDriverMonitorStatusChangeCard.defaultProps = {
  item: {},
};

export default UserListDriverMonitorStatusChangeCard;
