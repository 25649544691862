import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11.132"
    viewBox="0 0 13 11.132"
  >
    <path
      id="Path_13499"
      data-name="Path 13499"
      d="M12.031,12.748A3.565,3.565,0,0,1,9.9,11.334,11.647,11.647,0,0,0,8.757,10.2c.684-.571,1.182-1.235,1.707-1.716a2.744,2.744,0,0,1,1.567-.827V8.915l3.7-2.138-3.7-2.138v1.3a5.119,5.119,0,0,0-3.4,1.966A8.712,8.712,0,0,1,7.549,8.98a1.567,1.567,0,0,1-.986.364H2.734v1.715H6.563a1.569,1.569,0,0,1,.988.364c.5.364,1.026,1.069,1.74,1.748a4.438,4.438,0,0,0,2.74,1.3v1.305l3.7-2.134-3.7-2.137v1.247Z"
      transform="translate(-2.734 -4.64)"
      fill="#0076ff"
    />
  </svg>
);

const SplitIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default SplitIcon;
