import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import getText from '../lang';

import { SELECT_UNSELECT_NONE } from '../utils';

const URL_LIST = '/roles/get_all';

const fs = {
  getFullList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result.success ? result.data : [];
  },
};

const loc = {
  getFullListOptions: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT'),
    }
  ) => {
    let items = await fs.getFullList();
    const options = items.map((obj) => {
      return { value: obj.idRole, label: getText(obj.name, true) };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getFullListOptionsAll: async () => {
    return await loc.getFullListOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL_ROLES'),
    });
  },
};

const RolesActions = Object.assign(fs, loc);

export default RolesActions;
