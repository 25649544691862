import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import ApiStorage from '../../../reducers/ApiStorage';
import { useSelector } from 'react-redux';
import SideBar from '../SideBar';
import useWindowSize from '../../../utils/useWindowSize';
import './style.scss';
import './styleMobile.scss';

const { Content } = Layout;

const MainLayout = (props) => {
  const { children } = props;
  const { isMobile } = useWindowSize();

  const menuOpen = useSelector((state) => state.AuthReducer?.menuOpen);

  const idKey = useSelector((state) => {
    return state.AuthReducer.currentUser?.idKey;
  });

  const onToggle = () => {
    ApiStorage.setMenuOpen(!menuOpen);
  };

  const onCloseToggle = () => {
    ApiStorage.setMenuOpen(false);
  };

  return (
    <Layout className="main-layout">
      <SideBar
        onCloseToggle={onCloseToggle}
        onToggle={onToggle}
        menuOpen={menuOpen}
      />
      <Layout className="site-layout">
        {isMobile ? <Header onToggle={onToggle} {...props} /> : null}
        <Content
          className="site-layout-background"
          style={{
            minHeight: 280,
          }}
        >
          {idKey ? children : null}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
