import React from 'react';
import { Row, Col, Pagination, Empty } from 'antd';
import { TABLE_FETCH_PAGE_SIZE } from '../../../utils';
import LoadingOutlinedApp from '../LoadingOutlinedApp';
import './style.scss';

const MyGrid = (props) => {
  const {
    loading,
    data,
    listView,
    renderItem,
    pagination,
    paginationCurrentPage,
    paginationTotalCount,
    defaultPageSize,
    hideOnSinglePage,
    onPaginationChange,
    onShowSizeChange,
    style,
  } = props;

  return loading ? (
    <div className="spin-wrapper">
      <LoadingOutlinedApp style={{ fontSize: '60px', color: '#08c' }} />
    </div>
  ) : data && data.length ? (
    <div className="my-grid-container" style={style}>
      <Row gutter={[15, 15]}>
        {data.map((item, index) => {
          return (
            <Col
              key={JSON.stringify(item)}
              className="gutter-row"
              span={listView ? 24 : ''}
            >
              {renderItem(item)}
            </Col>
          );
        })}
      </Row>
      {pagination && (
        <Pagination
          className="my-grid-pagination"
          current={paginationCurrentPage + 1}
          total={paginationTotalCount}
          defaultPageSize={defaultPageSize}
          hideOnSinglePage={hideOnSinglePage}
          onChange={(e) => {
            onPaginationChange(e - 1);
          }}
          onShowSizeChange={(e, ee) => {
            onShowSizeChange(ee);
          }}
          size={'small'}
          responsive
          showTitle={false}
          showTotal={(total, range) => `Total: ${total}`}
          showSizeChanger={true}
          showQuickJumper={true}
        />
      )}
    </div>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};

MyGrid.defaultProps = {
  data: [],
  loading: false,
  renderItem: (item) => {},
  hideOnSinglePage: true,
  defaultPageSize: TABLE_FETCH_PAGE_SIZE,
  paginationCurrentPage: 1,
  paginationTotalCount: 0,
  pagination: true,
  onPaginationChange: () => {},
  onShowSizeChange: () => {},
};

export default MyGrid;
