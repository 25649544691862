import React from 'react';
import { Badge } from 'antd';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import './style.scss';

const DashboardCustomerBlockItem = (props) => {
  const { selectedItemActive, item, onClick } = props;

  return (
    <>
      <div
        className={
          'dashboard-customer-block-item ' +
          (selectedItemActive === item.idKey ? 'active' : '')
        }
        onClick={() => {
          onClick(item.idKey, item);
        }}
      >
        <Badge dot={item.driverIsOnline}>
          <ProfileAvatar icon={item.icon} value={item.fullName} />
        </Badge>
        <div className="dashboard-customer-block-item-name">
          {item.fullName}
        </div>
      </div>
    </>
  );
};

export default DashboardCustomerBlockItem;
