import { store } from '../store';
import {
  SET_USER,
  SET_LANG,
  SET_MENU_OPEN,
  SET_TOTAL_ACTIVE_ORDER_COUNT,
  SET_SCREEN_SIZE,
  SET_ACCESSTOKEN,
  SET_SOCKET_CB_FUNCTION,
  SET_TOTAL_ACTIVE_MANIFEST_COUNT,
  SET_ACCESSTOKEN_MONITORING,
  SET_SOCKET_CB_FUNCTION_MONITORING,
  SET_FILTER_TRANSACTION,
  SET_FILTER_MANIFEST,
  SET_FILTER_ASSIGN,
} from './AuthReducer';

const ApiStorage = {
  setLanguage: async (lang) => {
    await store.dispatch({ type: SET_LANG, payload: lang });
  },
  setAccessToken: async (accessToken) => {
    await store.dispatch({ type: SET_ACCESSTOKEN, payload: accessToken });
  },
  setAccessTokenMonitoring: async (accessTokenM) => {
    await store.dispatch({
      type: SET_ACCESSTOKEN_MONITORING,
      payload: accessTokenM,
    });
  },
  setScreenSize: async (screenSize) => {
    await store.dispatch({ type: SET_SCREEN_SIZE, payload: screenSize });
  },
  setTotalActiveOrdersCount: async (count) => {
    await store.dispatch({
      type: SET_TOTAL_ACTIVE_ORDER_COUNT,
      payload: count,
    });
  },
  setTotalActiveManifestCount: async (count) => {
    await store.dispatch({
      type: SET_TOTAL_ACTIVE_MANIFEST_COUNT,
      payload: count,
    });
  },
  setUser: async (user) => {
    ApiStorage.setFilterTransaction({});
    if (user) {
      if (user.hasOwnProperty('lang')) {
        await ApiStorage.setLanguage(user.lang);
      }
      if (user.hasOwnProperty('accessToken')) {
        await ApiStorage.setAccessToken(user.accessToken);
      }
      if (user.hasOwnProperty('badgeOrderCount')) {
        await ApiStorage.setTotalActiveOrdersCount(user.badgeOrderCount);
      }
      if (user.hasOwnProperty('badgeManifestCount')) {
        await ApiStorage.setTotalActiveManifestCount(user.badgeManifestCount);
      }
      // ApiLocalStorage.setItem(ApiLocalStorage.TEMP_FILTER_ASSIGN, {});
      ApiStorage.setFilterManifest({});
      ApiStorage.setFilterTransaction({});
      ApiStorage.setFilterAssign({});
    }
    await store.dispatch({ type: SET_USER, payload: user });
  },
  getAccessToken: () => {
    const { accessToken } = store.getState().AuthReducer;
    return accessToken;
  },
  getAccessTokenMonitoring: () => {
    const { accessTokenMonitoring } = store.getState().AuthReducer;
    return accessTokenMonitoring;
  },
  getUser: () => {
    const { currentUser } = store.getState().AuthReducer;
    return currentUser;
  },
  getIsLogined: () => {
    let user = ApiStorage.getUser();
    return user !== null;
  },
  getLanguage: () => {
    const { lang } = store.getState().AuthReducer;
    return lang;
  },
  getScreenSize: () => {
    const { screenSize } = store.getState().AuthReducer;
    return screenSize;
  },
  getTotalActiveOrdersCount: () => {
    const { totalActiveOrders } = store.getState().AuthReducer;
    return totalActiveOrders;
  },
  setMenuOpen: async (value) => {
    await store.dispatch({ type: SET_MENU_OPEN, payload: value });
  },
  getMenuOpen: () => {
    const { menuOpen } = store.getState().AuthReducer;
    return menuOpen;
  },
  getPageSocket: () => {
    const { socketCbFunction } = store.getState().AuthReducer;
    return socketCbFunction;
  },
  getPageSocketMonitoring: () => {
    const { socketCbFunctionMonitoring } = store.getState().AuthReducer;
    return socketCbFunctionMonitoring;
  },
  setPageSocket: async (fn) => {
    await store.dispatch({ type: SET_SOCKET_CB_FUNCTION, payload: fn });
  },
  setPageSocketMonitoring: async (fn) => {
    await store.dispatch({
      type: SET_SOCKET_CB_FUNCTION_MONITORING,
      payload: fn,
    });
  },
  setFilterTransaction: async (filter) => {
    await store.dispatch({
      type: SET_FILTER_TRANSACTION,
      payload: filter,
    });
  },
  getFilterTransaction: () => {
    const { filterTransaction } = store.getState().AuthReducer;
    return filterTransaction;
  },
  setFilterManifest: async (filter) => {
    await store.dispatch({
      type: SET_FILTER_MANIFEST,
      payload: filter,
    });
  },
  getFilterManifest: () => {
    const { filterManifest } = store.getState().AuthReducer;
    return filterManifest;
  },
  setFilterAssign: async (filter) => {
    await store.dispatch({
      type: SET_FILTER_ASSIGN,
      payload: filter,
    });
  },
  getFilterAssign: () => {
    const { filterAssign } = store.getState().AuthReducer;
    return filterAssign;
  },
};

export default ApiStorage;
