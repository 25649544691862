import React, { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import InputFormNumber from '../../components/antd/InputFormNumber';
import TransportTypeActions from '../../actions/TransportTypeActions';
import MyRow from '../../components/MyRow';
import SelectForm from '../../components/antd/SelectForm';
import SelectServiceTransportPriceMetode from '../../components/selects/SelectServiceTransportPriceMetode';
import { SELECT_UNSELECT_NONE } from '../../utils';
import ServiceTransportPriceActions from '../../actions/ServiceTransportPriceActions';
import getText from '../../lang';

const RenderPriceItem = ({
  field,
  index,
  listTransportTypes,
  remove,
  form,
}) => {
  const [priceMethod, setPriceMethod] = useState();

  useEffect(() => {
    let itt = form.getFieldsValue().itemsPrice[index];
    let priceMethod = itt ? itt.priceMethod : SELECT_UNSELECT_NONE;
    setPriceMethod(priceMethod);
  }, [form, index]);

  return (
    <div key={index} className="my-price-list-inputs">
      <MyRow className="add-price-list-row">
        <div className="select-width">
          <SelectForm
            label={index === 0 ? getText('WORD_TRANSPORT_TYPE') : ''}
            name={[field.name, 'idTransportType']}
            placeholder={getText('WORD_TRANSPORT_TYPE')}
            options={listTransportTypes}
            required
          />
        </div>
        <div className="select-width">
          <SelectServiceTransportPriceMetode
            name={[field.name, 'priceMethod']}
            label={index === 0 ? getText('WORD_PRICE_TYPE') : ''}
            onChange={(e) => {
              setPriceMethod(e);
            }}
            required
          />
        </div>
        {priceMethod !==
          ServiceTransportPriceActions.PRICEMETHOD.BYDISTANCE && (
          <InputFormNumber
            name={[field.name, 'price']}
            label={index === 0 ? getText('WORD_PRICE') : ''}
            placeholder={getText('WORD_TRANSPORT_PRICE')}
            className="sdsd"
            required
          />
        )}
        <MinusCircleOutlined
          className="dynamic-delete-button"
          style={{ marginLeft: 8, marginBottom: 30 }}
          onClick={() => {
            remove(field.name);
          }}
        />
      </MyRow>
      <MyRow>
        {priceMethod ===
          ServiceTransportPriceActions.PRICEMETHOD.BYDISTANCE && (
          <div className="min-price-km-wrapper">
            <InputFormNumber
              name={[field.name, 'minKm']}
              label={index === 0 ? getText('WORD_SERVICE_MIN_KM') : ''}
              placeholder={getText('WORD_SERVICE_MIN_KM')}
              required
            />
            <InputFormNumber
              name={[field.name, 'minPriceKm']}
              label={index === 0 ? getText('WORD_SERVICE_PRICE_MIN_KM') : ''}
              placeholder={getText('WORD_SERVICE_PRICE_MIN_KM')}
              required
            />
            <InputFormNumber
              name={[field.name, 'oneKmPrice']}
              label={index === 0 ? getText('WORD_SERVICE_PRICE_PER_KM') : ''}
              placeholder={getText('WORD_SERVICE_PRICE_PER_KM')}
              required
            />
          </div>
        )}
      </MyRow>
    </div>
  );
};

const ServiceEditListItem = (props) => {
  const [listTransportTypes, setListTransportTypes] = useState([]);

  const { form } = props;

  useEffect(() => {
    let fill = async () => {
      setListTransportTypes(await TransportTypeActions.getFullListOptions());
    };
    fill();
  }, []);

  return (
    <Form.List name="itemsPrice">
      {(fields, { add, remove }) => {
        return (
          <div
            className="service-price-list-wrapper"
            style={{ width: '100%', marginTop: 40 }}
          >
            <h5>{getText('WORD_SERVICE_PRICING')}</h5>
            {fields.map((field, index) => {
              return (
                <RenderPriceItem
                  key={field.key}
                  listTransportTypes={listTransportTypes}
                  field={field}
                  index={index}
                  remove={remove}
                  form={form}
                />
              );
            })}
            <Button
              onClick={() => {
                add({
                  idTransportType: SELECT_UNSELECT_NONE,
                  priceMethod: ServiceTransportPriceActions.PRICEMETHOD.FIX,
                });
              }}
              style={{ width: '100%', marginBottom: 5 }}
            >
              <PlusCircleOutlined /> {getText('WORD_SERVICE_ADD_PRICE')}
            </Button>
          </div>
        );
      }}
    </Form.List>
  );
};

export default ServiceEditListItem;
