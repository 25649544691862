import React from 'react';
import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from '../../utils/useWindowSize';
import getText from '../../lang';
import './InfiniteScrollList.scss';

const InfiniteScrollList = (props) => {
  const {
    className,
    onLoadMore,
    loading,
    dataSource,
    renderItem,
    id,
    dataLength,
    propHeight,
    width,
  } = props;

  const { height } = useWindowSize();

  const _divId = `scrollableDiv_${id}`;

  const heightFinal = propHeight || height - 130;

  return (
    <div
      id={_divId}
      className={`InfiniteScrollParent ${className || ''}`}
      style={{
        height: heightFinal,
        minHeight: heightFinal,
        width: width,
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        dataLength={dataSource.length}
        className={className}
        next={() => {
          onLoadMore && onLoadMore();
        }}
        hasMore={dataSource.length < dataLength}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={
          dataSource.length > 0 && dataSource.length === dataLength ? (
            <div style={{ textAlign: 'center' }}>
              {getText('WORD_END_OF_LIST')}🍦
            </div>
          ) : null
        }
        scrollableTarget={_divId}
      >
        <List
          className="myInfiniteList"
          loading={loading}
          dataSource={dataSource}
          split={false}
          renderItem={(item, key) => (
            <List.Item key={key} className="myInfiniteListChildren">
              {renderItem && renderItem(item, key)}
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollList;
