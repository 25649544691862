import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DriverMonitoringActions from '../actions/DriverMonitoringActions';
import ApiStorage from '../reducers/ApiStorage';
import LoadingOutlinedApp from '../components/antd/LoadingOutlinedApp';
import useNavigateApi from '../AppRoute/useNavigateApi';

const DriverPackingStatusMonitoring = (props) => {
  let { _token } = useParams();
  const navigateApi = useNavigateApi();

  useEffect(() => {
    const fill = async () => {
      let result = await DriverMonitoringActions.fillMonitoringList(_token);
      if (result.success) {
        ApiStorage.setAccessTokenMonitoring(_token);

        setTimeout(() => {
          navigateApi('driver/packing_status_monitor');
        }, 2000);
      }
    };
    fill();
  }, [_token, navigateApi]);

  return <LoadingOutlinedApp />;
};

export default DriverPackingStatusMonitoring;
