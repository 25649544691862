import { Button, message, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApicodeActions from '../../actions/ApicodeActions';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyRow from '../../components/MyRow';
import { notifyError } from '../../components/notify';
import getText from '../../lang';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const ApiView = (props) => {
  const navigateApi = useNavigateApi();

  const Line = () => {
    return <div className="divider-line" />;
  };

  let { _id } = useParams();
  const [item, setItem] = useState({});

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await ApicodeActions.getOneByidKey(_id);
        if (resultGet.success) {
          setItem(resultGet.data);
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('users/list');
        }
      }
    };
    getObj();
  }, [_id, navigateApi]);

  return (
    <>
      <HeaderSpace />
      <div></div>
      <MyBlock title={getText('WORD_API_VIEW')} topZero>
        <div className="apicode-view">
          <MyRow withoutTitle>
            <div className="first-item">{getText('WORD_NAME')}</div>
            <div className="second-item">{item.name}</div>
          </MyRow>
          <Line />
          <MyRow withoutTitle>
            <div className="first-item">{getText('WORD_TOKEN')}</div>
            <div className="copy-item">
              <div className="second-item">{item.token}</div>
              <CopyToClipboard
                text={item.token}
                onCopy={() => {
                  message.info('Copied');
                }}
              >
                <Tooltip title={getText('WORD_COPY_TOKEN')}>
                  <CopyOutlined />
                </Tooltip>
              </CopyToClipboard>
            </div>
          </MyRow>
          {item.driverName ? (
            <>
              <Line />
              <MyRow withoutTitle>
                <div className="first-item">{getText('WORD_DRIVER')}</div>
                <div className="second-item">{item.driverName}</div>
              </MyRow>
            </>
          ) : null}
        </div>
        <Button
          onClick={() => {
            navigateApi('apicode/list');
          }}
          type="cancel"
          className="api-code-cancel-btn"
        >
          {getText('WORD_CANCEL')}
        </Button>
      </MyBlock>
    </>
  );
};

export default ApiView;
