import React, { useEffect, useState } from 'react';
import getText from '../../lang';
import OrderActions from '../../actions/OrderActions';
import MySelect from '../antd/MySelect';

const SelectOrderCreateType = (props) => {
  const { withAll } = props;

  const [listData, setListData] = useState([]);

  useEffect(() => {
    let fill = () => {
      let result = [
        {
          label: getText('WORD_DEFAULT'),
          value: OrderActions.CREATEDTYPE.DEFAULT,
        },
        { label: getText('WORD_SPLIT'), value: OrderActions.CREATEDTYPE.SPLIT },
        {
          label: getText('WORD_NOT_DELEVERED'),
          value: OrderActions.CREATEDTYPE.NOTDELIVERED,
        },
      ];
      if (withAll) {
        result.unshift({ label: getText('WORD_SELECT_ALL'), value: '' });
      }
      setListData(result);
    };
    fill();
  }, [withAll]);

  return (
    <MySelect
      {...props}
      options={listData}
      placeholder={
        withAll
          ? getText('WORD_SELECT_ALL_CREATED_TYPE')
          : getText('WORD_SELECT_CREATED_TYPE')
      }
    />
  );
};

export default SelectOrderCreateType;
