import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

import { SELECT_UNSELECT_NONE } from '../utils';
import getText from '../lang';

const URL_LIST = '/countries/get_all';

const fs = {
  getFullList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result.success ? result.data : [];
  },
};

const loc = {
  getFullListOptions: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_COUNTRY'),
    }
  ) => {
    let items = await fs.getFullList();
    const options = items.map((obj) => {
      return { value: obj.idCountry, label: obj.countryName, ...obj };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getFullListOptionsAll: async () => {
    return await loc.getFullListOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_ALL_COUNTRIES'),
    });
  },
  getFullListCodeOptions: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT'),
    }
  ) => {
    let items = await fs.getFullList();
    const options = items.map((obj) => {
      return { value: obj.phoneCode, label: obj.phoneCode, ...obj };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getFullListCodeOptionsAll: async () => {
    return await loc.getFullListCodeOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_ALL_CODES'),
    });
  },
};

const CountryActions = Object.assign(fs, loc);

export default CountryActions;
