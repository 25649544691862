import React, { useEffect, useState } from 'react';
import moment from 'moment';
import getText from '../../lang';
import UtilDate from '../../utils/utilDate';
import UtilNumber from '../../utils/utilNumber';
import { formatDistance, formatDuration, formatPhoneNumber } from '../../utils';
import OrderActions from '../../actions/OrderActions';
import MyRow from '../../components/MyRow';
import MyField from '../../components/MyField';
import MyModal from '../../components/antd/MyModal';
import MySelect from '../../components/antd/MySelect';
import { notifyError, notifySuccess } from '../../components/notify';
import MyTextWithBadge from '../../components/myTextWithBadge/MyTextWithBadge';
import InputDateTimePicker from '../../components/antd/InputDateTimePicker/InputDateTimePicker';
import OrderViewContactCard from '../orders/OrderViewContactCard';
import SplitListItem from './splitListItem/SplitListItem';
// import TimelineDotIcon from '../../icons/svg/TimelineDotIcon';
import SplitIcon from '../../icons/svg/SplitIcon';
import { Button, Space, Timeline, Tooltip } from 'antd';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import './style.scss';

const OrderSplitModal = (props) => {
  const { idKey, onSave } = props;

  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingRight, setloadingRight] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [leftFieldValuesListWithBtn, setLeftFieldValuesListWithBtn] = useState(
    []
  );
  const [rightFieldValuesListWithBtn, setRightFieldValuesListWithBtn] =
    useState([]);
  const [listSplitOrders, setListSplitOrders] = useState([]);
  const [selectedRightOrder, setSelectedRightOrder] = useState([]);
  const [deliveryDate, setdeliveryDate] = useState(UtilDate.getDateTomorrow());

  const [leftTotalKG, setLeftTotalKG] = useState(0);
  const [rightTotalKg, setRightTotalKg] = useState(0);
  const [leftTotalM3, setLeftTotalM3] = useState(0);
  const [rightTotalM3, setRightTotalM3] = useState(0);

  useEffect(() => {
    const fill = async () => {
      setRightFieldValuesListWithBtn([]);
      let resultGet = await OrderActions.getOneByidKey(idKey);
      setOrderData(resultGet.data);
      setLeftFieldValuesListWithBtn(resultGet.data?.items);
      if (moment(resultGet.data.deliveryDate).isAfter(moment())) {
        setdeliveryDate(moment(resultGet.data.deliveryDate));
      } else {
        setdeliveryDate(moment());
      }
    };

    if (visible) {
      setLoading(true);
      fill();
      setLoading(false);
    }
  }, [idKey, visible, selectedRightOrder]);

  useEffect(() => {
    const fillSplitOrdersList = async () => {
      let resultListSplitOrder =
        await OrderActions.fillSplitListWithoutSelectedOptions(idKey);
      resultListSplitOrder.unshift({
        label: getText('WORD_NEW'),
        value: false,
      });
      setListSplitOrders(resultListSplitOrder);
    };

    if (visible) {
      fillSplitOrdersList();
      setloadingRight(false);
    }
  }, [idKey, visible]);

  useEffect(() => {
    const calcTotalsLeft = () => {
      let totalKG = 0;
      let totalM3 = 0;
      for (const it of leftFieldValuesListWithBtn) {
        totalKG += it.sizeWeight;
        totalM3 += it.sizeM3;
      }
      setLeftTotalKG(totalKG);
      setLeftTotalM3(totalM3);
    };
    calcTotalsLeft();
  }, [leftFieldValuesListWithBtn]);

  useEffect(() => {
    const calcTotalsRight = () => {
      let totalKG = 0;
      let totalM3 = 0;
      for (const it of rightFieldValuesListWithBtn) {
        totalKG += it.sizeWeight;
        totalM3 += it.sizeM3;
      }
      setRightTotalKg(totalKG);
      setRightTotalM3(totalM3);
    };
    calcTotalsRight();
  }, [rightFieldValuesListWithBtn]);

  useEffect(() => {
    const getRightListBySelect = async () => {
      setloadingRight(true);
      if (!selectedRightOrder.value) {
        setRightFieldValuesListWithBtn([]);
      } else {
        let resultGet = await OrderActions.getOneByidKey(
          selectedRightOrder.idKey
        );
        if (resultGet.success) {
          setRightFieldValuesListWithBtn(resultGet.data.items);
        } else {
          setRightFieldValuesListWithBtn([]);
        }
      }
      setloadingRight(false);
    };

    getRightListBySelect();
  }, [selectedRightOrder]);

  const handlePlus = (list, item, quantity) => {
    const calcWeight = () => {
      let oneKg = item.sizeWeight / item.itemQuantity;
      return oneKg * quantity;
    };

    const calcM3 = () => {
      let oneM3 = item.sizeM3 / item.itemQuantity;
      return oneM3 * quantity;
    };

    let found = list.find((oo) => {
      return oo.itemNumber === item.itemNumber;
    });
    if (found) {
      found.sizeWeight += calcWeight();
      found.sizeM3 += calcM3();
      found.itemQuantity = found.itemQuantity + quantity;
    } else {
      list.push(
        Object.assign({}, item, {
          sizeWeight: calcWeight(),
          sizeM3: calcM3(),
          itemQuantity: quantity,
        })
      );
    }
    return [...list];
  };

  const handleMinus = (list, item, quantity) => {
    const calcWeight = () => {
      let oneKg = item.sizeWeight / item.itemQuantity;
      return oneKg * (item.itemQuantity - quantity);
    };

    const calcM3 = () => {
      let oneM3 = item.sizeM3 / item.itemQuantity;
      return oneM3 * (item.itemQuantity - quantity);
    };

    let found = list.find((oo) => {
      return oo.itemNumber === item.itemNumber;
    });
    if (found) {
      found.sizeWeight = calcWeight();
      found.sizeM3 = calcM3();
      found.itemQuantity = found.itemQuantity - quantity;
    } else {
      list.push(
        Object.assign({}, item, {
          sizeWeight: calcWeight(),
          sizeM3: calcM3(),
          itemQuantity: quantity,
        })
      );
    }
    return [...list];
  };

  const handleSave = async () => {
    setSaving(true);
    let objToSend = {
      items: leftFieldValuesListWithBtn,
      sizeWeight: leftTotalKG,
      sizeM3: leftTotalM3,
      second: {
        items: rightFieldValuesListWithBtn,
        sizeWeight: rightTotalKg,
        sizeM3: rightTotalM3,
        deliveryDate: deliveryDate,
      },
    };
    if (selectedRightOrder && selectedRightOrder.idOrder) {
      objToSend.second.idOrder = selectedRightOrder.idOrder;
    }
    let result = await OrderActions.saveSplit(objToSend, idKey);
    setSaving(false);
    if (result.success) {
      notifySuccess(getText('WORD_ORDER_SPLITED_SUCCESSFULY'));
      onSave && onSave();

      setVisible(false);
    } else {
      notifyError(result.errMsg);
    }
  };

  return (
    <MyModal
      className="order-modal"
      destroyOnClose={true}
      trigger={
        <Tooltip placement="top" title={getText('WORD_OPEN_SPLIT_MODAL')}>
          <Button ghost className="button-trigger">
            <SplitIcon />
          </Button>
        </Tooltip>
      }
      saving={saving}
      width={700}
      loading={loading}
      visible={visible}
      onOk={handleSave}
      onCancel={() => setVisible(false)}
      okBtnText={getText('WORD_SAVE')}
      cancelBtnText={getText('WORD_CANCEL')}
      onTrrigerClick={(e) => {
        e.stopPropagation();
        setVisible(true);
      }}
    >
      <div className="main-content">
        <div className="left-side">
          <OrderViewContactCard
            type={getText('WORD_CUSTOMER')}
            fullName={orderData && orderData.customerName}
            phoneNumber={
              orderData &&
              formatPhoneNumber(orderData.phoneCode, orderData.phoneNumber)
            }
          />
          <MyField
            fieldValuesList={[
              {
                label: getText('WORD_SERVICE'),
                rightItem: orderData && orderData.serviceName,
              },
              {
                label: getText('WORD_CHECK_ID'),
                rightItem: orderData && orderData.documentNumber,
              },
              {
                label: getText('WORD_NOTES'),
                rightItem: orderData && orderData.description,
              },
            ]}
          />

          <div className="fields-wrapper-with-btn">
            {leftFieldValuesListWithBtn.map((field, index) => {
              return (
                <SplitListItem
                  key={index}
                  index={index}
                  item={field}
                  position="left"
                  onClickSend={(item, quantity) => {
                    setRightFieldValuesListWithBtn((list) =>
                      handlePlus(list, item, quantity)
                    );
                    setLeftFieldValuesListWithBtn((list) =>
                      handleMinus(list, item, quantity)
                    );
                  }}
                  onClickSendAll={(item, quantity) => {
                    setRightFieldValuesListWithBtn((list) =>
                      handlePlus(list, item, quantity)
                    );

                    setLeftFieldValuesListWithBtn((list) => {
                      return list.filter((oo) => {
                        return oo.itemNumber !== item.itemNumber;
                      });
                    });
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className="right-side">
          <MySelect
            name="selectStatus"
            placeholder={getText('WORD_NEW')}
            options={listSplitOrders}
            onChangeItem={setSelectedRightOrder}
          />
          <MyRow bottomZero withoutTitle className="timeline-section">
            <Timeline
              items={[
                { key: 1, children: orderData && orderData.addressFrom },
                { key: 2, children: orderData && orderData.addressTo },
              ]}
            />
          </MyRow>
          <InputDateTimePicker
            placeholder={getText('WORD_DELEVERY_DATE')}
            className="input-time-picker"
            showTime
            value={deliveryDate}
            onChange={setdeliveryDate}
            disabledDate={(current) => {
              return current && current.valueOf() < Date.now();
            }}
          />
          <MyField
            fieldValuesList={[
              {
                label: getText('WORD_DISTANCE'),
                rightItem: formatDistance(orderData && orderData.routeDistance),
              },
              {
                label: getText('WORD_DURATION'),
                rightItem: formatDuration(orderData && orderData.routeDuration),
              },
            ]}
          />

          {Boolean(loadingRight) ? (
            <LoadingOutlinedApp />
          ) : (
            <div className="fields-wrapper-with-btn">
              {rightFieldValuesListWithBtn.map((field, index) => {
                return (
                  <SplitListItem
                    key={index}
                    item={field}
                    position="right"
                    onClickSend={(item, quantity) => {
                      setLeftFieldValuesListWithBtn((list) =>
                        handlePlus(list, item, quantity)
                      );
                      setRightFieldValuesListWithBtn((list) =>
                        handleMinus(list, item, quantity)
                      );
                    }}
                    onClickSendAll={(item, quantity) => {
                      setLeftFieldValuesListWithBtn((list) =>
                        handlePlus(list, item, quantity)
                      );
                      setRightFieldValuesListWithBtn((list) => {
                        return list.filter((oo) => {
                          return oo.itemNumber !== item.itemNumber;
                        });
                      });
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>

      <MyRow withoutTitle className="content-footer">
        <div className="left-total">
          {
            <MyRow className="total-kg-m3" withoutTitle>
              <div className="left-text">{getText('WORD_TOTAL')}</div>
              <div className="right-count">
                <Space>
                  <span className="numerator">
                    {UtilNumber.formatDouble(leftTotalKG)}{' '}
                    {getText('WORD_KG_LC')}
                  </span>
                  /
                  <span className="denominator">
                    {UtilNumber.formatDouble(leftTotalM3)}{' '}
                    <MyTextWithBadge
                      mainText={getText('WORD_M3_LC')}
                      topSymbol={3}
                    />
                  </span>
                </Space>
              </div>
            </MyRow>
          }
        </div>

        <div className="right-total">
          {
            <MyRow className="total-kg-m3" withoutTitle>
              <div className="left-text">{getText('WORD_TOTAL')}</div>
              <div className="right-count">
                <Space>
                  <span className="numerator">
                    {UtilNumber.formatDouble(rightTotalKg)}{' '}
                    {getText('WORD_KG_LC')}
                  </span>
                  /
                  <span className="denominator">
                    {UtilNumber.formatDouble(rightTotalM3)}{' '}
                    <MyTextWithBadge
                      mainText={getText('WORD_M3_LC')}
                      topSymbol={3}
                    />
                  </span>
                </Space>
              </div>
            </MyRow>
          }
        </div>
      </MyRow>
    </MyModal>
  );
};

export default OrderSplitModal;
