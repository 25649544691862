import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';

const URL_SAVE = '/orders/save';
const URL_MULTI_ASSIGN = '/orders/multi_assign';
const URL_GET_ONE = '/orders/get_one';
const URL_GET_ONE_TRACKING = '/orders/tracking';
const URL_PAGINATION_LIST = '/orders/list';
const URL_ROUTE_ASSIGN = '/orders/route/save';
const URL_ROUTE_ASSIGN_SEND = '/orders/route/saveandsend';
const URL_SPLIT_LIST = '/orders/get_splits';
const URL_SPLIT_SAVE = '/orders/manage';

const fs = {
  getOneByidKeyTracking: async (idKeyClient, idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE_TRACKING + '/' + idKeyClient + '/' + idKey,
      urlParams
    );
    return result;
  },
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  fillSplitList: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_SPLIT_LIST + '/' + idKey,
      urlParams
    );
    return result;
  },
  saveSplit: async (values, idKey) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idKey) {
      result = await fetchFromUrlPOSTAsync(
        URL_SPLIT_SAVE + '/' + idKey,
        urlParams
      );
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SPLIT_SAVE, urlParams);
    }
    return result;
  },
  save: async (values, idKey = false) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idKey) {
      result = await fetchFromUrlPOSTAsync(URL_SAVE + '/' + idKey, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  assignMultiple: async (values) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_MULTI_ASSIGN, urlParams);
    return result;
  },
  assignRoute: async (values, idKey) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idKey) {
      result = await fetchFromUrlPOSTAsync(
        URL_ROUTE_ASSIGN + '/' + idKey,
        urlParams
      );
    } else {
      result = await fetchFromUrlPOSTAsync(URL_ROUTE_ASSIGN, urlParams);
    }
    return result;
  },
  assignRouteAndSend: async (values, idKey) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idKey) {
      result = await fetchFromUrlPOSTAsync(
        URL_ROUTE_ASSIGN_SEND + '/' + idKey,
        urlParams
      );
    } else {
      result = await fetchFromUrlPOSTAsync(URL_ROUTE_ASSIGN_SEND, urlParams);
    }
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
};

const loc = {
  STATUS: {
    PENDING: 1,
    ACTIVE: 2,
    BLOCKED: 3,
    INPROGRESS: 4,
    FINISHED: 5,
  },
  STATUS_ITEMS: {
    PENDING: 1,
    DELIVERED: 2,
    NOTDELIVERED: 3,
  },
  STATUSFINISH: {
    PENDING: 1,
    SUCCESS: 2,
    FAILED: 3,
  },
  ORDERLOGACTIONKEY: {
    CREATE: 1,
    UPDATE: 2,
    ASSIGN: 3,
    STEP: 4,
    FINISH: 5,
    ASSIGNMANIFEST: 6,
    START: 7,
  },
  CREATEDTYPE: {
    DEFAULT: 1,
    SPLIT: 2,
    NOTDELIVERED: 3,
  },

  getStatusOptions: (
    firstOption = { value: SELECT_UNSELECT_NONE, label: 'Select status' }
  ) => {
    const options = [
      { label: 'Pending', value: loc.STATUS.PENDING },
      { label: 'Active', value: loc.STATUS.ACTIVE },
      { label: 'Block', value: loc.STATUS.BLOCKED },
      { label: 'InProgress', value: loc.STATUS.INPROGRESS },
      { label: 'Finished', value: loc.STATUS.FINISHED },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusLabel: (status) => {
    let lab = loc.getStatusOptions().find((oo) => {
      return oo.value === status;
    });
    return lab ? lab.label : '';
  },
  fillSplitListOptions: async (idKey) => {
    let result = await fs.fillSplitList(idKey);
    let list = [];
    if (result.success) {
      list = result.data.map((oo) => {
        return {
          label: oo.apiOrderKey || oo.orderNumber,
          value: oo.idKey,
          ...oo,
        };
      });
    }
    return list;
  },
  fillSplitListWithoutSelectedOptions: async (idKey) => {
    let result = await fs.fillSplitList(idKey);
    let list = [];
    if (result.success) {
      list = result.data
        .filter((oo) => {
          return oo.idKey !== idKey;
        })
        .map((oo) => {
          return {
            label: oo.apiOrderKey || oo.orderNumber,
            value: oo.idKey,
            ...oo,
          };
        });
    }
    return list;
  },
  getStatusOptionsAll: () => {
    return loc.getStatusOptions({
      value: SELECT_UNSELECT_NONE,
      label: 'All status',
    });
  },
  getStatusFinisheOptions: (
    firstOption = { value: SELECT_UNSELECT_NONE, label: 'Select status' }
  ) => {
    const options = [
      { label: 'Pending', value: loc.STATUS.PENDING },
      { label: 'SUCCESS', value: loc.STATUSFINISH.SUCCESS },
      { label: 'FAILED', value: loc.STATUSFINISH.FAILED },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusFinisheLabel: (status) => {
    let lab = loc.getStatusOptions().find((oo) => {
      return oo.value === status;
    });
    return lab ? lab.label : '';
  },
  getStatusFinisheOptionsAll: () => {
    return loc.getStatusOptions({
      value: SELECT_UNSELECT_NONE,
      label: 'All status',
    });
  },
};

const OrderActions = Object.assign(fs, loc);

export default OrderActions;
