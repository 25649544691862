import useIsMounted from '@utilityjs/use-is-mounted';
import React, { useEffect, useState } from 'react';
import TransportTypeActions from '../../actions/TransportTypeActions';
import MySelect from '../antd/MySelect';
import getText from '../../lang';

const SelectUserTransportType = (props) => {
  const { withAll } = props;

  const isMounted = useIsMounted();

  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let fill = async () => {
      setLoading(true);
      let listTrType;
      if (withAll) {
        listTrType = await TransportTypeActions.getFullListOptionsAll();
      } else {
        listTrType = await TransportTypeActions.getFullListOptions();
      }
      if (isMounted()) {
        setListData(listTrType);
        setLoading(false);
      }
    };
    fill();
  }, [isMounted, withAll]);

  return (
    <MySelect
      {...props}
      options={listData}
      loading={loading}
      placeholder={
        withAll
          ? getText('WORD_SELECT_ALL_TRANSPORTS')
          : getText('WORD_SELECT_TRANSPORT')
      }
    />
  );
};

export default SelectUserTransportType;
