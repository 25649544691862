import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import getText from '../../lang';
import { Form } from 'antd';
import InputFormTextArea from '../../components/antd/InputFormTextArea';
import MyPanelFooter from '../../components/MyPanelFooter';
import InputFormText from '../../components/antd/InputFormText';
import NotificationsActions from '../../actions/NotificationsActions';
import { notifyError, notifySuccess } from '../../components/notify';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import { useLocation } from 'react-router-dom';
import useWindowSize from '../../utils/useWindowSize';

const NotificationsSettingsEmail = (props) => {
  const { state } = useLocation();
  const { isMobile } = useWindowSize();

  const item = state?.item;

  const navigateApi = useNavigateApi();
  const [form] = Form.useForm();

  const [textareaValue, setTextareaValue] = useState('');
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (item && item.message) {
      setTextareaValue(item.message);
      setCurrentCursorPosition(item.message.length);
      textCount(item.message);
    }
  }, [item]);

  const onFinish = async (values) => {
    values.notificationType = item.notificationsType;
    values.type = item.type;
    let result = await NotificationsActions.updateEmail(values);
    if (result.success) {
      notifySuccess(getText('WORD_TEMPLATE_UPDATED_SUCCESSFULLY'));
      navigateApi('notifications');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
  };

  const addNameInMessage = (text) => {
    let previousText = textareaValue || '';
    let newText = [
      previousText.slice(0, currentCursorPosition),
      text,
      previousText.slice(currentCursorPosition),
    ].join('');
    setTextareaValue(newText);
    setCurrentCursorPosition(newText.length);
    form.setFieldsValue({ message: newText });
  };

  const textCount = (text) => {
    let countKey = text.split('{{TRACKING_URL}}').length;
    if (countKey > 1) {
      let tmpValue = text.split('{{TRACKING_URL}}').join('');
      let tmpValueLength = tmpValue.length + (countKey - 1) * 24;
      setCount(tmpValueLength);
    } else {
      setCount(text.length);
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock {...props} title={getText('WORD_EDIT_ORDER_STARTED_TEMPLATE')}>
        <MyForm
          form={form}
          name="editnotificationsEmail"
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          initialValues={{
            message: item ? item.message : '',
            subject: item ? item.subject : '',
          }}
        >
          <div className="textarea-with-notes-wrapper">
            <InputFormText
              name="subject"
              label={getText('WORD_SUBJECT')}
              required
            />
            {item?.notificationsType ===
            NotificationsActions.NOTIFICATIONACTION.ORDER_START ? (
              <div className="customer-sms-tags">
                {getText('WORD_YOU_CAN_USE')}
                <label
                  style={{ cursor: 'pointer', color: '#0076FF' }}
                  onClick={() => {
                    setCount(count + 24);
                    addNameInMessage('{{TRACKING_URL}}');
                  }}
                >
                  {' {{TRACKING_URL}} '},
                </label>
                {getText(
                  'WORD_IN_MESSAGES_BELOW_TO_INSERT_THE_CUSTOMER_COMPANY_ORDER_ID_OR_AND_TRACKING_URL'
                )}
              </div>
            ) : item?.notificationsType ===
              NotificationsActions.NOTIFICATIONACTION.ORDER_DELIVERED ? (
              <div className="customer-sms-tags">
                {getText('WORD_YOU_CAN_USE')}
                <label
                  style={{ cursor: 'pointer', color: '#0076FF' }}
                  onClick={() => {
                    setCount(count + 24);
                    addNameInMessage('{{REVIEW_URL}}');
                  }}
                >
                  {' {{REVIEW_URL}} '},
                </label>
                {getText(
                  'WORD_IN_MESSAGES_BELOW_TO_INSERT_THE_CUSTOMER_COMPANY_ORDER_ID_OR_AND_RATING_URL'
                )}
              </div>
            ) : null}
            <div className="count-textarea">
              <InputFormTextArea
                name="message"
                minRows={4}
                placeholder={getText('WORD_MESSAGE')}
                onChange={(e) => {
                  setCurrentCursorPosition(e.target.selectionStart);
                  setTextareaValue(e.target.value);
                  textCount(textareaValue);
                }}
                detectCurrentPosition={(e) => {
                  setCurrentCursorPosition(e.target.selectionStart);
                  textCount(textareaValue);
                }}
                required
                value={item ? item.message : textareaValue}
              />
              {<div className="show-textarea-count">{count}</div>}
            </div>
            {item?.notificationsType === 1 ? (
              <>
                <div className="customer-sms-notes">
                  {getText('WORD_CUSTOMER_SMS_NOTES_1')}
                </div>
                <div className="customer-sms-notes">
                  {getText('WORD_CUSTOMER_SMS_NOTES_2')}
                </div>
                <div className="customer-sms-notes last">
                  {getText('WORD_CUSTOMER_SMS_NOTES_3')}
                </div>
              </>
            ) : (
              <>
                <div className="customer-sms-notes">
                  {getText('WORD_CUSTOMER_SMS_NOTES_1')}
                </div>
                <div className="customer-sms-notes last">
                  {getText('WORD_CUSTOMER_SMS_NOTES_3')}
                </div>
              </>
            )}
            <MyPanelFooter
              onCancelClick={() => {
                navigateApi('notifications');
              }}
              onPrimaryClick={() => {}}
              labelPrimary={getText('WORD_UPDATE')}
              labelCancel={getText('WORD_CANCEL')}
              formName={'editnotificationsEmail'}
            />
          </div>
        </MyForm>
      </MyBlock>
    </>
  );
};

export default NotificationsSettingsEmail;
