import React from 'react';

const MyRow = (props) => {
  const {
    withoutTitle,
    titleStyle,
    bottomZero,
    title,
    onClick,
    className,
    children,
    style,
  } = props;

  return (
    <>
      {withoutTitle ? null : (
        <h6
          className="my-block-title"
          style={Object.assign(titleStyle ? titleStyle : {}, {
            marginBottom: bottomZero ? 0 : 15,
          })}
        >
          {title}
        </h6>
      )}
      <div
        onClick={onClick}
        className={`my-row ${className ? className : ''}`}
        style={style}
      >
        {children}
      </div>
    </>
  );
};

export default MyRow;
