import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';
import getText from '../lang';

const URL_SAVE = '/manifests/save';
const URL_GET_ONE = '/manifests/get_one';
const URL_PAGINATION_LIST = '/manifests/list';
const URL_ACTIVE_LIST = '/manifests/get_all/active';
const URL_UPDATE_CHANGE = '/manifests/update/change';

const fs = {
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  saveUpdateChange: async (values, idKey) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(
      URL_UPDATE_CHANGE + '/' + idKey,
      urlParams
    );
    return result;
  },
  save: async (values, idKey = false) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idKey) {
      result = await fetchFromUrlPOSTAsync(URL_SAVE + '/' + idKey, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
  fillActive: async () => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(URL_ACTIVE_LIST, urlParams);
    return result;
  },
};

const loc = {
  STATUS: {
    ACTIVE: 1,
    ACTIVE_AND_SEND: 2,
    INPROGRESS: 3,
    FINISHED: 4,
  },

  getStatusOptions: (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT_STATUS'),
    }
  ) => {
    const options = [
      { label: 'Active', value: loc.STATUS.ACTIVE },
      { label: 'InProgress', value: loc.STATUS.INPROGRESS },
      { label: 'Finished', value: loc.STATUS.FINISHED },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusOptionsAll: () => {
    return loc.getStatusOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL_STATUSES'),
    });
  },
  getStatusLabel: (status) => {
    switch (status) {
      case loc.STATUS.ACTIVE:
        return getText('WORD_STATUS_ACTIVE');
      case loc.STATUS.ACTIVE_AND_SEND:
        return getText('WORD_STATUS_ACTIVE_SEND');
      case loc.STATUS.INPROGRESS:
        return getText('WORD_STATUS_INPROGRESS');
      case loc.STATUS.FINISHED:
        return getText('WORD_STATUS_FINISHED');
      default:
        return '';
    }
  },
};

const ManifestActions = Object.assign(fs, loc);

export default ManifestActions;
