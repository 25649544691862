import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import SendEmailIcon from '../../icons/svg/SendEmailIcon';
import SendEmail from '../../components/SendEmail';
import { LeftOutlined } from '@ant-design/icons';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const ForgotPasswordRequestAfter = (props) => {
  const navigateApi = useNavigateApi();

  const { state } = useLocation();

  return !state ||
    !state?.hasOwnProperty('type') ||
    state?.type !== 'forgotPasswordRequest' ? (
    <Navigate to="/en/login" />
  ) : (
    <div className="register-after-wrapper">
      <div
        className="back-to-login"
        onClick={() => {
          navigateApi('login');
        }}
      >
        <LeftOutlined color={'#0076FF'} />
        {getText('WORD_BUTTON_BACK')}
      </div>
      <div className="send-email-icon">
        <SendEmailIcon />
      </div>
      <h2 className="send-email-title">Almost done!</h2>
      <span className="send-email-text">
        Your application has been successfully sent. Our experts will review
        your application as soon as possible. You will receive an email on the
        answer. In case of mail or our questions, our specialists will contact
        you with the number mentioned in the application.
      </span>

      <SendEmail />
    </div>
  );
};

export default ForgotPasswordRequestAfter;
