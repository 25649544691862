const numberFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 3,
});
const UtilNumber = {
  formatDouble: (value) => {
    if (isNaN(value)) {
      return '';
    }
    try {
      return numberFormat.format(value);
    } catch (error) {}
    return value;
  },
  formatKMB(num, digits = 3) {
    if (!num) {
      return 0;
    }
    var si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (Math.abs(num) >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  },
};

export default UtilNumber;
