import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import InputSearch from '../../components/antd/InputSearch';
import MainPage from '../../components/MainPage';
import getText from '../../lang';
import SmsHistoryActions from '../../actions/SmsHistoryActions';
import utilDate from '../../utils/utilDate';
import { TABLE_FETCH_PAGE_SIZE } from '../../utils';
import { notifyError } from '../../components/notify';
import TextWithLinks from './TextWithLinks';
import InfiniteScrollTable from '../../components/InfiniteScrollTable/InfiniteScrollTable';
import SmsHistoryFilterDrawer from './SmsHistoryFilterDrawer';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import { Popover, Space, Statistic } from 'antd';
import SmsExportExcel from './SmsExportExcel';
import useIsMounted from '@utilityjs/use-is-mounted';
import './SmsHistoryList.scss';

const SmsHistory = () => {
  const isMounted = useIsMounted();

  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setpagination] = useState({
    page: 0,
    perPage: TABLE_FETCH_PAGE_SIZE,
    searchName: '',
  });
  const [loadingData, setLoadingData] = useState(true);
  const [filterData, setFilterData] = useState({
    fromDate: utilDate.getThisMonthStart(),
    toDate: utilDate.getThisMonthEnd(),
    notificationsType: '',
    status: '',
  });
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setInitialLoading(true);
  }, []);

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: { createDate: 'DESC' },
        filter: filterData,
        q: pagination?.searchName || '',
      };
      let result = await SmsHistoryActions.fillPaginationList(fetchParams);
      if (isMounted()) {
        if (result.success) {
          setInitialLoading(false);
          if (pagination?.page === 0) {
            setTableData(result.data);
          } else {
            setTableData((ov) => [...ov, ...(result.data ?? [])]);
          }
          setTotalCount(result.totalRows);
        } else {
          notifyError(result.errMsg);
        }
        setLoadingData(false);
      }
    };
    fill();
  }, [pagination, filterData, isMounted]);

  const columns = [
    {
      title: getText('WORD_SEND'),
      dataIndex: 'status',
      key: 'status',
      width: 'fit-content',
      align: 'center',
      render: (value, { resultInfo }) => {
        return (
          <Popover content={resultInfo} title="Sms info" placement="rightTop">
            <div>
              {value ? (
                <CheckCircleOutlined
                  style={{ color: '#abd40b', fontSize: 22 }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ color: '#ff0808', fontSize: 22 }}
                />
              )}
            </div>
          </Popover>
        );
      },
    },
    {
      title: getText('WORD_TEL'),
      dataIndex: 'phoneNumber',
      key: '1',
      width: '10%',
    },
    {
      title: getText('WORD_DATE'),
      dataIndex: 'createDate',
      key: '2',
      width: '12%',
      render: (value) => {
        return <div>{utilDate.formatDateTime(value)}</div>;
      },
    },
    {
      title: getText('WORD_NOTIFICATION_TYPE'),
      dataIndex: 'notificationsType',
      key: '3',
      width: '10%',
      render: (value) => {
        return <div>{SmsHistoryActions.getTypeLabel(value).title}</div>;
      },
    },
    {
      title: getText('WORD_TEXT_BODY'),
      dataIndex: 'textBody',
      key: '4',
      render: (value) => {
        return <TextWithLinks text={value} />;
      },
    },
  ];

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onPressEnter={(v) => {
            setpagination((ov) =>
              Object.assign({}, ov, { page: 0, searchName: v })
            );
          }}
        />
        <SmsHistoryFilterDrawer
          onChange={(fil) => {
            setFilterData(fil);
            setpagination((ov) => {
              ov.page = 0;
              return ov;
            });
          }}
          filter={filterData}
        />
        <SmsExportExcel search={pagination?.searchName} filter={filterData} />
      </HeaderSpace>
      <MainPage
        title={
          <Space>
            {getText('WORD_SMS_HISTORY')}
            <Statistic value={totalCount} prefix={<MobileOutlined />} />
          </Space>
        }
        topZero
      >
        {initialLoading ? (
          <LoadingOutlinedApp />
        ) : (
          <InfiniteScrollTable
            rowKey="idKey"
            dataSource={tableData}
            dataLength={totalCount}
            columns={columns}
            loading={loadingData}
            onLoadMore={() => {
              setpagination((ov) =>
                Object.assign({}, ov, { page: ov.page + 1 })
              );
            }}
          />
        )}
      </MainPage>
    </>
  );
};

export default SmsHistory;
