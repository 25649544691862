import React, { memo } from 'react';
import { Badge } from 'antd';
import CalButton from '../../components/CalButton';
import MyRow from '../../components/MyRow';
import getText from '../../lang';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import { formatPhoneNumber } from '../../utils';

const DashboardCardItem = (props) => {
  const { item, transport, index } = props;

  return (
    <div key={index} className="dashboard-right-side-driver-block">
      <MyRow bottomZero className="order-view-user-wrapper">
        <MyRow>
          <Badge dot={item.driverIsOnline}>
            <ProfileAvatar icon={item.icon} value={item.fullName} />
          </Badge>
          <div className="order-card-name-type-wrapper">
            <div className="user-name-div">{item.fullName}</div>
          </div>
        </MyRow>
        <CalButton
          size="small"
          style={{ width: 13, height: 13, display: 'flex', marginTop: -5 }}
          phoneNumber={item.phoneCode + item.phoneNumber}
        />
      </MyRow>
      <MyRow bottomZero className="customer-or-driver-info">
        <div className="first-item">{getText('WORD_PHONE')}</div>
        <div className="second-item">
          {formatPhoneNumber(item.phoneCode, item.phoneNumber)}
        </div>
      </MyRow>
      <MyRow bottomZero className="customer-or-driver-info">
        <div className="first-item">{getText('WORD_EMAIL')}</div>
        <div className="second-item">{item.email}</div>
      </MyRow>
      <MyRow bottomZero className="customer-or-driver-info">
        <div className="first-item">
          {transport ? transport : getText('WORD_NOT_TRANSPORT')}
        </div>
        <div className="second-item">
          {item.transportTypeName +
            (item.transportNumber ? ' - ' + item.transportNumber : '')}
        </div>
      </MyRow>
    </div>
  );
};

export default memo(DashboardCardItem);
