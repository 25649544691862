import React from 'react';
import MyRow from '../../components/MyRow';
import { Switch } from 'antd';
import EditIcon from '../../icons/svg/EditIcon';
import ServiceActions from '../../actions/ServiceActions';
import { notifySuccess, notifyError } from '../../components/notify';
import ServiceTransportPriceActions from '../../actions/ServiceTransportPriceActions';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import { useSelector } from 'react-redux';
import './style.scss';

const ServiceListCard = (props) => {
  const navigateApi = useNavigateApi();

  const { item, onChange } = props;

  const symbol = useSelector((state) => {
    return state.AuthReducer?.currentUser?.clientCurrencySymbol;
  });

  return (
    <div className="my-card service-list-card">
      <MyRow className="first-row">
        <div className="with-edit-buttin">
          <p>{item.name}</p>
          <div
            className="edit-icon"
            onClick={(e) => {
              e.stopPropagation();
              navigateApi('services/edit/' + item.idKey);
            }}
          >
            <EditIcon />
          </div>
        </div>
        <Switch
          checked={item.status === ServiceActions.STATUS.ACTIVE}
          onChange={async () => {
            let result;
            if (item.status === ServiceActions.STATUS.INACTIVE) {
              result = await ServiceActions.changeStatusActive(item.idKey);
            } else {
              result = await ServiceActions.changeStatusInActive(item.idKey);
            }
            if (result.success) {
              notifySuccess(
                'Status Changed successfuly (' + result.data.name + ')'
              );
              onChange && onChange(result.data);
            } else {
              notifyError(result.errMsg);
            }
          }}
        />
      </MyRow>
      {item.itemsPrice.map(function (item, index) {
        let price = '-';
        if (item.isUsed) {
          if (
            item.priceMethod ===
            ServiceTransportPriceActions.PRICEMETHOD.BYDISTANCE
          ) {
            price = `${item.minKm}km/${item.minPriceKm}${symbol}/${item.oneKmPrice}${symbol}`;
          } else {
            price = `${symbol}  ${item.price}`;
          }
        }

        return (
          <div key={index} className="list-service-card-price-wrapper">
            <div className="divider-line" />
            <MyRow className="second-row">
              <p>{getText(item.transportName, true)}</p>
              <p className="price-text">{price}</p>
            </MyRow>
          </div>
        );
      })}
    </div>
  );
};

ServiceListCard.defaultProps = {
  item: {},
};

export default ServiceListCard;
