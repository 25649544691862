import React from 'react';
import GmailIcon from '../../icons/svg/GmailIcon';
import MailRuIcon from '../../icons/svg/MailRuIcon';
import YandexIcon from '../../icons/svg/YandexIcon';
import './style.scss';

const SendEmail = (props) => {
  return (
    <div className="send-email-icon-wrapper">
      <div className="icon-block">
        <GmailIcon />
      </div>
      <div className="icon-block">
        <MailRuIcon />
      </div>
      <div className="icon-block">
        <YandexIcon />
      </div>
    </div>
  );
};

export default SendEmail;
