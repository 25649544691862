import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingOutlinedApp = () => {
  return (
    <div className="spin-wrapper">
      <LoadingOutlined style={{ fontSize: '60px', color: '#08c' }} />
    </div>
  );
};

export default LoadingOutlinedApp;
