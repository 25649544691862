import React, { useEffect, useState } from 'react';
import UtilNumber from '../../../utils/utilNumber';
import getText from '../../../lang';
import InputNumberWithAfterBefore from '../../../components/antd/InputNumberWithAfterBefore/InputNumberWithAfterBefore';
import MyTextWithBadge from '../../../components/myTextWithBadge/MyTextWithBadge';
import { notifyError } from '../../../components/notify';
import MyRow from '../../../components/MyRow';
import { Button, Space } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './SplitListItemStyle.scss';

const SplitListItem = (props) => {
  const { item, position, onClickSendAll, onClickSend, name, index } = props;

  const [inputValue, setInputValue] = useState(0);
  const [inputValueCurrent, setinputValueCurrent] = useState(0);

  useEffect(() => {
    if (item && item.itemQuantity) {
      setInputValue(item.itemQuantity);
      setinputValueCurrent(item.itemQuantity);
    }
  }, [item, item.itemQuantity]);

  return (
    <MyRow withoutTitle className="fields-item-with-btn">
      {index?.toString().length ? <div>{index + 1}-</div> : null}
      <div className="field-item-left-with-btn">
        <div className="name">{item?.itemName}</div>
        <div className="store">{item?.store}</div>
      </div>
      <div className="field-item-right-with-btn">
        <div className="input-with-helper-text">
          <div className="helper-text">{item.itemUnit}</div>
          <InputNumberWithAfterBefore
            name={name}
            min={0}
            value={inputValue}
            onChange={(e) => setInputValue(e)}
            addonAfter={
              <Button
                className="input-button"
                type="button"
                onClick={() => {
                  if (inputValue > 0 && inputValue <= inputValueCurrent) {
                    if (inputValueCurrent === inputValue) {
                      if (inputValue === 1) {
                        onClickSendAll && onClickSendAll(item, 1);
                      } else if (inputValue > 0) {
                        onClickSend && onClickSend(item, 1);
                      }
                    } else {
                      onClickSend && onClickSend(item, inputValue);
                    }
                  } else {
                    setInputValue(inputValueCurrent);
                    notifyError(
                      inputValue === 0
                        ? getText('ERROR_QUANTITY_MUST_BE_GREATER_THAN_ZERO')
                        : getText('ERROR_QUANTITY_IS_GREATER_THANAVAILABLE')
                    );
                  }
                }}
              >
                {position === 'right' ? (
                  <ArrowLeftOutlined />
                ) : (
                  <ArrowRightOutlined />
                )}
              </Button>
            }
            required
          />
          <div className="helper-text">
            <Space>
              <span className="numerator">
                {UtilNumber.formatDouble(item.sizeWeight)}{' '}
                {getText('WORD_KG_LC')}
              </span>
              /
              <span className="denominator">
                {UtilNumber.formatDouble(item.sizeM3)}{' '}
                <MyTextWithBadge
                  className="helper-text"
                  mainText={getText('WORD_M3_LC')}
                  topSymbol={3}
                />
              </span>
            </Space>
          </div>
        </div>

        <Button
          type="primary"
          className="fields-right-btn"
          onClick={() => {
            onClickSendAll && onClickSendAll(item, inputValueCurrent);
          }}
        >
          {position === 'right' ? (
            <ArrowLeftOutlined />
          ) : (
            <ArrowRightOutlined />
          )}
        </Button>
      </div>
    </MyRow>
  );
};

export default SplitListItem;
