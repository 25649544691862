import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import InputFormEmail from '../../components/antd/InputFormEmail';
import SelectForm from '../../components/antd/SelectForm';
import MyPanelFooter from '../../components/MyPanelFooter';
import InputFormText from '../../components/antd/InputFormText';
import { useForm } from 'antd/lib/form/Form';
import UserActions from '../../actions/UserActions';
import InputFormPhoneNumber from '../../components/antd/InputFormPhoneNumber';
import CountryActions from '../../actions/CountryActions';
import TransportTypeActions from '../../actions/TransportTypeActions';
import RolesActions from '../../actions/RolesActions';
import { useParams } from 'react-router-dom';
import InputFormPassword from '../../components/antd/InputFormPassword';
import { notifySuccess, notifyError } from '../../components/notify';
import getText from '../../lang';
import InputFormNumber from '../../components/antd/InputFormNumber';
import MyRow from '../../components/MyRow';
import ProfileAvatarFormS3 from '../../components/antd/ProfileAvatarFormS3';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import { useSelector } from 'react-redux';
import './style.scss';
import './styleResponsive.scss';

const ServicesEdit = (props) => {
  let { _id } = useParams();

  const { isNew } = props;

  const clientPhoneCode = useSelector((state) => {
    return state.AuthReducer?.currentUser?.clientPhoneCode;
  });

  const clientName = useSelector((state) => {
    return state.AuthReducer?.currentUser?.clientName;
  });

  const { isMobile } = useWindowSize();
  const navigateApi = useNavigateApi();
  const defaultRole = UserActions.ROLE.DRIVER;
  const [form] = useForm();
  const [selectedRole, setSelectedRole] = useState(defaultRole);
  const [listCountries, setListCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [saving, setSaving] = useState(false);
  const [listTransportTypes, setListTransportTypes] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [codeForSesrch, setCodeForSesrch] = useState('+374');

  useEffect(() => {
    let fill = async () => {
      setListCountries(await CountryActions.getFullListOptions());
      setListTransportTypes(await TransportTypeActions.getFullListOptions());
      setListRoles(await RolesActions.getFullListOptions());
    };
    fill();
  }, []);

  useEffect(() => {
    async function fill() {
      let cnt = listCountries.find((country) => {
        return country.phoneCode === codeForSesrch;
      });
      setSelectedCountry(cnt);
    }
    fill();
  }, [listCountries, codeForSesrch]);

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await UserActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
          });
          setSelectedRole(resultGet.data.idRole);
          setCodeForSesrch(resultGet.data.phoneCode);
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('users/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  const onFinish = async (values) => {
    if (selectedRole !== UserActions.ROLE.DRIVER) {
      values.transportKg = 0;
      values.transportM3 = 0;
    }
    setSaving(true);
    values.idPolicy = 1;
    let result = await UserActions.saveUser(values, _id);
    setSaving(false);
    if (result.success) {
      notifySuccess(
        isNew
          ? getText('WORD_USER_CREATED_SUCCESSFULY')
          : getText('WORD_USER_UPDATED_SUCCESSFULY')
      );
      navigateApi('users/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
    setSaving(false);
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock {...props} bottomZero className={'user-page-edit-wrapper'}>
        <MyForm
          name="userForm"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            transportKg: 0,
            transportM3: 0,
            phoneCode: clientPhoneCode || '',
          }}
        >
          <MyBlock
            title={getText('WORD_USER_INFO_TITLE')}
            topZero
            bottomZero
            leftZero
          >
            <ProfileAvatarFormS3
              name="icon"
              label={getText('WORD_AVATAR')}
              imageUrl={form.getFieldValue('icon')}
              value={clientName || ''}
              onChange={(img) => {
                form.setFieldsValue({ icon: img });
              }}
            />

            <InputFormText
              name="fullName"
              label={getText('WORD_USER_NAME')}
              minLength={4}
              required
            />

            <InputFormEmail
              name="email"
              label={getText('WORD_EMAIL')}
              required={false}
            />
            <InputFormPhoneNumber
              name="phoneNumber"
              nameCode="phoneCode"
              label={getText('WORD_TEL')}
              length={selectedCountry ? selectedCountry.phoneCodeLength : 15}
              options={listCountries?.map((it) => {
                return { label: it.phoneCode, value: it.phoneCode };
              })}
              onChangeCode={(codeItem) => {
                let country = listCountries.find((countery) => {
                  return countery.phoneCode === codeItem.value;
                });
                if (country) {
                  setSelectedCountry(country);
                  form.validateFields(['phoneNumber'], { force: true });
                }
              }}
              required
            />
            <SelectForm
              label={getText('WORD_USER_ROLE')}
              name="idRole"
              options={listRoles}
              onChange={(role) => {
                setSelectedRole(role);
              }}
              required
            />
          </MyBlock>

          <MyBlock
            title={getText('WORD_USER_LOGIN_SECTION_TITLE')}
            topZero
            bottomZero
            leftZero
          >
            <InputFormText
              name="login"
              label={getText('WORD_USER_LOGIN')}
              minLength={3}
              required
            />
            <InputFormPassword
              name="password"
              label={getText('WORD_PASSWORD')}
              autoComplete="new-password"
              required={Boolean(isNew)}
            />
          </MyBlock>
          {selectedRole === UserActions.ROLE.DRIVER && (
            <MyBlock
              title={getText('WORD_USER_TRANSPORT_SECTION_TITLE')}
              topZero
              bottomZero
              leftZero
            >
              <SelectForm
                label={getText('WORD_TRANSPORT_TYPE')}
                name="idTransportType"
                options={listTransportTypes}
                showSearch={true}
                required
              />
              <InputFormText
                label={getText('WORD_TRANSPORT_ID')}
                name="transportNumber"
              />
              <InputFormText
                label={getText('WORD_TRANSPORT_DETAILS')}
                name="transportDescription"
              />
              <MyRow className="two-input">
                <InputFormNumber
                  name="transportKg"
                  label={getText('WORD_KG')}
                  min={0}
                  required
                  className="two-inputs-num"
                />
                <InputFormNumber
                  name="transportM3"
                  label={getText('WORD_M3_WITH_3')}
                  min={0}
                  required
                  className="two-inputs-num"
                />
              </MyRow>
            </MyBlock>
          )}
          <MyPanelFooter
            onCancelClick={() => {
              navigateApi('users/list');
            }}
            formName={'userForm'}
            labelPrimary={
              isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
            }
            labelCancel={getText('WORD_CANCEL')}
            loading={saving}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default ServicesEdit;
