import React from 'react';
import MyRow from '../../components/MyRow';
import { Switch } from 'antd';
import EditIcon from '../../icons/svg/EditIcon';
import ReasonsActions from '../../actions/ReasonsActions';
import { notifySuccess, notifyError } from '../../components/notify';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import './style.scss';

const ReasonsListCard = (props) => {
  const navigateApi = useNavigateApi();

  const { item, onChange } = props;

  return (
    <div className="reasons-list-card">
      <MyRow className="first-row">
        <div className="with-edit-button">
          <p>{item.reasonTitle}</p>
          <div
            className="edit-icon"
            onClick={(e) => {
              e.stopPropagation();
              navigateApi('reasons/edit/' + item.idKey);
            }}
          >
            <EditIcon />
          </div>
        </div>
        <Switch
          checked={item.status === ReasonsActions.STATUS.ACTIVE}
          onChange={async () => {
            let result;
            if (item.status === ReasonsActions.STATUS.INACTIVE) {
              result = await ReasonsActions.changeStatusActive(item.idKey);
            } else {
              result = await ReasonsActions.changeStatusInActive(item.idKey);
            }
            if (result.success) {
              notifySuccess(
                'Status Changed successfuly (' + result.data.reasonTitle + ')'
              );
              onChange && onChange(result.data);
            } else {
              notifyError(result.errMsg);
            }
          }}
        />
      </MyRow>
    </div>
  );
};

ReasonsListCard.defaultProps = {
  item: {},
};

export default ReasonsListCard;
