import React from 'react';
import { toast } from 'react-toastify';
import './notify.scss';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

const config = {
  closeButton: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  pauseOnFocusLoss: false,
  position: 'top-right',
  hideProgressBar: true,
  autoClose: 3000,
  newestOnTop: true,
  icon: ({ _, type }) => {
    switch (type) {
      case 'error':
        return <CloseCircleOutlined />;
      case 'success':
        return <CheckCircleOutlined />;
      case 'warning':
        return <WarningOutlined />;
      case 'toast':
        return <InfoCircleOutlined />;
      default:
        break;
    }
  },
};

export const notifySuccess = (text) => toast.success(text, config);

export const notifyError = (text) => toast.error(text, config);

export const notifywarning = (text) => toast.warning(text, config);

export const notifyText = (text) => toast(text, config);
