import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';
import getText from '../lang';

const URL_LIST = '/transport_types/get_all';

const fs = {
  getFullList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result.success ? result.data : [];
  },
};

const loc = {
  TYPE: {
    CAR: 1,
    WALK: 2,
    BICYCLE: 3,
    TRACK: 4,
  },
  getMapRoutingMode: (it) => {
    switch (it) {
      case TransportTypeActions.TYPE.WALK:
        return 'pedestrian';
      case TransportTypeActions.TYPE.BICYCLE:
        return 'bicycle';
      default:
        return 'auto';
    }
  },
  getFullListOptions: async (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_TRANSPORT_TYPE'),
    }
  ) => {
    let items = await fs.getFullList();
    const options = items.map((obj) => {
      return { value: obj.idTransportType, label: getText(obj.name, true) };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getFullListOptionsAll: async () => {
    return await loc.getFullListOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL_TRANSPORTS'),
    });
  },
};

const TransportTypeActions = Object.assign(fs, loc);

export default TransportTypeActions;
