import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.971"
    height="16.52"
    viewBox="0 0 20.971 16.52"
  >
    <g id="menu-icon" transform="translate(-0.001 1)">
      <path
        id="Path_2561"
        data-name="Path 2561"
        d="M50.66,30.02H69.113a1.252,1.252,0,0,0,1.259-1.259h0a1.252,1.252,0,0,0-1.259-1.26H50.66A1.252,1.252,0,0,0,49.4,28.759h0a1.24,1.24,0,0,0,1.259,1.26Z"
        transform="translate(-49.4 -28.5)"
        fill="#7e7d89"
      />
      <path
        id="Path_11427"
        data-name="Path 11427"
        d="M50.66,30.02H69.113a1.252,1.252,0,0,0,1.259-1.259h0a1.252,1.252,0,0,0-1.259-1.26H50.66A1.252,1.252,0,0,0,49.4,28.759h0a1.24,1.24,0,0,0,1.259,1.26Z"
        transform="translate(-49.4 -21.5)"
        fill="#7e7d89"
      />
      <path
        id="Path_11428"
        data-name="Path 11428"
        d="M50.66,30.02H69.113a1.252,1.252,0,0,0,1.259-1.259h0a1.252,1.252,0,0,0-1.259-1.26H50.66A1.252,1.252,0,0,0,49.4,28.759h0a1.24,1.24,0,0,0,1.259,1.26Z"
        transform="translate(-49.4 -14.5)"
        fill="#7e7d89"
      />
    </g>
  </svg>
);

const HamburgerMenuIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default HamburgerMenuIcon;
