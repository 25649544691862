import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_UPDATE_SMS = '/templates/updatesms';
const URL_UPDATE_EMAIL = '/templates/updateemail';
const URL_GET_ONE = '/templates/get_one';
const URL_STATUS_ENABLE = '/notifications/enable';
const URL_STATUS_DISABLE = '/notifications/disable';
const URL_PAGINATION_LIST = '/notifications/list';

const fs = {
  getOneByType: async (type, templateType) => {
    const urlParams = [
      {
        key: 'notificationType',
        value: type,
      },
      {
        key: 'type',
        value: templateType,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_GET_ONE, urlParams);
    return result;
  },
  updateSMS: async (values) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    result = await fetchFromUrlPOSTAsync(URL_UPDATE_SMS, urlParams);
    return result;
  },
  updateEmail: async (values) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    result = await fetchFromUrlPOSTAsync(URL_UPDATE_EMAIL, urlParams);
    return result;
  },
  changeStatusEnable: async (type, templateType) => {
    const urlParams = [
      {
        key: 'notificationType',
        value: type,
      },
      {
        key: 'templateType',
        value: templateType,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_STATUS_ENABLE, urlParams);
    return result;
  },
  changeStatusDisable: async (type, templateType) => {
    const urlParams = [
      {
        key: 'notificationType',
        value: type,
      },
      {
        key: 'templateType',
        value: templateType,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_STATUS_DISABLE, urlParams);
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
};

const template = {
  TYPE: {
    SMS: 1,
    EMAIL: 2,
  },
  NOTIFICATIONACTION: {
    ORDER_START: 1,
    ORDER_DELIVERED: 2,
    ORDER_NOT_DELIVERED: 3,
  },
};

const NotificationsActions = Object.assign(fs, template);

export default NotificationsActions;
