import { Button, Drawer, Form, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import getText from '../../lang';
import MyForm from '../../components/MyForm';
import FilterIcon from '../../icons/svg/FilterIcon';
import InputFormDatePickerRange from '../../components/antd/InputFormDatePickerRange';
import SelectSmsType from '../../components/selects/SelectSmsType';
import SelectSmsStatus from '../../components/selects/SelectSmsStatus';

const SmsHistoryFilterDrawer = (props) => {
  const { filter, onChange } = props;

  const [showDrawer, setShowDrawer] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...filter,
      DateRange: [filter.fromDate, filter.toDate],
    });
  }, [filter, form]);

  const onFinish = (values) => {
    values.fromDate = values.DateRange[0];
    values.toDate = values.DateRange[1];
    onChange && onChange(values);
  };

  return (
    <div className="drawer-wrapper">
      <Button
        className="filter-button"
        icon={<FilterIcon />}
        onClick={() => setShowDrawer(true)}
        type="filter"
      >
        {getText('WORD_FILTERS')}
      </Button>
      <Drawer
        forceRender
        title={
          <Space
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => setShowDrawer(false)}
              type="text"
              className="filter-form-button"
            >
              {getText('WORD_CANCEL')}
            </Button>
            <Button
              type="text"
              htmlType="submit"
              form={'smsFilterForm'}
              onClick={() => setShowDrawer(false)}
              className="filter-form-button"
            >
              {getText('WORD_FILTER_APPLY')}
            </Button>
          </Space>
        }
        placement={'right'}
        closable={false}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        key={'right'}
      >
        <MyForm form={form} name="smsFilterForm" onFinish={onFinish}>
          <InputFormDatePickerRange
            name="DateRange"
            onChange={(val) => form.setFieldsValue({ DateRange: val })}
          />
          <SelectSmsType
            label={getText('WORD_NOTIFICATION_TYPE')}
            name="notificationsType"
          />
          <SelectSmsStatus label={getText('WORD_STATUS')} name="status" />
        </MyForm>
      </Drawer>
    </div>
  );
};

export default SmsHistoryFilterDrawer;
