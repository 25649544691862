import React, { useEffect, useRef, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import InputFormText from '../../components/antd/InputFormText';
import InputFormEmail from '../../components/antd/InputFormEmail';
import MyPanelFooter from '../../components/MyPanelFooter';
import { Form } from 'antd';
import MyMap from '../../components/MyMap';
import { SELECT_UNSELECT_NONE } from '../../utils';
import InputFormPhoneNumber from '../../components/antd/InputFormPhoneNumber';
import CountryActions from '../../actions/CountryActions';
import CustomerActions from '../../actions/CustomerActions';
import { useParams } from 'react-router-dom';
import { notifySuccess, notifyError } from '../../components/notify';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import { useSelector } from 'react-redux';
import './style.scss';
import './styleResponsive.scss';

const CustomerEdit = (props) => {
  let { _id } = useParams();
  const { isMobile, isDesktop } = useWindowSize();
  const navigateApi = useNavigateApi();

  const { isNew } = props;

  const clientPhoneCode = useSelector((state) => {
    return state.AuthReducer.currentUser?.clientPhoneCode;
  });

  const menuOpen = useSelector((state) => state.AuthReducer?.menuOpen);

  const refContainer = useRef(null);
  const [form] = Form.useForm();

  const [listCountries, setListCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(false);

  const [latLan, setLatLan] = useState([]);

  useEffect(() => {
    async function fill() {
      setListCountries(await CountryActions.getFullListCodeOptions());
    }
    fill();
  }, []);

  useEffect(() => {
    const refreshCodeValue = () => {
      let code = form.getFieldValue('phoneCode');
      let cnt = listCountries.find((country) => {
        return country.phoneCode === code;
      });
      setSelectedCountry(cnt);
    };
    refreshCodeValue();
  }, [listCountries, form]);

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await CustomerActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
            phoneNumber: resultGet.data.phoneNumber
              ? resultGet.data.phoneNumber.toString()
              : '',
          });
          setLatLan([resultGet.data.addressLat, resultGet.data.addressLng]);
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('customer/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  const onFinish = async (values) => {
    if (latLan.length) {
      values.addressLat = latLan[0];
      values.addressLng = latLan[1];
    }
    let result = await CustomerActions.save(values, _id);
    if (result.success) {
      notifySuccess(
        isNew
          ? getText('WORD_CUSTOMER_CREATED_SUCCESSFULY')
          : getText('WORD_CUSTOMER_UPDATED_SUCCESSFULY')
      );
      navigateApi('customer/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
  };

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      let suggestView = new ymaps.SuggestView('address', { results: 15 });
      suggestView.events.add('select', (e) => {
        let address = e.get('item').value;
        form.setFieldsValue({ address: address });
        setLocation(ymaps);
      });
      setLocation(ymaps);
    });
  };

  const setLocation = (ymaps) => {
    const MyMapRef = refContainer.current;
    let address = form.getFieldValue('address');
    ymaps.geocode(address, { results: 1 }).then((res) => {
      MyMapRef.geoObjects?.removeAll();
      setLatLan([]);
      if (res && res.geoObjects) {
        var MyGeoObj = res.geoObjects.get(0);
        const bounds = MyGeoObj.properties.get('boundedBy');
        let latLan = [
          MyGeoObj.geometry.getCoordinates()[0],
          MyGeoObj.geometry.getCoordinates()[1],
        ];
        setLatLan(latLan);
        var myPlacemark = new ymaps.Placemark(latLan);
        MyMapRef.geoObjects.add(myPlacemark);
        MyMapRef.setBounds(bounds, {
          checkZoomRange: true,
        });
      }
    });
  };

  const refreshCodeValue = () => {
    let code = form.getFieldValue('phoneCode');
    let cnt = listCountries.find((country) => {
      return country.phoneCode === code;
    });
    setSelectedCountry(cnt);
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <div className="create-address-wrapper">
        <MyBlock
          {...props}
          title={
            isNew
              ? getText('WORD_CUSTOMER_TITLE_NEW')
              : getText('WORD_CUSTOMER_TITLE_EDIT')
          }
        >
          <MyForm
            form={form}
            name="customerForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              typeSelect: SELECT_UNSELECT_NONE,
              phoneCode: clientPhoneCode || '',
              phoneNumber: '',
            }}
          >
            <InputFormText
              name="fullName"
              label={getText('WORD_NAME')}
              required
            />
            <InputFormEmail
              name="email"
              label={getText('WORD_EMAIL')}
              required={false}
            />
            <InputFormPhoneNumber
              name="phoneNumber"
              nameCode="phoneCode"
              label={getText('WORD_PHONE')}
              errorMessage={getText('WORD_ERROR_PHONE_NUMBER')}
              length={selectedCountry ? selectedCountry.phoneCodeLength : 15}
              options={listCountries}
              onChangeCode={(codeItem) => {
                refreshCodeValue();
                setTimeout(() => {
                  form.validateFields(['phoneNumber'], { force: true });
                }, 400);
              }}
              required
            />

            <InputFormText
              label={getText('WORD_ADDRESS')}
              name="address"
              id="address"
              onClear={() => {
                refContainer.current.geoObjects.removeAll();
              }}
              required
            />
            <MyPanelFooter
              onCancelClick={() => {
                navigateApi('customer/list');
              }}
              formName={'customerForm'}
              labelPrimary={
                isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
              }
              labelCancel={getText('WORD_CANCEL')}
            />
          </MyForm>
        </MyBlock>
        <div
          className={`customer-edit-map-wrapper ${
            menuOpen && isDesktop ? 'menu-open-for-map' : ''
          }`}
        >
          <MyMap
            ref={refContainer}
            onLoadMap={onLoadMap}
            height={isMobile ? 'calc(100vh - 400px)' : 'calc(100vh - 35px)'}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerEdit;
