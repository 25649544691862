import { useSelector } from 'react-redux';
import useNavigateApi from './useNavigateApi';
import { useEffect } from 'react';
import ApiStorage from '../reducers/ApiStorage';
import ApiLocalStorage from '../reducers/ApiLocalStorage';
import UtilObject from '../utils/UtilObject';

const RoutePrivate = (props) => {
  const { children, role } = props;
  const navigateApi = useNavigateApi();

  const currentUserStatus = useSelector((state) => {
    return state.AuthReducer.currentUser?.status;
  });

  const idRole = useSelector((state) => {
    return state.AuthReducer.currentUser?.idRole;
  });
  const idKey = useSelector((state) => {
    return state.AuthReducer.currentUser?.idKey;
  });

  useEffect(() => {
    ApiStorage.setPageSocket(() => {});
  }, []);

  useEffect(() => {
    if (!idKey) {
      let obj = ApiLocalStorage.getItem(ApiLocalStorage.TEMP_REMEMBER_ME);
      if (!UtilObject.isEmpty(obj)) {
        if (obj.isGuest) {
          navigateApi(`${obj.idKeyClient}/guest/login`);
        } else {
          navigateApi(`login`);
        }
      } else {
        navigateApi(`login`);
      }
    } else {
      const hasAccess = role?.includes(idRole) || true;
      if (!hasAccess) {
        navigateApi(`no_access`);
      }
    }
  }, [currentUserStatus, navigateApi, idKey, role, idRole]);

  return children;
};

export default RoutePrivate;
