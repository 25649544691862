import React, { useState } from 'react';
import MyForm from '../../components/MyForm';
import MyBlock from '../../components/MyBlock';
import HeaderSpace from '../../components/HeaderSpace';
import InputFormPassword from '../../components/antd/InputFormPassword';
import InputFormPasswordConfirm from '../../components/antd/InputFormPasswordConfirm';
import MyPanelFooter from '../../components/MyPanelFooter';
import UserActions from '../../actions/UserActions';
import { notifySuccess, notifyError } from '../../components/notify';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';

const UserChangePassword = (props) => {
  const { isMobile } = useWindowSize();
  const navigateApi = useNavigateApi();
  const [saving, setSaving] = useState(false);

  const onFinish = async (values) => {
    setSaving(true);
    let result = await UserActions.changePassword(values);
    setSaving(false);
    if (result.success) {
      notifySuccess('Password Changed success');
      navigateApi('account_settings');
    } else {
      notifyError(result.errMsg);
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock title={getText('WORD_CHANGE_PASSWORD_TITLE')}>
        <MyForm
          name="userChangePasswordForm"
          onFinish={onFinish}
          withoutMarginLeft
        >
          <InputFormPassword
            name="currentPassword"
            label={getText('WORD_CURRENT_PASSWORD')}
            autoComplete="new-password"
            required
          />
          <InputFormPassword
            name="newPassword"
            label={getText('WORD_NEW_PASSWORD')}
            autoComplete="new-password"
            required
          />
          <InputFormPasswordConfirm
            name="passwordConfirm"
            label={getText('WORD_REPEATE_NEW_PASSWORD')}
            passwordToConfirm="newPassword"
            required
          />
          <MyPanelFooter
            formName={'userChangePasswordForm'}
            onCancelClick={() => {
              navigateApi('account_settings');
            }}
            loading={saving}
            labelPrimary={getText('WORD_SAVE')}
            labelCancel={getText('WORD_CANCEL')}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default UserChangePassword;
