import React from 'react';
import MyRow from '../MyRow';
import './style.scss';

const MyField = (props) => {
  const { fieldValuesList } = props;

  return (
    <div className="fields-wrapper">
      {fieldValuesList &&
        fieldValuesList.map((item, index) => {
          return (
            <MyRow
              withoutTitle
              className="fields-item"
              key={index + '_' + item.label && item.label}
            >
              <div className="field-item-left">{item.label && item.label}</div>
              <div className="field-item-right">
                {item.rightItem && item.rightItem}
              </div>
            </MyRow>
          );
        })}
    </div>
  );
};

export default MyField;
