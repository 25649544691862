import React from 'react';
import { Form, Input } from 'antd';
import getText from '../../lang';

const InputFormTextArea = (props) => {
  const {
    label,
    name,
    hasFeedback,
    required,
    placeholder,
    maxLength,
    minLength,
    hidden,
    minRows,
    maxRows,
    onChange,
    detectCurrentPosition,
    readOnly,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: `${getText('WORD_ERROR_PLEASE_INPUT')} ${
            label ? label : placeholder
          }!`,
        },
        {
          max: maxLength,
          message: `${
            label ? label : placeholder
          } must be maximum ${maxLength} characters.`,
        },
        {
          min: minLength,
          message: `${
            label ? label : placeholder
          } must be minimum ${minLength} characters.`,
        },
      ]}
      layout="vertical"
      hidden={hidden}
    >
      <Input.TextArea
        placeholder={label ? label : placeholder}
        autoSize={{
          minRows: minRows ? minRows : 2,
          maxRows: maxRows ? maxRows : 4,
        }}
        onChange={onChange}
        maxLength={maxLength}
        onClick={detectCurrentPosition}
        onKeyUp={detectCurrentPosition}
        readOnly={readOnly}
      />
    </Form.Item>
  );
};

InputFormTextArea.defaultProps = {
  name: 'comment',
  placeholder: 'Comment',
  required: false,
  hasFeedback: true,
  allowClear: true,
  maxLength: 255,
  minLength: 0,
};

export default InputFormTextArea;
