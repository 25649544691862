import React from 'react';
import { List } from 'antd';
import './style.scss';

const MyListGrid = (props) => {
  const {
    className,
    pagination,
    loading,
    loadMore,
    dataSource,
    itemLayout,
    renderItem,
  } = props;

  return (
    <List
      className={`my-list-grid ${className}`}
      pagination={pagination}
      loading={loading}
      loadMore={loadMore}
      dataSource={dataSource}
      itemLayout={itemLayout}
      split={false}
      renderItem={(item) => {
        return <List.Item>{renderItem(item)}</List.Item>;
      }}
    />
  );
};

MyListGrid.defaultProps = {
  dataSource: [],
  itemLayout: 'horizontal',
  loading: false,
  renderItem: (item) => {},
};

export default MyListGrid;
