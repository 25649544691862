import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.79"
    height="20.228"
    viewBox="0 0 31.79 20.228"
  >
    <g
      id="Group_590"
      data-name="Group 590"
      transform="translate(-24.721 -33.914)"
    >
      <path
        id="Path_613"
        data-name="Path 613"
        d="M34.818,42.026v4.006s3.879-.005,5.459-.005c-.855,2.6-2.186,4.011-5.459,4.011a6.01,6.01,0,0,1,0-12.017,5.713,5.713,0,0,1,3.92,1.476c.83-.832.761-.951,2.874-2.95a10.106,10.106,0,1,0-6.795,17.6c8.335,0,10.372-7.27,9.7-12.118h-9.7Z"
        fill="#ee4134"
      />
      <path
        id="Path_614"
        data-name="Path 614"
        d="M66.115,45.064v-3.5h-2.5v3.5h-3.6v2.5h3.6v3.606h2.5V47.567h3.5v-2.5Z"
        transform="translate(-13.102 -2.838)"
        fill="#ee4134"
      />
    </g>
  </svg>
);

const GmailIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default GmailIcon;
