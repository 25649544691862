import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = (props) => {
  const { color } = props;

  const colorr = color || '#0076ff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 23.495 22.991"
    >
      <g
        id="Group_9241"
        data-name="Group 9241"
        transform="translate(-14 -17.7)"
      >
        <circle
          id="Ellipse_739"
          data-name="Ellipse 739"
          cx="3.558"
          cy="3.558"
          r="3.558"
          transform="translate(14 17.7)"
          fill={colorr}
        />
        <circle
          id="Ellipse_740"
          data-name="Ellipse 740"
          cx="3.558"
          cy="3.558"
          r="3.558"
          transform="translate(30.379 32.032)"
          fill={colorr}
        />
        <path
          id="Path_12621"
          data-name="Path 12621"
          d="M24.973,37.747a.716.716,0,0,0-.906,1.108l2.752,2.282H19.636c-2.618,0-3.826-1.645-3.826-3.155a2.95,2.95,0,0,1,.973-2.215A4.4,4.4,0,0,1,19.9,34.692H28.5c3.323,0,4.867-2.182,4.867-4.229,0-2.148-1.712-4.363-4.934-4.363H23.295a.7.7,0,0,0,0,1.41H28.43c2.417,0,3.524,1.51,3.524,2.92a2.691,2.691,0,0,1-.806,1.913,3.746,3.746,0,0,1-2.618.906H19.9a5.855,5.855,0,0,0-4.095,1.443,4.472,4.472,0,0,0-1.41,3.256c0,2.282,1.812,4.565,5.236,4.565h7.283L24.1,45.03a.732.732,0,0,0-.067,1.007.668.668,0,0,0,.537.235.8.8,0,0,0,.47-.168l4.2-3.759a.668.668,0,0,0,.235-.537.7.7,0,0,0-.269-.537Z"
          transform="translate(-0.266 -5.581)"
          fill={colorr}
        />
      </g>
    </svg>
  );
};

const ManifestIcon = (props) => (
  <Icon component={() => <SVGIcon {...props} />} />
);

export default ManifestIcon;
