import React, { useEffect, useRef, useState } from 'react';
// import ApiStorage from '../../reducers/ApiStorage';
import { useParams } from 'react-router-dom';
import { formatDistance, formatDuration } from '../../utils';
import OrderActions from '../../actions/OrderActions';
import TransportTypeActions from '../../actions/TransportTypeActions';
import getText from '../../lang';
import MyRow from '../../components/MyRow';
import MyMap from '../../components/MyMap';
import MyPanelFooter from '../../components/MyPanelFooter';
import OrderTimeLine from '../../components/OrderTimeLine';
import { notifyError } from '../../components/notify';
import ButtonCopy from '../../components/ButtonCopy/ButtonCopy';
import MainPage from '../../components/MainPage';
import OrderViewContactCard from './OrderViewContactCard';
import OrderProductsSection from './OrderProductsSection';
// import TimelineDotIcon from '../../icons/svg/TimelineDotIcon';
import { Image, Rate, Timeline } from 'antd';
import useIsMounted from '@utilityjs/use-is-mounted';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import { getBaseURLReact } from '../../utils/AxiosAdapter';
import { useSelector } from 'react-redux';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import utilDate from '../../utils/utilDate';
import { Typography } from 'antd';
import OrderNotificationSection from './OrderNotificationSection';
import './style.scss';

const { Paragraph } = Typography;

const OrderView = (props) => {
  let { _id } = useParams();

  const { isDesktop, isMobile } = useWindowSize();

  const clientCurrencySymbol = useSelector((state) => {
    return state.AuthReducer?.currentUser?.clientCurrencySymbol;
  });

  const menuOpen = useSelector((state) => {
    return state.AuthReducer?.menuOpen;
  });

  const isMounted = useIsMounted();
  const navigateApi = useNavigateApi();

  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const refContainer = useRef(null);

  const clientIdKey = useSelector((state) => {
    return state.AuthReducer.currentUser?.clientIdKey;
  });

  const lang = useSelector((state) => {
    return state.AuthReducer.lang;
  });

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        setLoading(true);
        let resultGet = await OrderActions.getOneByidKey(_id);
        if (isMounted()) {
          if (resultGet.success) {
            let orderItemsTotal_delevered = 0;
            let orderItemsTotal_deleveredNot = 0;
            let orderItemsTotal_total = 0;
            resultGet.data.items.forEach((it) => {
              orderItemsTotal_total += it.itemPrice;
              if (it.status === OrderActions.STATUS_ITEMS.DELIVERED) {
                orderItemsTotal_delevered += it.itemPrice;
              } else if (it.status === OrderActions.STATUS_ITEMS.NOTDELIVERED) {
                orderItemsTotal_deleveredNot += it.itemPrice;
              }
            });
            resultGet.data._orderItemsTotal_delevered =
              orderItemsTotal_delevered;
            resultGet.data._orderItemsTotal_deleveredNot =
              orderItemsTotal_deleveredNot;
            resultGet.data._orderItemsTotal_total = orderItemsTotal_total;
            resultGet.data.statusFinishImages = JSON.parse(
              resultGet.data.statusFinishImages
            );
            setItem(resultGet.data);
            setLoading(false);
          } else {
            setLoading(false);
            notifyError(resultGet.errMsg);
            navigateApi('orders/list');
          }
        }
      }
    };
    getObj();
  }, [_id, isMounted, navigateApi]);

  const drawRouteAddress = (ymaps) => {
    const MyMapRef = refContainer.current;
    if (MyMapRef) {
      MyMapRef.geoObjects.removeAll();
      let routeMode = TransportTypeActions.getMapRoutingMode(
        item?.idTransportType
      );
      let listPoints = item?.itemsLog
        .filter((oo) => {
          if (oo.mapLng && oo.mapLat) {
            let coordinates = [];
            coordinates.push(oo.mapLat, oo.mapLng);
            return coordinates;
          }
          return oo.address;
        })
        .map((oo) => {
          if (oo.mapLng && oo.mapLat) {
            let coordinates = [];
            coordinates.push(oo.mapLat, oo.mapLng);
            return coordinates;
          }
          return oo.address;
        });
      if (listPoints.length >= 2) {
        var multiRoute = new ymaps.multiRouter.MultiRoute(
          {
            referencePoints: listPoints,
            params: {
              results: 2,
              routingMode: routeMode,
            },
          },
          {
            boundsAutoApply: true,
          }
        );
        MyMapRef.geoObjects.add(multiRoute);
      }
    }
  };

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      drawRouteAddress(ymaps);
    });
  };

  return (
    <>
      {loading ? (
        <LoadingOutlinedApp />
      ) : (
        <div className="create-new-order-wrapper">
          <div className="order-view-two-blocks">
            <MainPage bottomZero page topZero>
              <MyRow className="order-view-block-header">
                <MyRow>
                  <div className="order-view-block-name">
                    {getText('WORD_ORDER')}
                  </div>
                  <div className="order-view-block-number">
                    {item?.apiOrderKey || item?.orderNumber}
                  </div>
                </MyRow>
                <ButtonCopy
                  value={`${getBaseURLReact()}${lang}/orders/view/tracking/${clientIdKey}/${_id}`}
                  btnText={getText('WORD_TRUCKING_URL')}
                  notifyMessage={getText(
                    'WORD_TRACKING_URL_COPIED_SUCCESSFULY'
                  )}
                />
                {/* )} */}
              </MyRow>
              <OrderViewContactCard
                type={getText('WORD_CUSTOMER')}
                fullName={item?.customerName}
                phoneNumber={
                  item?.phoneCode &&
                  item?.phoneNumber &&
                  item?.phoneCode + '' + item?.phoneNumber
                }
                address=""
              />
              <div className="order-timeline-wrapper order-timeline-with-point">
                <div className="timeline-header">{getText('WORD_ADDRESS')}</div>
                <Timeline
                  items={[
                    { key: 1, children: item?.addressFrom },
                    { key: 2, children: item?.addressTo },
                  ]}
                />
              </div>
              <div className="order-view-description">
                <div className="about-order-wrapper">
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_SERVICE')}
                    </div>
                    <div className="about-order-name">{item?.serviceName}</div>
                  </div>
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_CHECK_ID')}
                    </div>
                    <div className="about-order-name">
                      <Paragraph copyable>
                        {item?.documentNumber || '---'}
                      </Paragraph>
                      {/* {item?.documentNumber || '---'} */}
                    </div>
                  </div>
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_BRANCH')}
                    </div>
                    <div className="row-second-item">
                      <span>{item?.branchName}</span>
                    </div>
                  </div>
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_DELIVERY_DATE')}
                    </div>
                    <div className="row-second-item">
                      <span>{utilDate.formatDateTime(item?.deliveryDate)}</span>
                    </div>
                  </div>
                  <div className="about-order-row">
                    <div
                      className="about-order-type"
                      style={{ paddingRight: 40 }}
                    >
                      {getText('WORD_NOTES')}
                    </div>
                    <div className="about-order-name">{item?.description}</div>
                  </div>
                </div>
                <div className="order-view-services-prices">
                  <div className="serives-row">
                    <div className="row-first-item">
                      {getText('WORD_SERVICE_AMOUNT')}
                    </div>
                    <div className="row-second-item">
                      {item?.amountDelivery}
                      <span className="currency-symbol">
                        {clientCurrencySymbol}
                      </span>
                    </div>
                  </div>
                  <div className="divider-line" />
                  {/* <div className="serives-row">
                    <div className="row-first-item">
                      {getText('WORD_ORDER_AMOUNT')}
                    </div>
                    <div className="row-second-item">
                      {item?.amountOrder}
                      <span className="currency-symbol">
                        {clientCurrencySymbol}
                      </span>
                    </div>
                  </div> */}
                  <div className="divider-line" />

                  <OrderProductsSection
                    item={item}
                    clientCurrencySymbol={clientCurrencySymbol}
                  />

                  {/* <div className="serives-row big">
                    <div className="row-first-item">
                      {getText('WORD_SUBTOTAL')}
                    </div>
                    <div className="row-second-item">
                      {item?._orderItemsTotal_total}
                      <span className="currency-symbol">
                        {clientCurrencySymbol}
                      </span>
                    </div>
                  </div> */}
                  <div className="serives-row">
                    <div className="row-first-item">
                      {getText('WORD_ORDER_AMOUNT')}
                    </div>
                    <div className="row-second-item">
                      <strong>{item?.amountOrder}</strong>
                      <span className="currency-symbol">
                        {clientCurrencySymbol}
                      </span>
                    </div>
                  </div>
                  {item?._orderItemsTotal_deleveredNot > 0 ? (
                    <>
                      <div className="divider-line" />
                      <div className="serives-row big">
                        <div className="row-first-item">
                          {getText('WORD_REFUND')}
                        </div>
                        <div className="row-second-item">
                          {item?._orderItemsTotal_deleveredNot}
                          <span className="currency-symbol">
                            {clientCurrencySymbol}
                          </span>
                        </div>
                      </div>
                      <div className="divider-line" />
                      <div className="serives-row big">
                        <div className="row-first-item">
                          {getText('WORD_TOTAL')}
                        </div>
                        <div className="row-second-item">
                          {
                            // item?._orderItemsTotal_total -
                            (
                              item?.amountOrder -
                              item?._orderItemsTotal_deleveredNot
                            ).toString()
                          }
                          <span className="currency-symbol">
                            {clientCurrencySymbol}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <MyPanelFooter
                onCancelClick={() => {
                  navigateApi('orders/list');
                }}
                onPrimaryClick={() => {}}
                hideSave
                labelCancel={getText('WORD_BUTTON_BACK')}
              />
            </MainPage>
            <MainPage bottomZero>
              <OrderViewContactCard
                type={getText('WORD_DRIVER')}
                fullName={item?.driverName}
                icon={item?.driverIcon}
                phoneNumber={
                  item?.driverPhoneCode &&
                  item?.driverPhoneNumber &&
                  item?.driverPhoneCode + '' + item?.driverPhoneNumber
                }
                address=""
                style={{ marginTop: 10 }}
              />
              <OrderTimeLine item={item} />
              <div className="order-view-description">
                <div className="about-order-wrapper">
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_STATUS')}
                    </div>
                    <div className="about-order-name">
                      {getText(OrderActions.getStatusLabel(item?.status))}
                    </div>
                  </div>
                  {item?.statusFinish === OrderActions.STATUSFINISH.FAILED && (
                    <div className="about-order-row">
                      <div className="about-order-type">
                        {getText('WORD_REASON_TITLE')}
                      </div>
                      <div className="about-order-name">
                        {item?.reasonTitle || '---'}
                      </div>
                    </div>
                  )}
                  {item?.statusFinishNote && (
                    <div className="about-order-row">
                      <div className="about-order-type">
                        {getText('WORD_NOTES')}
                      </div>
                      <div className="about-order-name">
                        {item?.statusFinishNote}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <MyRow
                title={getText('WORD_RATING_TITLE')}
                className="order-view-rating"
              >
                <div className="serives-row big">
                  <Rate value={item?.rating} disabled={true} />
                  {item?.review ? (
                    <div className="row-second-item">{item?.review}</div>
                  ) : (
                    <div className="row-second-item">
                      {getText('WORD_REVIEW')}
                    </div>
                  )}
                </div>
                {Boolean(
                  item && item?.hasOwnProperty('reviewLink') && item?.reviewLink
                ) && (
                  <ButtonCopy
                    value={item?.reviewLink}
                    btnText={getText('WORD_RATING_URL')}
                    notifyMessage={getText(
                      'WORD_RATING_URL_COPIED_SUCCESSFULY'
                    )}
                  />
                )}
              </MyRow>
              <div className="image-preview-group">
                <Image.PreviewGroup>
                  {item?.statusFinishImages?.map((it, index) => {
                    return (
                      <div key={index}>
                        <Image src={it} className="image-item" />
                      </div>
                    );
                  })}
                </Image.PreviewGroup>
              </div>
              <OrderNotificationSection items={item.itemsNotification} />
            </MainPage>
          </div>
          <div
            className={`new-order-map-wrapper map-wrapper-responsive ${
              menuOpen && isDesktop ? 'menu-open-for-map' : ''
            }`}
          >
            <div className="map-with-height">
              <MyMap
                ref={refContainer}
                onLoadMap={onLoadMap}
                height={isMobile ? '300px' : 'calc(100vh - 180px'}
              />
              <div className="duration-distance-wrapper">
                <div className="duration-distance active">
                  <h5>{formatDistance(item?.routeDistance)}</h5>
                  <h5>{formatDuration(item?.routeDuration)}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

OrderView.defaultProps = {
  item: {},
};

export default OrderView;
