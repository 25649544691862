import React, { useEffect, useRef, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import MyMap from '../../components/MyMap';
import { Form, Collapse } from 'antd';
import getText from '../../lang';
import TransportTypeActions from '../../actions/TransportTypeActions';
import { formatDistance, formatDuration } from '../../utils';
import { useLocation, useParams } from 'react-router-dom';
import InputFormText from '../../components/antd/InputFormText';
import MyPanelFooter from '../../components/MyPanelFooter';
import MyRow from '../../components/MyRow';
import SelectManifestActive from '../manifest/SelectManifestActive';
import InputFormSelectACCustomer from '../../components/antd/InputFormSelectACCustomer';
import SelectForm from '../../components/antd/SelectForm';
import CountryActions from '../../actions/CountryActions';
import UserActions from '../../actions/UserActions';
import ServiceTransportPriceActions from '../../actions/ServiceTransportPriceActions';
import InputFormPhoneNumber from '../../components/antd/InputFormPhoneNumber';
import InputFormNumber from '../../components/antd/InputFormNumber';
import InputFormTextArea from '../../components/antd/InputFormTextArea';
import OrderItemProduct from './OrderItemProduct';
import OrderActions from '../../actions/OrderActions';
import { notifyError, notifySuccess } from '../../components/notify';
import InputFormDateTimePicker from '../../components/antd/InputFormDateTimePicker';
import MyTextWithBadge from '../../components/myTextWithBadge/MyTextWithBadge';
import moment from 'moment';
import CheckboxForm from '../../components/antd/CheckboxForm';
import { CloseOutlined } from '@ant-design/icons';
import OrderingIcon from '../../icons/svg/OrderingIcon';
import DraggableList from '../../components/antd/DraggableList';
import SelectBranchForm from '../../components/selects/SelectBranchForm';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import { useSelector } from 'react-redux';
import utilString from '../../utils/utilString';

const { Panel } = Collapse;

const OrderEdit = (props) => {
  const [MyYmaps, setMyYmaps] = useState();
  let { _id } = useParams();
  const navigateApi = useNavigateApi();
  const { state } = useLocation();

  const { isNew, menuOpen } = props;

  const { isMobile, isDesktop } = useWindowSize();

  const clientPhoneCode = useSelector((state) => {
    return state.AuthReducer.currentUser?.clientPhoneCode;
  });

  const clientCountryName = useSelector((state) => {
    return state.AuthReducer?.currentUser?.clientCountryName;
  });

  const isFromManifestView = state?.manifest;

  const [distance, setDistance] = useState({});
  const [duration, setDuration] = useState({});
  const [listRoutes, setlistRoutes] = useState([]);
  const [selectedRoute, setselectedRoute] = useState();
  const [myMultiRoute, setmyMultiRoute] = useState();
  const refContainerMap = useRef(null);
  const [form] = Form.useForm();
  const [listInput, setlistInput] = useState([]);
  const [
    listServiceTransportPriceOptions,
    setlistServiceTransportPriceOptions,
  ] = useState([]);
  const [selectService, setSelectService] = useState(false);
  const [listCountries, setListCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(clientPhoneCode);
  const [listDrivers, setListDrivers] = useState([]);
  const isUserDriverDisabled = Boolean(form.getFieldValue('idManifest'));
  const [isStepChanged, setisStepChanged] = useState(false);
  const [isCustomAmountDelivery, setisCustomAmountDelivery] = useState(false);
  const [toCalc, settoCalc] = useState(false);
  const [selectedFocusInput, setselectedFocusInput] = useState();

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await OrderActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
            phoneNumber: resultGet.data.phoneNumber
              ? resultGet.data.phoneNumber.toString()
              : '',
            deliveryDate: moment(resultGet.data.deliveryDate),
          });
          let list = resultGet.data.itemsLog
            .filter((oo) => {
              return (
                Number(oo.actionKey) === OrderActions.ORDERLOGACTIONKEY.STEP
              );
            })
            .map((oo) => {
              let idd = getName();
              let ff = {
                [idd]: oo.address,
                ['phoneCode_' + idd]: oo.phoneCode,
                ['phoneNumber_' + idd]: oo.phoneNumber,
                ['note_' + idd]: oo.note,
              };
              form.setFieldsValue(ff);
              return {
                id: idd,
                address: oo.address,
                addLat: oo.mapLat,
                addLan: oo.mapLng,
                checkPoint: Boolean(oo.checkPoint),
                phoneCode: oo.phoneCode,
                phoneNumber: oo.phoneNumber,
                note: oo.note,
                idOrdersLogs: oo.idOrdersLogs,
              };
            });
          setlistInput(list);
          setisStepChanged(false);
          setDistance({ value: resultGet.data.routeDistance });
          setDuration({ value: resultGet.data.routeDuration });
          setisCustomAmountDelivery(
            form.getFieldValue('isCustomAmountDelivery')
          );
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('orders/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  useEffect(() => {
    const fill = async () => {
      setListCountries(await CountryActions.getFullListCodeOptions());
      setlistServiceTransportPriceOptions(
        await ServiceTransportPriceActions.getFullListOptions()
      );
      setListDrivers(await UserActions.getFullListDriversOptions());
    };
    fill();
  }, []);

  useEffect(() => {
    const refreshCodeValue = () => {
      let code = form.getFieldValue('phoneCode');
      let cnt = listCountries.find((country) => {
        return country.phoneCode === code;
      });
      setSelectedCountry(cnt);
    };
    refreshCodeValue();
  }, [listCountries, form]);

  useEffect(() => {
    if (distance && selectService && !isCustomAmountDelivery) {
      let rDistance = distance.value / 1000;
      let price = 0;
      if (
        selectService.priceMethod ===
        ServiceTransportPriceActions.PRICEMETHOD.FIX
      ) {
        price = selectService.price;
      } else {
        if (rDistance <= selectService.minKm) {
          price = selectService.minPriceKm;
        } else {
          let disKm = rDistance - selectService.minKm;
          price =
            selectService.minPriceKm +
            Math.round(disKm * selectService.oneKmPrice);
        }
      }
      form.setFieldsValue({ amountDelivery: price ? price : 0 });
      settoCalc(false);
    }
  }, [distance, selectService, isCustomAmountDelivery, toCalc, form]);

  useEffect(() => {
    if (myMultiRoute && selectedRoute) {
      myMultiRoute.setActiveRoute(selectedRoute);
      const activeRoute = myMultiRoute.getActiveRoute();
      if (activeRoute) {
        setDistance(activeRoute.properties.get('distance'));
        setDuration(activeRoute.properties.get('duration'));
      }
    }
  }, [myMultiRoute, selectedRoute]);

  useEffect(() => {
    const drawRouteAddress = () => {
      const MyMapRef = refContainerMap.current;
      if (!MyMapRef) return;
      if (!MyYmaps) return;
      MyMapRef.geoObjects.removeAll();
      let find = listServiceTransportPriceOptions.find((oo) => {
        return oo.value === selectService;
      });
      let idt = '';
      if (find) idt = find.idTransportType;
      let routeMode = TransportTypeActions.getMapRoutingMode(idt);
      let listPoints = listInput
        .filter((oo) => {
          if (oo.addLan && oo.addLat) {
            let coordinates = [];
            coordinates.push(oo.addLat, oo.addLan);
            return coordinates;
          }
          return oo.address;
        })
        .map((oo) => {
          if (oo.addLan && oo.addLat) {
            let coordinates = [];
            coordinates.push(oo.addLat, oo.addLan);
            return coordinates;
          }
          return oo.address;
        });
      let listVia = [];
      listPoints.forEach((oo, index) => {
        if (index > 0 && index < listPoints.length - 1) {
          listVia.push(index);
        }
      });
      if (listPoints.length >= 2) {
        const multiRoute = new MyYmaps.multiRouter.MultiRoute(
          {
            referencePoints: listPoints,
            params: {
              results: 2,
              routingMode: routeMode,
            },
          },
          {
            boundsAutoApply: true,
          }
        );
        MyMapRef.geoObjects.add(multiRoute);
        multiRoute.model.events.remove('requestsuccess');
        multiRoute.model.events.add('requestsuccess', () => {
          const routs = multiRoute.getRoutes();
          let list = [];
          setselectedRoute(null);
          routs.each((rut) => {
            const sdv = Math.round(rut.properties.get('distance').value);
            const isSelectedRoute = distance.value
              ? Math.round(distance.value) === sdv
              : false;
            if (isSelectedRoute) {
              setselectedRoute(rut);
            }
            list.push(rut);
            rut.events.add('click', function () {
              setselectedRoute(rut);
            });
          });
          setlistRoutes(list);
          setselectedRoute(multiRoute.getActiveRoute());
        });
        setmyMultiRoute(multiRoute);
      }
    };
    drawRouteAddress();
  }, [
    MyYmaps,
    form,
    listInput,
    listServiceTransportPriceOptions,
    selectService,
    distance.value,
  ]);

  const handle_selectedFocusInput = (idd) => {
    const reverseAddress = (address) => {
      try {
        let finalString = address.split(',').reverse().join(',');
        return finalString;
      } catch (error) {
        return address;
      }
    };

    const getLatLan = async (address) => {
      let res = await MyYmaps.geocode(address, { results: 1 });
      if (res && res.geoObjects) {
        var MyGeoObj = res.geoObjects.get(0);
        let latLan = [
          MyGeoObj.geometry.getCoordinates()[0],
          MyGeoObj.geometry.getCoordinates()[1],
        ];
        return latLan;
      }
      return [];
    };

    if (selectedFocusInput) {
      selectedFocusInput.destroy();
    }

    const sv = new MyYmaps.SuggestView(idd, {
      provider: {
        suggest: (request) => {
          let st;
          if (utilString.checkLanguage(request) === 'en') {
            st = `${clientCountryName}, ${request}`;
          } else {
            st = `${request}`;
          }
          return MyYmaps.suggest(st);
        },
      },
    });
    sv._panel._element.classList.add('ymap-input');
    sv.events.add('select', async (e) => {
      var t = e.get('item').value;
      form.setFieldsValue({ [idd]: t });
      let lt = await getLatLan(t);
      let list = listInput.map((oo) => {
        if (idd === oo.id) {
          oo.addLatLan = lt;
          oo.addLat = lt[0];
          oo.addLan = lt[1];
          oo.address = reverseAddress(t);
        }
        return oo;
      });
      setlistInput(list);
    });
    setselectedFocusInput(sv);
  };

  const getName = () => {
    return 'address_' + Math.random();
  };

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      setMyYmaps(ymaps);
      if (isNew) {
        setTimeout(() => {
          setlistInput([{ id: getName() }, { id: getName() }]);
        }, 10);
      }
    });
  };

  const calcTotals = () => {
    const items = form.getFieldValue('items');
    if (items) {
      let totalKG = 0;
      let totalM3 = 0;
      for (const pro of items) {
        totalKG += pro.sizeWeight;
        totalM3 += pro.sizeM3;
      }
      form.setFieldsValue({ sizeWeight: totalKG, sizeM3: totalM3 });
    }
  };

  const prepareValues = (values) => {
    if (values.hasOwnProperty('idCustomer')) {
      if (typeof values.idCustomer !== 'number') {
        values.customerName = values.idCustomer;
        values.idCustomer = 0;
      }
    }
    values.isStepChanged = isStepChanged;
    if (selectService) {
      values.idService = selectService.idService;
      values.idTransportType = selectService.idTransportType;
    }
    values.routeDuration = duration ? duration.value : 0;
    values.routeDistance = distance ? distance.value : 0;
    let fv = form.getFieldsValue();
    values.steps = listInput.map((oo) => {
      let note = fv['note_' + oo.id];
      let phoneNumber = fv['phoneNumber_' + oo.id];
      let phoneCode = fv['phoneCode_' + oo.id];
      let checkPoint = fv['checkPoint_' + oo.id];
      return {
        address: oo.address,
        mapLat: oo.addLat,
        mapLng: oo.addLan,
        checkPoint: Boolean(checkPoint),
        phoneCode: phoneCode ? phoneCode : '',
        phoneNumber: phoneNumber ? phoneNumber : '',
        note: note ? note : '',
        idOrdersLogs: oo.idOrdersLogs,
      };
    });
  };

  useEffect(() => {
    async function fill() {
      if (!isNew) {
        let idService = form.getFieldValue('idService');
        let idTransportType = form.getFieldValue('idTransportType');
        let cnt = listServiceTransportPriceOptions.find((item) => {
          return (
            item.idService === idService &&
            item.idTransportType === idTransportType
          );
        });
        setSelectService(cnt);
        if (cnt) {
          form.setFieldsValue({ selectService: cnt.value });
        }
      }
    }
    fill();
  }, [listServiceTransportPriceOptions, form, _id, isNew]);

  const onFinish = async (values) => {
    prepareValues(values);
    let result = await OrderActions.save(values, _id);
    if (result.success) {
      navigateApi('orders/list');
      notifySuccess(
        isNew
          ? getText('WORD_ORDER_CREATED_SUCCESSFULY')
          : getText('WORD_ORDER_UPDATED_SUCCESSFULY')
      );
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    prepareValues(errorInfo.values);
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
  };

  const refreshCodeValue = () => {
    let code = form.getFieldValue('phoneCode');
    let cnt = listCountries.find((country) => {
      return country.phoneCode === code;
    });
    setSelectedCountry(cnt);
  };

  const getIndexDotColor = (index) => {
    if (index === 0) {
      return '#FF9300';
    } else if (index === listInput.length - 1) {
      return '#1FC97C';
    } else {
      return 'grey';
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <div className="create-new-order-wrapper">
        <MyBlock
          {...props}
          title={_id ? getText('WORD_ORDER_EDIT') : getText('WORD_NEW_ORDER')}
        >
          <MyForm
            form={form}
            name="orderForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              orderNumber: '',
              idService: '',
              idTransportType: '',
              idUserDriver: '',
              documentNumber: '',
              amountOrder: 0,
              isCustomAmountDelivery: 0,
              description: '',
              idCustomer: '',
              deliveryDate: moment().add(3, 'hours'),
              sizeWidth: 0,
              sizeHeight: 0,
              sizeLength: 0,
              sizeWeight: 0,
              idManifest: '',
              phoneNumber: '',
              itemUnit: '',
              phoneCode: clientPhoneCode || '',
              items: [],
            }}
          >
            <MyRow className="two-input">
              <InputFormText
                label={getText('WORD_ORDER_ID')}
                name="orderNumber"
                required={!isNew}
              />
              <SelectManifestActive
                name={'idManifest'}
                label={getText('WORD_FREIGHT_MANAGEMENT')}
                disabled={isFromManifestView}
                onChangeItem={(it) => {
                  form.setFieldsValue({
                    idManifest: it.idManifest,
                    idUserDriver: it.idUserDriver,
                  });
                }}
              />
            </MyRow>
            <InputFormSelectACCustomer
              label={getText('WORD_CUSTOMER')}
              customerName={form.getFieldValue('customerName')}
              idCustomer={form.getFieldValue('idCustomer')}
              name="idCustomer"
              onChangeItem={(obj) => {
                let aadd = listInput[listInput.length - 1];
                aadd.address = obj.address;
                aadd.addLat = obj.addressLat;
                aadd.addLan = obj.addressLng;
                aadd.checkPoint = false;
                aadd.phoneCode = obj.phoneCode;
                aadd.phoneNumber = obj.phoneNumber;
                aadd.note = '';
                setisStepChanged(true);
                settoCalc(true);
                form.setFieldsValue({
                  customerName: obj.customerName,
                });
                if (obj.phoneNumber) {
                  form.setFieldsValue({
                    phoneCode: obj.phoneCode,
                    phoneNumber: obj.phoneNumber
                      ? obj.phoneNumber.toString()
                      : '',
                    [aadd.id]: obj.address,
                    [`phoneCode_${aadd.id}`]: obj.phoneCode,
                    [`phoneNumber_${aadd.id}`]: obj.phoneNumber,
                    [`checkPoint_${aadd.id}`]: false,
                    [`note_${aadd.id}`]: '',
                  });
                }
              }}
              required={false}
            />
            <InputFormPhoneNumber
              name="phoneNumber"
              nameCode="phoneCode"
              label={getText('WORD_PHONE')}
              errorMessage={getText('WORD_ERROR_PHONE_NUMBER')}
              length={selectedCountry ? selectedCountry.phoneCodeLength : 15}
              options={listCountries}
              onChangeCode={(codeItem) => {
                refreshCodeValue();
                setTimeout(() => {
                  form.validateFields(['phoneNumber'], { force: true });
                }, 400);
              }}
              required
            />
            <MyRow withoutTitle className="service-amount-row">
              <SelectForm
                name="selectService"
                label={getText('WORD_SERVICE')}
                options={listServiceTransportPriceOptions}
                showSearch={true}
                errorMessage={getText('WORD_ERROR_SERVICE_ONE')}
                onChangeItem={(item) => {
                  setSelectService(item);
                  settoCalc(true);
                }}
                required
              />
              <CheckboxForm
                name={'isCustomAmountDelivery'}
                label={''}
                onChange={(value) => {
                  setisCustomAmountDelivery(value);
                  settoCalc(true);
                }}
              />
              <InputFormNumber
                name="amountDelivery"
                label={`${
                  isCustomAmountDelivery
                    ? getText('WORD_CUSTOM_AMOUNT_DELIVERY')
                    : getText('WORD_AMOUNT_DELEVERY')
                }`}
                min={0}
                disabled={!isCustomAmountDelivery}
                required
              />
            </MyRow>
            <div className="show-paths-block-wrapper">
              <DraggableList
                lineClassName="pathway-drag-line"
                data={listInput}
                onChange={(list) => {
                  setlistInput(list);
                  setisStepChanged(true);
                  settoCalc(true);
                }}
                renderItem={(oo, index) => {
                  const idd = oo.id;
                  return (
                    <div className="pathway-with-color-circle" key={idd}>
                      <div
                        className="pathway-circle"
                        style={{ backgroundColor: getIndexDotColor(index) }}
                      />
                      <div className="show-paths-block-inputs">
                        <MyRow withoutTitle className="input-with-checkpoint">
                          <InputFormText
                            placeholder={
                              index === 0
                                ? getText('WORD_FROM')
                                : index === listInput.length - 1
                                ? getText('WORD_TO')
                                : getText('WORD_WAYPOINT')
                            }
                            name={idd}
                            id={idd}
                            onClear={() => {}}
                            onFocus={() => {
                              if (
                                !selectedFocusInput ||
                                selectedFocusInput._panel._anchor.id !== idd
                              ) {
                                handle_selectedFocusInput(idd);
                              }
                            }}
                            required
                            label={getText('WORD_ADDRESS')}
                            suffix={
                              listInput.length > 2 && (
                                <CloseOutlined
                                  onClick={() => {
                                    let list = listInput.filter((ooo) => {
                                      return idd !== ooo.id;
                                    });
                                    setlistInput(list);
                                    setisStepChanged(true);
                                    settoCalc(true);
                                  }}
                                />
                              )
                            }
                          />
                          <CheckboxForm
                            name={'checkPoint_' + idd}
                            label={getText('WORD_ORDER_STEP_CHECKPOINT')}
                          />
                        </MyRow>
                        <Collapse ghost>
                          <Panel header={getText('WORD_ADD_DETAILS')} key="1">
                            <MyRow withoutTitle>
                              <InputFormPhoneNumber
                                name={'phoneNumber_' + idd}
                                nameCode={'phoneCode_' + idd}
                                label={getText('WORD_PHONE')}
                                errorMessage={getText(
                                  'WORD_ERROR_PHONE_NUMBER'
                                )}
                                options={listCountries}
                                onChangeCode={() => {}}
                                required={false}
                              />
                            </MyRow>
                            <InputFormTextArea
                              name={'note_' + idd}
                              label={getText('WORD_NOTES')}
                            />
                          </Panel>
                        </Collapse>
                      </div>
                      <div className="ordering-icon">
                        <OrderingIcon />
                      </div>
                    </div>
                  );
                }}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  let newField = { id: getName() };
                  setlistInput([...listInput, newField]);
                  setisStepChanged(true);
                  settoCalc(true);
                }}
                className="add-waypoint-btn"
              >
                {getText('WORD_ADD_A_WAYPOINT')}
              </div>
            </div>
            <SelectForm
              name="idUserDriver"
              prefix={'kkk'}
              label={getText('WORD_DRIVER')}
              options={listDrivers}
              showSearch={true}
              disabled={isUserDriverDisabled}
            />
            <SelectBranchForm name="idBranch" label={getText('WORD_BRANCH')} />
            <MyRow className="two-input">
              <InputFormText
                name="documentNumber"
                label={getText('WORD_CHECK_ID')}
              />
              <InputFormNumber
                name="amountOrder"
                label={getText('WORD_ORDER_AMOUNT')}
              />
            </MyRow>
            <InputFormTextArea
              name="description"
              label={getText('WORD_NOTES')}
            />
            <OrderItemProduct onChange={calcTotals} />
            <MyRow
              title={getText('WORD_PACKAGE_SIZE')}
              className="my-row-flex-end two-input"
              titleStyle={{ marginTop: 40 }}
            >
              <InputFormNumber
                name="sizeWeight"
                label={getText('WORD_WEIGHT_KG_CM')}
                placeholder={getText('WORD_WEIGHT_KG_CM')}
                disabled
              />
              <InputFormNumber
                name="sizeM3"
                label={
                  <MyTextWithBadge
                    className="m3-input-label"
                    mainText={getText('WORD_M3')}
                    topSymbol={3}
                  />
                }
                placeholder={getText('WORD_M3_WITH_3')}
                disabled
              />
            </MyRow>
            <InputFormDateTimePicker
              name="deliveryDate"
              label={getText('WORD_DELEVERY_DATE')}
              required
            />
            <MyPanelFooter
              onCancelClick={() => {
                navigateApi('orders/list');
              }}
              onPrimaryClick={() => {}}
              formName={'orderForm'}
              labelPrimary={
                isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
              }
              labelCancel={getText('WORD_CANCEL')}
            />
          </MyForm>
        </MyBlock>

        <div
          className={`new-order-map-wrapper ${
            menuOpen && isDesktop ? 'menu-open-for-map' : ''
          }`}
        >
          <MyMap
            ref={refContainerMap}
            onLoadMap={onLoadMap}
            height={`${
              !listRoutes.length ? 'calc(100vh - 40px)' : 'calc(100vh - 90px)'
            }`}
          />
          <div className="duration-distance-wrapper">
            {listRoutes &&
              listRoutes.map((rut, index) => {
                const rp = rut.properties;
                const RutDistance = Math.round(rp.get('distance').value);
                const RutDuration = Math.round(rp.get('duration').value);
                const isSelectedRoute = distance.value
                  ? Math.round(distance.value) === RutDistance
                  : false;
                return (
                  <div
                    onClick={() => {
                      setselectedRoute(rut);
                    }}
                    key={index}
                    className={`duration-distance ${
                      isSelectedRoute ? 'active' : ''
                    }`}
                  >
                    <h5>{formatDistance(RutDistance)}</h5>
                    <h5>{formatDuration(RutDuration)}</h5>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderEdit;
