import { fetchFromUrlPOSTAsyncWEBAPIT } from '../utils/UrlHelperWebApi';

const URL_PACKING_MONITORING_LIST = '/packing/monitoring/list';

const fs = {
  fillMonitoringList: async (_token) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsyncWEBAPIT(
      URL_PACKING_MONITORING_LIST,
      urlParams,
      _token
    );
    return result;
  },
};

const loc = {};

const DriverMonitoringActions = Object.assign(fs, loc);

export default DriverMonitoringActions;
