export const SET_USER = 'SET_USER';
export const SET_ACCESSTOKEN = 'SET_ACCESSTOKEN';
export const SET_LANG = 'SET_LANG';
export const SET_TOTAL_ACTIVE_ORDER_COUNT = 'SET_TOTAL_ACTIVE_ORDER_COUNT';
export const SET_TOTAL_ACTIVE_MANIFEST_COUNT =
  'SET_TOTAL_ACTIVE_MANIFEST_COUNT';
export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';
export const SET_SOCKET_CB_FUNCTION = 'SET_SOCKET_CB_FUNCTION';
export const SET_SOCKET_CB_FUNCTION_MONITORING =
  'SET_SOCKET_CB_FUNCTION_MONITORING';
export const SET_ACCESSTOKEN_MONITORING = 'SET_ACCESSTOKEN_MONITORING';
export const SET_FILTER_TRANSACTION = 'SET_FILTER_TRANSACTION';
export const SET_FILTER_MANIFEST = 'SET_FILTER_MANIFEST';
export const SET_FILTER_ASSIGN = 'SET_FILTER_ASSIGN';

const intState = {
  currentUser: null,
  accessToken: '',
  accessTokenMonitoring: '',
  lang: 'en',
  menuOpen: false,
  totalActiveOrdersCount: 0,
  totalActiveManifestCount: 0,
  screenSize: 'desktop',
  socketCbFunction: false,
  socketCbFunctionMonitoring: false,
  filterTransaction: {},
  filterManifest: {},
  filterAssign: {},
};

export default function AuthReducer(state = intState, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, currentUser: action.payload };
    case SET_ACCESSTOKEN:
      return { ...state, accessToken: action.payload };
    case SET_ACCESSTOKEN_MONITORING:
      return { ...state, accessTokenMonitoring: action.payload };
    case SET_LANG:
      return { ...state, lang: action.payload };
    case SET_TOTAL_ACTIVE_ORDER_COUNT:
      return { ...state, totalActiveOrdersCount: action.payload };
    case SET_TOTAL_ACTIVE_MANIFEST_COUNT:
      return { ...state, totalActiveManifestCount: action.payload };
    case SET_SCREEN_SIZE:
      return { ...state, screenSize: action.payload };
    case SET_MENU_OPEN:
      return { ...state, menuOpen: action.payload };
    case SET_SOCKET_CB_FUNCTION:
      return { ...state, socketCbFunction: action.payload };
    case SET_SOCKET_CB_FUNCTION_MONITORING:
      return { ...state, socketCbFunctionMonitoring: action.payload };
    case SET_FILTER_TRANSACTION:
      return { ...state, filterTransaction: action.payload };
    case SET_FILTER_MANIFEST:
      return { ...state, filterManifest: action.payload };
    case SET_FILTER_ASSIGN:
      return { ...state, filterAssign: action.payload };
    default:
      return state;
  }
}
