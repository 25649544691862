import React, { useEffect, useState } from 'react';
import HeaderSpace from '../components/HeaderSpace';
import MyBlock from '../components/MyBlock';
import { Form } from 'antd';
import CountryActions from '../actions/CountryActions';
import MyForm from '../components/MyForm';
import MyPanelFooter from '../components/MyPanelFooter';
import ClientActions from '../actions/ClientActions';
import { notifySuccess, notifyError } from '../components/notify';
import InputFormText from '../components/antd/InputFormText';
import InputFormPhoneNumber from '../components/antd/InputFormPhoneNumber';
import SelectForm from '../components/antd/SelectForm';
import CurrencyActions from '../actions/CurrencyActions';
import InputFormEmail from '../components/antd/InputFormEmail';
import getText from '../lang';
import ProfileAvatarFormS3 from '../components/antd/ProfileAvatarFormS3';
import { useSelector } from 'react-redux';
import useWindowSize from '../utils/useWindowSize';
import { useWatch } from 'antd/es/form/Form';
import UtilObject from '../utils/UtilObject';
import SmsTest from './SmsTest';
import MyRow from '../components/MyRow';

const ClientAccountSettings = (props) => {
  const [form] = Form.useForm();
  const { isMobile } = useWindowSize();

  const currentUser = useSelector((state) => {
    return state.AuthReducer?.currentUser;
  });

  const icon = useWatch('icon', form);

  const [listCountries, setListCountries] = useState([]);
  const [listCurrencies, setListCurrencies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    let fill = async () => {
      setListCountries(await CountryActions.getFullListOptions());
      setListCurrencies(await CurrencyActions.getFullListOptions());
    };

    fill();
  }, []);

  useEffect(() => {
    form.setFieldsValue(
      UtilObject.isEmpty(currentUser)
        ? {}
        : {
            icon: currentUser.clientIcon,
            name: currentUser.clientName,
            idCountry: currentUser.clientidCountry,
            idCurrency: currentUser.clientidCurrency,
            phoneCode: currentUser.clientPhoneCode,
            phoneNumber: currentUser.clientPhoneNumber,
            nameLegal: currentUser.clientLegalName,
            email: currentUser.clientEmail,
            tin: currentUser.clientTin,
          }
    );
  }, [currentUser, form]);

  const onFinish = async (values) => {
    setSaving(true);
    let result = await ClientActions.saveAccountSetting(values);
    if (result.success) {
      notifySuccess(getText('WORD_COMPANY_SETTINGS_CHANGED_SUCCESSFULLY'));
    } else {
      notifyError(result.errMsg);
    }
    setSaving(false);
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock {...props} title={getText('WORD_COMPANY_SETTINGS_TITLE')}>
        <MyForm
          form={form}
          name="clientSettingsForm"
          onFinish={onFinish}
          withoutMarginLeft={isMobile ? true : false}
        >
          <div className="user-edit-icon-title">
            <ProfileAvatarFormS3
              name="icon"
              imageUrl={icon}
              value={currentUser?.clientName || ''}
              onChange={(img) => {
                form.setFieldsValue({ icon: img });
              }}
            />
          </div>
          <InputFormText
            name="name"
            label={getText('WORD_COMPANY_NAME')}
            required
          />
          <InputFormText
            name="nameLegal"
            label={getText('WORD_LEGAL_NAME')}
            minLength={3}
            required={false}
          />
          <InputFormText
            name="tin"
            label={getText('WORD_TIN')}
            minLength={3}
            required={false}
          />
          <SelectForm
            name="idCountry"
            label={getText('WORD_COUNTRY')}
            options={listCountries}
            showSearch
            required
            onChange={(id) => {
              let country = listCountries.find((countery) => {
                return countery.value === id;
              });
              if (country) {
                setSelectedCountry(country);
                form.setFieldsValue({ phoneCode: country.phoneCode });
                form.validateFields(['phoneNumber'], { force: true });
              }
            }}
          />
          <InputFormEmail
            name="email"
            label={getText('WORD_EMAIL')}
            disabled={true}
            required
          />
          <InputFormPhoneNumber
            name="phoneNumber"
            nameCode="phoneCode"
            label={getText('WORD_TEL')}
            length={selectedCountry.phoneCodeLength || 15}
            disabledCode={true}
            options={listCountries?.map((it) => {
              return { label: it.phoneCode, value: it.phoneCode };
            })}
            required
          />
          <SelectForm
            name="idCurrency"
            label={getText('WORD_CURRENCY')}
            options={listCurrencies}
            showSearch
            required
          />
        </MyForm>
        <MyRow>
          <SmsTest />
          <MyPanelFooter
            formName={'clientSettingsForm'}
            hideCancel
            labelPrimary={getText('WORD_SAVE')}
            labelCancel={getText('WORD_CANCEL')}
            loading={saving}
          />
        </MyRow>
      </MyBlock>
    </>
  );
};

export default ClientAccountSettings;
