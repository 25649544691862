import React, { useCallback, useEffect, useRef, useState } from 'react';
import PolygonsActions from '../../actions/PolygonsActions';
import getText from '../../lang';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import MyMap from '../../components/MyMap';
import MyListGrid from '../../components/antd/MyListGrid';
import InputFormText from '../../components/antd/InputFormText';
import MyPanelFooter from '../../components/MyPanelFooter';
import InputFormTextArea from '../../components/antd/InputFormTextArea';
import { notifyError, notifySuccess } from '../../components/notify';
import DeleteButton from '../../components/antd/DeleteButton';
import MyColorPickerForm from '../../components/myColorPickerForm/MyColorPickerForm';
import MyRow from '../../components/MyRow';
import EditIcon from '../../icons/svg/EditIcon';
import { Button, Form, Space } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import useIsMounted from '@utilityjs/use-is-mounted';
import useWindowSize from '../../utils/useWindowSize';
import { useSelector } from 'react-redux';
import './PolygonPageStyle.scss';
import SwitchForm from '../../components/antd/SwitchForm';
import InputFormNumber from '../../components/antd/InputFormNumber';

const PolygonPage = (props) => {
  const { isDesktop, isMobile } = useWindowSize();

  const menuOpen = useSelector((state) => state.AuthReducer?.menuOpen);
  const [MyYmaps, setMyYmaps] = useState();

  const refContainerMap = useRef(null);
  const [form] = Form.useForm();
  const isMounted = useIsMounted();

  const [polygonsList, setpolygonsList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [areaValue, setareaValue] = useState('');

  const fillFromDB = useCallback(async () => {
    if (!edit && MyYmaps && refContainerMap) {
      setLoading(true);
      const MyMapRef = refContainerMap.current;
      if (MyMapRef) {
        MyMapRef.geoObjects.removeAll();
      }
      let result = await PolygonsActions.getFullList();
      if (isMounted()) {
        if (result.success) {
          setpolygonsList(
            result.data.map((obj) => {
              let styles = JSON.parse(obj.styles);
              let poly = new MyYmaps.Polygon(
                [JSON.parse(obj.coordinates), []],
                {
                  hintContent: obj.name,
                },
                {
                  editorDrawingCursor: 'crosshair',
                  editorMaxPoints: 15,
                  strokeWidth: 3,
                  strokeColor: styles.color,
                  fillColor: styles.color,
                  opacity: 0.2,
                }
              );
              poly.events.add('click', (e) => {});
              Object.assign(obj, {
                styles: styles,
                polygon: poly,
              });
              MyMapRef && MyMapRef.geoObjects.add(obj.polygon);
              return obj;
            })
          );
        } else {
          notifyError(result.errMsg);
        }
        setLoading(false);
      }
    }
  }, [edit, refContainerMap, MyYmaps, isMounted]);

  useEffect(() => {
    fillFromDB();
  }, [fillFromDB]);

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      setMyYmaps(ymaps);
    });
  };

  const createPolygonEdit = (cor) => {
    const MyMapRef = refContainerMap.current;
    if (!MyMapRef) return;
    var myPolygon = new MyYmaps.Polygon(
      [cor ? cor : [], []],
      {},
      {
        editorDrawingCursor: 'crosshair',
        editorMaxPoints: 15,
        strokeWidth: 3,
        fill: false,
      }
    );
    MyMapRef.geoObjects.add(myPolygon);
    var stateMonitor = new MyYmaps.Monitor(myPolygon.editor.state);
    stateMonitor.add('drawing', function (newValue) {
      myPolygon.options.set('strokeColor', newValue ? '#FF0000' : '#0000FF');
      let coordinates = myPolygon.geometry._coordPath._coordinates[0];
      form.setFieldsValue({ coordinates: JSON.stringify(coordinates) });
    });
    myPolygon.editor.startDrawing();
  };

  const onFinish = async (values) => {
    values.coordinates = JSON.parse(values.coordinates);
    values.styles = { color: values.color };
    let result = await PolygonsActions.save(values, values.idKey);
    if (result.success) {
      notifySuccess(
        !form.getFieldValue('idKey')
          ? getText('WORD_BUSINESS_ZONE_CREATED_SUCCESSFULY')
          : getText('WORD_BUSINESS_ZONE_UPDATED_SUCCESSFULY')
      );
      setEdit(false);
    } else {
      notifyError(result.errMsg);
    }
  };

  const handleEdit = (polygonObject) => {
    form.setFieldsValue({
      ...polygonObject,
      color: polygonObject.styles.color,
    });
    const MyMapRef = refContainerMap.current;
    if (MyMapRef) {
      MyMapRef.geoObjects?.remove(polygonObject.polygon);
    }
    createPolygonEdit(JSON.parse(polygonObject.coordinates));
    setEdit(true);
  };

  const handleDelete = async (polygonObject) => {
    let result = await PolygonsActions.delete(polygonObject.idKey);
    if (result.success) {
      notifySuccess(getText('WORD_BUSINESS_ZONE_DELETED_SUCCESSFULY'));
      await fillFromDB();
    } else {
      notifyError(result.errMsg);
    }
  };

  const searchAction = useCallback(async () => {
    const { color, name } = form.getFieldsValue(true);
    if (name) {
      let result = await PolygonsActions.findAllFromExtrnal(
        name.trim().replaceAll(' ', '+')
      );
      if (isMounted() && result.success && MyYmaps && refContainerMap) {
        setLoading(true);
        const MyMapRef = refContainerMap.current;
        if (MyMapRef) {
          MyMapRef.geoObjects.removeAll();
        }
        let obj = {
          name: name,
          styles: { color: color },
          coordinates: result.data.coordinates,
        };
        let styles = obj.styles;
        let poly = new MyYmaps.Polygon(
          [obj.coordinates, []],
          {
            hintContent: obj.name,
          },
          {
            editorDrawingCursor: 'crosshair',
            editorMaxPoints: 15,
            strokeWidth: 3,
            strokeColor: styles.color,
            fillColor: styles.color,
            opacity: 0.2,
          }
        );
        poly.events.add('click', (e) => {});
        Object.assign(obj, {
          styles: styles,
          polygon: poly,
        });
        MyMapRef && MyMapRef.geoObjects.add(obj.polygon);
        setpolygonsList([obj]);
        setareaValue(result.data.display_name);
        form.setFieldsValue({
          coordinates: JSON.stringify(result.data.coordinates),
        });
        setLoading(false);
      }
    } else {
      setpolygonsList([]);
      form.setFieldsValue({
        coordinates: JSON.stringify([]),
        name: '',
      });
    }
  }, [form, MyYmaps, refContainerMap, isMounted]);

  return (
    <>
      {
        <HeaderSpace>
          {!edit && (
            <div className="business-zone-header">
              <h6 className="business-zone-title">
                {getText('WORD_BUSINESS_ZONES')}
              </h6>
              <Button
                type="primary"
                onClick={(e) => {
                  form.resetFields();
                  createPolygonEdit();
                  setEdit(true);
                }}
              >
                {getText('WORD_ADD_BUSINESS_ZONES')}
              </Button>
            </div>
          )}
        </HeaderSpace>
      }
      <div className="create-new-order-wrapper">
        {edit ? (
          <MyBlock
            {...props}
            title={
              form.getFieldValue('idKey')
                ? getText('WORD_BUSINESS_ZONES_EDIT')
                : getText('WORD_NEW_BUSINESS_ZONES')
            }
          >
            <MyForm
              form={form}
              name="PolygonForm"
              onFinish={onFinish}
              initialValues={{
                idKey: '',
                color: '#0693e3',
                coordinates: JSON.stringify([]),
                name: '',
                useNotifications: 1,
              }}
            >
              <InputFormText name="idKey" required={false} hidden />
              <InputFormNumber name="ordering" required={false} hidden />
              <InputFormText
                label={getText('WORD_NAME')}
                name="name"
                required
                suffix={<GlobalOutlined onClick={searchAction} />}
              />
              <h3>{areaValue}</h3>
              <InputFormTextArea
                name="coordinates"
                label={getText('WORD_NOTES')}
                maxLength={20000000}
                hidden
              />
              <MyColorPickerForm
                name={'color'}
                label={getText('WORD_COLOR')}
                required
              />
              <SwitchForm
                name="useNotifications"
                label={getText('WORD_SEND_NOTIFICATIONS')}
              />
              <MyPanelFooter
                onCancelClick={() => {
                  form.resetFields();
                  setEdit(false);
                }}
                formName={'PolygonForm'}
                labelPrimary={
                  !form.getFieldValue('idKey')
                    ? getText('WORD_CREATE')
                    : getText('WORD_UPDATE')
                }
                labelCancel={getText('WORD_CANCEL')}
              />
            </MyForm>
          </MyBlock>
        ) : (
          <MyBlock {...props}>
            <MyListGrid
              className="polygon-list"
              dataSource={polygonsList}
              pagination={false}
              loading={loading}
              renderItem={(polygonItem) => {
                return (
                  <MyRow
                    className="polygon-list-item"
                    // style={{
                    //   borderLeft: `9px ${polygonItem.styles.color} solid`,
                    // }}
                  >
                    <Space>
                      <div
                        style={{
                          backgroundColor: polygonItem?.styles.color,
                          width: 14,
                          height: 14,
                          borderRadius: 11,
                        }}
                      />
                      <div className="list-item-name">{polygonItem.name}</div>
                    </Space>
                    <div className="list-item-icons">
                      <EditIcon
                        className="edit-icon"
                        onClick={() => {
                          handleEdit(polygonItem);
                        }}
                      />
                      <DeleteButton
                        className="delete-icon"
                        color={'#0076ff'}
                        onConfirm={async () => {
                          await handleDelete(polygonItem);
                        }}
                      />
                    </div>
                  </MyRow>
                );
              }}
            />
          </MyBlock>
        )}

        <div
          className={`polygon-map-wrapper ${
            menuOpen && isDesktop ? 'menu-open-for-map' : ''
          }`}
        >
          <MyMap
            ref={refContainerMap}
            onLoadMap={onLoadMap}
            height={isMobile ? 'calc(100vh - 400px' : 'calc(100vh - 35px'}
          />
        </div>
      </div>
    </>
  );
};

export default PolygonPage;
