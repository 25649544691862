import React, { useState } from 'react';
import OrderActions from '../../actions/OrderActions';
import UtilNumber from '../../utils/utilNumber';
import { formatDateTime } from '../../utils';
import MyRow from '../MyRow';
import { Timeline, Tooltip } from 'antd';
// import TimelineDotIcon from '../../icons/svg/TimelineDotIcon';
import getText from '../../lang';
import MyTextWithBadge from '../myTextWithBadge/MyTextWithBadge';
import OrderSplitModal from '../../pages/assign/OrderSplitModal';
import DrawerOrderView from '../../pages/assign/DrawerOrderView';
import { ShareAltOutlined, WarningOutlined } from '@ant-design/icons';
import './style.scss';

const MyCard = (props) => {
  const { item, onClick, className, canSplit, onSave, style } = props;

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const isSplited = item.createdType === OrderActions.CREATEDTYPE.SPLIT;
  const isNotDelivered =
    item.createdType === OrderActions.CREATEDTYPE.NOTDELIVERED;
  const showDrawer = (e) => {
    e.stopPropagation();
    setVisibleDrawer(true);
  };
  const closeDrawer = () => {
    setVisibleDrawer(false);
  };

  return (
    <>
      <div
        className={`my-card-block-wrapper ${
          isSplited
            ? 'splitted-card'
            : isNotDelivered
            ? 'not-delivered-card'
            : ''
        } ${className || ''}`}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e, item);
        }}
      >
        <MyRow bottomZero withoutTitle>
          <MyRow className="my-card-header-row" withoutTitle>
            <MyRow withoutTitle>
              <div className="card-number" onClick={(e) => showDrawer(e)}>
                {item.apiOrderKey || item.orderNumber}
              </div>
              {Boolean(canSplit) && (
                <OrderSplitModal
                  item={item}
                  idKey={item.idKey}
                  onSave={onSave}
                />
              )}
            </MyRow>
            <MyRow className="my-card-header-right-content" withoutTitle>
              <div className="weigth secondary-text">
                {UtilNumber.formatDouble(item.sizeWeight)}
                {getText('WORD_KG_LC')}
              </div>
              |
              <div className="length secondary-text">
                {UtilNumber.formatDouble(item.sizeM3)}
                <MyTextWithBadge
                  mainText={getText('WORD_M3_LC')}
                  topSymbol={3}
                  className="secondary-text"
                />
              </div>
            </MyRow>
          </MyRow>
        </MyRow>
        <MyRow withoutTitle bottomZero className="secondary-text">
          <div className="branch-div">{item.branchName}</div>
          {/* <div className="date-div">{formatDateTime(item.deliveryDate)}</div> */}
          <div className="date-div">{formatDateTime(item.createDate)}</div>
        </MyRow>
        <div className="customer-name secondary-text">{item.customerName}</div>
        <MyRow bottomZero withoutTitle>
          <Timeline
            items={[
              { key: 1, children: item && item.addressFrom },
              { key: 2, children: item && item.addressTo },
            ]}
          />
          <div className="card-status">
            {isSplited && (
              <div className="my-card-shared-item">
                <Tooltip placement="top" title={getText('WORD_SPLITTED')}>
                  <ShareAltOutlined />
                </Tooltip>
              </div>
            )}
            {isNotDelivered && (
              <div className="my-card-not-deleved-item">
                <Tooltip placement="top" title={getText('WORD_NOT_DELIVERED')}>
                  <WarningOutlined />
                </Tooltip>
              </div>
            )}
          </div>
        </MyRow>
      </div>
      <DrawerOrderView
        visible={visibleDrawer}
        setVisible={setVisibleDrawer}
        idKey={item.idKey}
        onClose={closeDrawer}
      />
    </>
  );
};

export default MyCard;
