import React from 'react';
import { Form } from 'antd';
import useWindowSize from '../../utils/useWindowSize';
import LoadingOutlinedApp from '../antd/LoadingOutlinedApp';

const MyForm = (props) => {
  const {
    form,
    size,
    name,
    initialValues,
    onFinish,
    onFinishFailed,
    className,
    children,
    withoutMarginLeft,
    style,
    loading,
  } = props;

  const { isDesktop } = useWindowSize();

  return (
    <Form
      form={form}
      size={size}
      name={name}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError
      validateTrigger={['onBlur', 'onChange']}
      layout="vertical"
      className={`my-form ${className || ''}`}
      style={{ marginLeft: isDesktop && withoutMarginLeft ? 0 : 10, style }}
    >
      {loading ? <LoadingOutlinedApp /> : children}
    </Form>
  );
};

MyForm.defaultProps = {
  name: 'my-form',
  size: 'large',
  initialValues: {},
  onFinish: () => {},
  onFinishFailed: () => {},
};

export default MyForm;
