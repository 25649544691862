import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import OrderCard from './OrderCard';
import { TABLE_FETCH_PAGE_SIZE } from '../../utils';
import OrderActions from '../../actions/OrderActions';
import { notifyError } from '../../components/notify';
import MyListSection from '../../components/myList/MyListSection';
import useIsMounted from '@utilityjs/use-is-mounted';
import UtilDate from '../../utils/utilDate';

const OrdersListPanel = forwardRef((props, ref) => {
  const { filter, listType, title } = props;

  const isMounted = useIsMounted();

  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [page, setPage] = useState(0);
  const [perPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      removeOrder: (order) => {
        let find = listData?.find((oo) => {
          return oo.idKey === order.idKey;
        });
        if (find) {
          let list = listData?.filter((oo) => {
            return oo.idKey !== order.idKey;
          });
          setListData(list);
          setTotalCount((ov) => ov - 1);
        }
      },
      addOrder: (order) => {
        setListData(
          [order, ...listData].sort(
            (a, b) =>
              UtilDate.getDate(a.deliveryDate) -
              UtilDate.getDate(b.deliveryDate)
          )
        );
        setTotalCount((ov) => ov + 1);
      },
    }),
    [listData]
  );

  useEffect(() => {
    const fill = async () => {
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {
          'o.createDate': 'DESC',
        },
        filter: {
          // idUserDriver: filter.selectedDrivers,
          // idService: filter.selectedService,
          // idBranch: filter.selectedBranch,
          // fromDate: filter.selectedDateRange ? filter.selectedDateRange[0] : '',
          // toDate: filter.selectedDateRange ? filter.selectedDateRange[1] : '',

          idUserDriver: filter?.driver || '',
          idService: filter?.service || '',
          idBranch: filter?.branch || '',
          fromDate: filter?.dateRange ? filter?.dateRange[0] : '',
          toDate: filter?.dateRange ? filter?.dateRange[1] : '',
          createdType: filter?.createdType || '',
        },
        q: filter?.searchName || '',
      };

      if (listType === 'UNASSIGNED') {
        fetchParams.filter.status = OrderActions.STATUS.ACTIVE;
        fetchParams.filter.assignedUnassigned = 'unassigned';
      } else if (listType === 'PENDINGWAITING') {
        fetchParams.filter.status = OrderActions.STATUS.ACTIVE;
        fetchParams.filter.assignedUnassigned = 'assigned';
      } else if (listType === 'STARTEDONWAY') {
        fetchParams.filter.status = OrderActions.STATUS.INPROGRESS;
      } else if (listType === 'DONEARCHIVE') {
        fetchParams.filter.status = OrderActions.STATUS.FINISHED;
      }

      let result = await OrderActions.fillPaginationList(fetchParams);
      if (isMounted()) {
        if (result.success) {
          setTotalCount(result.totalRows);
          if (page > 0) {
            setListData((searches) => {
              let list = searches.concat(result.data);
              return list;
            });
          } else {
            setListData(result.data);
          }
        } else {
          notifyError(result.errMsg);
        }
        setLoadingData(false);
      }
    };
    fill();
  }, [page, perPage, filter, listType, isMounted]);

  const handleLoadMore = async () => {
    if (totalCount !== listData.length && !loadingData) {
      setPage(page + 1);
    }
  };

  return (
    <MyListSection
      title={title}
      loading={loadingData}
      totalCount={totalCount}
      dataSource={listData}
      onLoadMore={handleLoadMore}
      id={listType}
      renderItem={(item, index) => <OrderCard key={index} item={item} />}
    />
  );
});

OrdersListPanel.defaultProps = {
  title: '',
};

export default OrdersListPanel;
