import React from 'react';
import { Form, Select } from 'antd';
import { SELECT_UNSELECT_NONE } from '../../../utils';
import getText from '../../../lang';
import './style.scss';

const SelectForm = (props) => {
  const {
    errorMessage,
    placeholder,
    label,
    name,
    hasFeedback,
    required,
    className,
    showSearch,
    disabled,
    loading,
    prefix,
    onSearch,
    onClick,
    onChange,
    onChangeItem,
    options,
  } = props;

  const errorMessagee = errorMessage
    ? errorMessage
    : `${getText('WORD_ERROR_PLEASE_SELECT')} ${
        placeholder ? placeholder : label
      }!`;

  return (
    <div className="my-select-item">
      <Form.Item
        name={name}
        label={label}
        hasFeedback={hasFeedback}
        rules={[
          { required: required, message: errorMessagee },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (getFieldValue(name) !== SELECT_UNSELECT_NONE) {
                return Promise.resolve();
              }
              return Promise.reject(errorMessagee);
            },
          }),
        ]}
      >
        <Select
          className={`my-select-wrapper ${className || ''}`}
          placeholder={placeholder || label}
          showSearch={showSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={disabled}
          loading={loading}
          prefix={prefix}
          onSearch={onSearch}
          onClick={onClick}
          onChange={(e, ee) => {
            onChange && onChange(e, ee);
            let find = options.find((oo) => {
              return oo.value === e;
            });
            if (find) {
              onChangeItem && onChangeItem(find);
            }
          }}
        >
          {options.map((item, index) => {
            return (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

SelectForm.defaultProps = {
  hasFeedback: true,
  showSearch: true,
  required: false,
  options: [],
};

export default SelectForm;
