import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import InputSearch from '../../components/antd/InputSearch';
import OrdersListPanel from './OrdersListPanel';
import MyDatePickerRange from '../../components/antd/MyDatePickerRange';
import { Drawer, Button } from 'antd';
import FilterIcon from '../../icons/svg/FilterIcon';
import MyFilterApply from '../../components/MyFilterApply';
import getText from '../../lang';
import ApiStorage from '../../reducers/ApiStorage';
import OrderActions from '../../actions/OrderActions';
import SelectBranch from '../../components/selects/SelectBranch';
import useIsMounted from '@utilityjs/use-is-mounted';
import useWindowSize from '../../utils/useWindowSize';
import MyCarousel from '../../components/myCarousel/MyCarousel';
import SelectUserDrivers from '../../components/selects/SelectUserDrivers';
import SelectServiceTransportPrice from '../../components/selects/SelectServiceTransportPrice';
import { useSelector } from 'react-redux';
import utilDate from '../../utils/utilDate';
import './style.scss';
import './styleResponsive.scss';
import SelectOrderCreateType from '../../components/selects/SelectOrderCreateType';

const OrdersList = () => {
  const isMounted = useIsMounted();

  const filter = useSelector((state) => {
    const sta = state.AuthReducer.filterTransaction;
    if (sta.dateRange) {
      sta.dateRange = [
        utilDate.getDate(sta.dateRange[0]),
        utilDate.getDate(sta.dateRange[1]),
      ];
      if (sta.fromDate) {
        sta.fromDate = utilDate.getDate(sta.fromDate);
      }
      if (sta.toDate) {
        sta.toDate = utilDate.getDate(sta.toDate);
      }
    }
    return sta;
  });

  const { isMobile } = useWindowSize();

  // const [searchName, setsearchName] = useState('');
  const [visible, setVisible] = useState(false);
  const [showArchiveSection, setshowArchiveSection] = useState(false);

  const refListUnassigned = useRef(null);
  const refListPending = useRef(null);
  const refListOnWay = useRef(null);
  const refListArchive = useRef(null);

  // useEffect(() => {
  //   setsearchName(filter?.searchName || '');
  // }, [filter?.searchName]);

  const setFilter = useCallback(
    (fil) => {
      ApiStorage.setFilterTransaction(Object.assign({}, filter, fil));
    },
    [filter]
  );

  const setFilterClear = () => {
    ApiStorage.setFilterTransaction({});
  };

  useEffect(() => {
    ApiStorage.setPageSocket((msg) => {
      if (isMounted() && filter?.searchName) {
        const obj = msg.body;
        const typ = msg.type;
        if (typ === 'ORDERCHANGE') {
          if (refListUnassigned.current) {
            refListUnassigned.current.removeOrder(obj);
          }
          if (refListPending.current) {
            refListPending.current.removeOrder(obj);
          }
          if (refListOnWay.current) {
            refListOnWay.current.removeOrder(obj);
          }
          if (obj.status === OrderActions.STATUS.ACTIVE) {
            if (obj.idUserDriver) {
              if (refListPending.current) {
                refListPending.current.addOrder(obj);
              }
            } else {
              if (refListUnassigned.current) {
                refListUnassigned.current.addOrder(obj);
              }
            }
          } else if (obj.status === OrderActions.STATUS.INPROGRESS) {
            if (refListOnWay.current) {
              refListOnWay.current.addOrder(obj);
            }
          } else if (obj.status === OrderActions.STATUS.FINISHED) {
            if (refListArchive.current) {
              refListArchive.current.addOrder(obj);
            }
          }
        }
      }
    });
  });

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const isFiltered = false;

  const RenderFilter = useMemo(() => {
    return (
      <>
        <MyDatePickerRange
          value={filter?.dateRange}
          defaultDateRange={[]}
          onChange={(e) => {
            setFilter({
              dateRange: e,
              fromDate: e && e.length ? e[0] : '',
              toDate: e && e.length ? e[1] : '',
            });
          }}
        />
        <SelectBranch
          value={filter?.branch}
          withAll
          onChange={(e) => {
            setFilter({ branch: e });
          }}
        />
        <SelectUserDrivers
          value={filter?.driver}
          withAll
          onChange={(e) => {
            setFilter({ driver: e });
          }}
        />
        <SelectServiceTransportPrice
          value={filter?.service}
          withAll
          onChange={(e) => {
            setFilter({ service: e });
          }}
        />
        <SelectOrderCreateType
          value={filter?.createdType}
          withAll
          onChange={(e) => {
            setFilter({ createdType: e });
          }}
        />
      </>
    );
  }, [filter, setFilter]);

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH_PRESS_ENTER')}
          // value={searchName}
          defaultValue={filter?.searchName}
          // onChange={(v) => setsearchName(v)}
          onClear={() => setFilter({ searchName: '' })}
          onPressEnter={(v) => setFilter({ searchName: v })}
        />
        {isMobile ? (
          <>
            <Button
              className={`filter-button ${isFiltered ? 'filter-active' : ''}`}
              type="filter"
              onClick={showDrawer}
            >
              {/* {getText('WORD_FILTERS')} */}
              <FilterIcon />
            </Button>
            <Drawer
              title={
                <MyFilterApply
                  onCancelClick={onClose}
                  onClick={onClose}
                  onApplyClick={() => {
                    setFilterClear();
                    onClose();
                  }}
                  labelCancel={getText('WORD_CANCEL')}
                  labelPrimary={getText('WORD_FILTER_APPLY')}
                />
              }
              placement={'right'}
              closable={false}
              onClose={onClose}
              open={visible}
              key={'right'}
              width={300}
            >
              {RenderFilter}
            </Drawer>
          </>
        ) : (
          RenderFilter
        )}
      </HeaderSpace>
      <div className="my-order-lists-main-wrapper">
        {isMobile ? (
          <MyCarousel
            infinite={true}
            items={[
              <OrdersListPanel
                ref={refListUnassigned}
                title={getText('WORD_UNASSIGNED')}
                filter={filter}
                listType="UNASSIGNED"
              />,
              <OrdersListPanel
                ref={refListPending}
                title={getText('WORD_PENDING_WAITING')}
                filter={filter}
                listType="PENDINGWAITING"
              />,
              <OrdersListPanel
                ref={refListOnWay}
                title={getText('WORD_STARTED_ON_WAY')}
                filter={filter}
                listType="STARTEDONWAY"
              />,
              <OrdersListPanel
                ref={refListArchive}
                title={getText('WORD_DONE_ARCHIVE')}
                filter={filter}
                listType="DONEARCHIVE"
              />,
            ]}
          />
        ) : (
          <div className="my-order-lists-main-wrapper">
            <OrdersListPanel
              ref={refListUnassigned}
              title={getText('WORD_UNASSIGNED')}
              filter={filter}
              listType="UNASSIGNED"
            />
            <OrdersListPanel
              ref={refListPending}
              title={getText('WORD_PENDING_WAITING')}
              filter={filter}
              listType="PENDINGWAITING"
            />
            <OrdersListPanel
              ref={refListOnWay}
              title={getText('WORD_STARTED_ON_WAY')}
              filter={filter}
              listType="STARTEDONWAY"
            />

            {showArchiveSection ? (
              <OrdersListPanel
                ref={refListArchive}
                title={getText('WORD_DONE_ARCHIVE')}
                filter={filter}
                listType="DONEARCHIVE"
              />
            ) : (
              <div className="order-show-archive-section">
                <Button
                  className="add-freight-btn"
                  onClick={() => setshowArchiveSection((ov) => !ov)}
                >
                  Show archive
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default OrdersList;
