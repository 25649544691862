import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.156"
    height="10.156"
    viewBox="0 0 10.156 10.156"
  >
    <g id="noun_edit_1751206" transform="translate(-577.581 -275.995)">
      <g
        id="Group_17"
        data-name="Group 17"
        transform="translate(577.581 275.995)"
      >
        <path
          id="Path_19"
          data-name="Path 19"
          d="M5.5,9.959l.3-.3L3.108,6.977,9.153.932a1.425,1.425,0,0,1,2.015,0l.672.672a1.425,1.425,0,0,1,0,2.015Zm-.38.373a1.425,1.425,0,0,1-.923.339H2.338a.237.237,0,0,1-.237-.237V8.575a1.425,1.425,0,0,1,.339-.923Z"
          transform="translate(-2.101 -0.515)"
          fill="#0076ff"
        />
      </g>
    </g>
  </svg>
);

const EditIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default EditIcon;
