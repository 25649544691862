import React from 'react';
import HamburgerMenuIcon from '../../icons/svg/HamburgerMenuIcon';
import { formatDateTime, redirectPathNewTab } from '../../utils';
import getText from '../../lang';
// import useNavigateApi from '../../AppRoute/useNavigateApi';

const UserViewListCard = (props) => {
  // const navigateApi = useNavigateApi();

  const { item } = props;

  return (
    <div className="user-view-list-item">
      <div className="draggable-icon">
        <HamburgerMenuIcon />
      </div>
      <div className="list-item list-item-small">
        <div className="list-item-title">{getText('WORD_ORDER_ID')}</div>
        <div
          className="list-item-info order-number"
          onClick={() => {
            redirectPathNewTab(`orders/view/${item.idKey}`);
          }}
        >
          {item?.apiOrderKey || item?.orderNumber}
        </div>
      </div>
      <div className="list-item list-item-large">
        <div className="list-item-title">{getText('WORD_PICKUP_ADDRESS')}</div>
        <div className="list-item-info">{item.addressFrom}</div>
      </div>
      <div className="list-item list-item-large">
        <div className="list-item-title">
          {getText('WORD_DROP_OFF_ADDRESS')}
        </div>
        <div className="list-item-info">{item.addressTo}</div>
      </div>
      <div className="list-item list-item-small">
        <div className="list-item-title">{getText('WORD_DELEVERY_DATE')}</div>
        <div className="list-item-info">
          {formatDateTime(item.deliveryDate)}
        </div>
      </div>
      <div className="list-item list-item-small">
        <div className="list-item-title">{getText('WORD_SERVICE')}</div>
        <div className="list-item-info">{item.serviceName}</div>
      </div>
    </div>
  );
};

UserViewListCard.defaultProps = {
  item: {},
};

export default UserViewListCard;
