import React, { useState } from 'react';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import { Button } from 'antd';
import MyRow from '../../components/MyRow';
import UserActions from '../../actions/UserActions';
import { notifySuccess, notifyError } from '../../components/notify';
import InputFormPassword from '../../components/antd/InputFormPassword';
import InputFormPasswordConfirm from '../../components/antd/InputFormPasswordConfirm';
import { useParams } from 'react-router-dom';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import MyCaptcha from '../../components/Captcha/MyCaptcha';

const ForgotPasswordChange = (props) => {
  const [saving, setSaving] = useState(false);
  const [captchaCheck, setcaptchaCheck] = useState(false);
  let { _id } = useParams();
  const navigateApi = useNavigateApi();

  const onFinish = async (values) => {
    setSaving(true);
    let result = await UserActions.forgotPasswordChange(_id, values.password);
    setSaving(false);
    if (result.success) {
      notifySuccess(getText('WORD_CHANGED_PASSWORD_SUCCESS'));
      navigateApi('login', '', {});
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setSaving(false);
  };

  return (
    <div className="login-page-wrapper">
      <MyBlock title="Recovery Password">
        <MyForm
          name="recovoryPasswordChangeForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <InputFormPassword
            name="password"
            label="Password"
            autoComplete="new-password"
            required
          />
          <InputFormPasswordConfirm
            name="passwordConfirm"
            label="Confirm password"
            passwordToConfirm="password"
            required
          />

          <MyRow style={{ marginTop: 20 }}>
            <Button
              type="primary"
              htmlType="submit"
              form={'recovoryPasswordChangeForm'}
              loading={saving}
              disabled={!captchaCheck}
            >
              {getText('WORD_CHANGE')}
            </Button>
            <Button
              onClick={() => {
                navigateApi('login');
              }}
            >
              {getText('WORD_LOGIN')}
            </Button>
          </MyRow>
        </MyForm>
        <MyCaptcha
          onChange={(e) => {
            setcaptchaCheck(Boolean(e));
          }}
        />
      </MyBlock>
    </div>
  );
};

export default ForgotPasswordChange;
