import React, { useEffect, useState } from 'react';
import InputSearch from '../../components/antd/InputSearch';
import MyGrid from '../../components/antd/MyGrid';
import HeaderSpace from '../../components/HeaderSpace';
import MainPage from '../../components/MainPage';
import getText from '../../lang';
import { SELECT_UNSELECT_NONE, TABLE_FETCH_PAGE_SIZE } from '../../utils';
import DeviceListCard from './DeviceListCard';
import { notifyError, notifySuccess } from '../../components/notify';
import DeviceActions from '../../actions/DeviceActions';
import './style.scss';

const DeviceList = (props) => {
  const [searchName, setSearchName] = useState('');
  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedStatus] = useState(SELECT_UNSELECT_NONE);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {},
        filter: {
          status: selectedStatus,
        },
        q: searchName || '',
      };
      let result = await DeviceActions.fillPaginationList(fetchParams);
      if (result.success) {
        setListData(result.data);
        setTotalCount(result.totalRows);
      }
      setLoadingData(false);
    };
    fill();
  }, [selectedStatus, searchName, page, perPage]);

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onChange={(v) => setSearchName(v)}
        />
      </HeaderSpace>
      <MainPage title={getText('WORD_DEVICES')} topZero>
        <MyGrid
          loading={loadingData}
          data={listData}
          pagination={true}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          renderItem={(item) => {
            return (
              <DeviceListCard
                {...props}
                key={JSON.stringify(item)}
                item={item}
                onChange={(data) => {
                  let oo = listData.map((obj) => {
                    if (obj.idKey === data.idKey) {
                      obj.status = data.status;
                    }
                    return obj;
                  });
                  setListData(oo);
                }}
                onDelete={async () => {
                  let result = await DeviceActions.delete(item.idKey);
                  if (result.success) {
                    notifySuccess('Device deleted successfully');
                    let list = listData.filter((oo) => {
                      return oo.idKey !== item.idKey;
                    });
                    setListData(list);
                  } else {
                    notifyError(result.errMsg);
                  }
                }}
              />
            );
          }}
        />
      </MainPage>
    </>
  );
};

export default DeviceList;
