import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './styles';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 26, color: 'rgb(9, 177, 255)' }} spin />
);

Spin.setDefaultIndicator(<Spin indicator={antIcon} />);

const root = createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>,
);

serviceWorker.unregister();
