import React, { useEffect, useState } from 'react';
import ManifestActions from '../../actions/ManifestActions';
import SelectForm from '../../components/antd/SelectForm';
import { notifyError } from '../../components/notify';

const SelectManifestActive = (props) => {
  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { onLoad, name, placeholder, label, disabled, onChangeItem } = props;

  useEffect(() => {
    const fillOptions = (list) => {
      return list.map((man) => {
        return { value: man.idManifest, label: man.manifestName, ...man };
      });
    };
    const fill = async () => {
      setLoadingData(true);
      let result = await ManifestActions.fillActive();
      setLoadingData(false);
      if (result.success) {
        setListData(fillOptions(result.data));
      } else {
        notifyError(result.errMsg);
      }
    };
    fill();
  }, []);

  useEffect(() => {
    onLoad && onLoad(true);
  }, [listData, onLoad]);

  return (
    <SelectForm
      loading={loadingData}
      name={name}
      placeholder={placeholder}
      label={label}
      options={listData}
      showSearch={true}
      required={false}
      disabled={disabled}
      onChangeItem={onChangeItem}
    />
  );
};

export default SelectManifestActive;
