import React from 'react';
import { Form, Input } from 'antd';
import getText from '../../lang';

const InputFormText = (props) => {
  const {
    label,
    name,
    hasFeedback,
    required,
    errorMessage,
    placeholder,
    maxLength,
    minLength,
    hidden,
    id,
    prefix,
    suffix,
    allowClear,
    onFocus,
    onPressEnter,
    value,
    type,
    autoComplete,
    disabled,
    onClear,
    onChange,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: errorMessage
            ? errorMessage
            : `${getText('WORD_ERROR_PLEASE_INPUT')} ${
                placeholder ? placeholder : label
              }!`,
        },
        {
          max: maxLength,
          message: `${
            label ? label : placeholder
          } must be maximum ${maxLength} characters.`,
        },
        {
          min: minLength,
          message: `${
            label ? label : placeholder
          } must be minimum ${minLength} characters.`,
        },
      ]}
      hidden={hidden}
    >
      <Input
        id={id}
        placeholder={placeholder ? placeholder : label}
        prefix={prefix}
        suffix={suffix}
        allowClear={allowClear}
        onFocus={onFocus}
        onPressEnter={onPressEnter}
        value={value}
        type={type}
        autoComplete={autoComplete}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.value === '') {
            onClear();
          }
          onChange && onChange(e);
        }}
      />
    </Form.Item>
  );
};

InputFormText.defaultProps = {
  name: 'text',
  type: 'text',
  required: false,
  hasFeedback: true,
  allowClear: true,
  autoComplete: 'off',
  onPressEnter: (e) => {
    e.preventDefault();
  },
  onClear: () => {},
  maxLength: 255,
  minLength: 0,
};

export default InputFormText;
