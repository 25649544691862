import React from 'react';
import { Form, Input, Select } from 'antd';
import getText from '../../lang';
import './inputs.scss';

const InputFormPhoneNumber = (props) => {
  const {
    nameCode,
    disabledCode,
    disabled,
    onChangeCode,
    options,
    label,
    name,
    hasFeedback,
    autoComplete,
    placeholder,
    errorMessage,
    required,
    length,
  } = props;

  const prefixSelector = (
    <Form.Item name={nameCode} noStyle>
      <Select
        className={`my-select-wrapper phone-number`}
        style={{ minWidth: 90 }}
        disabled={disabledCode || disabled}
        placeholder={getText('WORD_SELECT')}
        onChange={(code, selectedItem) => {
          onChangeCode && onChangeCode(selectedItem);
        }}
      >
        {options.map((item, index) => {
          return (
            <Select.Option key={index} value={item.value}>
              {item.label}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const listRules = [
    {
      required: required,
      message: errorMessage
        ? errorMessage
        : getText('ERROR_PLEASE_INPUT_YOUR_PHONE_NUMBER'),
    },
  ];
  if (length) {
    listRules.push({
      len: length,
      message: getText('WORD_THE_PHONE_LENGTH_MUST_BE') + ' ' + length,
    });
  }

  return (
    <Form.Item
      name={name}
      label={label}
      hasFeedback={hasFeedback}
      rules={listRules}
    >
      <Input
        className="input-phone-number"
        // style={{ height: 40 }}
        placeholder={label ? label : placeholder}
        addonBefore={prefixSelector}
        autoComplete={autoComplete}
        disabled={disabled}
      />
    </Form.Item>
  );
};

InputFormPhoneNumber.defaultProps = {
  name: 'phoneNumber',
  nameCode: 'phoneCode',
  placeholder: 'Phone number',
  required: false,
  hasFeedback: true,
  allowClear: true,
  autoComplete: 'off',
  options: [],
  maxLength: 255,
  onPressEnter: (e) => {
    e.preventDefault();
  },
  onClear: () => {},
};

export default InputFormPhoneNumber;
