// import { redirectPath } from '.';
// import ApiLocalStorage from '../reducers/ApiLocalStorage';
import ApiLocalStorage from '../reducers/ApiLocalStorage';
import ApiStorage from '../reducers/ApiStorage';
import adapter from './AxiosAdapter';

export async function fetchFromUrlPOSTAsync(
  url,
  parameters = [],
  options = {}
) {
  try {
    let dataPost = {
      device: 'web',
      offset: new Date().getTimezoneOffset(),
    };

    if (Array.isArray(parameters)) {
      parameters.forEach((par) => {
        dataPost[par.key] = par.value;
      });
    } else {
      Object.assign(dataPost, parameters);
    }
    let token = await ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common['Authorization'];
    }
    let result = await adapter.post(url, dataPost, options);
    if (result.status === 401) {
      // ApiStorage.setUser({});
      await ApiStorage.setUser(null);
      ApiLocalStorage.removeItem(ApiLocalStorage.TEMP_REMEMBER_ME);
      // ApiLocalStorage.removeItem(ApiLocalStorage.TEMP_REMEMBER_ME);
      // if (isGuest) {
      // redirectPath(`${idKeyClient}/guest/login`);
      // } else {
      // redirectPath('login');
      // }
    }
    return result.data;
  } catch (error) {
    return { success: false, errMsg: error.message };
  }
}

export async function fetchFromUrlPOSTAsyncFile(url, parameters = []) {
  return await fetchFromUrlPOSTAsync(url, parameters, { responseType: 'blob' });
}
