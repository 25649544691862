import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ProfileLetters from './ProfileLetters';
// import { getBaseURLShowImage } from '../../utils/UrlHelper';

const ProfileAvatar = (props) => {
  const { icon, value } = props;

  const srci = icon || '';
  return (
    <Avatar
      className={icon || value ? '' : 'border-dash'}
      src={srci}
      size="large"
      icon={value ? <ProfileLetters value={value} /> : <UserOutlined />}
    />
  );
};

export default ProfileAvatar;
