import React from 'react';

const TextWithLinks = ({ text }) => {
  if (text) {
    const urlRegex = /https?:\/\/[^\s]+/g;

    // Function to replace URLs with anchor tags
    const replaceWithAnchorTags = (text) => {
      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    };

    const replacedText = replaceWithAnchorTags(text);

    return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
  }
  return null;
};

export default TextWithLinks;
