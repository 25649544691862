import React, { useState } from 'react';
import { DatePicker, Button } from 'antd';
import MyRow from '../MyRow';
import getText from '../../lang';
import UtilDate from '../../utils/utilDate';

const MyDatePickerRange = (props) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const { onChange, value, defaultDateRange, style } = props;

  const handleChange = (e) => {
    onChange && onChange(e);
    // setOpenDatePicker(false);
  };
  return (
    <DatePicker.RangePicker
      value={value}
      defaultValue={defaultDateRange}
      format={'DD/MM/YYYY'}
      open={openDatePicker}
      style={style}
      onOpenChange={setOpenDatePicker}
      placeholder={[getText('WORD_START_DATE'), [getText('WORD_END_DATE')]]}
      onChange={onChange}
      renderExtraFooter={() => {
        return (
          <MyRow>
            <Button
              type="link"
              onClick={() => {
                const dat = UtilDate.getDateYesterday();
                // setSelectedDateRange([dat, dat]);
                // onChange([dat, dat]);
                // setOpenDatePicker(false);
                handleChange([dat, dat]);
              }}
            >
              {getText('WORD_YESTERDAY')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                const dat = UtilDate.getDateToday();
                // setSelectedDateRange([dat, dat]);
                // onChange([dat, dat]);
                // setOpenDatePicker(false);
                handleChange([dat, dat]);
              }}
            >
              {getText('WORD_TODAY')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                const dat = UtilDate.getDateTomorrow();
                // setSelectedDateRange([dat, dat]);
                // onChange([dat, dat]);
                // setOpenDatePicker(false);
                handleChange([dat, dat]);
              }}
            >
              {getText('WORD_TOMORROW')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                const weekStart = UtilDate.getThisWeekStart();
                const weekEnd = UtilDate.getThisWeekEnd();
                // setSelectedDateRange([weekStart, weekEnd]);
                // onChange([weekStart, weekEnd]);
                // setOpenDatePicker(false);
                handleChange([weekStart, weekEnd]);
              }}
            >
              {getText('WORD_THIS_WEEK')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                const monthStart = UtilDate.getThisMonthStart();
                const monthEnd = UtilDate.getThisMonthEnd();
                // setSelectedDateRange([monthStart, monthEnd]);
                // onChange([monthStart, monthEnd]);
                // setOpenDatePicker(false);
                handleChange([monthStart, monthEnd]);
              }}
            >
              {getText('WORD_THIS_MONTH')}
            </Button>
          </MyRow>
        );
      }}
    />
  );
};

MyDatePickerRange.defaultProps = {
  defaultDateRange: [UtilDate.getDateToday(), UtilDate.getDateToday()],
  onChange: (e) => {},
};

export default MyDatePickerRange;
