import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';
import getText from '../lang';

const URL_PAGINATION_LIST = '/devices/list';
const URL_GET_ONE = '/devices/get_one';
const URL_STATUS_ACTIVE = '/devices/change_status/active';
const URL_STATUS_INACTIVE = '/devices/change_status/inactive';
const URL_DELETE = '/devices/delete';
const URL_SAVE = '/devices/save';

const fs = {
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  changeStatusActive: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_STATUS_ACTIVE + '/' + idKey,
      urlParams
    );
    return result;
  },
  changeStatusInActive: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_STATUS_INACTIVE + '/' + idKey,
      urlParams
    );
    return result;
  },
  delete: async (idKay) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_DELETE + '/' + idKay,
      urlParams
    );
    return result;
  },
  save: async (values, idToken = false) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idToken) {
      result = await fetchFromUrlPOSTAsync(URL_SAVE + '/' + idToken, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
};

const loc = {
  STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  getStatusOptions: (
    firstOption = { value: SELECT_UNSELECT_NONE, label: getText('WORD_SELECT') }
  ) => {
    const options = [
      { label: getText('WORD_STATUS_ACTIVE'), value: loc.STATUS.ACTIVE },
      { label: getText('WORD_STATUS_INACTIVE'), value: loc.STATUS.INACTIVE },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusOptionsAll: () => {
    return loc.getStatusOptions({
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_ALL'),
    });
  },
};

const DeviceActions = Object.assign(fs, loc);

export default DeviceActions;
