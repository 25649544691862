import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="647"
    height="455"
    viewBox="0 0 647 455"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#27d3d6" />
        <stop offset="1" stopColor="#56aae2" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clipPath">
        <rect
          id="Rectangle_3441"
          data-name="Rectangle 3441"
          width="647"
          height="455"
          transform="translate(366)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clipPath-2">
        <rect
          id="Rectangle_18"
          data-name="Rectangle 18"
          width="1025.275"
          height="628.619"
          transform="translate(-295 -181)"
          fill="url(#linear-gradient)"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-2"
        x1="0.579"
        y1="1.438"
        x2="0.502"
        y2="0.149"
        href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#00e1db" />
        <stop offset="0.703" stopColor="#00a7f4" />
        <stop offset="1" stopColor="#00a0f7" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="0.262"
        y1="-0.459"
        x2="1.612"
        y2="1.743"
        href="#linear-gradient-3"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="0.45"
        y1="0.105"
        x2="0.478"
        y2="1.43"
        href="#linear-gradient-3"
      />
    </defs>
    <g
      id="Mask_Group_2"
      data-name="Mask Group 2"
      transform="translate(-366)"
      clipPath="url(#clipPath)"
    >
      <g
        id="Group_7318"
        data-name="Group 7318"
        transform="translate(465 110.072)"
      >
        <g
          id="Group_588"
          data-name="Group 588"
          transform="translate(0 70.81)"
          clipPath="url(#clipPath-2)"
        >
          <path
            id="Path_633"
            data-name="Path 633"
            d="M630.492,61.83c2.808,18.885,5.621,38.049,3.155,56.98S622.722,156.738,606.955,168c-18.225,13.01-44.026,14.452-59.586,30.428-22.227,22.822-12.754,63.691-34.726,86.748C499.886,298.56,478.95,302.93,467.78,317.61c-16.784,22.061-4.987,58.526-26.024,76.778-10.078,8.744-24.648,10.134-36.671,16.083s-23.168,20.04-14.04,29.73l-6.254.235H255.445c1.913-11.71-8.911-21.737-19.885-26.739s-23.54-7.734-32.061-16.155c-11.309-11.181-11.987-28.665-12.326-44.4s-1.955-33.412-14.24-43.557c-12.065-9.962-30.44-9.082-43.179-18.2-14.274-10.219-17.323-29.483-19.539-46.668s-6.15-36.533-21.152-45.7c-11.194-6.838-25.533-5.929-37.929-10.347-14.469-5.157-25.392-17.383-32.11-30.955S13.262,129.15,10.282,114.35Z"
            transform="translate(-108.307 -247.685)"
            opacity="0.33"
            fill="url(#linear-gradient-2)"
          />
          <path
            id="Path_634"
            data-name="Path 634"
            d="M320.873,246.208"
            transform="translate(-82.159 -115.72)"
            fill="#f5b59d"
          />
          <g
            id="Group_585"
            data-name="Group 585"
            transform="translate(259.156 50.315)"
          >
            <path
              id="Path_635"
              data-name="Path 635"
              d="M427.124,138.442l-25.285,59.327-18.48-12.643-10.71,15.562-1.933-26.251L348.35,160.808Z"
              transform="translate(-348.35 -138.442)"
              fill="url(#linear-gradient-3)"
            />
            <path
              id="Path_636"
              data-name="Path 636"
              d="M386.96,187.4l22.577,15.451-18.48-12.643-10.71,15.562-1.933-26.251,35.639-21.225Z"
              transform="translate(-356.048 -143.525)"
              fill="#3d3080"
            />
            <path
              id="Path_637"
              data-name="Path 637"
              d="M378.888,185.954l35.165-27.66L378.414,179.52Z"
              transform="translate(-356.048 -143.525)"
              fill="#281e5a"
            />
          </g>
          <g
            id="Group_587"
            data-name="Group 587"
            transform="translate(197.127 116.068)"
          >
            <g id="Group_586" data-name="Group 586">
              <path
                id="Path_638"
                data-name="Path 638"
                d="M379.794,230.717c.2-1.156.452-2.308.751-3.428l-1.734-.464c-.313,1.172-.577,2.378-.786,3.587Z"
                transform="translate(-293.92 -226.825)"
                fill="#211c35"
              />
              <path
                id="Path_639"
                data-name="Path 639"
                d="M371.665,272.982A13.057,13.057,0,0,0,378,270.873l.992,1.5a14.847,14.847,0,0,1-7.209,2.405Zm-13.249-4.313,1.335-1.2a18.072,18.072,0,0,0,5.369,4.228l-.792,1.611A19.819,19.819,0,0,1,358.416,268.669Zm23.963-2.827a13.011,13.011,0,0,0,1.361-5.727c0-.157,0-.315-.01-.476l-.018-.327,1.79-.132.021.389c.008.182.012.364.012.545a14.806,14.806,0,0,1-1.547,6.521Zm-75.107.1.132-1.79c2.406.178,4.812.273,7.15.284l-.009,1.8C312.164,266.216,309.717,266.118,307.271,265.938ZM321.7,264.2l1.122-.085c2.153-.18,4.1-.422,5.955-.742l.305,1.768c-1.906.33-3.9.579-6.112.762l-1.146.089Zm-28.83-.208.34-1.762c2.384.459,4.764.853,7.072,1.167l-.242,1.779C297.7,264.856,295.286,264.457,292.87,263.991Zm42.766-2.329a32.967,32.967,0,0,0,5.577-2.471c.267-.15.53-.3.792-.463l.928,1.537c-.278.168-.559.333-.84.49a34.74,34.74,0,0,1-5.878,2.606Zm15.4-5.7,1.688-.609a40.3,40.3,0,0,0,2.961,6.4l-1.557.893A42.059,42.059,0,0,1,351.034,255.96Zm-72.273,4.63.494-1.726c2.361.676,4.7,1.294,6.943,1.838l-.423,1.745C283.506,261.9,281.147,261.272,278.761,260.59Zm-13.788-4.45.6-1.691q3.387,1.2,6.809,2.315l-.553,1.707Q268.383,257.356,264.973,256.141Zm82.894-1.484a51.857,51.857,0,0,1,5.54-3.639c.211-.115.426-.23.639-.339l.822,1.6-.6.318a49.711,49.711,0,0,0-5.344,3.517Zm27.376-4.383.6-1.693a15.34,15.34,0,0,1,6.606,3.544l-1.255,1.283A13.977,13.977,0,0,0,375.243,250.274Zm-14.211-2.121a30.427,30.427,0,0,1,7.384-.723l-.044,1.8-.728-.01a28.525,28.525,0,0,0-6.221.689Zm-12.559-6.64,1.794-.009a41.656,41.656,0,0,0,.638,7.028l-1.767.314A43.252,43.252,0,0,1,348.473,241.513Z"
                transform="translate(-264.973 -230.584)"
                fill="#211c35"
              />
            </g>
          </g>
          <path
            id="Path_640"
            data-name="Path 640"
            d="M254.58,256.754l.638-1.678q-1.675-.638-3.346-1.293l-.652,1.672Q252.9,256.11,254.58,256.754Z"
            transform="translate(-64.324 -117.66)"
            fill="#00c8b7"
          />
          <path
            id="Path_641"
            data-name="Path 641"
            d="M209.188,297.555l62.53,36.874,62.53-36.874a.365.365,0,0,0-.01-.1c-.051-.234-3.5-3.116-8.766-7.391-.051-.041-.091-.082-.142-.123-17.266-14.018-53.612-42.728-53.612-42.728s-36.345,28.71-53.611,42.728c-5.345,4.337-8.857,7.269-8.908,7.514A.34.34,0,0,0,209.188,297.555Z"
            transform="translate(-53.562 -115.977)"
            fill="#3d3080"
          />
          <path
            id="Path_642"
            data-name="Path 642"
            d="M249.191,206.163h67.466a10.478,10.478,0,0,1,10.478,10.478V318.648a10.478,10.478,0,0,1-10.478,10.478H232.12a10.478,10.478,0,0,1-10.478-10.478V233.712Z"
            transform="translate(-56.751 -105.466)"
            fill="#fff"
          />
          <path
            id="Path_643"
            data-name="Path 643"
            d="M249.191,223.234a10.478,10.478,0,0,1-10.478,10.478H221.642l27.549-27.549Z"
            transform="translate(-56.751 -105.466)"
            fill="rgba(237,238,246,0.34)"
          />
          <rect
            id="Rectangle_17"
            data-name="Rectangle 17"
            width="30.741"
            height="30.741"
            rx="5.017"
            transform="translate(254.426 148.68) rotate(-180)"
            fill="#7f859d"
          />
          <path
            id="Path_644"
            data-name="Path 644"
            d="M261.794,282.143h55.849a3.007,3.007,0,0,1,3.007,3.008h0a3.007,3.007,0,0,1-3.007,3.007H261.794a3.007,3.007,0,0,1-3.007-3.007h0A3.007,3.007,0,0,1,261.794,282.143Z"
            transform="translate(-66.262 -124.921)"
            fill="rgba(237,238,246,0.34)"
          />
          <path
            id="Path_645"
            data-name="Path 645"
            d="M261.794,302.28h55.849a3.007,3.007,0,0,1,3.007,3.008h0a3.007,3.007,0,0,1-3.007,3.007H261.794a3.007,3.007,0,0,1-3.007-3.007h0A3.007,3.007,0,0,1,261.794,302.28Z"
            transform="translate(-66.262 -130.077)"
            fill="rgba(237,238,246,0.34)"
          />
          <path
            id="Path_646"
            data-name="Path 646"
            d="M295.888,321.815h30.485a3.007,3.007,0,0,1,3.007,3.007h0a3.007,3.007,0,0,1-3.007,3.008H295.888a3.008,3.008,0,0,1-3.008-3.008h0A3.008,3.008,0,0,1,295.888,321.815Z"
            transform="translate(-74.991 -135.079)"
            fill="rgba(237,238,246,0.34)"
          />
          <path
            id="Path_647"
            data-name="Path 647"
            d="M209.18,314.878v59.989a7.64,7.64,0,0,0,7.62,7.62H326.64a7.623,7.623,0,0,0,7.6-7.62V314.878l-62.53,36.869Z"
            transform="translate(-53.56 -133.303)"
            fill="url(#linear-gradient-4)"
          />
          <path
            id="Path_648"
            data-name="Path 648"
            d="M212.021,388.695l40.246-40.246,20.17,11.893,20.179-11.9,40.249,40.249a7.55,7.55,0,0,1-5.5,2.39H217.527A7.583,7.583,0,0,1,212.021,388.695Z"
            transform="translate(-54.287 -141.897)"
            fill="url(#linear-gradient-5)"
          />
        </g>
      </g>
    </g>
  </svg>
);

const SearchIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default SearchIcon;
