import React, { useEffect, useState } from 'react';
import ServiceTransportPriceActions from '../../actions/ServiceTransportPriceActions';
import SelectForm from '../antd/SelectForm';

const SelectServiceTransportPriceMetode = (props) => {
  const [listData, setListData] = useState([]);

  const { label, name, showSearch, required, onChangeItem, onChange } = props;

  useEffect(() => {
    let fill = () => {
      setListData(ServiceTransportPriceActions.getPriceMetodeOptions());
    };
    fill();
  }, []);

  return (
    <SelectForm
      label={label}
      name={name}
      options={listData}
      showSearch={showSearch}
      required={required}
      onChangeItem={onChangeItem}
      onChange={onChange}
    />
  );
};

export default SelectServiceTransportPriceMetode;
