import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';

const URL_SAVE = '/customers/save';
const URL_GET_ONE = '/customers/get_one';
const URL_PAGINATION_LIST = '/customers/list';

const fs = {
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  save: async (values, idd = false) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idd) {
      result = await fetchFromUrlPOSTAsync(URL_SAVE + '/' + idd, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
  findAutoComplete: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
};

const loc = {
  STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  getStatusOptions: (
    firstOption = { value: SELECT_UNSELECT_NONE, label: 'Select' }
  ) => {
    const options = [
      { label: 'Active', value: loc.STATUS.ACTIVE },
      { label: 'Inactive', value: loc.STATUS.INACTIVE },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusOptionsAll: () => {
    return loc.getStatusOptions({ value: SELECT_UNSELECT_NONE, label: 'All' });
  },
};

const CustomerActions = Object.assign(fs, loc);

export default CustomerActions;
