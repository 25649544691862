import React from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import './style.scss';

const OrderImport = (props) => {
  return (
    <>
      <HeaderSpace />
      <div>Order Import</div>
    </>
  );
};

export default OrderImport;
