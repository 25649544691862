import React from 'react';
import { Input } from 'antd';

const InputText = (props) => {
  const {
    className,
    id,
    placeholder,
    label,
    prefix,
    suffix,
    allowClear,
    onPressEnter,
    value,
    type,
    onClick,
    autoComplete,
    disabled,
    onClear,
    onChange,
  } = props;

  return (
    <Input
      className={`my-input-text ${className}`}
      id={id}
      placeholder={placeholder ? placeholder : label}
      prefix={prefix}
      suffix={suffix}
      allowClear={allowClear}
      onPressEnter={onPressEnter}
      value={value}
      type={type}
      onClick={onClick}
      autoComplete={autoComplete}
      disabled={disabled}
      onChange={(e) => {
        if (e.target.value === '') {
          onClear();
        }
        onChange && onChange(e);
      }}
    />
  );
};

InputText.defaultProps = {
  type: 'text',
  required: false,
  hasFeedback: true,
  allowClear: true,
  autoComplete: 'off',
  onPressEnter: (e) => {
    e.preventDefault();
  },
  onClear: () => {},
  maxLength: 255,
  minLength: 0,
};

export default InputText;
