import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import InputSearch from '../../components/antd/InputSearch';
import MySelect from '../../components/antd/MySelect';
import ReasonsListCard from './ReasonsListCard';
import MyGrid from '../../components/antd/MyGrid';
import { Button, Drawer } from 'antd';
import FilterIcon from '../../icons/svg/FilterIcon';
import MyFilterApply from '../../components/MyFilterApply';
import { SELECT_UNSELECT_NONE, TABLE_FETCH_PAGE_SIZE } from '../../utils';
import ReasonsActions from '../../actions/ReasonsActions';
import MainPage from '../../components/MainPage';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import './style.scss';

const ReasonsList = (props) => {
  const navigateApi = useNavigateApi();

  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(SELECT_UNSELECT_NONE);
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [visibleFilter, setVisibleFilter] = useState(false);

  const { isMobile } = useWindowSize();

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {},
        filter: {
          status: selectedStatus,
        },
        q: searchName || '',
      };
      let result = await ReasonsActions.fillPaginationList(fetchParams);
      if (result.success) {
        setListData(result.data);
        setTotalCount(result.totalRows);
      }
      setLoadingData(false);
    };
    fill();
  }, [selectedStatus, searchName, page, perPage]);

  const showDrawer = () => {
    setVisibleFilter(true);
  };

  const onClose = () => {
    setVisibleFilter(false);
  };

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onChange={(v) => setSearchName(v)}
        />
        {isMobile ? (
          <>
            <Button
              icon={<FilterIcon />}
              className="filter-button"
              type="filter"
              onClick={showDrawer}
            >
              {getText('WORD_FILTERS')}
            </Button>
            <Drawer
              title={
                <MyFilterApply
                  onCancelClick={onClose}
                  onClick={onClose}
                  labelCancel={getText('WORD_CANCEL')}
                  labelPrimary={getText('WORD_FILTER_APPLY')}
                />
              }
              placement={'right'}
              closable={false}
              onClose={onClose}
              open={visibleFilter}
              key={'right'}
              width={300}
            >
              <MySelect
                name="selectStatus"
                defaultValue={SELECT_UNSELECT_NONE}
                options={ReasonsActions.getStatusOptionsAll()}
                value={selectedStatus}
                showSearch={false}
                onChange={(e) => setSelectedStatus(e)}
                placeholder={getText('WORD_SELECT_ALL_STATUSES')}
              />
            </Drawer>
          </>
        ) : (
          <MySelect
            name="selectStatus"
            defaultValue={SELECT_UNSELECT_NONE}
            options={ReasonsActions.getStatusOptionsAll()}
            value={selectedStatus}
            showSearch={false}
            onChange={(e) => setSelectedStatus(e)}
            placeholder={getText('WORD_SELECT_ALL_STATUSES')}
          />
        )}
        <Button
          type="primary"
          onClick={() => {
            navigateApi('reasons/create');
          }}
        >
          {getText('WORD_REASONS_ADD_NEW')}
        </Button>
      </HeaderSpace>
      <MainPage title={getText('WORD_REASON_TITLE')} topZero>
        <MyGrid
          loading={loadingData}
          data={listData}
          pagination={true}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          renderItem={(item) => {
            return (
              <ReasonsListCard
                {...props}
                key={JSON.stringify(item)}
                item={item}
                onChange={(data) => {
                  let oo = listData.map((obj) => {
                    if (obj.idKey === data.idKey) {
                      obj.status = data.status;
                    }
                    return obj;
                  });
                  setListData(oo);
                }}
              />
            );
          }}
        />
      </MainPage>
    </>
  );
};

export default ReasonsList;
