import React from 'react';
import { Form, DatePicker } from 'antd';
import getText from '../../lang';

const InputFormDateTimePicker = (props) => {
  const {
    label,
    name,
    hasFeedback,
    onChange,
    onOk,
    placeholder,
    format,
    required,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: `${getText('ERROR_WORD_SELECT_DATE_TIME')}!`,
        },
      ]}
      layout="vertical"
    >
      <DatePicker
        className="input-time-picker"
        showTime
        onChange={onChange}
        showSecond={false}
        onOk={onOk}
        placeholder={label ? label : placeholder}
        format={format}
      />
    </Form.Item>
  );
};

InputFormDateTimePicker.defaultProps = {
  name: 'DateTimeValue',
  placeholder: 'Select Date Time',
  required: false,
  hasFeedback: true,
  allowClear: true,
  format: 'DD/MM/YYYY HH:mm',
};

export default InputFormDateTimePicker;
