import React from 'react';
import MyRow from '../MyRow';
import { Button } from 'antd';
import './style.scss';

const MyPanelFooter = (props) => {
  const {
    withoutTitle,
    hideCancel,
    onCancelClick,
    loadingCancel,
    labelCancel,
    hideSave,
    formName,
    onPrimaryClick,
    loading,
    labelPrimary,
  } = props;

  return (
    <MyRow className="my-footer-panel-buttons" withoutTitle={withoutTitle}>
      {hideCancel ? (
        <div />
      ) : (
        <Button onClick={onCancelClick} type="cancel" loading={loadingCancel}>
          {labelCancel}
        </Button>
      )}
      {hideSave ? (
        <div />
      ) : (
        <Button
          type="primary"
          htmlType="submit"
          form={formName}
          onClick={onPrimaryClick}
          loading={loading}
        >
          {labelPrimary}
        </Button>
      )}
    </MyRow>
  );
};

MyPanelFooter.defaultProps = {
  labelPrimary: 'Save',
  labelCancel: 'Cancel',
  cancel: true,
};

export default MyPanelFooter;
