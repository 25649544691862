import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = (props) => {
  const { width, height, color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '15px'}
      height={height ? height : '15px'}
      viewBox="0 0 12.641 10.524"
    >
      <g
        id="Group_9714"
        data-name="Group 9714"
        transform="translate(-0.5 -2.5)"
      >
        <path
          id="Path_13492"
          data-name="Path 13492"
          d="M1,2.5H8.937a.5.5,0,0,1,.5.5V9.879a.5.5,0,0,1-.5.5H1a.5.5,0,0,1-.5-.5V3A.5.5,0,0,1,1,2.5Zm7.437,1H1.5V9.379H8.437Z"
          fill={color ? color : '#303436'}
        />
        <path
          id="Path_13493"
          data-name="Path 13493"
          d="M16,7.5h2.116a.5.5,0,0,1,.354.146l1.587,1.587a.5.5,0,0,1,.146.354v2.646a.5.5,0,0,1-.5.5H16a.5.5,0,0,1-.5-.5V8A.5.5,0,0,1,16,7.5Zm1.909,1H16.5v3.233h2.7V9.794Z"
          transform="translate(-7.063 -2.354)"
          fill={color ? color : '#303436'}
        />
        <path
          id="Path_13494"
          data-name="Path 13494"
          d="M4.323,19.146a1.823,1.823,0,1,1,1.823-1.823A1.825,1.825,0,0,1,4.323,19.146Zm0-2.646a.823.823,0,1,0,.823.823A.824.824,0,0,0,4.323,16.5Z"
          transform="translate(-0.942 -6.121)"
          fill={color ? color : '#303436'}
        />
        <path
          id="Path_13495"
          data-name="Path 13495"
          d="M17.323,19.146a1.823,1.823,0,1,1,1.823-1.823A1.825,1.825,0,0,1,17.323,19.146Zm0-2.646a.823.823,0,1,0,.823.823A.824.824,0,0,0,17.323,16.5Z"
          transform="translate(-7.063 -6.121)"
          fill={color ? color : '#303436'}
        />
      </g>
    </svg>
  );
};

const TruckIcon = (props) => <Icon component={() => <SVGIcon {...props} />} />;

export default TruckIcon;
