const texts = {
  WORD_LOGIN: 'Login',
  WORD_REGISTER: 'Register',
  WORD_UNASSIGNED: 'Unassigned',
  WORD_PENDING_WAITING: 'Pending/Waiting',
  WORD_STARTED_ON_WAY: 'Started/On way',
  WORD_DONE_ARCHIVE: 'Done/Archive',
  WORD_FORGOT_PASSWORD: 'Forgot password?',
  WORD_FORGOT_PASSWORD_WA: 'Forgot password',
  WORD_PLEASE_INPUT_USERNAME: 'Please input username!',
  WORD_PLEASE_INPUT_EMAIL: 'Please input email!',
  WORD_USERNAME_OR_EMAIL: 'Username or Email',
  WORD_PASSWORD: 'Password',
  WORD_PASSWORD_CONFIRM: 'Confirm Password',
  WORD_PLEASE_INPUT_PASSWORD: 'Please input password!',
  WORD_PLEASE_CONFIRM_YOUR_PASSWORD: 'Please confirm your password!',
  WORD_DOWNLOAD_DRIVER_APP: 'Download driver app',
  WORD_SELECT_PACKAGE: 'Select package',
  WORD_YOUR_FIRST_14_DAYS_ARE_FREE:
    'Your first 14 days are free, and you can cancel at any time - no hassle and no questions asked',
  WORD_PER_MONTH_BILLED_ANNUALLY: 'per month, billed annually',
  WORD_MOBILE_DEVICES: 'Mobile devices',
  WORD_TEAM_MEMBERS: 'Team members',
  WORD_GPS_TRACKING: 'GPS tracking',
  WORD_ANALYTICS: 'Analytics',
  WORD_MAP: 'Map',
  WORD_START_FREE_TRIAL: 'Start free trial',
  WORD_INCLUDE: 'include',
  WORD_UP_TO: 'up to',
  WORD_NEED_CUSTOM_SOLUTION: 'Need custom solution',
  WORD_LETS_TALK_ABOUT_WHAT_UBICROSS_DELIVERY_APP:
    'Let’s talk about what Delivery app can do for your business',
  WORD_REQUEST_DEMO: 'Request demo',
  WORD_DRIVER: 'Driver',
  WORD_DRIVERS: 'Drivers',
  WORD_SERVICE: 'Service',
  WORD_CHECK_ID: 'Check ID',
  WORD_TIMELINE: 'Timeline',
  WORD_ORDER_AMOUNT: 'Order amount',
  WORD_SERVICE_AMOUNT: 'Service price',
  WORD_TOTAL_AMOUNT: 'Total amount',
  WORD_ORDER: 'Order',
  WORD_NOTES: 'Notes',
  WORD_ORDER_EDIT: 'Order edit',
  WORD_NEW_ORDER: 'New order',
  WORD_ORDER_ID: 'Order ID',
  WORD_MANIFEST: 'Manifest',
  WORD_CUSTOMER: 'Customer',
  WORD_PHONE: 'Tel',
  WORD_PICKUP_ADDRESS: 'Pickup address',
  WORD_DROP_OFF_ADDRESS: 'Drop-off address',
  WORD_WIDTH_CM: 'Width (cm.)',
  WORD_HEIGHT_CM: 'Height (cm.)',
  WORD_LENGTH_CM: 'Length (cm.)',
  WORD_WEIGHT_KG_CM: 'Weight (kg.)',
  WORD_PRODUCT_NUMBER: 'Product number',
  WORD_PRODUCT_NAME: 'Product name',
  WORD_PRODUCT_PRICE: 'Product price',
  WORD_ADD_PRODUCT: 'Add product',
  WORD_SELECT_MANIFEST: 'Select manifest',
  WORD_SELECT_DRIVER: 'Select driver',
  WORD_ERROR_SELECT_DRIVER: 'Please select driver',
  WORD_ERROR_PHONE_NUMBER: 'Please input your tel number!',
  WORD_ERROR_PICKUP_ADDRESS: 'Please input Pickup address!',
  WORD_ERROR_DROPOFF_ADDRESS: 'Please input Drop-off address!',
  WORD_ERROR_SERVICE_ONE: 'Please select select one!',
  WORD_PACKAGE_SIZE: 'Package size',
  WORD_ITEMS_LIST: 'Items list',
  WORD_CREATE: 'Create',
  WORD_UPDATE: 'Update',
  WORD_CANCEL: 'Cancel',
  WORD_ADDRESS: 'Address',
  WORD_STATUS: 'Status',
  WORD_EMAIL: 'Email',
  WORD_EMAIL_LOGIN: 'Email (Login)',
  WORD_DRIVER_INFORMATION: 'Driver’s information',
  WORD_ACTIVITY: 'Activity',
  WORD_ON_WAY: 'On way',
  WORD_DELIVERED: 'Delivered',
  WORD_NOT_DELIVERED: 'Not delivered',
  WORD_NOT_TRANSPORT: 'Transport',
  WORD_FLEXIBLE: 'Flexible',
  WORD_PLANS: 'Plans',
  WORD_NEW_SERVICE: 'New service',
  WORD_SERVICE_EDIT: 'Service edit',
  WORD_KM: 'km',
  WORD_HR: 'hr',
  WORD_MIN: 'min',
  WORD_DASHBOARD_LEFT_TAB_ONLINE: 'Online',
  WORD_DASHBOARD_LEFT_TAB_AVAILABLE: 'Available',
  WORD_DASHBOARD_LEFT_TAB_ALL: 'All',
  WORD_SIDEBAR_ACCOUNT_SETTINGS: 'Account settings',
  WORD_SIDEBAR_LOGOUT: 'Logout',
  WORD_SIDEBAR_DASHBOARD: 'Dashboard',
  WORD_SIDEBAR_ORDERS: 'Orders',
  WORD_SIDEBAR_MANIFESTS: 'Manifests',
  WORD_SIDEBAR_SERVICES: 'Services',
  WORD_SIDEBAR_REASONS: 'Reasons for not-delivery',
  WORD_SIDEBAR_TEAM: 'Team',
  WORD_SIDEBAR_CUSTOMERS: 'Customers',
  WORD_SIDEBAR_SETTINGS: 'Settings',
  WORD_SIDEBAR_RATING: 'Ratings',

  WORD_REASONS_ADD_NEW: 'Add new',
  WORD_FILTERS: 'Filters',
  WORD_REASONS: 'Reasons for not-delivery',
  WORD_SEARCH: 'Search',
  WORD_YESTERDAY: 'Yesterday',
  WORD_TODAY: 'Today',
  WORD_TOMORROW: 'Tomorrow',
  WORD_THIS_WEEK: 'This week',
  WORD_THIS_MONTH: 'This month',
  WORD_SELECT_ALL_DRIVERS: 'All drivers',
  WORD_SELECT_SELECT_DRIVER: 'Select driver',
  WORD_SELECT_ALL_STATUSES: 'All statuses',
  WORD_SELECT_SELECT_STATUS: 'Select status',
  WORD_SELECT_SELECT_SERVICE: 'Select service',
  WORD_SELECT_ALL_SERVICES: 'All services',
  WORD_SELECT_SELECT_TYPE: 'Select type',
  WORD_SELECT_SELECT: 'Select',
  WORD_SELECT_ALL: 'All',
  WORD_ADD_NEW_WIDGET_IMPORT_ORDERS: 'Import orders',
  WORD_ADD_NEW_WIDGET_CREATE_MANIFEST: 'Create manifest',
  WORD_ADD_NEW_WIDGET_CREATE_ORDER: 'Create order',
  WORD_BUTTON_BACK: 'Back',
  WORD_SUBTOTAL: 'Subtotal',
  WORD_REFUND: 'Refund',
  WORD_TOTAL: 'Total',
  WORD_ORDER_STATUS_START: 'Start',
  WORD_ORDER_STATUS_COMPLETE: 'Complete',
  WORD_NEW_MANIFEST: 'New manifest',
  WORD_ORDERS: 'Orders',
  WORD_PENDING: 'Pending',
  WORD_ARCHIVE: 'Archive',
  WORD_COMPLETED: 'Completed',
  WORD_SELECT: 'Select',
  WORD_STATUS_ACTIVE: 'Active',
  WORD_STATUS_INACTIVE: 'Inactive',
  WORD_ALL: 'All',
  WORD_ADD_SERVICE: 'Add service',
  WORD_SERVICES: 'Services',
  WORD_DESCRIPTION: 'Description',
  WORD_NAME: 'Name',
  WORD_ADD_SERVICE_STEP: 'Add more',
  WORD_ADD_SERVICE_STEPS_LIST: 'Order steps',
  WORD_ORDER_STEP_CHECKPOINT: 'Checkpoint',
  WORD_TRANSPORT_TYPE: 'Transport type',
  WORD_SELECT_TRANSPORT_TYPE: 'Select type',
  WORD_SELECT_ALL_TRANSPORTS: 'All transports',
  WORD_PRICE_TRANSPORT_TYPE_CAR: 'Car',
  WORD_PRICE_TRANSPORT_TYPE_WALK: 'Walk',
  WORD_PRICE_TRANSPORT_TYPE_BICYCLE: 'Bicycle',
  WORD_PRICE_TRANSPORT_TYPE_TRACK: 'Track',
  WORD_PRICE: 'Price',
  WORD_PRICE_TYPE: 'Pricing type',
  WORD_PRICE_FIXED_PRICE: 'Fixed price',
  WORD_PRICE_BY_DISTANCE: 'By distance',
  WORD_SERVICE_MIN_KM: 'Min KM',
  WORD_SERVICE_PRICE_MIN_KM: 'Min KM Price',
  WORD_SERVICE_PRICE_PER_KM: 'One KM price',
  WORD_SERVICE_PRICING: 'Pricing',
  WORD_SERVICE_ADD_PRICE: 'Add more',
  WORD_TRANSPORT_PRICE: 'Price',
  WORD_REASON_NEW_TITLE: 'New reason',
  WORD_REASON_UPDATE_TITLE: 'Edit reason',
  NOTE_REASON_CREADE_SUCCESS: 'Reason created successfully!',
  NOTE_REASON_UPDATE_SUCCESS: 'Reason updated successfully!',
  WORD_REASON_TITLE: 'Reason',
  WORD_ADD_USER: 'Add user',
  WORD_TEAM_LIST_TITLE: 'Team',
  WORD_FILTER_APPLY: 'Apply',
  WORD_ORDER_DUE_DATE: 'Date',
  WORD_TEL: 'Tel',
  WORD_TRANSPORT: 'Transport',
  WORD_USER_INFO_TITLE: 'User info',
  WORD_AVATAR: 'Profile picture',
  WORD_USER_NAME: 'Full name',
  WORD_USER_ROLE: 'User role',
  WORD_USER_LOGIN_SECTION_TITLE: 'Login credentials',
  WORD_USER_LOGIN: 'Username',
  WORD_USER_TRANSPORT_SECTION_TITLE: 'Transport',
  WORD_TRANSPORT_ID: 'Transport ID',
  WORD_TRANSPORT_DETAILS: 'Transport details',
  WORD_SELECT_ALL_ROLES: 'All roles',
  WORD_CUSTOMERS_LIST_TITLE: 'Customers',
  WORD_ADD_CUSTOMER: 'Add customer',
  WORD_CUSTOMER_TITLE_NEW: 'New customer',
  WORD_CUSTOMER_TITLE_EDIT: 'Edit customer',
  WORD_COMPANY_SETTINGS_TITLE: 'Company settings',
  WORD_COMPANY_NAME: 'Company name',
  WORD_LEGAL_NAME: 'Legal name',
  WORD_TIN: 'TIN',
  WORD_COUNTRY: 'Country',
  WORD_CURRENCY: 'Currency',
  WORD_SAVE: 'Save',
  WORD_CHANGE_PASSWORD: 'Change password',
  WORD_LANGUAGE: 'Language',
  WORD_ACCOUNT_SETTINGS_TITLE: 'Account settings',
  WORD_CHANGE_PASSWORD_TITLE: 'Change password',
  WORD_CURRENT_PASSWORD: 'Current password',
  WORD_NEW_PASSWORD: 'New password',
  WORD_REPEATE_NEW_PASSWORD: 'Repeat password',
  WORD_CHANGE: 'Change',
  WORD_API_CODE: 'API code',
  WORD_ADD_API_CODE: 'Add API code',
  WORD_NEW_API_CODE: 'New API code',
  WORD_EDIT_API_CODE: 'Edit API code',
  WORD_API_VIEW: 'API view',
  WORD_TOKEN: 'Token',
  WORD_COPY_TOKEN: 'Copy token',
  WORD_DEVICES: 'Device',
  WORD_ADD_NEW_DEVICES: 'Add new device',
  WORD_EDIT_DEVICES: 'Edit device',
  WORD_ADD_DETAILS: 'Add details',
  WORD_DEVICE_CREATED_SUCCESSFULY: 'Device created successfuly',
  WORD_DEVICE_UPDATED_SUCCESSFULY: 'Device updated successfuly',
  WORD_M: 'M.',
  WORD_CREATE_DATE: 'Create date',
  WORD_OS: 'OS',
  WORD_START_DATE: 'Start date',
  WORD_END_DATE: 'End date',
  WORD_RESET: 'Reset',
  ARE_YOU_SURE_TO_DELETE_THIS_ITEM: 'Do you want to delete this item?',
  WORD_YES: 'Yes',
  WORD_NO: 'No',
  WORD_ERROR_PLEASE_INPUT: 'Please input',
  WORD_ERROR_PLEASE_INPUT_TIME_RANGE: 'Please input time range',
  WORD_ERROR_PLEASE_SELECT: 'Please select',
  WORD_SEND: 'Send',
  WORD_ENTERPRISE: 'Enterprise',
  WORD_DEFINATION: 'Defination',
  ERROR_PLEASE_INPUT_YOUR_PHONE_NUMBER: 'Please input your phone number!',
  WORD_CUSTOM_AMOUNT_DELIVERY: 'Custom amount delivery',
  WORD_AMOUNT_DELEVERY: 'Amount delivery',
  WORD_DELEVERY_DATE: 'Delivery date',
  WORD_ADD_A_WAYPOINT: 'Add a waypoint',
  WORD_WAYPOINT: 'Waypoint',
  WORD_FROM: 'From',
  WORD_TO: 'To',
  WORD_SELECT_COUNTRY: 'Select country',
  WORD_ALL_COUNTRIES: 'All countries',
  WORD_ALL_CODES: 'All codes',
  WORD_CODES: 'Codes',
  WORD_NOTIFICATIONS: 'Notifications',
  WORD_SMS: 'SMS',
  WORD_CUSTOMER_NOTIFICATIONS: 'Customer notifications',
  WORD_EDIT_TEMPLATE: 'Edit template',
  WORD_EDIT_ORDER_STARTED_TEMPLATE: 'Edit order started template',
  WORD_YOU_CAN_USE: 'You can use',
  WORD_IN_MESSAGES_BELOW_TO_INSERT_THE_CUSTOMER_COMPANY_ORDER_ID_OR_AND_TRACKING_URL:
    'in messages below to insert the order tracking url.',
  WORD_IN_MESSAGES_BELOW_TO_INSERT_THE_CUSTOMER_COMPANY_ORDER_ID_OR_AND_RATING_URL:
    'in messages below to insert the rating tracking url.',
  WORD_CUSTOMER_SMS_NOTES_1: '1 SMS = 160 symbols.',
  WORD_CUSTOMER_SMS_NOTES_2:
    'If you use {{TRACKING_ID}} tag in your SMS then 1SMS = 135 symbols.',
  WORD_CUSTOMER_SMS_NOTES_3:
    'Please note that if the message has more than 160 symbols it will be charged in the next way (message symbols count /160).',
  WORD_SUBJECT: 'Subject',
  WORD_MESSAGE: 'Message',
  WORD_ENABLED: 'enabled',
  WORD_DISABLED: 'disabled',
  WORD_SUCCESSFULY: 'successfully',
  WORD_KG: 'KG',
  WORD_M3: 'M',
  WORD_M3_WITH_3: 'M3',
  WORD_FREIGHT_MANAGEMENT: 'Freight management',
  WORD_NEW_FREIGHT: 'New freight',
  WORD_ADD_FREIGHT: 'Add freight',
  WORD_FREIGHTS: 'Freights',
  WORD_KG_LC: 'kg',
  WORD_M3_LC: 'm',
  WORD_SPLITTED: 'Splitted',
  WORD_CHOOSEN: 'Choosen',
  WORD_QUANTITY: 'Quantity',
  WORD_POLYGONS: 'Polygons',
  WORD_POLYGONS_EDIT: 'Polygons edit',
  WORD_BUSINESS_ZONES: 'Business zones',
  WORD_BUSINESS_ZONES_EDIT: 'Business zone edit',
  WORD_NEW_BUSINESS_ZONES: 'New business zone',
  WORD_ADD_BUSINESS_ZONES: 'Add business zone',
  WORD_BUSINESS_ZONE_CREATED_SUCCESSFULY: 'Business zone created successfuly',
  WORD_BUSINESS_ZONE_UPDATED_SUCCESSFULY: 'Business zone updated successfuly',
  WORD_BUSINESS_ZONE_DELETED_SUCCESSFULY: 'Business zone deleted successfuly',
  WORD_NEW_POLYGON: 'New polygon',
  WORD_ADD_POLYGON: 'Add polygon',
  WORD_COLOR: 'Color',
  WORD_EVALUATE_YOUR_EXPERIENCE: 'Evaluate your experience',
  WORD_TELL_US_ABOUT_YOUR_EXPERIENCE: 'Tell us about your experience',
  WORD_POST: 'Post',
  WORD_DISTANCE: 'Distance',
  WORD_DURATION: 'Duration',
  WORD_NEW: 'New',
  WORD_ORDER_SPLITED_SUCCESSFULY: 'Order splited successfully',
  ERROR_QUANTITY_IS_GREATER_THANAVAILABLE: 'Quantity is greater than available',
  ERROR_QUANTITY_MUST_BE_GREATER_THAN_ZERO:
    'Quantity must be greater than zero',
  WORD_ORDERS_ASSIGNED_SUCCESSFULY: 'Oders assigned successfully',
  WORD_ORDERS_ASSIGNED_AND_SEND_SUCCESSFULY:
    'Oders assigned and send successfully',
  WORD_SHIPMENT: 'Shipment',
  WORD_GATE: 'Gate',
  WORD_PENDING_STATUS: 'Pending',
  WORD_IN_PROGRESS: 'In progress',
  WORD_READY: 'Ready',
  WORD_READY_FOR_DELEVERY: 'Ready for delivery',
  WORD_STATUS_CHANGED_SUCCESSFULLY: 'Status changed successfully',
  WORD_PLEASE_INPUT_GATE: 'Please input the gate number',
  WORD_TOKEN_GENERATE_SUCCESSFULLY: 'Token generate successfully',
  WORD_ORDER_CREATED_SUCCESSFULY: 'Order created successfully',
  WORD_ORDER_UPDATED_SUCCESSFULY: 'Order updated successfully',
  FREIGHT_CREATED_SUCCESSFULY: 'Freight created successfully',
  FREIGHT_UPDATED_SUCCESSFULY: 'Freight updated successfully',
  WORD_OPEN_LINK: 'Open link',
  WORD_OPEN_SPLIT_MODAL: 'Open split modal',
  WORD_CHANGED_PASSWORD_SUCCESS: 'Changed password successfully',
  WORD_CHANGED_PROFILE_SETTINGS_SUCCESS:
    'Profile settings changed successfully',
  WORD_ARMENIAN: 'Armenian',
  WORD_SELECT_ONE: 'Select one',
  WORD_RATING_TITLE: 'Rating',
  WORD_COPY: 'Copy',
  WORD_REGENERATE_TOKEN: 'Regenerate token',
  WORD_TRUCKING_URL: 'Tracking URL',
  WORD_RATING_URL: 'Rating URL',
  WORD_ORDER_RATINGS: 'Order rating',
  WORD_REVIEW: 'Review',
  WORD_BY_REGISTERING_IN_DELIVERY_MANAGER_YOU_AGREE_WITH_OUR:
    'By registering in Delivery Manager you agree with our',
  WORD_TERMS_CONDITIONS: 'Terms & Conditions ',
  WORD_AND: 'and',
  WORD_PRIVACY_POLICY: 'Privacy Policy',
  WORD_COMPANY_SETTINGS_CHANGED_SUCCESSFULLY:
    'Company settings changed successfully',
  WORD_REGISTERED_SUCCESSFULLY: 'Registered successfully',
  WORD_USER_CREATED_SUCCESSFULY: 'User created successfuly',
  WORD_USER_UPDATED_SUCCESSFULY: 'User updated successfuly',
  WORD_API_CODE_CREATED_SUCCESSFULY: 'Api code created successfuly',
  WORD_API_CODE_UPDATED_SUCCESSFULY: 'Api code updated successfuly',
  WORD_API_CODE_DELETED_SUCCESSFULY: 'Api code deleted successfully',
  WORD_THE_PHONE_LENGTH_MUST_BE: 'The phone length must be',
  WORD_ERROR_PLEASE_INPUT_ALL_FIELDS: 'Please input all fields',
  WORD_PRODUCT_UNIT: 'Unit',
  WORD_CUSTOMER_CREATED_SUCCESSFULY: 'Customer created successfuly',
  WORD_CUSTOMER_UPDATED_SUCCESSFULY: 'Customer updated successfuly',
  WORD_DISTRIBUTOR: 'Distributor',
  WORD_READ_MORE: 'More',
  WORD_READ_LESS: 'Less',
  WORD_MONITORING_LINK_COPIED_SUCCESSFULY: 'Monitoring link copied successfuly',
  WORD_TRACKING_URL_COPIED_SUCCESSFULY: 'Tracking url copied successfuly',
  WORD_RATING_URL_COPIED_SUCCESSFULY: 'Rating url copied successfuly',
  WORD_SELECT_DATE: 'Select date',
  WORD_SELECT_BUSINESS_ZONE: 'Select business zone',
  WORD_OTHER: 'Other',
  WORD_CHANGE_DRIVER_FREIGHT: 'Change driver freight',
  WORD_BRANCH: 'Branch',
  WORD_ADD_NEW_BRANCH: 'New branch',
  WORD_EDIT_BRANCH: 'Edit branch',
  WORD_ADD_BRANCH: 'Add branch',
  WORD_CODE: 'Code',
  WORD_BRANCH_CREATED_SUCCESSFULY: 'Branch created successfully',
  WORD_BRANCH_UPDATED_SUCCESSFULY: 'Branch updated successfully',
  WORD_SELECT_BRANCHES: 'Select branches',
  WORD_ALL_BRANCHES: 'All branches',
  WORD_SEARCH_PRESS_ENTER: 'Type and press enter',
  WORD_RATE_TITLE: 'Rate',
  WORD_WRITE_REVIEW: 'Write a review',
  WORD_REMEMBER_ME: 'Remember me',
  WORD_STATUS_ACTIVE_SEND: 'Send',
  WORD_STATUS_INPROGRESS: 'Inprogress',
  WORD_STATUS_FINISHED: 'Finished',
  WORD_TEMPLATE_UPDATED_SUCCESSFULLY:
    'Notification template updated successfully',
  WORD_DATE: 'Date',
  WORD_SMS_HISTORY: 'Sms history',
  WORD_NOTIFICATION_TYPE: 'Notif type',
  WORD_TEXT_BODY: 'Text body',
  WORD_SELECT_SERVICE: 'Select service',
  WORD_DELIVERY_DATE: 'Delivery date',
  WORD_END_OF_LIST: 'End of list',
  WORD_ORDER_START: 'Order started',
  WORD_ORDER_DELIVERED: 'Order delivered',
  WORD_ORDER_NOT_DELIVERED: 'Order not delivered',
  WORD_ORDER_COLLECTED: 'Order is collected',
  WORD_ALL_BUSINESS_ZONES: 'All business zones',
  WORD_SELECT_TRANSPORT: 'Select transport',
  WORD_EXPORT: 'Export',
  WORD_ORDER_TEST: 'test',
  WORD_FAILED: 'Failed',
  WORD_SUCCESS: 'Success',
  WORD_SELECT_ALL_CREATED_TYPE: 'All created type',
  WORD_SELECT_CREATED_TYPE: 'Select created type',
  WORD_DEFAULT: 'Default',
  WORD_SPLIT: 'Splitted',
  WORD_NOT_DELEVERED: 'Not Delivered',
  WORD_WAITING: 'Waiting',
  WORD_SEND_NOTIFICATIONS: 'send Notifications',

  // server
  Create: 'Create',
  'Assign Driver': 'Assign Driver',
  'Assign Manifest': 'Assign Manifest',
  Start: 'Start',
  Finish: 'Finish',
  Active: 'Active',
  ios: 'iOS',
  android: 'Android',
  Micro: 'Micro',
  Small: 'Small',
  Standard: 'Standard',
  Finished: 'Finished',
  InProgress: 'In progress',
  Car: 'Car',
  Walk: 'Walk',
  Bicycle: 'Bicycle',
  Track: 'Track',
  'Super Admin': 'Super Admin',
  Admin: 'Admin',
  Operator: 'Operator',
  Driver: 'Driver',
  'Order is collected': 'Order is collected',
  'Order started': 'Order started',
  'Order delivered': 'Order delivered',
  'Order not delivered': 'Order not delivered',
  'Send message to customer when order is collected':
    'Send message to customer when order is collected',
  'Send message to customer when order start':
    'Send message to customer when order start',
  'Send message to customer when order delivered':
    'Send message to customer when order delivered',
  'Send message to customer when order not delivered':
    'Send message to customer when order not delivered',
};

export default texts;
