import useIsMounted from '@utilityjs/use-is-mounted';
import React, { useEffect, useState } from 'react';
import UserActions from '../../actions/UserActions';
import SelectForm from '../antd/SelectForm';

const SelectFormUserDrivers = (props) => {
  const [listData, setListData] = useState([]);

  const {
    label,
    name,
    showSearch,
    required,
    onChangeItem,
    onChange,
    hasFeedback,
  } = props;

  const isMounted = useIsMounted();

  useEffect(() => {
    let fill = async () => {
      if (isMounted()) {
        setListData(await UserActions.getFullListDriversOptions());
      }
    };
    fill();
  }, [isMounted]);

  return (
    <SelectForm
      label={label}
      name={name}
      options={listData}
      showSearch={showSearch}
      required={required}
      onChangeItem={onChangeItem}
      onChange={onChange}
      hasFeedback={hasFeedback}
    />
  );
};

export default SelectFormUserDrivers;
