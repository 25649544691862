import ApiStorage from '../reducers/ApiStorage';
import history from './history';
import moment from 'moment';
import getText, { LANGS } from '../lang';

export const isDebugMode = process.env.REACT_APP_BASE_URL.startsWith(
  'http://localhost'
)
  ? true
  : false;

export const SELECT_UNSELECT_NONE = '';
export const DEFAULT_LANGUAGE = 'en';
export const TABLE_FETCH_PAGE_SIZE = 20;

export function urlImg(fileName) {
  return '../icons/' + fileName;
}

export function urlImgPublic(fileName) {
  return process.env.PUBLIC_URL + '/icons/' + fileName;
}

export function getRedirectLink(path) {
  let lang = ApiStorage.getLanguage();
  if (!lang || !LANGS.includes(lang)) {
    lang = DEFAULT_LANGUAGE;
  }
  return `/${lang}/${path}`;
}

export const redirectPath = (path, search, state) => {
  let loc = { pathname: getRedirectLink(path), search, state };
  history.push(loc);
};

export const redirectPathNewTab = (path) => {
  window.open(getRedirectLink(path), '_blank');
};

export const formatDateTime = (dat) => {
  if (dat) {
    try {
      return moment(dat).format('DD/MM/YYYY HH:mm');
    } catch (error) {
      return '---';
    }
  }
  return '---';
};

export const formatDistance = (metr) => {
  if (metr) {
    let dis = Math.round((metr / 1000) * 10) / 10;
    if (dis < 1) {
      return metr + ' ' + getText('WORD_M');
    }
    return dis + ' ' + getText('WORD_KM');
  }
  return '';
};

export const formatDuration = (d) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  // let s = Math.floor(d % 3600 % 60);

  if (!h && !m) m = 1;
  let hDisplay = h > 0 ? h + ' ' + getText('WORD_HR') : '';
  let mDisplay = m > 0 ? m + ' ' + getText('WORD_MIN') : '';
  return hDisplay + ' ' + mDisplay;
};

export const formatPhoneNumber = (phoneCode, phoneNumber) => {
  let value = `${phoneCode}-${phoneNumber}`;
  if (!value.startsWith('+') && !value.startsWith('00')) {
    value = `+${value}`;
  }
  return value;
};
