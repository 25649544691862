import React from 'react';
import { List } from 'antd';
import ReactDragListView from 'react-drag-listview';

const DraggableList = (props) => {
  const { onChange, lineClassName, loading, data, renderItem } = props;

  const onDragEnd = (fromIndex, toIndex) => {
    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    onChange && onChange(items, toIndex, item);
  };

  return (
    <div>
      <ReactDragListView
        nodeSelector=".ant-list-item.draggble"
        onDragEnd={onDragEnd}
        lineClassName={`draggble-line ${lineClassName}`}
      >
        <List
          size="small"
          bordered={false}
          loading={loading}
          dataSource={data}
          renderItem={(item, index) => {
            return (
              <List.Item className={'draggble'}>
                {renderItem(item, index)}
              </List.Item>
            );
          }}
        />
      </ReactDragListView>
    </div>
  );
};

export default DraggableList;
