import React, { memo } from 'react';
import MyRow from '../../components/MyRow';
import { Space, Timeline } from 'antd';
// import TimelineDotIcon from '../../icons/svg/TimelineDotIcon';
// import EditIcon from "../../icons/svg/EditIcon";
import { formatDateTime } from '../../utils';
// import OrderActions from "../../actions/OrderActions";
import ManifestIcon from '../../icons/svg/ManifestIcon';
import CalButton from '../../components/CalButton';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import OrderIcon from '../../icons/svg/OrderIcon';
import useNavigateApi from '../../AppRoute/useNavigateApi';
// import UserActions from "../../actions/UserActions";

const OrderCard = (props) => {
  const { item } = props;

  const navigateApi = useNavigateApi();

  const RenderDate = () => {
    return (
      <div className="date-div">
        {/* {item?.deliveryDate && formatDateTime(item?.deliveryDate)} */}
        {formatDateTime(item?.createDate)}
      </div>
    );
  };

  return (
    <div
      className="one-order-block-wrapper"
      onClick={(e) => {
        e.stopPropagation();
        navigateApi(`orders/view/${item?.idKey}`);
      }}
    >
      <MyRow bottomZero>
        <div className="order-number-with-icon">
          <MyRow className="test">
            {item?.idManifest ? <ManifestIcon /> : <OrderIcon />}
            <div className="number-branch-wrapper">
              <div className="number-div">
                {item?.apiOrderKey || item?.orderNumber}
              </div>
              <div className="branch-div">{item?.branchName}</div>
            </div>
          </MyRow>
        </div>
        <RenderDate />
      </MyRow>
      <div className="divider-line" />
      <MyRow bottomZero>
        <Timeline
          items={[
            { key: 1, children: item?.addressFrom },
            { key: 2, children: item?.addressTo },
          ]}
        />
      </MyRow>
      <div className="divider-line" />
      <MyRow withoutTitle bottomZero className="callbtn-with-avatar">
        {/* <MyRow withoutTitle bottomZero className="avatar-name"> */}
        <Space className="avatar-name">
          <ProfileAvatar icon={item?.driverIcon} value={item?.driverName} />
          <div className="number-div">{item?.driverName || '---'}</div>
        </Space>
        {/* </MyRow> */}
        {Boolean(item?.driverName) && (
          <CalButton
            phoneNumber={item?.driverPhoneCode + '' + item?.driverPhoneNumber}
          />
        )}
      </MyRow>
    </div>
  );
};

export default memo(OrderCard);
