import React from 'react';
import { Badge } from 'antd';
import './MyTextWithBadge.scss';

const MyTextWithBadge = (props) => {
  const { mainText, topSymbol, className, size, offset, color } = props;
  return (
    <Badge
      count={topSymbol}
      className={`my-text-with-badge ${className || ''}`}
      size={size || 'small'}
      offset={offset || [4, 2]}
      color={color}
    >
      {mainText}
    </Badge>
  );
};

export default MyTextWithBadge;
