import ApiStorage from '../reducers/ApiStorage';
import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_ChangeAccountSettings = '/clients/account_settings';
const URL_SMS_TEST = '/clients/sms/test';

const fs = {
  saveAccountSetting: async (values) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_ChangeAccountSettings,
      urlParams
    );
    if (result.success) {
      await ApiStorage.setUser(result.data);
    }
    return result;
  },
  sendSmsTest: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_SMS_TEST, {
      saveData: urlParams,
    });
    return result;
  },
};

const loc = {};

const ClientActions = Object.assign(fs, loc);

export default ClientActions;
