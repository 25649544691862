import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';
import getText from '../lang';

const URL_SAVE = '/reasons/save';
const URL_GET_ONE = '/reasons/get_one';
const URL_STATUS_ACTIVE = '/reasons/change_status/active';
const URL_STATUS_INACTIVE = '/reasons/change_status/inactive';
const URL_PAGINATION_LIST = '/reasons/list';

const fs = {
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  changeStatusActive: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_STATUS_ACTIVE + '/' + idKey,
      urlParams
    );
    return result;
  },
  changeStatusInActive: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_STATUS_INACTIVE + '/' + idKey,
      urlParams
    );
    return result;
  },
  save: async (values, idService = false) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result;
    if (idService) {
      result = await fetchFromUrlPOSTAsync(
        URL_SAVE + '/' + idService,
        urlParams
      );
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE, urlParams);
    }
    return result;
  },
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
};

const loc = {
  STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  TYPE: {
    ORDER: 1,
  },
  getStatusOptions: (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT'),
    }
  ) => {
    const options = [
      { label: getText('WORD_STATUS_ACTIVE'), value: loc.STATUS.ACTIVE },
      { label: getText('WORD_STATUS_INACTIVE'), value: loc.STATUS.INACTIVE },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusOptionsAll: () => {
    return loc.getStatusOptions({
      value: SELECT_UNSELECT_NONE,
      // label: getText('WORD_SELECT_ALL'),
      label: getText('WORD_SELECT_ALL_STATUSES'),
    });
  },
};

const ReasonsActions = Object.assign(fs, loc);

export default ReasonsActions;
