import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const MyCaptcha = ({ onChange }) => {
  const reCaptchaRef = useRef();

  useEffect(() => {
    const check = async () => {
      const token = await reCaptchaRef.current.executeAsync();
      if (token) {
        onChange && onChange(token);
      }
      // reCaptchaRef.current.reset();
    };
    check();
  }, [onChange]);

  return (
    <ReCAPTCHA
      ref={reCaptchaRef}
      className="my-captcha"
      sitekey={process.env.REACT_APP_CHAPCHA_KEY}
      // onChange={onChange}
      size="invisible"
    />
  );
};

export default MyCaptcha;
