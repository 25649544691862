import useIsMounted from '@utilityjs/use-is-mounted';
import React, { useEffect, useState } from 'react';
import BranchActions from '../../actions/BranchActions';
import getText from '../../lang';
import MySelect from '../antd/MySelect';
import './style.scss';

const SelectBranch = (props) => {
  const { withAll } = props;

  const [listData, setListData] = useState([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    let fill = async () => {
      let result;
      if (withAll) {
        result = await BranchActions.getFullListOptionsAll();
      } else {
        result = await BranchActions.getFullListOptions();
      }
      if (isMounted()) {
        setListData(result);
      }
    };
    fill();
  }, [isMounted, withAll]);

  return (
    <MySelect
      {...props}
      className="selectBranch"
      name="idBranch"
      options={listData}
      placeholder={
        withAll ? getText('WORD_ALL_BRANCHES') : getText('WORD_SELECT_BRANCHES')
      }
    />
  );
};

SelectBranch.defaultProps = {
  showSearch: true,
};

export default SelectBranch;
