import React from 'react';
import { Form, Switch } from 'antd';

const SwitchForm = (props) => {
  const { label, name } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      layout="vertical"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
  );
};

SwitchForm.defaultProps = {
  name: 'booleanSwichValue',
  type: 'boolean',
};

export default SwitchForm;
