import React, { forwardRef } from 'react';
import { Select } from 'antd';
import './style.scss';

const MySelect = forwardRef((props, ref) => {
  const {
    className,
    placeholder,
    label,
    defaultValue,
    showSearch,
    options,
    onChangeItem,
    onChange,
    value,
    style,
    onClick,
    loading,
  } = props;

  return (
    <div className={`my-select ${className || ''}`}>
      <Select
        className="my-select-wrapper"
        placeholder={placeholder || label}
        defaultValue={defaultValue}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e, ee) => {
          let find = options.find((oo) => {
            return oo.value === e;
          });
          onChangeItem && onChangeItem(find, e);
          onChange && onChange(e, ee);
        }}
        value={value || null}
        style={style}
        onClick={onClick}
        loading={loading}
      >
        {options.map((item, index) => {
          return (
            <Select.Option key={index} value={item.value}>
              {item.label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
});

MySelect.defaultProps = {
  hasFeedback: true,
  showSearch: true,
  required: false,
  options: [],
};

export default MySelect;
