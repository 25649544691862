import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="12"
    viewBox="0 0 15 12"
  >
    <g id="Group_9618" data-name="Group 9618" transform="translate(-518 -350)">
      <rect
        id="Rectangle_4879"
        data-name="Rectangle 4879"
        width="15"
        height="3"
        rx="1.5"
        transform="translate(518 350)"
        fill="#bebebe"
      />
      <rect
        id="Rectangle_4880"
        data-name="Rectangle 4880"
        width="15"
        height="3"
        rx="1.5"
        transform="translate(518 359)"
        fill="#bebebe"
      />
    </g>
  </svg>
);

const OrderingIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default OrderingIcon;
