import React from 'react';
import HeaderSpace from '../components/HeaderSpace';

const Analytics = (props) => {
  return (
    <>
      <HeaderSpace />
    </>
  );
};
export default Analytics;
