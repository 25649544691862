import React from 'react';

const ProfileLetters = (props) => {
  const { className, value } = props;

  return (
    <div className={'user-profile-letters ' + (className ? className : '')}>
      {typeof value === 'string'
        ? value &&
          value
            .toUpperCase()
            .split(' ')
            .map((word) => word.substring(0, 1))
            .slice(0, 2)
        : Array.isArray(value)
        ? value.map((word) => {
            return word ? word.toUpperCase().substring(0, 1).slice(0, 2) : null;
          })
        : null}
    </div>
  );
};

export default ProfileLetters;
