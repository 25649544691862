import React, { useState } from 'react';
import { Button, DatePicker, Form } from 'antd';
import getText from '../../lang';
import utilDate from '../../utils/utilDate';
import MyRow from '../MyRow';

const InputFormDatePickerRange = (props) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const {
    onChange,
    value,
    defaultDateRange,
    style,
    name,
    label,
    hasFeedback,
    required,
  } = props;

  const handleChnage = (e) => {
    onChange && onChange(e);
    setOpenDatePicker(false);
  };

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: `${getText('WORD_ERROR_PLEASE_INPUT_TIME_RANGE')} ${label}!`,
        },
      ]}
      layout="vertical"
    >
      <DatePicker.RangePicker
        value={value}
        defaultValue={defaultDateRange}
        format={'DD/MM/YYYY'}
        open={openDatePicker}
        style={style}
        onOpenChange={(e) => {
          setOpenDatePicker(e);
        }}
        placeholder={[getText('WORD_START_DATE'), [getText('WORD_END_DATE')]]}
        onChange={handleChnage}
        renderExtraFooter={() => {
          return (
            <MyRow>
              <Button
                type="link"
                onClick={() => {
                  const dat = utilDate.getDateYesterday();
                  // setSelectedDateRange([dat, dat]);
                  // onChange([dat, dat]);
                  // setOpenDatePicker(false);
                  handleChnage([dat, dat]);
                }}
              >
                {getText('WORD_YESTERDAY')}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  const dat = utilDate.getDateToday();
                  // setSelectedDateRange([dat, dat]);
                  // onChange([dat, dat]);
                  // setOpenDatePicker(false);
                  handleChnage([dat, dat]);
                }}
              >
                {getText('WORD_TODAY')}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  const dat = utilDate.getDateTomorrow();
                  // setSelectedDateRange([dat, dat]);
                  // onChange([dat, dat]);
                  // setOpenDatePicker(false);
                  handleChnage([dat, dat]);
                }}
              >
                {getText('WORD_TOMORROW')}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  const weekStart = utilDate.getThisWeekStart();
                  const weekEnd = utilDate.getThisWeekEnd();
                  // setSelectedDateRange([weekStart, weekEnd]);
                  // onChange([weekStart, weekEnd]);
                  // setOpenDatePicker(false);
                  handleChnage([weekStart, weekEnd]);
                }}
              >
                {getText('WORD_THIS_WEEK')}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  const monthStart = utilDate.getThisMonthStart();
                  const monthEnd = utilDate.getThisMonthEnd();
                  // setSelectedDateRange([monthStart, monthEnd]);
                  // onChange([monthStart, monthEnd]);
                  // setOpenDatePicker(false);
                  handleChnage([monthStart, monthEnd]);
                }}
              >
                {getText('WORD_THIS_MONTH')}
              </Button>
            </MyRow>
          );
        }}
      />
    </Form.Item>
  );
};

InputFormDatePickerRange.defaultProps = {
  label: '',
  name: 'timeRangeValue',
  required: false,
  hasFeedback: true,
  allowClear: true,
  format: 'h:mm a',
  use12Hours: true,
};

export default InputFormDatePickerRange;
