import React, { useEffect, useRef, useState } from 'react';
// import MyRow from '../../components/MyRow';
import MyMap from '../../components/MyMap';
// import OrderTimeLine from '../../components/OrderTimeLine';
import { useParams } from 'react-router-dom';
import OrderActions from '../../actions/OrderActions';
import getText from '../../lang';
// import OrderViewContactCard from './OrderViewContactCard';
import { Timeline } from 'antd';
// import TimelineDotIcon from '../../icons/svg/TimelineDotIcon';
import MainPage from '../../components/MainPage';
import { formatDateTime } from '../../utils';
import { Button } from 'antd';
import useWindowSize from '../../utils/useWindowSize';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const OrderViewTracking = () => {
  const navigateApi = useNavigateApi();
  const { _id, _idKeyClient } = useParams();

  const [item, setItem] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [loading, setLoading] = useState(false);
  const refContainer = useRef(null);

  const { isDesktop } = useWindowSize();

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        setLoading(true);
        let resultGet = await OrderActions.getOneByidKeyTracking(
          _idKeyClient,
          _id
        );
        setLoading(false);
        if (resultGet.success) {
          let orderItemsTotal_delevered = 0;
          let orderItemsTotal_deleveredNot = 0;
          let orderItemsTotal_total = 0;
          resultGet.data.items.forEach((it) => {
            orderItemsTotal_total += it.itemPrice;
            if (it.status === OrderActions.STATUS_ITEMS.DELIVERED) {
              orderItemsTotal_delevered += it.itemPrice;
            } else if (it.status === OrderActions.STATUS_ITEMS.NOTDELIVERED) {
              orderItemsTotal_deleveredNot += it.itemPrice;
            }
          });
          resultGet.data._orderItemsTotal_delevered = orderItemsTotal_delevered;
          resultGet.data._orderItemsTotal_deleveredNot =
            orderItemsTotal_deleveredNot;
          resultGet.data._orderItemsTotal_total = orderItemsTotal_total;
          setItem(resultGet.data);
          setCompanyData(resultGet.company);
        } else {
          navigateApi('error_page_401');
        }
      }
    };
    getObj();
  }, [_id, _idKeyClient, navigateApi]);

  const drawRouteAddress = (ymaps) => {
    const MyMapRef = refContainer.current;
    if (MyMapRef) {
      MyMapRef.geoObjects.removeAll();
      let from = item.addressFrom;
      let to = item.addressTo;
      if (from && to) {
        var multiRoute = new ymaps.multiRouter.MultiRoute(
          {
            referencePoints: [from, to],
            params: {
              results: 2,
            },
          },
          {
            boundsAutoApply: true,
          }
        );
        MyMapRef.geoObjects.add(multiRoute);
      }
      let driver = item.driverLocation;
      if (
        driver &&
        driver.isOnline &&
        driver.isDeviceOnline &&
        driver.lastLat &&
        driver.lastLng
      ) {
        let color = 'blue';
        let hint = formatDateTime(driver.lastLocationDate);
        var m = new ymaps.Placemark(
          [driver.lastLat, driver.lastLng],
          {
            balloonContent: `${driver.transportTypeName}
                            ${
                              driver.transportNumber
                                ? driver.transportNumber
                                : ''
                            }
                            ${
                              driver.transportDescription
                                ? driver.transportDescription
                                : ''
                            }`,
            clusterCaption: 'Another one placemark',
            hintContent: hint,
            iconContent: `${driver.fullName}`,
          },
          {
            preset: `islands#${color}StretchyIcon`,
            balloonCloseButton: true,
            hideIconOnBalloonOpen: false,
          }
        );
        MyMapRef.geoObjects.add(m);
      }
    }
  };

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      drawRouteAddress(ymaps);
    });
  };

  const RenderProductItems = () => {
    return item.items
      ? item.items.map((oo, index) => {
          return (
            <div className="serives-row" key={index}>
              <div className="row-first-item">{oo.itemName}</div>
              <div className="row-first-item">
                {oo.itemQuantity}
                <span className="item-unit">{oo.itemUnit}</span>
              </div>
              <div className="row-second-item">
                {oo.itemPrice}
                <span className="currency-symbol">
                  {companyData.clientCurrencySymbol}
                </span>
              </div>
            </div>
          );
        })
      : null;
  };

  return (
    <>
      {loading ? (
        <LoadingOutlinedApp />
      ) : (
        <div className="create-new-order-wrapper order-tracking-wrapper">
          <div className="order-view-two-blocks">
            <MainPage topZero bottomZero>
              {/* <MyRow className="order-view-block-header">
                  <MyRow>
                    <div className="order-view-block-name">
                      {getText("WORD_ORDER")}
                    </div>
                    <div className="order-view-block-number">
                      #{item?.apiOrderKey || item?.orderNumber}
                    </div>
                  </MyRow>
                </MyRow> */}
              {/* <OrderViewContactCard
                                type={getText("WORD_CUSTOMER")}
                                fullName={item.customerName}
                                phoneNumber={item.phoneCode + '' + item.phoneNumber}
                                address=""
                                icon={''}
                            /> */}
              <div className="order-timeline-wrapper order-timeline-with-point">
                <div className="timeline-header">{getText('WORD_ADDRESS')}</div>
                <Timeline
                  items={[
                    { key: 1, children: item.addressFrom },
                    { key: 2, children: item.addressTo },
                  ]}
                />
              </div>
              <div className="order-view-description">
                <div className="about-order-wrapper">
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_SERVICE')}
                    </div>
                    <div className="about-order-name">{item.serviceName}</div>
                  </div>
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_CHECK_ID')}
                    </div>
                    <div className="about-order-name">
                      {item.documentNumber ? item.documentNumber : '---'}
                    </div>
                  </div>
                  <div className="about-order-row">
                    <div className="about-order-type">
                      {getText('WORD_BRANCH')}
                    </div>
                    <div className="about-order-name">
                      {item.branchName || '---'}
                    </div>
                  </div>
                  {/* <div className="about-order-row">
                                        <div className="about-order-type">{getText("WORD_NOTES")}</div>
                                        <div className="about-order-name">{item.description}</div>
                                    </div> */}
                </div>
                <div className="order-view-services-prices">
                  <div className="serives-row">
                    <div className="row-first-item">
                      {getText('WORD_SERVICE_AMOUNT')}
                    </div>
                    <div className="row-second-item">
                      {item.amountDelivery}
                      <span className="currency-symbol">
                        {companyData.clientCurrencySymbol}
                      </span>
                    </div>
                  </div>
                  <div className="serives-row">
                    <div className="row-first-item">
                      {getText('WORD_ORDER_AMOUNT')}
                    </div>
                    <div className="row-second-item">
                      {item.amountOrder}
                      <span className="currency-symbol">
                        {companyData.clientCurrencySymbol}
                      </span>
                    </div>
                  </div>
                  <RenderProductItems />
                  <div className="serives-row big">
                    <div className="row-first-item">{'Subtotal'}</div>
                    <div className="row-second-item">
                      {item._orderItemsTotal_total}
                      <span className="currency-symbol">
                        {companyData.clientCurrencySymbol}
                      </span>
                    </div>
                  </div>
                  <div className="serives-row big">
                    <div className="row-first-item">{'Refund'}</div>
                    <div className="row-second-item">
                      {item._orderItemsTotal_deleveredNot}
                      <span className="currency-symbol">
                        {companyData.clientCurrencySymbol}
                      </span>
                    </div>
                  </div>
                  <div className="serives-row big">
                    <div className="row-first-item">{'Total'}</div>
                    <div className="row-second-item">
                      {(
                        item._orderItemsTotal_total -
                        item._orderItemsTotal_deleveredNot
                      ).toString()}
                      <span className="currency-symbol">
                        {companyData.clientCurrencySymbol}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {Boolean(
                item &&
                  item.hasOwnProperty('reviewLink') &&
                  item.reviewLink &&
                  item.status === OrderActions.STATUS.FINISHED
              ) && (
                <div className="btn-wrapper-rate">
                  <Button
                    type="primary"
                    onClick={() => {
                      window.open(item.reviewLink, '_blank');
                    }}
                  >
                    {getText('WORD_WRITE_REVIEW')}
                  </Button>
                </div>
              )}
            </MainPage>
            {/* <MainPage
                            topZero
                            bottomZero
                            page
                        >
                            <OrderViewContactCard
                                type={getText("WORD_DRIVER")}
                                fullName={item.driverName}
                                phoneNumber={(item.driverPhoneCode ? item.driverPhoneCode : '') + '' + (item.driverPhoneNumber ? item.driverPhoneNumber : '')}
                                address=""
                                icon={item.driverIcon}
                            />
                            <OrderTimeLine
                                item={item}
                            />
                            <div className="order-view-description">
                                <div className="about-order-wrapper">
                                    {
                                        item.statusFinish &&
                                        <div className="about-order-row">
                                            <div className="about-order-type">{getText("WORD_STATUS")}</div>
                                            <div className="about-order-name">{OrderActions.getStatusFinisheLabel(item.statusFinish)}</div>
                                        </div>
                                    }
                                    {
                                        item.statusFinish === OrderActions.STATUSFINISH.FAILED &&
                                        <div className="about-order-row">
                                            <div className="about-order-type">{getText("WORD_REASON_TITLE")}</div>
                                            <div className="about-order-name">{item.reasonTitle ? item.reasonTitle : '---'}</div>
                                        </div>
                                    }
                                    {
                                        item.statusFinishNote &&
                                        <div className="about-order-row">
                                            <div className="about-order-type">{getText("WORD_NOTES")}</div>
                                            <div className="about-order-name">{item.statusFinishNote}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </MainPage> */}
          </div>
          <div
            className={`${
              !isDesktop
                ? 'new-order-view-map-wrapperrr'
                : 'new-order-view-map-wrapper'
            }  map-wrapper-responsive`}
          >
            <div className="map-with-height">
              <MyMap
                ref={refContainer}
                onLoadMap={onLoadMap}
                height={`calc(100vh - ${!isDesktop ? '60vh' : '40px'})`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

OrderViewTracking.defaultProps = {
  item: {},
};

export default OrderViewTracking;
