import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { SELECT_UNSELECT_NONE } from '../../utils';
import CustomerActions from '../../actions/CustomerActions';
import { notifyError } from '../notify';
import getText from '../../lang';

const InputFormSelectACCustomer = (props) => {
  const {
    label,
    placeholder,
    idCustomer,
    customerName,
    name,
    hasFeedback,
    required,
    onChange,
    onChangeItem,
    showSearch,
  } = props;

  const errorMessage = `${getText('WORD_ERROR_PLEASE_SELECT')} ${
    label ? label : placeholder
  }!`;

  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (customerName) {
      let list = [];
      list.push({ value: idCustomer, label: customerName });
      setListData(list);
    }
  }, [customerName, idCustomer]);

  return (
    <div>
      <Form.Item
        name={name}
        label={label}
        hasFeedback={hasFeedback}
        rules={[
          { required: required, message: errorMessage },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (getFieldValue(name) !== SELECT_UNSELECT_NONE) {
                return Promise.resolve();
              }
              return Promise.reject(errorMessage);
            },
          }),
        ]}
      >
        <Select
          className="my-select-wrapper"
          placeholder={label ? label : placeholder}
          showSearch={showSearch}
          filterOption={(input, option) => {
            if (option.children) {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
          onSearch={async (ee) => {
            if (ee.length >= 1) {
              let fetchParams = {
                page: 0,
                perPage: 10,
                sort: {},
                filter: {},
                q: ee || '',
              };
              let result = await CustomerActions.findAutoComplete(fetchParams);
              if (result.success) {
                let data = result.data.map((oo) => {
                  return { value: oo.idCustomer, label: oo.fullName, ...oo };
                });
                let find = data.find((oo) => {
                  return oo.label.toLowerCase() === ee.toLowerCase();
                });
                if (!find) {
                  data.push({ value: ee, label: ee });
                }
                setListData(data);
              } else {
                notifyError(result.errMsg);
              }
            }
          }}
          onChange={(e, ee) => {
            onChange && onChange(e, ee);
            let find = listData.find((oo) => {
              return oo.value === e;
            });
            onChangeItem && onChangeItem(find);
          }}
        >
          {listData.map((item, index) => {
            return (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

InputFormSelectACCustomer.defaultProps = {
  placeholder: 'Search customer',
  hasFeedback: true,
  showSearch: true,
  required: false,
  options: [],
};

export default InputFormSelectACCustomer;
