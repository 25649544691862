import React, { useEffect, useState } from 'react';
import getText from '../../lang';
import utilDate from '../../utils/utilDate';
import MyFilterApply from '../../components/MyFilterApply';
import MyDatePickerRange from '../../components/antd/MyDatePickerRange';
import SelectUserDrivers from '../../components/selects/SelectUserDrivers';
import MySlider from '../../components/antd/mySlider/MySlider';
import { Drawer } from 'antd';
import './style.scss';

const RatePageDrawer = (props) => {
  const { onCloseDrawer, visibleDrawer, onChangeFilterData, filter } = props;
  const monthStart = utilDate.getThisMonthStart();
  const monthEnd = utilDate.getThisMonthEnd();

  const [selectedDateRange, setSelectedDateRange] = useState([
    monthStart,
    monthEnd,
  ]);
  const [selectedDriver, setselectedDriver] = useState();
  const [sliderValue, setSliderValue] = useState([1, 5]);

  useEffect(() => {
    setSelectedDateRange([filter.fromDate, filter.toDate]);
    setSliderValue([filter.fromRating, filter.toRating]);
    setselectedDriver(filter.idUserDriver);
  }, [filter]);

  return (
    <div className="drawer-wrapper">
      <Drawer
        title={
          <MyFilterApply
            onCancelClick={onCloseDrawer}
            onClick={onCloseDrawer}
            onApplyClick={() => {
              let fill = {};
              if (selectedDriver && selectedDriver.idUser) {
                fill.idUserDriver = selectedDriver.idUser;
              }
              if (selectedDateRange) {
                fill.fromDate = selectedDateRange[0];
                fill.toDate = selectedDateRange[1];
              }
              if (sliderValue) {
                fill.fromRating = sliderValue[0];
                fill.toRating = sliderValue[1];
              }
              onChangeFilterData && onChangeFilterData(fill);
              onCloseDrawer();
            }}
            labelCancel={getText('WORD_CANCEL')}
            labelPrimary={getText('WORD_FILTER_APPLY')}
          />
        }
        placement={'right'}
        closable={false}
        onClose={onCloseDrawer}
        open={visibleDrawer}
        key={'right'}
        // width={300}
      >
        <SelectUserDrivers
          value={selectedDriver && selectedDriver.idUser}
          placeholder={getText('WORD_SELECT_DRIVER')}
          style={{ width: '100%' }}
          onChangeItem={(driver) => {
            setselectedDriver(driver);
          }}
        />
        <MyDatePickerRange
          value={selectedDateRange}
          onChange={(e) => {
            setSelectedDateRange(e);
          }}
          style={{ marginTop: 30 }}
        />
        <MySlider
          range
          sliderLabel={getText('WORD_RATING_TITLE')}
          style={{ paddingTop: 20 }}
          value={sliderValue}
          min={0}
          max={5}
          onChange={setSliderValue}
        />
      </Drawer>
    </div>
  );
};

export default RatePageDrawer;
