import React from 'react';
import { Input } from 'antd';
import SearchIcon from '../../icons/svg/SearchIcon';

const InputSearch = (props) => {
  const {
    label,
    placeholder,
    allowClear,
    onPressEnter,
    onClear,
    onChange,
    className,
    defaultValue,
  } = props;

  return (
    <Input
      className={`input-search ${className || ''}`}
      placeholder={label || placeholder}
      prefix={<SearchIcon />}
      allowClear={allowClear}
      defaultValue={defaultValue}
      size={'large'}
      onPressEnter={(e) => {
        e.preventDefault();
        onPressEnter(e.target.value);
      }}
      onChange={(e) => {
        if (e.target.value === '') {
          onClear();
        }
        onChange && onChange(e.target.value);
      }}
    />
  );
};

InputSearch.defaultProps = {
  name: 'searchInput',
  placeholder: 'Search',
  allowClear: true,
  onPressEnter: (e) => {
    e?.preventDefault();
  },
  onClear: () => {},
  maxLength: 255,
};

export default InputSearch;
