// import ApiStorage from '../reducers/ApiStorage';
import getText from '../lang';
import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlPOSTAsyncFile,
} from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';

const URL_PAGINATION_LIST = '/sms_history/list';
const URL_LIST_EXCEL = '/sms_history/list-excel';

const fs = {
  fillPaginationList: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_PAGINATION_LIST, urlParams);
    return result;
  },
  listExcelFile: async (params) => {
    const urlParams = [
      {
        key: 'paginationData',
        value: params,
      },
    ];
    return await fetchFromUrlPOSTAsyncFile(URL_LIST_EXCEL, urlParams);
  },
};

const loc = {
  NOTIFICATION_CHANNEL: {
    SMS: 1,
    EMAIL: 2,
  },
  NOTIFTYPE: {
    ORDER_TEST: 0,
    ORDER_START: 1,
    ORDER_DELIVERED: 2,
    ORDER_NOT_DELIVERED: 3,
    ORDER_COLLECTED: 4,
  },
  getTypeLabel: (type) => {
    switch (type) {
      case 0:
        return { title: getText('WORD_ORDER_TEST') };
      case 1:
        return { title: getText('WORD_ORDER_START') };
      case 2:
        return { title: getText('WORD_ORDER_DELIVERED') };
      case 3:
        return { title: getText('WORD_ORDER_NOT_DELIVERED') };
      case 4:
        return { title: getText('WORD_ORDER_COLLECTED') };
      default:
        return { title: '---' };
    }
  },
  getStatusOptions: (
    firstOption = {
      value: SELECT_UNSELECT_NONE,
      label: getText('WORD_SELECT_SELECT_STATUS'),
    }
  ) => {
    const options = [
      { label: getText('WORD_ORDER_TEST'), value: loc.NOTIFTYPE.ORDER_TEST },
      { label: getText('WORD_ORDER_START'), value: loc.NOTIFTYPE.ORDER_START },
      {
        label: getText('WORD_ORDER_DELIVERED'),
        value: loc.NOTIFTYPE.ORDER_DELIVERED,
      },
      {
        label: getText('WORD_ORDER_NOT_DELIVERED'),
        value: loc.NOTIFTYPE.ORDER_NOT_DELIVERED,
      },
      {
        label: getText('WORD_ORDER_COLLECTED'),
        value: loc.NOTIFTYPE.ORDER_COLLECTED,
      },
    ];
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
  getStatusOptionsAll: () => {
    return loc.getStatusOptions({
      value: SELECT_UNSELECT_NONE,
      // label: getText('WORD_SELECT_ALL'),
      label: getText('WORD_SELECT_ALL'),
    });
  },
};

const SmsHistoryActions = Object.assign(fs, loc);

export default SmsHistoryActions;
