import React, { useEffect, useState } from 'react';
import { Upload, message, Avatar, Form } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import ProfileLetters from './ProfileLetters';
import ApiStorage from '../../reducers/ApiStorage';
import LoadingOutlinedApp from './LoadingOutlinedApp';
import { getBaseURL } from '../../utils/AxiosAdapter';

const URL_UPLOAD_IMAGE = 'uploads/image/s3';

function beforeUpload(file) {
  const isJPG = true;
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 2MB!');
  }
  return isJPG && isLt2M;
}

const ProfileAvatarFormS3 = (props) => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  useEffect(() => {
    setImageUrl(props.imageUrl);
  }, [props.imageUrl]);

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        setImageUrl(info.file.response.data.filePath);
        props.onChange && props.onChange(info.file.response.data.filePath);
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
    setLoading(false);
  };

  const srci = imageUrl || '';

  const handleClear = () => {
    setImageUrl('');
    props.onClear && props.onClear();
  };

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
      rules={[
        {
          required: props.required,
          message: props.errorMessage
            ? props.errorMessage
            : `Please input ${props.placeholder || props.label}!`,
        },
      ]}
    >
      <div>
        <ImgCrop rotationSlider>
          <Upload
            className="avatar-uploader"
            name="image"
            showUploadList={false}
            action={getBaseURL() + URL_UPLOAD_IMAGE}
            headers={{
              authorization: `Bearer ${ApiStorage.getAccessToken()}`,
            }}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            <Avatar
              src={srci}
              // crossOrigin="anonymous"
              size="large"
              style={{ margin: 2 }}
              icon={
                loading ? (
                  <LoadingOutlinedApp />
                ) : props.value ? (
                  <ProfileLetters value={props.value} />
                ) : (
                  <UserOutlined />
                )
              }
            />
          </Upload>
        </ImgCrop>
        {showDeleteIcon && imageUrl && (
          <CloseOutlined
            onClick={handleClear}
            style={{
              position: 'absolute',
              // color: '#fff',
              top: 1,
              left: 20,
            }}
          />
        )}
      </div>
    </Form.Item>
  );
};

export default ProfileAvatarFormS3;
