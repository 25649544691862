import React, { useEffect } from 'react';
import getText from '../../lang';
import ManifestActions from '../../actions/ManifestActions';
import MyForm from '../../components/MyForm';
import MyPanelFooter from '../../components/MyPanelFooter';
import { notifyError, notifySuccess } from '../../components/notify';
import SelectFormUserDrivers from '../../components/selects/SelectFormUserDrivers';
import { Drawer, Form } from 'antd';
import useIsMounted from '@utilityjs/use-is-mounted';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const DrawerEdit = (props) => {
  const { visible, idKey, onClose, setVisible } = props;

  const isMounted = useIsMounted();
  const [form] = Form.useForm();
  const navigateApi = useNavigateApi();

  useEffect(() => {
    const getObj = async () => {
      if (idKey && visible) {
        let resultGet = await ManifestActions.getOneByidKey(idKey);
        if (isMounted()) {
          if (resultGet.success) {
            form.setFieldsValue({
              ...resultGet.data,
            });
          } else {
            notifyError(resultGet.errMsg);
            navigateApi('manifest/list');
          }
        }
      }
    };
    getObj();
  }, [idKey, visible, isMounted, form, navigateApi]);

  const onFinish = async (values) => {
    let result = await ManifestActions.saveUpdateChange(values, idKey);
    if (result.success) {
      notifySuccess(getText('FREIGHT_UPDATED_SUCCESSFULY'));
      setVisible(false);
      navigateApi('manifest/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
  };

  return (
    <Drawer
      title={
        <MyPanelFooter
          formName={'manifestCardForm'}
          labelPrimary={getText('WORD_FILTER_APPLY')}
          labelCancel={getText('WORD_CANCEL')}
          onCancelClick={onClose}
          withoutTitle
        />
      }
      placement={'right'}
      closable={false}
      maskClosable={true}
      open={visible}
      key={'right'}
      width={350}
      onClose={() => setVisible(false)}
    >
      <MyForm
        form={form}
        name="manifestCardForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{}}
      >
        <SelectFormUserDrivers
          name="idUserDriver"
          label={getText('WORD_SELECT_DRIVER')}
          hasFeedback={false}
        />
      </MyForm>
    </Drawer>
  );
};

export default DrawerEdit;
