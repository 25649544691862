import React from 'react';
import getText from '../../lang';
import { notifySuccess } from '../notify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './ButtonCopyStyle.scss';

const ButtonCopy = (props) => {
  const { disabled, value, icon, btnText, notifyMessage } = props;

  return (
    <CopyToClipboard
      text={value}
      onCopy={() => {
        if (!disabled) {
          notifySuccess(notifyMessage);
        } else {
          return false;
        }
      }}
    >
      <Button type="primary" disabled={disabled} className="copy-button">
        {btnText || getText('WORD_COPY')}
        {icon ? icon : <CopyOutlined />}
      </Button>
    </CopyToClipboard>
  );
};

export default ButtonCopy;
