import React from 'react';
import MyRow from '../../components/MyRow';
import EditIcon from '../../icons/svg/EditIcon';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import { Button } from 'antd';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import './Branch.scss';

const BranchListCard = (props) => {
  const navigateApi = useNavigateApi();

  const { item } = props;

  return (
    <div className="my-card users-list-card">
      <MyRow withoutTitle>
        <ProfileAvatar value={item.branchName} />
        <div className="branch-name-div">{item.branchName}</div>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            navigateApi('branch/edit/' + item.idKey);
          }}
          ghost
          className="branch-button-wrapper"
        >
          <EditIcon />
        </Button>
      </MyRow>
    </div>
  );
};

export default BranchListCard;
