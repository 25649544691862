import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import BlankLayout from '../components/antd/BlankLayout';
import MainLayout from '../components/antd/MainLayout';
import Login from '../pages/users/Login';
import RoutePrivate from './RoutePrivate';
import Dashboard from '../pages/dashboard/Dashboard';
import ServicesEdit from '../pages/services/ServicesEdit';
import Analytics from '../pages/Analytics';
import ClientAccountSettings from '../pages/ClientAccountSettings';
import AccountSettings from '../pages/users/AccountSettings';
import OrdersList from '../pages/orders/OrdersList';
import OrderEdit from '../pages/orders/OrderEdit';
import OrderImport from '../pages/orders/OrderImport';
import ServicesList from '../pages/services/ServicesList';
import UserList from '../pages/users/UserList';
import UserEdit from '../pages/users/UserEdit';
import CustomerEdit from '../pages/customer/CustomerEdit';
import CustomerList from '../pages/customer/CustomerList';
import OrderView from '../pages/orders/OrderView';
import OrderViewTracking from '../pages/orders/OrderViewTracking';
import UserView from '../pages/users/UserView';
import ForgotPasswordRequest from '../pages/users/ForgotPasswordRequest';
import ForgotPasswordRequestAfter from '../pages/users/ForgotPasswordRequestAfter';
import UserEmailConfirm from '../redirects/UserEmailConfirm';
import ForgotPasswordChange from '../pages/users/ForgotPasswordChange';
import UserChangePassword from '../pages/users/UserChangePassword';
import NoAccessPage from '../pages/NoAccessPage';
import UserActions from '../actions/UserActions';
import { DEFAULT_LANGUAGE } from '../utils';
import { LANGS } from '../lang';
import ApiStorage from '../reducers/ApiStorage';
import ManifestList from '../pages/manifest/ManifestList';
import ManifestView from '../pages/manifest/ManifestView';
import ReasonsList from '../pages/reasons/ReasonsList';
import ReasonsEdit from '../pages/reasons/ReasonsEdit';
import ApiList from '../pages/api/ApiList';
import ApiEdit from '../pages/api/ApiEdit';
import ApiView from '../pages/api/ApiView';
import DeviceList from '../pages/device/DeviceList';
import DeviceEdit from '../pages/device/DeviceEdit';
import NotificationsSettings from '../pages/notifications/NotificationsSettings';
import NotificationsSettingsSMS from '../pages/notifications/NotificationsSettingsSMS';
import NotificationsSettingsEmail from '../pages/notifications/NotificationsSettingsEmail';
import AssignPage from '../pages/assign/AssignPage';
import PolygonPage from '../pages/polygons/PolygonPage';
import RatePage from '../pages/rate/RatePage';
import DriverPackingStatusMonitorPage from '../pages/drivers/driverPackingStatusMonitorPage/DriverPackingStatusMonitorPage';
import DriverPackingStatusMonitoring from '../redirects/DriverPackingStatusMonitoring';
import Error401Page from '../pages/errorPage/Error401Page';
import { mainConnect } from '../utils/MySocket';
import RatingList from '../pages/ratingList/RatingList';
import UserListDriverMonitorStatusChange from '../pages/drivers/UserListDriverMonitorStatusChange';
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';
import BranchList from '../pages/branch/BranchList';
import BranchEdit from '../pages/branch/BranchEdit';
import utilString from '../utils/utilString';
import SmsHistory from '../pages/sms/SmsHistoryList';

const AppRoute = (props) => {
  const location = useLocation();

  const [lang, setLang] = useState(ApiStorage.getLanguage());

  useEffect(() => {
    mainConnect();
  }, []);

  useEffect(() => {
    const fill = () => {
      let { pathname } = location;
      let lang = '';
      if (pathname?.startsWith('/')) {
        lang = pathname?.substring(1, 3);
      } else {
        lang = pathname?.substring(0, 2);
      }
      if (!lang) {
        lang = DEFAULT_LANGUAGE;
      }
      if (LANGS.includes(lang) && ApiStorage.getLanguage() !== lang) {
        ApiStorage.setLanguage(lang);
        setLang(lang);
      }
      document.documentElement.lang = lang;
    };
    fill();
  }, [lang, location]);

  return (
    <Routes>
      <Route
        path="/:lang/dashboard"
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <Dashboard {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/home"
        exact
        element={<Navigate to={`/${lang}/dashboard`} replace />}
      />
      <Route
        path="/:lang/manifest/list"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <ManifestList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/manifest/create"
        element={
          <RoutePrivate>
            <MainLayout>
              <AssignPage {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/manifest/edit/:_id"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <AssignPage {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/manifest/view/:_id"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <ManifestView {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/polygons/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout addWidget>
              <PolygonPage {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/orders/list"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <OrdersList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/orders/create"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <OrderEdit
                {...props}
                isNew
                formID={utilString.getUniqueString()}
              />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/orders/edit/:_id"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <OrderEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/orders/view/:_id"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <OrderView {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/orders/view/tracking/:_idKeyClient/:_id"
        exact
        element={
          <BlankLayout>
            <OrderViewTracking {...props} isTracking />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/orders/import"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <OrderImport {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/services/list"
        exact
        role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
        element={
          <RoutePrivate>
            <MainLayout addWidget>
              <ServicesList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/services/create"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ServicesEdit {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/services/edit/:_id"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ServicesEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/reasons/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout addWidget>
              <ReasonsList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/reasons/create"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ReasonsEdit {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/reasons/edit/:_id"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ReasonsEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/apicode/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ApiList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/apicode/create"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ApiEdit {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/apicode/edit/:_id"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ApiEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/apicode/view/:_id"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ApiView {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/analytics"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout addWidget>
              <Analytics {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/settings"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <ClientAccountSettings {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/notifications"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <NotificationsSettings {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/notifications/sms"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <NotificationsSettingsSMS {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/notifications/email"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <NotificationsSettingsEmail {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/customer/create"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <CustomerEdit {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/customer/edit/:_id"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <CustomerEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/customer/list"
        exact
        element={
          <RoutePrivate>
            <MainLayout addWidget>
              <CustomerList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/account_settings"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <AccountSettings {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/change_password"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <UserChangePassword {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/driver/list"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <UserListDriverMonitorStatusChange {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/users/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <UserList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/driver/packing_status_monitor/:_token"
        exact
        element={
          <BlankLayout addWidget>
            <DriverPackingStatusMonitoring {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/driver/packing_status_monitor"
        exact
        element={
          <BlankLayout addWidget>
            <DriverPackingStatusMonitorPage {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/users/create"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <UserEdit {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/users/edit/:_id"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <UserEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/users/view/:_id"
        element={
          <RoutePrivate>
            <MainLayout>
              <UserView {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/login"
        exact
        element={
          <BlankLayout>
            <Login {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/users/confirm/:_id"
        exact
        element={
          <BlankLayout>
            <UserEmailConfirm {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/rating/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <RatingList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/users/confirm/:_id"
        exact
        element={
          <BlankLayout>
            <UserEmailConfirm {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/users/forgot_password/:_id"
        exact
        element={
          <BlankLayout>
            <ForgotPasswordChange {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/forgot_password/request"
        exact
        element={
          <BlankLayout>
            <ForgotPasswordRequest {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/forgot_password/request/after"
        exact
        element={
          <BlankLayout>
            <ForgotPasswordRequestAfter {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/device/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <DeviceList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/device/edit/:_id"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <DeviceEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/no_access"
        element={
          <RoutePrivate>
            <MainLayout>
              <NoAccessPage {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/assign"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <AssignPage {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/assign/:_id"
        exact
        element={
          <RoutePrivate>
            <MainLayout>
              <AssignPage {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/error_page_401"
        exact
        element={
          <BlankLayout>
            <Error401Page {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/order/review/:_IdKeyClient/:_idKeyOrrderRate"
        exact
        element={
          <BlankLayout>
            <RatePage {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/privacy"
        exact
        element={
          <BlankLayout>
            <PrivacyPolicy {...props} />
          </BlankLayout>
        }
      />
      <Route
        path="/:lang/branch/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <BranchList {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/branch/edit/:_id"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <BranchEdit {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/branch/create"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <BranchEdit {...props} isNew />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/:lang/sms_history/list"
        exact
        element={
          <RoutePrivate
            role={[UserActions.ROLE.SUPERADMIN, UserActions.ROLE.ADMIN]}
          >
            <MainLayout>
              <SmsHistory {...props} />
            </MainLayout>
          </RoutePrivate>
        }
      />
      <Route
        path="/"
        index
        element={<Navigate to={`${lang}/login`} replace />}
      />
      {/* <Route exact path="/:lang/" component={Login} /> */}
      {/* <Route component={Error401Page} /> */}
    </Routes>
  );
};

export default AppRoute;
