import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    height="35"
    viewBox="0 0 331 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2_28)">
      <path
        d="M314.31 61.8101L184.4 108.58C172.038 113.03 158.512 113.03 146.15 108.58L16.24 61.8101C15.037 61.3755 13.9973 60.5806 13.2625 59.5337C12.5276 58.4869 12.1333 57.239 12.1333 55.96C12.1333 54.681 12.5276 53.4331 13.2625 52.3863C13.9973 51.3394 15.037 50.5447 16.24 50.1101L146.15 3.34012C158.512 -1.10947 172.038 -1.10947 184.4 3.34012L314.31 50.1101C315.513 50.5447 316.553 51.3394 317.288 52.3863C318.022 53.4331 318.417 54.681 318.417 55.96C318.417 57.239 318.022 58.4869 317.288 59.5337C316.553 60.5806 315.513 61.3755 314.31 61.8101Z"
        fill="url(#paint0_linear_2_28)"
      />
      <path
        d="M314.63 50.23L314.31 50.1101C315.513 50.5447 316.553 51.3394 317.288 52.3862C318.022 53.4331 318.417 54.6809 318.417 55.96C318.417 57.239 318.022 58.4869 317.288 59.5337C316.553 60.5805 315.513 61.3755 314.31 61.8101L184.4 108.58C172.038 113.03 158.512 113.03 146.15 108.58L16.24 61.8101C15.037 61.3755 13.9973 60.5805 13.2625 59.5337C12.5276 58.4869 12.1333 57.239 12.1333 55.96C12.1333 54.6809 12.5276 53.4331 13.2625 52.3862C13.9973 51.3394 15.037 50.5447 16.24 50.1101L15.9199 50.23C11.2569 51.9095 7.2259 54.987 4.37671 59.0425C1.52752 63.0979 -0.000975134 67.9336 4.66736e-07 72.8899V246.58C-0.000975134 251.536 1.52752 256.372 4.37671 260.427C7.2259 264.483 11.2569 267.56 15.9199 269.24L146.15 316.13C158.512 320.58 172.038 320.58 184.4 316.13L314.63 269.24C319.294 267.561 323.327 264.484 326.177 260.428C329.028 256.373 330.559 251.537 330.56 246.58V72.8899C330.559 67.9328 329.028 63.0968 326.177 59.0415C323.327 54.9862 319.294 51.9091 314.63 50.23Z"
        fill="url(#paint1_linear_2_28)"
      />
      <path
        opacity="0.4"
        d="M185.04 294.59L216.21 283.37V266.64L185.04 277.86V294.59Z"
        fill="url(#paint2_linear_2_28)"
      />
      <path
        opacity="0.4"
        d="M221.14 281.59L230.46 278.24V261.51L221.14 264.86V281.59Z"
        fill="url(#paint3_linear_2_28)"
      />
      <g opacity="0.9">
        <path
          opacity="0.9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.4899 156.91L42.8 181.44C42.7507 181.482 42.7133 181.537 42.6918 181.598C42.6704 181.66 42.6655 181.726 42.6777 181.79C42.6898 181.854 42.7187 181.913 42.7612 181.962C42.8037 182.012 42.8584 182.049 42.9199 182.07L55.7199 186.35V262.11C55.7213 262.301 55.7816 262.487 55.8925 262.643C56.0035 262.799 56.1598 262.916 56.34 262.98L83.4099 272.03C83.4949 272.059 83.5857 272.067 83.6745 272.053C83.7633 272.04 83.8475 272.005 83.9201 271.952C83.9927 271.899 84.0515 271.83 84.0915 271.749C84.1315 271.669 84.1514 271.58 84.1498 271.49V195.86L98.2199 200.57C98.2912 200.593 98.3675 200.593 98.4389 200.571C98.5103 200.549 98.5735 200.506 98.6201 200.448C98.6666 200.389 98.6943 200.318 98.6996 200.244C98.705 200.169 98.6876 200.095 98.6498 200.03L72.7299 157.11C72.6674 157.009 72.5837 156.922 72.4846 156.856C72.3855 156.79 72.2734 156.745 72.1557 156.726C72.0381 156.707 71.9176 156.714 71.8027 156.746C71.6878 156.777 71.5812 156.833 71.4899 156.91Z"
          fill="url(#paint4_linear_2_28)"
        />
      </g>
      <path
        opacity="0.9"
        d="M280.75 73.89L115.01 14.55L74.75 29.05L240.49 88.39L280.75 73.89Z"
        fill="url(#paint5_linear_2_28)"
      />
      <path
        opacity="0.9"
        d="M274.03 115.83L280.74 121.83V73.9199L240.49 88.4099V136.32L247.2 125.49L253.91 131.49L260.62 120.68L267.32 126.68L274.03 115.83Z"
        fill="url(#paint6_linear_2_28)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2_28"
        x1="318.42"
        y1="55.9601"
        x2="12.13"
        y2="55.9601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.32" stopColor="#F9F9F9" />
        <stop offset="0.77" stopColor="#E7E9E7" />
        <stop offset="1" stopColor="#DBDEDC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_28"
        x1="4.66764e-07"
        y1="184.79"
        x2="330.56"
        y2="184.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.05" stopColor="#EFEFEF" />
        <stop offset="0.46" stopColor="#EEEFEF" />
        <stop offset="0.52" stopColor="#E1E1E1" />
        <stop offset="1" stopColor="#F9F9FA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_28"
        x1="195.54"
        y1="271.8"
        x2="205.72"
        y2="289.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66C6E4" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_28"
        x1="13988.7"
        y1="-41273.5"
        x2="13804.8"
        y2="-41125.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66C6E4" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2_28"
        x1="41.6498"
        y1="248.41"
        x2="102.86"
        y2="187.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A7E1" />
        <stop offset="1" stopColor="#008AF4" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2_28"
        x1="115.04"
        y1="-11.24"
        x2="240.46"
        y2="114.18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFE1" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2_28"
        x1="286.28"
        y1="79.4299"
        x2="234.95"
        y2="130.76"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#74A9C9" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <clipPath id="clip0_2_28">
        <rect width="330.56" height="319.47" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const LogoSmall = (props) => <Icon component={SVGIcon} {...props} />;

export default LogoSmall;
