import React from 'react';
import { Form, Rate } from 'antd';
import { SELECT_UNSELECT_NONE } from '../../../utils';
import getText from '../../../lang';

const MyRate = (props) => {
  const {
    errorMessage,
    placeholder,
    label,
    name,
    hasFeedback,
    required,
    disabled,
  } = props;

  const errorMessagee = errorMessage
    ? errorMessage
    : `${getText('WORD_ERROR_PLEASE_SELECT')} ${getText(
        placeholder ? placeholder : label
      )}!`;

  return (
    <div>
      <Form.Item
        name={name}
        label={label}
        hasFeedback={hasFeedback}
        rules={[
          { required: required, message: errorMessagee },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (getFieldValue(name) !== SELECT_UNSELECT_NONE) {
                return Promise.resolve();
              }
              return Promise.reject(errorMessagee);
            },
          }),
        ]}
      >
        <Rate disabled={disabled} />
      </Form.Item>
    </div>
  );
};

MyRate.defaultProps = {
  placeholder: 'WORD_SELECT_ONE',
  hasFeedback: true,
  showSearch: true,
  required: false,
  options: [],
};

export default MyRate;
