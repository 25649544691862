import { Timeline } from 'antd';
import React from 'react';
// import OrderActions from '../../actions/OrderActions';
import MyRow from '../../components/MyRow';
// import EditIcon from '../../icons/svg/EditIcon';
// import TimelineDotIcon from '../../icons/svg/TimelineDotIcon';
import getText from '../../lang';
import { formatDateTime } from '../../utils';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const DashboardActivityItem = (props) => {
  const navigateApi = useNavigateApi();

  const { item } = props;

  const RenderDate = () => {
    return (
      <>
        <div className="date-div">{formatDateTime(item.deliveryDate)}</div>
      </>
    );
  };
  return (
    <div
      className="one-order-block-wrapper dashboard-block-wrapper"
      onClick={(e) => {
        e.stopPropagation();
        navigateApi('orders/view/' + item.idKey);
      }}
    >
      <MyRow bottomZero>
        <div className="order-number-with-icon">
          <div className="number-div">
            #{item.apiOrderKey || item.orderNumber}
          </div>
          {/* {item.status === OrderActions.STATUS.ACTIVE && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigateApi('orders/edit/' + item.idKey);
              }}
              className="edit-icon"
            >
              <EditIcon />
            </div>
          )} */}
        </div>
        <RenderDate />
      </MyRow>
      <div className="driver-order-status orange">{getText('WORD_ON_WAY')}</div>
      <MyRow bottomZero>
        <Timeline
          items={[
            { key: 1, children: item.addressFrom },
            { key: 2, children: item.addressTo },
          ]}
        />
      </MyRow>
    </div>
  );
};

export default DashboardActivityItem;
