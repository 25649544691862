import axios from 'axios';

let BASE_URL = process.env.REACT_APP_BASE_URL;
console.warn('BASE_URL = ', BASE_URL);

// export function urlServer(path = "") {
//     return BASE_URL + path;
// }

export const getBaseURL = () => {
  return `${BASE_URL}/api/v1/`;
};

export const getBaseURLShowImage = () => {
  return `${BASE_URL}/`;
};

export const getBaseURLReact = () => {
  return `${process.env.REACT_APP_BASE_URL_REACT}/`;
};

const adapter = axios.create({
  baseURL: getBaseURL(),
  headers: {
    // "Authorization": 'Bearer 1b626e736a9ad6dc980984137ecf52df',
    Accept: 'application/json;charset=UTF-8',
    // "Accept": '*/*',
    'Content-Type': 'application/json',
    // Referer: 'https://api.ipify.org',
  },
  validateStatus: false,
  withCredentials: false,
  // method: 'POST',
  timeout: 15000,
  method: 'post',
});

export default adapter;
