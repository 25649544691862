import { Dropdown } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import getText from '../../../lang';
import { UserOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';
import ProfileAvatar from '../ProfileAvatar';
import UserActions from '../../../actions/UserActions';
import { notifyError } from '../../notify';
import useNavigateApi from '../../../AppRoute/useNavigateApi';
import { useSelector } from 'react-redux';

const MenuProfileSettings = () => {
  const navigateApi = useNavigateApi();

  const icon = useSelector((state) => {
    return state.AuthReducer.currentUser?.icon;
  });

  const lang = useSelector((state) => {
    return state.AuthReducer.currentUser?.lang;
  });

  const fullName = useSelector((state) => {
    return state.AuthReducer.currentUser?.fullName;
  });

  const menuOpen = useSelector((state) => state.AuthReducer?.menuOpen);

  const accountSettingsItems = [
    {
      label: (
        <Link to={`/${lang}/account_settings`}>
          {getText('WORD_SIDEBAR_ACCOUNT_SETTINGS')}
        </Link>
      ),
      key: '1',
      icon: <UserOutlined />,
    },
    {
      label: getText('WORD_SIDEBAR_LOGOUT'),
      key: '2',
      icon: <LogoutOutlined />,
      onClick: async () => {
        let result = await UserActions.logout();
        if (!result.success) {
          notifyError(result.errMsg);
        }
        navigateApi('login');
      },
    },
  ];

  return (
    <Dropdown
      trigger="click"
      menu={{
        items: accountSettingsItems,
      }}
    >
      <div>
        <ProfileAvatar icon={icon || ''} value={fullName || ''} />
        {menuOpen ? (
          <>
            <span className="name">{fullName || ''}</span>
            <DownOutlined />
          </>
        ) : null}
      </div>
    </Dropdown>
  );
};

export default MenuProfileSettings;
