import React from 'react';
import useWindowSize from '../../utils/useWindowSize';
import './style.scss';
import './styleResponsive.scss';

const MyBlock = (props) => {
  const { isMobile } = useWindowSize();

  const { className, topZero, bottomZero, title, children, leftZero } = props;

  return (
    <div
      className={`my-block ${className || ''}`}
      style={
        isMobile
          ? { marginLeft: 0, paddingTop: 0 }
          : { marginLeft: leftZero ? 0 : 20, paddingTop: topZero ? 0 : 20 }
      }
    >
      <h6
        className="my-block-title"
        style={{
          marginBottom: bottomZero ? 0 : 15,
          // marginLeft: leftZero ? 0 : 10,
          marginLeft: 0,
        }}
      >
        {title}
      </h6>
      {children}
    </div>
  );
};

export default MyBlock;
