const texts = {
  WORD_LOGIN: 'Մուտք',
  WORD_REGISTER: 'Գրանցում',
  WORD_UNASSIGNED: 'Հայտեր',
  WORD_PENDING_WAITING: 'Սպասվող',
  WORD_STARTED_ON_WAY: 'Ընթացիկ',
  WORD_DONE_ARCHIVE: 'Ավարտված',
  WORD_FORGOT_PASSWORD: 'Մոռացել է՞ք գաղտնաբառը',
  WORD_FORGOT_PASSWORD_WA: 'Մոռացել էք գաղտնաբառը',
  WORD_USERNAME_OR_EMAIL: 'Օգտանուն կամ էլ. հասցե',
  WORD_PLEASE_INPUT_USERNAME: 'Խնդրում ենք լրացնել օգտանունը',
  WORD_PLEASE_INPUT_EMAIL: 'Խնդրում ենք լրացնել  էլ. հասցեն',
  WORD_PASSWORD: 'Գաղտնաբառ',
  WORD_PASSWORD_CONFIRM: 'Հաստատել գաղտնաբառը',
  WORD_PLEASE_INPUT_PASSWORD: 'Խնդրում ենք լրացնել գաղտնաբառը',
  WORD_PLEASE_CONFIRM_YOUR_PASSWORD: 'Խնդրում ենք հաստատել գաղտնաբառը',
  WORD_DOWNLOAD_DRIVER_APP: 'Ներբեռնեք վարորդի ծրագիրը',
  WORD_SELECT_PACKAGE: 'Ընտրեք փաթեթը',
  WORD_YOUR_FIRST_14_DAYS_ARE_FREE:
    'Ձեր առաջին 14 օրն անվճար է, և դուք կարող եք ցանկացած պահի չեղարկել՝ առանց քաշքշուկի և առանց տրված հարցերի',
  WORD_PER_MONTH_BILLED_ANNUALLY: 'ամսական, տարեկան վճարում',
  WORD_MOBILE_DEVICES: 'Շարժական սարքեր',
  WORD_TEAM_MEMBERS: 'Թիմի անդամներ',
  WORD_GPS_TRACKING: 'GPS tracking',
  WORD_ANALYTICS: 'Վերլուծություն',
  WORD_MAP: 'Քարտեզ',
  WORD_START_FREE_TRIAL: 'Անվճար փորձարկել',
  WORD_INCLUDE: 'ներառյալ',
  WORD_UP_TO: 'մինչև',
  WORD_NEED_CUSTOM_SOLUTION: 'Պահանջվում է անհատական լուծում',
  WORD_LETS_TALK_ABOUT_WHAT_UBICROSS_DELIVERY_APP:
    'Եկեք խոսենք այն մասին, թե ինչ կարող է անել Առաքման հավելվածը ձեր բիզնեսի համար',
  WORD_REQUEST_DEMO: 'Դեմոի հարցում',
  WORD_DRIVER: 'Վարորդ',
  WORD_DRIVERS: 'Վարորդներ',
  WORD_SERVICE: 'Ծառայություն',
  WORD_CHECK_ID: 'Կտրոն #',
  WORD_TIMELINE: 'Ժամանակացույց',
  WORD_ORDER_AMOUNT: 'Պատվերի գումար',
  WORD_SERVICE_AMOUNT: 'Ծառայության գումար',
  WORD_TOTAL_AMOUNT: 'Ընդհանուր գումար',
  WORD_ORDER: 'Պատվեր',
  WORD_NOTES: 'Նշումներ',
  WORD_ORDER_EDIT: 'Պատվերի խմբագրում',
  WORD_NEW_ORDER: 'Նոր պատվեր',
  WORD_ORDER_ID: 'Պատվեր #',
  WORD_MANIFEST: 'Երթուղի',
  WORD_CUSTOMER: 'Հաճախորդ',
  WORD_PHONE: 'Հեռ.',
  WORD_PICKUP_ADDRESS: 'Վերցնելու հասցե',
  WORD_DROP_OFF_ADDRESS: 'Առաքման հասցե',
  WORD_WIDTH_CM: 'Լայն. (սմ)',
  WORD_HEIGHT_CM: 'Բարձր. (սմ)',
  WORD_LENGTH_CM: 'Երկար. (սմ)',
  WORD_WEIGHT_KG_CM: 'Քաշ (կգ)',
  WORD_PRODUCT_NUMBER: 'Կոդ',
  WORD_PRODUCT_NAME: 'Ապրանքի անուն',
  WORD_PRODUCT_PRICE: 'Ապրանքի գին',
  WORD_ADD_PRODUCT: 'Ավելացնել ապրանք',
  WORD_SELECT_MANIFEST: 'Ընտրել մանիֆեսթը',
  WORD_SELECT_DRIVER: 'Ընտրել վարորդին',
  WORD_ERROR_SELECT_DRIVER: 'Խնդրում ենք ընտրել վարորդ',
  WORD_ERROR_PHONE_NUMBER: 'Խնդրում ենք լրացնել հեռախոսահամարը',
  WORD_ERROR_PICKUP_ADDRESS: 'Խնդրում ենք լրացնել վերցնելու հասցեն',
  WORD_ERROR_DROPOFF_ADDRESS: 'Խնդրում ենք լրացնել առաքման հասցեն',
  WORD_ERROR_SERVICE_ONE: 'Խնդրում ենք ընտրել ծառայություններից մեկը',
  WORD_PACKAGE_SIZE: 'Առաքանու չափեր',
  WORD_ITEMS_LIST: 'Ցուցակ',
  WORD_CREATE: 'Ստեղծել',
  WORD_UPDATE: 'Թարմացնել',
  WORD_CANCEL: 'Չեղարկել',
  WORD_ADDRESS: 'Հասցե',
  WORD_STATUS: 'Կարգավիճակ',
  WORD_EMAIL: 'Էլ. հասցե',
  WORD_EMAIL_LOGIN: 'Էլ. հասցե',
  WORD_DRIVER_INFORMATION: 'Վարորդի տվյալներ',
  WORD_ACTIVITY: 'Գործունեություն',
  WORD_ON_WAY: 'Ճանապարհին',
  WORD_DELIVERED: 'Առաքված',
  WORD_NOT_DELIVERED: 'Չառաքված',
  WORD_NOT_TRANSPORT: 'Տրանսպորտ',
  WORD_FLEXIBLE: 'Ճկուն',
  WORD_PLANS: 'Փաթեթներ',
  WORD_NEW_SERVICE: 'Նոր ծառայություն',
  WORD_SERVICE_EDIT: 'Ծառայության խմբագրում',
  WORD_KM: 'կմ',
  WORD_HR: 'ժ',
  WORD_MIN: 'ր',

  //Dashboard
  WORD_DASHBOARD_LEFT_TAB_ONLINE: 'Օնլայն',
  WORD_DASHBOARD_LEFT_TAB_AVAILABLE: 'Հասանելի',
  WORD_DASHBOARD_LEFT_TAB_ALL: 'Բոլորը',
  WORD_SIDEBAR_ACCOUNT_SETTINGS: 'Հաշվի կարգավորումներ',
  WORD_SIDEBAR_LOGOUT: 'Դուրս գալ',
  WORD_SIDEBAR_DASHBOARD: 'Գլխավոր',
  WORD_SIDEBAR_ORDERS: 'Պատվերներ',
  WORD_SIDEBAR_MANIFESTS: 'Երթուղիներ',
  WORD_SIDEBAR_SERVICES: 'Ծառայություններ',
  WORD_SIDEBAR_REASONS: 'Չհանձնման պատճառներ',
  WORD_SIDEBAR_TEAM: 'Թիմ',
  WORD_SIDEBAR_CUSTOMERS: 'Հաճախորդներ',
  WORD_SIDEBAR_SETTINGS: 'Կարգավորումներ',
  WORD_SIDEBAR_RATING: 'Վարկանիշներ',

  WORD_REASONS_ADD_NEW: 'Ավելացնել',
  WORD_FILTERS: 'Ֆիլտր',
  WORD_REASONS: 'Չհանձնման պատճառներ',
  WORD_SEARCH: 'Որոնել',
  WORD_YESTERDAY: 'Երեկ',
  WORD_TODAY: 'Այսօր',
  WORD_TOMORROW: 'Վաղը',
  WORD_THIS_WEEK: 'Այս շաբաթ',
  WORD_THIS_MONTH: 'Այս ամիս',
  WORD_SELECT_ALL_DRIVERS: 'Բոլոր վարորդները',
  WORD_SELECT_SELECT_DRIVER: 'Ընտրել վարորդ',
  WORD_SELECT_ALL_STATUSES: 'Բոլոր կարգավիճակները',
  WORD_SELECT_SELECT_STATUS: 'Ընտրել կարգավիճակ',
  WORD_SELECT_SELECT_SERVICE: 'Ընտրել ծառայություն',
  WORD_SELECT_ALL_SERVICES: 'Բոլոր ծառայությունները',
  WORD_SELECT_SELECT_TYPE: 'Ընտրել տեսակը',
  WORD_SELECT_SELECT: 'Ընտրել',
  WORD_SELECT_ALL: 'Բոլորը',
  WORD_ADD_NEW_WIDGET_IMPORT_ORDERS: 'Ներմուծել պատվերներ',
  WORD_ADD_NEW_WIDGET_CREATE_MANIFEST: 'Նոր երթուղի',
  WORD_ADD_NEW_WIDGET_CREATE_ORDER: 'Նոր պատվեր',
  WORD_BUTTON_BACK: 'Հետ',
  WORD_SUBTOTAL: 'Ենթագումար',
  WORD_REFUND: 'Վերադարձ',
  WORD_TOTAL: 'Ընդհանուր',
  WORD_ORDER_STATUS_START: 'Սկիզբ',
  WORD_ORDER_STATUS_COMPLETE: 'Ավարտ',
  WORD_NEW_MANIFEST: 'Նոր երթուղի',
  WORD_ORDERS: 'Պատվերներ',
  WORD_PENDING: 'Ընթացիկ',
  WORD_ARCHIVE: 'Արխիվ',
  WORD_COMPLETED: 'Ավարտված',
  WORD_SELECT: 'Ընտրել',
  WORD_STATUS_ACTIVE: 'Ակտիվները',
  WORD_STATUS_INACTIVE: 'Ոչ ակտիվները',
  WORD_ALL: 'Բոլորը',
  WORD_ADD_SERVICE: 'Ավելացնել',
  WORD_SERVICES: 'Ծառայություններ',
  WORD_DESCRIPTION: 'Նկարագրություն',
  WORD_NAME: 'Անվանում',
  WORD_ADD_SERVICE_STEP: 'Ավելացնել',
  WORD_ADD_SERVICE_STEPS_LIST: 'Գործողություններ',
  WORD_ORDER_STEP_CHECKPOINT: 'Անցակետ',
  WORD_TRANSPORT_TYPE: 'Տրանսպորտ',
  WORD_SELECT_TRANSPORT_TYPE: 'Ընտրել տեսակը',
  WORD_SELECT_ALL_TRANSPORTS: 'Բոլոր տրանսպորտները',
  WORD_PRICE_TRANSPORT_TYPE_CAR: 'Մարդատար',
  WORD_PRICE_TRANSPORT_TYPE_WALK: 'Ոտքով',
  WORD_PRICE_TRANSPORT_TYPE_BICYCLE: 'Հեծանիվ',
  WORD_PRICE_TRANSPORT_TYPE_TRACK: 'Բեռնատար',
  WORD_PRICE: 'Արժեք',
  WORD_PRICE_TYPE: 'Գնատեսակ',
  WORD_PRICE_FIXED_PRICE: 'Ֆիքսված',
  WORD_PRICE_BY_DISTANCE: 'Ըստ տարածության',
  WORD_SERVICE_MIN_KM: 'Մին. կմ',
  WORD_SERVICE_PRICE_MIN_KM: 'Մին. արժեք',
  WORD_SERVICE_PRICE_PER_KM: '1կմ արժեք',
  WORD_SERVICE_PRICING: 'Գնացուցակ',
  WORD_SERVICE_ADD_PRICE: 'Ավելացնել',
  WORD_TRANSPORT_PRICE: 'Արժեք',
  WORD_REASON_NEW_TITLE: 'Ավելացնել նոր պատճառ',
  WORD_REASON_UPDATE_TITLE: 'Խմագրել ',
  NOTE_REASON_CREADE_SUCCESS: 'Հաջողությամբ ավելացվել է։',
  NOTE_REASON_UPDATE_SUCCESS: 'Հաջողությամբ խմբագրվել է։',
  WORD_REASON_TITLE: 'Պատճառ',
  WORD_ADD_USER: 'Ավելացնել',
  WORD_TEAM_LIST_TITLE: 'Թիմ',
  WORD_FILTER_APPLY: 'Կիրառել',
  WORD_ORDER_DUE_DATE: 'Ամսաթիվ',
  WORD_TEL: 'Հեռ',
  WORD_TRANSPORT: 'Տրանսպորտ',
  WORD_USER_INFO_TITLE: 'Օգտատիրոջ տվյալներ',
  WORD_AVATAR: 'Պրոֆիլի նկար',
  WORD_USER_NAME: 'Անուն, ազգանուն',
  WORD_USER_ROLE: 'Պաշտոն',
  WORD_USER_LOGIN_SECTION_TITLE: 'Մուտքի տվյալներ',
  WORD_USER_LOGIN: 'Լոգին',
  WORD_USER_TRANSPORT_SECTION_TITLE: 'Տրանսպորտ',
  WORD_TRANSPORT_ID: 'Պետհամարանիշ',
  WORD_TRANSPORT_DETAILS: 'Տրանսպորտի տվյալներ',
  WORD_SELECT_ALL_ROLES: 'Բոլոր պաշտոնները',
  WORD_CUSTOMERS_LIST_TITLE: 'Հաճախորդներ',
  WORD_ADD_CUSTOMER: 'Ավելացնել',
  WORD_CUSTOMER_TITLE_NEW: 'Ավելացնել հաճախորդ',
  WORD_CUSTOMER_TITLE_EDIT: 'Հաճախորդի տվյալներ',
  WORD_COMPANY_SETTINGS_TITLE: 'Կազմակերպության տվյալներ',
  WORD_COMPANY_NAME: 'Կազմակերպության անվանում',
  WORD_LEGAL_NAME: 'Իրավաբանական անուն',
  WORD_TIN: 'ՀՎՀՀ',
  WORD_COUNTRY: 'Երկիր',
  WORD_CURRENCY: 'Արժույթ',
  WORD_SAVE: 'Պահպանել',
  WORD_CHANGE_PASSWORD: 'Փոխել գաղտնաբառը',
  WORD_LANGUAGE: 'Լեզու',
  WORD_ACCOUNT_SETTINGS_TITLE: 'Հաշվի կարգավորուներ',
  WORD_CHANGE_PASSWORD_TITLE: 'Գաղտնաբառի փոփոխություն',
  WORD_CURRENT_PASSWORD: 'Գործող գաղտնաբառ',
  WORD_NEW_PASSWORD: 'Նոր գաղտնաբառ',
  WORD_REPEATE_NEW_PASSWORD: 'Կրկնել նոր գաղտնաբառը',
  WORD_API_CODE: 'API կոդ',
  WORD_ADD_API_CODE: 'Ավելացնել API կոդ',
  WORD_NEW_API_CODE: 'Նոր API կոդ',
  WORD_EDIT_API_CODE: 'Խմբագրել API կոդը',
  WORD_TOKEN: 'Տոկեն',
  WORD_API_VIEW: 'API էջ',
  WORD_COPY_TOKEN: 'Պատճենել տոկենը',
  // WORD_DEVICES: "սարքեր",
  WORD_ADD_DETAILS: 'Մանրամասներ',
  WORD_M: 'մ.',
  WORD_DEVICES: 'Սարքեր',
  // WORD_NEW_API_CODE: "Ավելացնել նոր սարք",
  WORD_EDIT_DEVICES: 'Խմբագրել սարքը',
  WORD_CREATE_DATE: 'Ստեղծման ամսաթիվ',
  WORD_OS: 'ՕՀ',
  WORD_DEVICE_CREATED_SUCCESSFULY: 'Սարքը հաջողությամբ ստեղծվել է։',
  WORD_DEVICE_UPDATED_SUCCESSFULY: 'Սարքը հաջողությամբ թարմացվել է։',
  WORD_START_DATE: 'Սկիզբ',
  WORD_END_DATE: 'Վերջ',
  WORD_RESET: 'Վերականգնել',
  ARE_YOU_SURE_TO_DELETE_THIS_ITEM: 'Դուք վստահե՞ք, որ ուզում եք ջնջել այն',
  WORD_YES: 'Այո',
  WORD_NO: 'Ոչ',
  WORD_ERROR_PLEASE_INPUT: 'Խնդրում ենք լրացնել',
  WORD_ERROR_PLEASE_INPUT_TIME_RANGE: 'Խնդրում ենք լրացնել ժամանակահատվածը',
  WORD_ERROR_PLEASE_SELECT: 'Խնդրում ենք ընտրել',
  WORD_SEND: 'Ուղարկել',
  WORD_ENTERPRISE: 'Ձեռնարկություն',
  WORD_DEFINATION: 'Սահմանում',
  ERROR_PLEASE_INPUT_YOUR_PHONE_NUMBER: 'Խնդրում ենք լրացնել հեռախոսահամարը!',
  WORD_CUSTOM_AMOUNT_DELIVERY: 'Պատվերով առաքման գումար',
  WORD_AMOUNT_DELEVERY: 'Առաքման գումարը',
  WORD_DELEVERY_DATE: 'Առաքման օր',
  ERROR_WORD_SELECT_DATE_TIME: 'Խնդրում ենք ընտրել ամսաթիվն ու ժամը',
  WORD_ADD_A_WAYPOINT: 'Ավելացրեք ճանապարհային կետ',
  WORD_WAYPOINT: 'Ճանապարհային կետ',
  WORD_FROM: 'Որտեղից',
  WORD_TO: 'Որտեղ',
  WORD_SELECT_COUNTRY: 'Ընտրել երկիրը',
  WORD_ALL_COUNTRIES: 'Բոլոր երկրները',
  WORD_ALL_CODES: 'Բոլոր կոդերը',
  WORD_CODES: 'Կոդեր',
  WORD_NOTIFICATIONS: 'Ծանուցումներ',
  WORD_SMS: 'SMS հաղորդագրություն',
  WORD_CUSTOMER_NOTIFICATIONS: 'Հաճախորդի ծանուցումներ',
  WORD_EDIT_TEMPLATE: 'Խմբագրել ձևանմուշը',
  WORD_EDIT_ORDER_STARTED_TEMPLATE: 'Խմբագրել պատվերը սկսված ձևանմուշը',
  WORD_YOU_CAN_USE: 'Կարող եք օգտագործել',
  WORD_IN_MESSAGES_BELOW_TO_INSERT_THE_CUSTOMER_COMPANY_ORDER_ID_OR_AND_TRACKING_URL:
    'պատվերի ընթացքին հետևելու հղումը տեղադրելու համար։',
  WORD_IN_MESSAGES_BELOW_TO_INSERT_THE_CUSTOMER_COMPANY_ORDER_ID_OR_AND_RATING_URL:
    'պատվերի վարկանիշին հետևելու հղումը տեղադրելու համար։',
  WORD_CUSTOMER_SMS_NOTES_1:
    '1 SMS = 160 լատինատառ սինվոլ կամ 70 հայատառ սինվոլ։',
  WORD_CUSTOMER_SMS_NOTES_2:
    'Եթե օգտագրծեք {{TRACKING_ID}} տագը հաղրդագրության մեջ ապա 1SMS = 135 լատինատառ սինվոլ կամ 45 հայատառ սինվոլ։',
  WORD_CUSTOMER_SMS_NOTES_3:
    'Խնդրում ենք նկատի ունենալ, որ եթե հաղորդագրությունն լինի ավելի քան 160 լատինատառ կամ 70 հայատառ սինվոլ, ապա այն գանձվում է հետևյալ եղանակով (հաղորդագրության սինվոլների քանակը / 160):',
  WORD_SUBJECT: 'Թեմա',
  WORD_MESSAGE: 'Նամակ',
  WORD_ENABLED: 'միացված է',
  WORD_DISABLED: 'անջատված է',
  WORD_SUCCESSFULY: 'հաջողությամբ',
  WORD_KG: 'Կգ',
  WORD_M3: 'Մ',
  WORD_M3_WITH_3: 'Մ3',
  WORD_FREIGHT_MANAGEMENT: 'Երթուղի',
  WORD_NEW_FREIGHT: 'Նոր երթուղի',
  WORD_ADD_FREIGHT: 'Ավելացնել երթուղի',
  WORD_FREIGHTS: 'Երթուղիներ',
  WORD_KG_LC: 'կգ',
  WORD_M3_LC: 'մ',
  WORD_SPLITTED: 'Կիսված',
  WORD_CHOOSEN: 'Ընտրված',
  WORD_QUANTITY: 'Քանակ',
  WORD_POLYGONS: 'Պոլիգոն',
  WORD_POLYGONS_EDIT: 'Պոլիգոնի խմբագրում',
  WORD_BUSINESS_ZONES: 'Բիզնես գոտիներ',
  WORD_BUSINESS_ZONES_EDIT: 'Բիզնես գոտու խմբագրում',
  WORD_NEW_BUSINESS_ZONES: 'Նոր բիզնես գոտի',
  WORD_ADD_BUSINESS_ZONES: 'Ավելացնել բիզնես գոտի',
  WORD_BUSINESS_ZONE_CREATED_SUCCESSFULY:
    'Բիզնես գոտին հաջողությամբ ստեղծվել է։',
  WORD_BUSINESS_ZONE_UPDATED_SUCCESSFULY:
    'Բիզնես գոտին հաջողությամբ թարմացվել է։',
  WORD_BUSINESS_ZONE_DELETED_SUCCESSFULY: 'Բիզնես գոտին հաջողությամբ ջնջվել է։',
  WORD_NEW_POLYGON: ' Նոր պոլիգոն',
  WORD_ADD_POLYGON: 'Ավելացնել պոլիգոն',
  WORD_COLOR: 'Գույն',
  WORD_EVALUATE_YOUR_EXPERIENCE: 'Գնահատեք ձեր փորձը',
  WORD_TELL_US_ABOUT_YOUR_EXPERIENCE: 'Պատմեք ձեր փորձի մասի',
  WORD_POST: 'Հրապարակել',
  WORD_DISTANCE: 'Հեռավորություն',
  WORD_DURATION: 'Ժամանակ',
  WORD_NEW: 'Նոր',
  WORD_ORDER_SPLITED_SUCCESSFULY: 'Պատվերը հաջողությամբ բաժանվեց։',
  ERROR_QUANTITY_IS_GREATER_THANAVAILABLE: 'Քանակը ավել է առկա քանակից։',
  ERROR_QUANTITY_MUST_BE_GREATER_THAN_ZERO:
    'Քանակի դաշտը պետք է լինի 0-ից մեծ։',
  WORD_ORDERS_ASSIGNED_SUCCESSFULY: 'Պատվերը հաջողությամբ նշանակվել է։',
  WORD_ORDERS_ASSIGNED_AND_SEND_SUCCESSFULY:
    'Պատվերը հաջողությամբ նշանակվել և ուղարկվել է։',
  WORD_SHIPMENT: 'Բեռնակրում',
  WORD_GATE: 'Դարպաս',
  WORD_PENDING_STATUS: 'Սպասվող',
  WORD_IN_PROGRESS: 'Ընթացքի մեջ',
  WORD_READY: 'Պատրաստ է',
  WORD_READY_FOR_DELEVERY: 'Պատրաստ է առաքման',
  WORD_STATUS_CHANGED_SUCCESSFULLY: 'Կարգավիճակը հաջողությամբ փոփոխվել է:',
  WORD_PLEASE_INPUT_GATE: 'Խնդրում ենք լրացնել դարպասի համարը։',
  WORD_TOKEN_GENERATE_SUCCESSFULLY: 'Տոկենը գեներացվել է հաջողությամբ։',
  WORD_ORDER_CREATED_SUCCESSFULY: 'Պատվերը հաջողությամբ ստեղծվել է։',
  WORD_ORDER_UPDATED_SUCCESSFULY: 'Պատվերը հաջողությամբ թարմացվել է։',
  FREIGHT_CREATED_SUCCESSFULY: 'Առաքումը հաջողությամբ ստեղծվել է։',
  FREIGHT_UPDATED_SUCCESSFULY: 'Առաքումը հաջողությամբ թարմացվել է',
  WORD_OPEN_LINK: 'Անցնել հղումով',
  WORD_OPEN_SPLIT_MODAL: 'Բացել բաժանող մոդալը',
  WORD_CHANGED_PASSWORD_SUCCESS: 'Գաղտնաբառի փոփոխությունը հաջողվեց։',
  WORD_CHANGED_PROFILE_SETTINGS_SUCCESS:
    'Հաշվի կարգավորումների փոփոխությունը հաջողվեց։',
  WORD_ARMENIAN: 'Հայերեն',
  WORD_SELECT_ONE: 'Ընտրել մեկը',
  WORD_RATING_TITLE: 'Վարկանիշ',
  WORD_COPY: 'Պատճենել',
  WORD_REGENERATE_TOKEN: 'Վերագեներացնել տոկեն',
  WORD_TRUCKING_URL: 'Երթուղու հղում',
  WORD_RATING_URL: 'Վարկանիշի հղում',
  WORD_ORDER_RATINGS: 'Պատվերի վարկանիշ',
  WORD_REVIEW: 'Կարծիք',
  WORD_BY_REGISTERING_IN_DELIVERY_MANAGER_YOU_AGREE_WITH_OUR:
    'Գրանցվելով Առաքումների կառավարման համակարգում` Դուք համաձայն եք մեր',
  WORD_TERMS_CONDITIONS: 'Պայմանների և դրույթների,',
  WORD_AND: 'և',
  WORD_PRIVACY_POLICY: 'Գաղտնիության քաղաքականության հետ։',
  WORD_COMPANY_SETTINGS_CHANGED_SUCCESSFULLY:
    'Կազմակերպության կարգավորումների փոփոխությունը հաջողվեց։',
  WORD_USER_CREATED_SUCCESSFULY: 'Օգտատիրոջ հաշիվը հաջողությամբ ստեղծվել է։',
  WORD_USER_UPDATED_SUCCESSFULY: 'Օգտատիրոջ հաշիվը հաջողությամբ թարմացվել է։',
  WORD_API_CODE_CREATED_SUCCESSFULY: 'Api կոդը հաջողությամբ ստեղծվել է։',
  WORD_API_CODE_UPDATED_SUCCESSFULY: 'Api կոդը հաջողությամբ թարմացվել է։',
  WORD_API_CODE_DELETED_SUCCESSFULY: 'Api կոդը հաջողությամբ ջնջվել է։',
  WORD_THE_PHONE_LENGTH_MUST_BE: 'Հեռախոսահամարի երկարությունը պետք է լինի',
  WORD_ERROR_PLEASE_INPUT_ALL_FIELDS: 'Խնդրում ենք լրացնել բոլոր դաշտերը',
  WORD_PRODUCT_UNIT: 'Միավոր',
  WORD_CUSTOMER_CREATED_SUCCESSFULY:
    'Հաճախորդի հաշիվը հաջողությամբ ստեղծվել է։',
  WORD_CUSTOMER_UPDATED_SUCCESSFULY:
    'Հաճախորդի հաշիվը հաջողությամբ թարմացվել է։',
  WORD_DISTRIBUTOR: 'Առաքիչ',
  WORD_READ_MORE: 'Կարդալ ավելին',
  WORD_READ_LESS: 'Փակել',
  WORD_MONITORING_LINK_COPIED_SUCCESSFULY:
    'Մոնիտորինգի հղումը պատճենվել է հաջողությամբ։',
  WORD_TRACKING_URL_COPIED_SUCCESSFULY:
    'Ընթացքին հետևելու հղումը պատճենվել է հաջողությամբ։',
  WORD_RATING_URL_COPIED_SUCCESSFULY:
    'Վարկանիշին հետևելու հղումը պատճենվել է հաջողությամբ։',
  WORD_SELECT_DATE: 'Ընտրել ամսաթիվը',
  WORD_SELECT_BUSINESS_ZONE: 'Ընտրել բիզնես գոտին',
  WORD_OTHER: 'Այլ',
  WORD_CHANGE_DRIVER_FREIGHT: 'Փոխել վարորդի երթուղին',
  WORD_BRANCH: 'Մասնաճյուղ',
  WORD_ADD_NEW_BRANCH: 'Նոր մասնաճյուղ',
  WORD_EDIT_BRANCH: 'խմբագրել մասնաճյուղը',
  WORD_ADD_BRANCH: 'Ավելացնել մասնաճյուղ',
  WORD_CODE: 'կոդ',
  WORD_BRANCH_CREATED_SUCCESSFULY: 'Մասնաճյուղը հաջողությամբ ստեղծվել է։',
  WORD_BRANCH_UPDATED_SUCCESSFULY: 'Մասնաճյուղը հաջողությամբ ստեղծվել է։',
  WORD_SELECT_BRANCHES: 'Ընտրեք մասնաճյուղեր',
  WORD_ALL_BRANCHES: 'Բոլոր մասնաճյուղերը',
  WORD_SEARCH_PRESS_ENTER: 'Մուտքագրեք և սեղմեք enter',
  WORD_RATE_TITLE: 'Վարկանիշ',
  WORD_WRITE_REVIEW: 'Գրեք կարծիք',
  WORD_REMEMBER_ME: 'Հիշիր ինձ',
  WORD_STATUS_ACTIVE_SEND: 'Ուղարկել',
  WORD_STATUS_INPROGRESS: 'Ընթացքի մեջ է',
  WORD_STATUS_FINISHED: 'Ավարտված',
  WORD_TEMPLATE_UPDATED_SUCCESSFULLY:
    'Ծանուցման ձևանմուշը հաջողությամբ թարմացվեց',
  WORD_DATE: 'Ամսաթիվ',
  WORD_SMS_HISTORY: 'SMS պատմություն',
  WORD_NOTIFICATION_TYPE: 'Ծանուցման տեսակը',
  WORD_TEXT_BODY: 'Տեքստի մարմին',
  WORD_SELECT_SERVICE: 'Ընտրեք ծառայություն',
  WORD_DELIVERY_DATE: 'Առաքման օրը',
  WORD_END_OF_LIST: 'Ցուցակի վերջը',
  WORD_ORDER_START: 'Պատվերը սկսվեց',
  WORD_ORDER_DELIVERED: 'Պատվերը առաքված է',
  WORD_ORDER_NOT_DELIVERED: 'Պատվերը չի առաքվել',
  WORD_ORDER_COLLECTED: 'Պատվերը հավաքվում է',
  WORD_ALL_BUSINESS_ZONES: 'Բոլոր բիզնես գոտիները',
  WORD_SELECT_TRANSPORT: 'Ընտրեք տրանսպորտ',
  WORD_EXPORT: 'Արտահանում',
  WORD_ORDER_TEST: 'թեստ',
  WORD_FAILED: 'ձախողվեց',
  WORD_SUCCESS: 'հաջողվաց',
  WORD_SELECT_ALL_CREATED_TYPE: 'Բոլոր ստեղծված տեսակը',
  WORD_SELECT_CREATED_TYPE: 'Ընտրեք ստեղծված տեսակը',
  WORD_DEFAULT: 'Կանխադրված',
  WORD_SPLIT: 'բաժանված',
  WORD_NOT_DELEVERED: 'Չի առաքվել',
  WORD_WAITING: 'Waiting',
  WORD_SEND_NOTIFICATIONS: 'ուղարկել Ծանուցումներ',

  // Server
  Create: 'Գրանցում',
  'Assign Driver': 'Վարորդի նշանակում',
  'Assign Manifest': 'Երթուղու նշանակում',
  Start: 'Սկիզբ',
  Finish: 'Ավարտ',
  Active: 'Ակտիվ',
  ios: 'iOS',
  android: 'Անդրոիդ',
  Micro: 'Միկրո',
  Small: 'Փոքր',
  Standard: 'Ստանդարտ',
  Finished: 'Ավարտված',
  InProgress: 'Ընթացքում',
  Car: 'Մարդատար',
  Walk: 'Քայլ',
  Bicycle: 'Հեծանիվ',
  Track: 'Բեռնատար',
  'Super Admin': 'Սուպեր ադմին',
  Admin: 'Ադմին',
  Operator: 'Օպերատոր',
  Driver: 'Վարորդ',
  'Order is collected': 'Պատվերը հավաքվում է',
  'Order started': 'Պատվերը սկսվեց',
  'Order delivered': 'Պատվերը առաքված է',
  'Order not delivered': 'Պատվերը չի առաքվել',
  'Send message to customer when order is collected':
    'Ուղարկեք պատվիրատուին հաղորդագրություն՝ պատվերի հավաքվելու մասին',
  'Send message to customer when order start':
    'Ուղարկեք պատվիրատուին հաղորդագրություն՝ պատվերի սկսվելու մասին',
  'Send message to customer when order delivered':
    'Ուղարկեք պատվիրատուին հաղորդագրություն առաքման մասին',
  'Send message to customer when order not delivered':
    'Ուղարկեք պատվիրատուին հաղորդագրություն, երբ պատվերը չի առաքվելու',
};

export default texts;
