import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export default function useWindowSize() {
  const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    isDesktop: window.innerWidth > 1000,
    isTablet: window.innerWidth > 500 && window.innerWidth <= 1000,
    isMobile: window.innerWidth <= 500,
    isTV: window.innerWidth >= 1000,
    isWatch: window.innerWidth <= 300,
  });

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(getWindowSize());
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
