import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_SAVE = '/reviews/save';
const URL_GET_ONE = '/reviews/get_one';

const fs = {
  getOneByidKey: async (_IdKeyClient, _idKeyOrrderRate) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + _IdKeyClient + '/' + _idKeyOrrderRate,
      urlParams
    );
    return result;
  },
  save: async (values, _IdKeyClient, _idKeyOrrderRate) => {
    const urlParams = [
      {
        key: 'saveData',
        value: values,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(
      URL_SAVE + '/' + _IdKeyClient + '/' + _idKeyOrrderRate,
      urlParams
    );
    return result;
  },
};

const loc = {};

const OrderReviewActions = Object.assign(fs, loc);

export default OrderReviewActions;
