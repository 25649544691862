import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    id="iconfinder_yandex_bookmarks_386760"
    xmlns="http://www.w3.org/2000/svg"
    width="26.85"
    height="26.85"
    viewBox="0 0 26.85 26.85"
  >
    <rect
      id="rect2987"
      width="26.85"
      height="26.85"
      rx="13.425"
      fill="#e7322b"
    />
    <path
      id="path9"
      d="M170.264,56h-3.179c-3.123,0-5.772,2.377-5.772,6.993a5.943,5.943,0,0,0,3.569,5.818l-4.266,7.72c-.14.252,0,.447.222.447h1.98a.316.316,0,0,0,.335-.195l3.876-7.58h1.394v7.58a.209.209,0,0,0,.195.195h1.729c.167,0,.223-.083.223-.223V56.28c0-.2-.112-.28-.307-.28Zm-1.84,11.412h-1.171c-1.84,0-3.624-1.343-3.624-4.7,0-3.5,1.673-4.923,3.373-4.923h1.422v9.622Z"
      transform="translate(-152.14 -53.063)"
      fill="#fff"
    />
  </svg>
);

const YandexIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default YandexIcon;
