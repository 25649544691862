import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = (props) => {
  const { color, width, height, opacity } = props;

  const colorr = color || '#0076ff';
  const widthh = width || '15.594';
  const heightt = height || '15.144';
  const opacityy = opacity || '0.8;';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={widthh}
      height={heightt}
      viewBox="0 0 15.594 15.144"
    >
      <g
        id="Group_8281"
        data-name="Group 8281"
        transform="translate(1382 -3148)"
        opacity={opacityy}
      >
        <g id="Group_8280" data-name="Group 8280">
          <g
            id="Group_8260"
            data-name="Group 8260"
            transform="translate(-1382 3148)"
          >
            <g
              id="Group_8251"
              data-name="Group 8251"
              transform="translate(0 2.815)"
            >
              <g id="Group_8250" data-name="Group 8250">
                <path
                  id="Path_5900"
                  data-name="Path 5900"
                  d="M55.8,239.677l-.71-.312-1.7-.751-2.052-.905q-.892-.393-1.782-.784c-.287-.126-.573-.26-.863-.381l-.012-.005.332.579v-6.878c0-.319.007-.638,0-.955v-.013L48,229.851l.71.312,1.7.751,2.052.905q.892.393,1.782.784c.287.126.573.26.863.381l.012.005-.332-.579v6.878c0,.319-.005.638,0,.955v.013a.672.672,0,1,0,1.343,0v-6.878c0-.319.005-.638,0-.955v-.013a.657.657,0,0,0-.332-.579l-.71-.312-1.7-.751-2.052-.905q-.892-.393-1.782-.784l-.863-.381-.012-.005a.686.686,0,0,0-.339-.092.657.657,0,0,0-.339.092.674.674,0,0,0-.332.579v6.878c0,.319-.005.638,0,.955v.013A.657.657,0,0,0,48,237.7l.71.312,1.7.751,2.052.905q.892.393,1.782.784l.863.381.012.005a.728.728,0,0,0,.517.067.685.685,0,0,0,.4-.309.672.672,0,0,0,.067-.517A.647.647,0,0,0,55.8,239.677Z"
                  transform="translate(-47.667 -228.6)"
                  fill={colorr}
                />
              </g>
            </g>
            <g
              id="Group_8253"
              data-name="Group 8253"
              transform="translate(7.123 2.815)"
            >
              <g id="Group_8252" data-name="Group 8252">
                <path
                  id="Path_5901"
                  data-name="Path 5901"
                  d="M479.324,236.539l-.71.312-1.7.751-2.052.905q-.892.393-1.782.784c-.287.126-.578.249-.863.381l-.012.005,1.011.579v-6.878c0-.319.007-.638,0-.955v-.013l-.332.579.71-.312,1.7-.751,2.052-.905q.892-.393,1.782-.784c.287-.126.578-.249.863-.381l.012-.005-1.011-.579v6.878c0,.319-.005.638,0,.955v.013a.672.672,0,1,0,1.343,0v-6.878c0-.319.005-.638,0-.955v-.013a.674.674,0,0,0-.332-.579.685.685,0,0,0-.339-.092.656.656,0,0,0-.339.092l-.71.312-1.7.751-2.052.905q-.892.393-1.782.784c-.287.126-.579.245-.863.381l-.012.005a.657.657,0,0,0-.332.579v6.878c0,.319-.005.638,0,.955v.013a.674.674,0,0,0,.332.579.685.685,0,0,0,.339.092.656.656,0,0,0,.339-.092l.71-.312,1.7-.751,2.052-.905q.892-.393,1.782-.784c.287-.126.579-.245.863-.381L480,237.7a.674.674,0,0,0,.309-.4.694.694,0,0,0-.067-.517.679.679,0,0,0-.4-.309c-.059-.008-.119-.017-.178-.023A.691.691,0,0,0,479.324,236.539Z"
                  transform="translate(-471.867 -228.6)"
                  fill={colorr}
                />
              </g>
            </g>
            <g
              id="Group_8255"
              data-name="Group 8255"
              transform="translate(0.007)"
            >
              <g id="Group_8254" data-name="Group 8254">
                <path
                  id="Path_5902"
                  data-name="Path 5902"
                  d="M62.678,63.865l-.71.312-1.7.751-2.052.905q-.892.393-1.782.784c-.287.126-.578.249-.863.381L55.556,67h.678l-.71-.312-1.7-.751-2.052-.905q-.892-.393-1.782-.784c-.287-.126-.573-.26-.863-.381l-.012-.005-.161,1.227.71-.28q.849-.335,1.7-.672l2.055-.811q.892-.353,1.783-.7c.287-.114.579-.218.863-.341l.012-.005h-.358l.71.28q.849.335,1.7.672l2.055.811q.892.353,1.783.7c.287.114.573.237.863.341l.012.005a.671.671,0,0,0,.517-.067.672.672,0,0,0,.242-.918l-.106-.136a.666.666,0,0,0-.3-.173l-.71-.28-1.706-.673-2.062-.814-1.778-.7c-.284-.113-.566-.233-.855-.338a.616.616,0,0,0-.475.039c-.148.059-.3.118-.443.175l-1.609.635-2.052.811-1.852.732-1.011.4-.047.018a.828.828,0,0,0-.3.173.663.663,0,0,0-.185.386.648.648,0,0,0,.045.348.615.615,0,0,0,.275.321l.714.314,1.713.756q1.03.453,2.06.908l1.775.782c.284.124.568.252.851.374l.008,0h0l.161.067a.5.5,0,0,0,.269.012,1.025,1.025,0,0,0,.26-.084l.092-.04.442-.195,1.609-.709,2.05-.9,1.859-.819c.339-.149.677-.3,1.016-.447l.047-.02a.674.674,0,0,0,.309-.4.694.694,0,0,0-.067-.517.679.679,0,0,0-.4-.309c-.059-.008-.119-.017-.178-.024A.658.658,0,0,0,62.678,63.865Z"
                  transform="translate(-48.105 -60.958)"
                  fill={colorr}
                />
              </g>
            </g>
            <g
              id="Group_8257"
              data-name="Group 8257"
              transform="translate(10.856 4.294)"
            >
              <g id="Group_8256" data-name="Group 8256">
                <path
                  id="Path_5903"
                  data-name="Path 5903"
                  d="M694.2,317.336v2.188a.672.672,0,0,0,1.343,0v-2.188a.672.672,0,1,0-1.343,0Z"
                  transform="translate(-694.2 -316.664)"
                  fill={colorr}
                />
              </g>
            </g>
            <g
              id="Group_8259"
              data-name="Group 8259"
              transform="translate(3.735 1.36)"
            >
              <g id="Group_8258" data-name="Group 8258">
                <path
                  id="Path_5904"
                  data-name="Path 5904"
                  d="M270.613,143.284l.709.292,1.7.7,2.059.846q.889.365,1.78.732c.287.118.573.245.865.356l.012.005a.672.672,0,0,0,.517-.067.672.672,0,0,0,.242-.918l-.106-.136a.666.666,0,0,0-.3-.173l-.709-.292-1.7-.7-2.059-.846q-.889-.365-1.78-.732c-.287-.118-.573-.245-.865-.356l-.012-.005a.672.672,0,0,0-.517.067.672.672,0,0,0-.242.918l.106.136a.667.667,0,0,0,.3.173Z"
                  transform="translate(-270.121 -141.962)"
                  fill={colorr}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const OrderIcon = (props) => {
  return <Icon {...props} component={() => <SVGIcon {...props} />} />;
};

export default OrderIcon;
