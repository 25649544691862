import { Button, Form, Popover } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputFormPhoneNumber from '../components/antd/InputFormPhoneNumber';
import InputFormTextArea from '../components/antd/InputFormTextArea';
import MyPanelFooter from '../components/MyPanelFooter';
import MyForm from '../components/MyForm';
import ClientActions from '../actions/ClientActions';
import { notifyError, notifySuccess } from '../components/notify';
import getText from '../lang';

const SmsTest = () => {
  const defaultPhoneCode = useSelector((state) => {
    return state.AuthReducer?.currentUser?.clientPhoneCode;
  });

  const [form] = Form.useForm();

  const [openPopover, setopenPopover] = useState(false);

  const onFinish = async (values) => {
    const result = await ClientActions.sendSmsTest(values);
    if (result.success) {
      notifySuccess('Sent SMS successfuly');
      setopenPopover(false);
    } else {
      notifyError(result.errMsg);
    }
  };

  return (
    <MyForm
      form={form}
      name="testSmsForm"
      onFinish={onFinish}
      initialValues={{
        phoneCode: defaultPhoneCode,
      }}
      withoutMarginLeft
    >
      <Popover
        open={openPopover}
        content={
          <>
            <InputFormPhoneNumber
              name="phoneNumber"
              nameCode="phoneCode"
              label={getText('WORD_PHONE')}
              required
            />
            <InputFormTextArea
              name="smsMesage"
              label={getText('WORD_MESSAGE')}
              maxLength={600}
              required
            />

            <MyPanelFooter
              formName={'testSmsForm'}
              labelPrimary={getText('WORD_SEND')}
              onCancelClick={() => setopenPopover(false)}
              //   onPrimaryClick={() => setopenPopover(false)}
            />
          </>
        }
        title="Send Test Sms"
        trigger="click"
        onOpenChange={(e) => {
          form.resetFields();
          setopenPopover(e);
        }}
      >
        <Button type="cancel">Send Sms</Button>
      </Popover>
    </MyForm>
  );
};

export default SmsTest;
