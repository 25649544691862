import React from 'react';
import { formatPhoneNumber } from '../../utils';
import ProfileAvatar from '../../components/antd/ProfileAvatar';
import MyField from '../../components/MyField';
import MyRow from '../../components/MyRow';
import getText from '../../lang';
import EditIcon from '../../icons/svg/EditIcon';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import './style.scss';

const CustomerListCard = (props) => {
  const navigateApi = useNavigateApi();

  const { item } = props;

  return (
    <div className="my-card users-list-card customer">
      <MyRow className="avatar-name">
        <ProfileAvatar icon={item.icon} value={item.fullName} />

        <div className="user-card-name-type-wrapper">
          <div className="with-edit-buttin">
            <div className="user-name-div">{item.fullName}</div>
            <div
              className="edit-icon"
              onClick={(e) => {
                e.stopPropagation();
                navigateApi('customer/edit/' + item.idKey);
              }}
            >
              <EditIcon />
            </div>
          </div>
        </div>
      </MyRow>

      <MyField
        fieldValuesList={[
          {
            label: getText('WORD_TEL'),
            rightItem: formatPhoneNumber(item.phoneCode, item.phoneNumber),
          },
          {
            label: getText('WORD_EMAIL'),
            rightItem: item.email,
          },
          {
            label: getText('WORD_ADDRESS'),
            rightItem: item.address,
          },
        ]}
      />
    </div>
  );
};

CustomerListCard.defaultProps = {
  item: {},
};

export default CustomerListCard;
