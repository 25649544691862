import React from 'react';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import AppRoute from './AppRoute';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import history from './utils/history';
// import TagManager from 'react-gtm-module';

function App() {
  // const tagManagerArgs = {
  //   gtmId: 'GTM-W3KHC74',
  // };

  // TagManager.initialize(tagManagerArgs);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter history={history}>
          <ToastContainer />
          <AppRoute />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
