import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    id="iconfinder_Mail.ru_2744969"
    xmlns="http://www.w3.org/2000/svg"
    width="27.422"
    height="27.422"
    viewBox="0 0 27.422 27.422"
  >
    <g id="Social_icons">
      <g id="Mail.ru">
        <path
          id="back"
          d="M0,13.711A13.711,13.711,0,1,1,13.711,27.422,13.711,13.711,0,0,1,0,13.711Z"
          fill="#168de2"
          fillRule="evenodd"
        />
        <path
          id="Fill-4"
          d="M23.068,20.548a2.96,2.96,0,0,0-3.075-2.913h-.061A2.924,2.924,0,0,0,17.007,20.8a2.862,2.862,0,0,0,2.918,3.071,3.062,3.062,0,0,0,3.148-2.951Zm-3.127-4.541a4.716,4.716,0,0,1,3.308,1.426v0a.684.684,0,0,1,.672-.733h.1a.746.746,0,0,1,.736.761l0,6.5a.421.421,0,0,0,.706.372c1.043-1.072,2.291-5.511-.648-8.083a7.039,7.039,0,0,0-8.37-.655,6.317,6.317,0,0,0-2.116,7.584,6.4,6.4,0,0,0,7.832,3.253c1.213-.49,1.773,1.15.513,1.685-1.9.811-7.2.729-9.674-3.557a7.843,7.843,0,0,1,2.851-10.625,8.652,8.652,0,0,1,10.56,1.357c2.818,2.944,2.654,8.456-.1,10.6A1.92,1.92,0,0,1,23.233,24.5l-.013-.464A4.626,4.626,0,0,1,19.941,25.4a4.8,4.8,0,0,1-4.671-4.67,4.825,4.825,0,0,1,4.671-4.721Z"
          transform="translate(-6.515 -6.912)"
          fill="#ffa930"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const MailRuIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default MailRuIcon;
