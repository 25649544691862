import React, { useState } from 'react';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import InputFormEmail from '../../components/antd/InputFormEmail';
import { Button } from 'antd';
import MyRow from '../../components/MyRow';
import UserActions from '../../actions/UserActions';
import { notifySuccess, notifyError } from '../../components/notify';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const ForgotPasswordRequest = (props) => {
  const navigateApi = useNavigateApi();

  const [saving, setSaving] = useState(false);

  const onFinish = async (values) => {
    setSaving(true);
    let result = await UserActions.forgotPasswordRequest(values.email);
    setSaving(false);
    if (result.success) {
      notifySuccess('sent email success');
      navigateApi('forgot_password/request/after', '', {
        type: 'forgotPasswordRequest',
      });
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
    setSaving(false);
  };

  return (
    <div className="login-page-wrapper">
      <MyBlock title={getText('WORD_FORGOT_PASSWORD_WA')}>
        <MyForm
          name="forgotPasswordRequestForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <InputFormEmail name="email" label={getText('WORD_EMAIL')} required />
          <MyRow style={{ marginTop: 20 }}>
            <Button
              type="primary"
              htmlType="submit"
              form={'forgotPasswordRequestForm'}
              loading={saving}
            >
              {getText('WORD_SEND')}
            </Button>
            <Button
              onClick={() => {
                navigateApi('login');
              }}
            >
              {getText('WORD_LOGIN')}
            </Button>
          </MyRow>
        </MyForm>
      </MyBlock>
    </div>
  );
};

export default ForgotPasswordRequest;
