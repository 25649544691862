import React from 'react';
import { Layout } from 'antd';
import DrawerOpenIcon from '../../../icons/svg/DrawerOpenIcon';

const Header = (props) => {
  const { fullHeader, onToggle, menuOpen } = props;

  const fullHeaderStyle = {
    width: '100%',
    position: 'relative',
  };

  return (
    <Layout.Header style={fullHeader ? fullHeaderStyle : {}}>
      <div className={'trigger'} onClick={onToggle}>
        <DrawerOpenIcon className={menuOpen ? 'rotate-icon' : ''} />
      </div>
    </Layout.Header>
  );
};

Header.defaultProps = {
  fullHeader: false,
};

export default Header;
