import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DeviceActions from '../../actions/DeviceActions';
import InputFormText from '../../components/antd/InputFormText';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import MyPanelFooter from '../../components/MyPanelFooter';
import { notifyError, notifySuccess } from '../../components/notify';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';

const DeviceEdit = (props) => {
  const navigateApi = useNavigateApi();
  let { _id } = useParams();
  const [form] = useForm();
  const { isMobile } = useWindowSize();

  const { isNew } = props;

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await DeviceActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
          });
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('device/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  const onFinish = async (values) => {
    let result = await DeviceActions.save(values, _id);
    if (result.success) {
      notifySuccess(
        isNew
          ? getText('WORD_DEVICE_CREATED_SUCCESSFULY')
          : getText('WORD_DEVICE_UPDATED_SUCCESSFULY')
      );
      navigateApi('device/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
    for (const key in errorInfo.values.itemsStep) {
      if (!errorInfo.values.itemsStep[key].checkPoint) {
        errorInfo.values.itemsStep[key].checkPoint = false;
      }
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock
        {...props}
        title={
          isNew ? getText('WORD_ADD_NEW_DEVICES') : getText('WORD_EDIT_DEVICES')
        }
        page
      >
        <MyForm
          name="apicodeForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <InputFormText
            name="deviceName"
            label={getText('WORD_NAME')}
            required
          />
          <MyPanelFooter
            onCancelClick={() => {
              navigateApi('device/list');
            }}
            formName={'apicodeForm'}
            labelPrimary={
              isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
            }
            labelCancel={getText('WORD_CANCEL')}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default DeviceEdit;
