import React from 'react';

const HeaderSpace = (props) => {
  const { className, children, style } = props;

  return (
    <div style={style} className={`header-space ${className || ''}`}>
      {children}
    </div>
  );
};

export default HeaderSpace;
