import React, { useEffect, useState } from 'react';
import getText from '../../lang';
import DashboardActivityItem from './DashboardActivityItem';
import DashboardCardItem from './DashboardCardItem';
import CloseIcon from '../../icons/svg/CloseIcon';
import MyListSection from '../../components/myList/MyListSection';
import OrderActions from '../../actions/OrderActions';
import useWindowSize from '../../utils/useWindowSize';

const DashboardRight = (props) => {
  const [listData, setListData] = useState([]);
  const [loadingData, setloadingData] = useState(true);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const { isMobile } = useWindowSize();

  const { selectedDriverKey, onClose } = props;

  useEffect(() => {
    const fill = async () => {
      setloadingData(true);
      let fetchParams = {
        page: page,
        perPage: 10,
        sort: {
          createDate: 'DESC',
        },
        filter: {
          idUserDriver: selectedDriverKey.idUser,
          // assignedUnassigned: 'assigned',
          status: 4,
        },
        q: '',
      };

      let result = await OrderActions.fillPaginationList(fetchParams);
      if (result.success) {
        if (page > 0) {
          setListData((ov) => {
            let list = ov.concat(result.data);
            return list;
          });
        } else {
          setListData(result.data);
          setTotalCount(result.totalRows);
        }
      }
      setloadingData(false);
    };
    fill();
  }, [selectedDriverKey?.idUser, page]);

  const handleLoadMore = async () => {
    if (totalCount !== listData.length && !loadingData) {
      setPage(page + 1);
    }
  };

  return (
    <div
      className="dashboard-right-side-wrapper"
      style={{ width: isMobile ? 300 : 600 }}
    >
      <div
        className="dashboard-right-close-icon"
        onClick={() => {
          onClose && onClose();
        }}
      >
        <CloseIcon />
      </div>
      <div className="right-block-title">
        {getText('WORD_DRIVER_INFORMATION')}
      </div>
      <DashboardCardItem item={selectedDriverKey} />
      <div className="right-block-title">{getText('WORD_ACTIVITY')}</div>
      <MyListSection
        loading={loadingData}
        totalCount={totalCount}
        dataSource={listData}
        onLoadMore={handleLoadMore}
        height={isMobile ? 510 : 670}
        renderItem={(item, index) => (
          <DashboardActivityItem key={index} item={item} />
        )}
      />
    </div>
  );
};

export default DashboardRight;
