import React, { useState, useEffect } from 'react';
import getText from '../../lang';
import { redirectPathNewTab } from '../../utils';
import ApiStorage from '../../reducers/ApiStorage';
import { notifySuccess } from '../../components/notify';
import MyRow from '../../components/MyRow';
import ApicodeActions from '../../actions/ApicodeActions';
import DeleteButton from '../../components/antd/DeleteButton';
import ButtonCopy from '../../components/ButtonCopy/ButtonCopy';
import EditIcon from '../../icons/svg/EditIcon';
import UserActions from '../../actions/UserActions';
import { Button } from 'antd';
import { getBaseURLReact } from '../../utils/AxiosAdapter';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import './style.scss';

const ApiListCard = (props) => {
  const navigateApi = useNavigateApi();

  const [tokenMonitoringLink, setTokenMonitoringLink] = useState('');
  const [tokenMonitoring, setTokenMonitoring] = useState('');
  const [loadingBtn, setLoadingBtn] = useState(false);

  const { item, disableView, onDelete } = props;

  useEffect(() => {
    const fillToken = async () => {
      let resultToken = await UserActions.getPackingToken();
      if (resultToken.success) {
        const lang = ApiStorage.getLanguage();
        setTokenMonitoringLink(
          `${getBaseURLReact()}${lang}/driver/packing_status_monitor/${
            resultToken.data.token
          }`
        );
      }
    };
    if (Boolean(item.type === ApicodeActions.TYPE.PACKING)) {
      fillToken();
    }
  }, [item.type, tokenMonitoring, tokenMonitoringLink]);

  const handleClickGenerateBtn = async () => {
    const resultRegenerate = await ApicodeActions.regenerateMonitoringToken();
    if (resultRegenerate.success) {
      setLoadingBtn(true);
      setTokenMonitoring(resultRegenerate.data.token);
      notifySuccess(getText('WORD_TOKEN_GENERATE_SUCCESSFULLY'));
      setLoadingBtn(false);
    }
  };

  return (
    <div
      className="my-card service-list-card api-list-card"
      onClick={(e) => {
        if (Boolean(item.type !== ApicodeActions.TYPE.PACKING)) {
          if (!disableView) {
            e.stopPropagation();
            navigateApi('apicode/view/' + item.idKey);
          }
        }
      }}
    >
      {Boolean(item.type !== ApicodeActions.TYPE.PACKING) ? (
        <MyRow className="first-row">
          <p className="card-name">{item.name}</p>
          <div className="edit-delete-button">
            <div
              className="edit-icon"
              onClick={(e) => {
                e.stopPropagation();
                navigateApi('apicode/edit/' + item.idKey);
              }}
            >
              <EditIcon />
            </div>
            <div className="delete-icon">
              <DeleteButton onConfirm={onDelete} />
            </div>
          </div>
        </MyRow>
      ) : (
        <MyRow className="second-row">
          <p className="card-name">{item.name}</p>
          <div className="api-card-buttons">
            <ButtonCopy
              value={tokenMonitoringLink}
              disabled={!tokenMonitoringLink}
              notifyMessage={getText('WORD_MONITORING_LINK_COPIED_SUCCESSFULY')}
            />
            <Button
              type="primary"
              className="generate-btn"
              loading={loadingBtn}
              onClick={handleClickGenerateBtn}
            >
              {getText('WORD_REGENERATE_TOKEN')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                redirectPathNewTab(
                  tokenMonitoringLink.split('/').slice(4, 8).join('/')
                );
              }}
            >
              {getText('WORD_OPEN_LINK')}
            </Button>
          </div>
        </MyRow>
      )}
    </div>
  );
};

ApiListCard.defaultProps = {
  item: {},
};

export default ApiListCard;
