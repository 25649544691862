import { Table, Skeleton } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteScrollTable = (props) => {
  const { dataSource, onLoadMore, columns, rowKey, dataLength, height } = props;

  return (
    <div
      id="scrollableDiv"
      style={{ height: height || '90vh', overflow: 'auto' }}
    >
      <InfiniteScroll
        dataLength={dataSource.length}
        next={() => {
          onLoadMore && onLoadMore();
        }}
        hasMore={dataSource.length < dataLength}
        loader={
          <Skeleton
            paragraph={{
              rows: 1,
              width: '100%',
            }}
            style={{ padding: 10 }}
            //   title={false}
          />
        }
        scrollableTarget="scrollableDiv"
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey={rowKey}
          pagination={false}
        />
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollTable;
