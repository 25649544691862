import React from 'react';
import { Form, Checkbox } from 'antd';

const CheckboxForm = (props) => {
  const { name, disabled, onChange, label } = props;

  return (
    <Form.Item name={name} valuePropName="checked">
      <Checkbox
        defaultChecked={false}
        disabled={disabled}
        onChange={(e) => {
          onChange && onChange(e.target.checked);
        }}
      >
        {label}
      </Checkbox>
    </Form.Item>
  );
};

CheckboxForm.defaultProps = {
  name: 'booleanCheckBoxValue',
  label: 'checkBox',
  disabled: false,
  defaultChecked: false,
};

export default CheckboxForm;
