import React from 'react';
import { Button } from 'antd';
import PhoneIcon from '../icons/svg/PhoneIcon';

const CalButton = (props) => {
  const { phoneNumber, style, size } = props;

  return (
    <a
      href={`tel:${phoneNumber}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Button size={size} shape="circle" icon={<PhoneIcon style={style} />} />
    </a>
  );
};

export default CalButton;
