import React from 'react';
import CheckboxForm from '../../components/antd/CheckboxForm';
import MyRow from '../../components/MyRow';
import getText from '../../lang';
import { Form } from 'antd';
import MyForm from '../../components/MyForm';
import NotificationsActions from '../../actions/NotificationsActions';
import { notifyError, notifySuccess } from '../../components/notify';
import useNavigateApi from '../../AppRoute/useNavigateApi';

const NotificationsListItem = (props) => {
  const { item, onChange } = props;
  const navigateApi = useNavigateApi();
  const [form] = Form.useForm();

  return (
    <div className="user-view-list-item notifications-list-item-wrapper">
      <MyRow className="notification-row">
        <div className="list-item list-item-small">
          <div className="list-item-title" style={{ paddingBottom: 10 }}>
            {getText(item.title)}
          </div>
          <div className="list-item-info">{getText(item.description)}</div>
        </div>
        <MyForm
          form={form}
          name={'notificationsCheckboxForm' + item.idKey}
          initialValues={{
            sms: item ? item.useSms : 0,
            email: item ? item.useEmail : 0,
          }}
        >
          <div className="checkox-list-item-wrapper">
            <div className="title-with-action">
              <div className="action-title">{getText('WORD_SMS')}</div>
              <div className="checkbox-item">
                <CheckboxForm
                  name="sms"
                  label={''}
                  onChange={async (value) => {
                    let result;
                    if (value) {
                      result = await NotificationsActions.changeStatusEnable(
                        item.type,
                        NotificationsActions.TYPE.SMS
                      );
                    } else {
                      result = await NotificationsActions.changeStatusDisable(
                        item.type,
                        NotificationsActions.TYPE.SMS
                      );
                    }
                    if (result.success) {
                      notifySuccess(
                        `${getText('WORD_SMS')} ${
                          result.data.useSms === 1
                            ? getText('WORD_ENABLED')
                            : getText('WORD_DISABLED')
                        } ${getText('WORD_SUCCESSFULY')}`
                      );
                      onChange && onChange(result.data);
                    } else {
                      notifyError(result.errMsg);
                    }
                  }}
                />
                {item.useSms === 1 ? (
                  <div
                    className="edit-template"
                    onClick={async (e) => {
                      e.stopPropagation();
                      let result = await NotificationsActions.getOneByType(
                        item.type,
                        NotificationsActions.TYPE.SMS
                      );
                      if (result.success) {
                        navigateApi('notifications/sms', {
                          state: { item: result.data },
                        });
                      } else {
                        notifyError(result.errMsg);
                      }
                    }}
                  >
                    {getText('WORD_EDIT_TEMPLATE')}
                  </div>
                ) : (
                  <div className="edit-template disabled">
                    {getText('WORD_EDIT_TEMPLATE')}
                  </div>
                )}
              </div>
            </div>
            <div className="title-with-action">
              <div className="action-title">{getText('WORD_EMAIL')}</div>
              <div className="checkbox-item">
                <CheckboxForm
                  name="email"
                  label={''}
                  onChange={async (value) => {
                    let result;
                    if (value) {
                      result = await NotificationsActions.changeStatusEnable(
                        item.type,
                        NotificationsActions.TYPE.EMAIL
                      );
                    } else {
                      result = await NotificationsActions.changeStatusDisable(
                        item.type,
                        NotificationsActions.TYPE.EMAIL
                      );
                    }
                    if (result.success) {
                      notifySuccess(
                        `${getText('WORD_EMAIL')} ${
                          result.data.useEmail === 1
                            ? getText('WORD_ENABLED')
                            : getText('WORD_DISABLED')
                        } ${getText('WORD_SUCCESSFULY')}`
                      );
                      onChange && onChange(result.data);
                    } else {
                      notifyError(result.errMsg);
                    }
                  }}
                />
                {item.useEmail === 1 ? (
                  <div
                    className="edit-template"
                    onClick={async (e) => {
                      e.stopPropagation();
                      let result = await NotificationsActions.getOneByType(
                        item.type,
                        NotificationsActions.TYPE.EMAIL
                      );
                      if (result.success) {
                        navigateApi('notifications/email', {
                          state: { item: result.data },
                        });
                      } else {
                        notifyError(result.errMsg);
                      }
                    }}
                  >
                    {getText('WORD_EDIT_TEMPLATE')}
                  </div>
                ) : (
                  <div className="edit-template disabled">
                    {getText('WORD_EDIT_TEMPLATE')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </MyForm>
      </MyRow>
    </div>
  );
};

export default NotificationsListItem;
