import React, { useEffect } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import InputFormText from '../../components/antd/InputFormText';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import MyPanelFooter from '../../components/MyPanelFooter';
import { notifySuccess, notifyError } from '../../components/notify';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import ReasonsActions from '../../actions/ReasonsActions';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import './style.scss';

const ReasonsEdit = (props) => {
  let { _id } = useParams();
  const { isMobile } = useWindowSize();

  const { isNew } = props;

  const [form] = useForm();
  const navigateApi = useNavigateApi();

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await ReasonsActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
          });
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('reasons/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  const onFinish = async (values) => {
    let result = await ReasonsActions.save(values, _id);
    if (result.success) {
      notifySuccess(
        isNew
          ? getText('NOTE_REASON_CREADE_SUCCESS')
          : getText('NOTE_REASON_UPDATE_SUCCESS')
      );
      navigateApi('reasons/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock
        {...props}
        title={
          isNew
            ? getText('WORD_REASON_NEW_TITLE')
            : getText('WORD_REASON_UPDATE_TITLE')
        }
      >
        <MyForm
          name="reasonsForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{
            reasonTitle: '',
          }}
        >
          <InputFormText
            name="reasonTitle"
            label={getText('WORD_REASON_TITLE')}
            required
          />
          <MyPanelFooter
            onCancelClick={() => {
              navigateApi('reasons/list');
            }}
            formName={'reasonsForm'}
            labelPrimary={
              isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
            }
            labelCancel={getText('WORD_CANCEL')}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default ReasonsEdit;
