import React, { useState, useEffect, useRef, useCallback } from 'react';
import PolygonsActions from '../../actions/PolygonsActions';
import UtilDate from '../../utils/utilDate';
import MyMap from '../../components/MyMap';
import MyBlock from '../../components/MyBlock';
import HeaderSpace from '../../components/HeaderSpace';
import SelectPolygon from '../../components/selects/SelectPolygon';
import MyDatePickerRange from '../../components/antd/MyDatePickerRange';
import DraggableOrderList from './DraggableOrderList';
import InputSearch from '../../components/antd/InputSearch';
import getText from '../../lang';
import SelectBranch from '../../components/selects/SelectBranch';
import { useParams } from 'react-router-dom';
import useWindowSize from '../../utils/useWindowSize';
import { useSelector } from 'react-redux';
import ApiStorage from '../../reducers/ApiStorage';
import utilDate from '../../utils/utilDate';
import useIsMounted from '@utilityjs/use-is-mounted';
import './style.scss';

const AssignPage = (props) => {
  const { isNew } = props;

  let { _id } = useParams();
  const { isDesktop } = useWindowSize();

  const isMounted = useIsMounted();

  const menuOpen = useSelector((state) => state.AuthReducer?.menuOpen);

  const orderRef = useRef(null);

  const refContainerMap = useRef(null);
  const [MyYmaps, setMyYmaps] = useState();
  const [listAddresses, setlistAddresses] = useState([]);

  const filter = useSelector((state) => {
    const sta = state.AuthReducer.filterAssign;
    if (sta.dateRange) {
      sta.dateRange = [
        utilDate.getDate(sta.dateRange[0]),
        utilDate.getDate(sta.dateRange[1]),
      ];
      if (sta.fromDate) {
        sta.fromDate = utilDate.getDate(sta.fromDate);
      }
      if (sta.toDate) {
        sta.toDate = utilDate.getDate(sta.toDate);
      }
    } else {
      sta.dateRange = [UtilDate.getDateToday(), UtilDate.getDateToday()];
      sta.fromDate = UtilDate.getDateToday();
      sta.toDate = UtilDate.getDateToday();
    }
    return sta;
  });

  const setFilter = (fil) => {
    ApiStorage.setFilterAssign(Object.assign({}, filter, fil));
  };

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      setMyYmaps(ymaps);
    });
  };

  const createMarker = useCallback(
    (address) => {
      if (!MyYmaps) return;
      const MyMapRef = refContainerMap.current;

      var m = new MyYmaps.Placemark(
        [address.toLat, address.toLng],
        {
          balloonContent: `${address.addressTo}`,
          clusterCaption: 'Another one placemark',
          boundsAutoApply: true,
        },
        {
          balloonCloseButton: true,
          hideIconOnBalloonOpen: false,
          boundsAutoApply: true,
        }
      );
      MyMapRef.geoObjects.add(m);
      MyMapRef.setBounds(MyMapRef.geoObjects.getBounds());
      if (MyMapRef.geoObjects?.getLength() === 1) {
        MyMapRef.setZoom(18);
      }
    },
    [MyYmaps]
  );

  const fillFromDBPoligon = useCallback(async () => {
    if (MyYmaps && refContainerMap) {
      const MyMapRef = refContainerMap.current;
      if (MyMapRef) {
        MyMapRef.geoObjects.removeAll();
      }
      let fetchParams = {
        page: 0,
        perPage: 1000,
        sort: {
          createDate: 'DESC',
        },
        filter: {},
        q: '',
      };
      let result = await PolygonsActions.fillPaginationList(fetchParams);
      if (isMounted()) {
        if (result.success) {
          for (const obj of result.data) {
            let styles = JSON.parse(obj.styles);
            let poly = new MyYmaps.Polygon(
              [JSON.parse(obj.coordinates), []],
              {
                hintContent: obj.name,
              },
              {
                strokeWidth: 3,
                strokeColor: styles.color,
                fillColor: styles.color,
                opacity: 0.2,
              }
            );
            Object.assign(obj, {
              styles: styles,
              polygon: poly,
            });
            MyMapRef && MyMapRef.geoObjects.add(obj.polygon);
          }
        }
      }
    }
  }, [refContainerMap, MyYmaps, isMounted]);

  useEffect(() => {
    fillFromDBPoligon();
    if (listAddresses) {
      for (const ad of listAddresses) {
        createMarker(ad);
      }
    }
  }, [listAddresses, createMarker, fillFromDBPoligon]);

  return (
    <>
      <HeaderSpace className="assign-page">
        <InputSearch
          placeholder={getText('WORD_SEARCH')}
          defaultValue={filter?.searchName}
          onClear={() => setFilter({ searchName: '' })}
          onPressEnter={(v) => setFilter({ searchName: v })}
        />
        <MyDatePickerRange
          value={filter?.dateRange}
          defaultDateRange={[]}
          onChange={(e) => {
            setFilter({
              dateRange: e,
              fromDate: e && e.length ? e[0] : '',
              toDate: e && e.length ? e[1] : '',
            });
          }}
        />
        <SelectBranch
          value={filter?.idBranch}
          withAll
          onChange={(e) => setFilter({ idBranch: e })}
        />
        <SelectPolygon
          value={filter?.idPolygon}
          withAll
          onChange={(e) => setFilter({ idPolygon: e })}
        />
      </HeaderSpace>
      <MyBlock className="assign-list-wrapper" topZero bottomZero>
        <div className="my-list-wrapper-row">
          <DraggableOrderList
            ref={orderRef}
            _id={_id}
            isNew={isNew}
            onChangeList={(list) => {
              setlistAddresses(list);
            }}
          />
        </div>
        <div
          className={`assign-map-wrapper ${
            menuOpen && isDesktop ? 'menu-open-for-mapp' : ''
          }`}
        >
          <MyMap
            ref={refContainerMap}
            onLoadMap={onLoadMap}
            height={'calc(100vh - 90px'}
          />
        </div>
      </MyBlock>
    </>
  );
};

export default AssignPage;
