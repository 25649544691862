import { Slider } from 'antd';
import React from 'react';

const MySlider = (props) => {
  const {
    sliderLabel,
    value,
    min,
    max,
    onChange,
    onAfterChange,
    defaultValue,
    tooltipVisible,
    style,
  } = props;
  return (
    <div style={style}>
      <h4>{sliderLabel}</h4>
      <Slider
        range
        value={value}
        min={min}
        max={max}
        tooltipOpen={tooltipVisible}
        onChange={onChange}
        onAfterChange={onAfterChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default MySlider;
