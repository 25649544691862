import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import getText from '../../lang';
import MainPage from '../../components/MainPage';
import NotificationsListItem from './NotificationsListItem';
import NotificationsActions from '../../actions/NotificationsActions';
import { SELECT_UNSELECT_NONE, TABLE_FETCH_PAGE_SIZE } from '../../utils';
import MyGrid from '../../components/antd/MyGrid';
import useWindowSize from '../../utils/useWindowSize';
import './style.scss';

const NotificationsSettings = (props) => {
  const { isMobile } = useWindowSize();

  const [loadingData, setLoadingData] = useState(true);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [searchName] = useState('');
  const [selectedStatus] = useState(SELECT_UNSELECT_NONE);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {},
        filter: {
          status: selectedStatus,
        },
        q: searchName || '',
      };
      let result = await NotificationsActions.fillPaginationList(fetchParams);
      if (result.success) {
        setListData(result.data);
        setTotalCount(result.totalRows);
      }
      setLoadingData(false);
    };
    fill();
  }, [selectedStatus, searchName, page, perPage]);

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MainPage
        {...props}
        title={getText('WORD_CUSTOMER_NOTIFICATIONS')}
        isMobile
      >
        <MyGrid
          loading={loadingData}
          data={listData}
          pagination={true}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          listView
          renderItem={(item) => {
            return (
              <NotificationsListItem
                {...props}
                key={JSON.stringify(item)}
                item={item}
                onChange={(data) => {
                  let oo = listData.map((obj) => {
                    if (obj.idKey === data.idKey) {
                      obj.useEmail = data.useEmail;
                      obj.useSms = data.useSms;
                    }
                    return obj;
                  });
                  setListData(oo);
                }}
              />
            );
          }}
        />
      </MainPage>
    </>
  );
};

export default NotificationsSettings;
