import React, { memo } from 'react';
import { Timeline, Collapse } from 'antd';
import OrderGreenDone from '../icons/svg/OrderGreenDone';
import { formatDateTime, formatPhoneNumber } from '../utils';
import getText from '../lang';
import OrderActions from '../actions/OrderActions';
import { PhoneOutlined, ContainerOutlined } from '@ant-design/icons';

const OrderTimeLine = (props) => {
  const { item } = props;
  const { itemsLog } = item;

  const RenderCollapseContent = ({ itemTim }) => {
    let key = itemTim.idOrdersLogs + '_' + itemTim.name;
    return (
      <Collapse
        defaultActiveKey={[key]}
        collapsible="icon"
        ghost
        expandIconPosition="end"
        items={[
          {
            key: key,
            label: (
              <p>
                {Number(itemTim?.actionKey) ===
                OrderActions.ORDERLOGACTIONKEY.STEP
                  ? itemTim?.name
                  : getText(itemTim?.name)}{' '}
                {itemTim?.status ? `- ${formatDateTime(itemTim?.date)}` : ''}
              </p>
            ),
            children: (
              <>
                <p>
                  <PhoneOutlined />
                  {formatPhoneNumber(itemTim?.phoneCode, itemTim?.phoneNumber)}
                </p>
                {itemTim?.note ? (
                  <p>
                    <ContainerOutlined />
                    {itemTim?.note}
                  </p>
                ) : null}
              </>
            ),
          },
        ]}
      />
    );
  };

  return (
    <div className="order-timeline-wrapper">
      <div className="timeline-header">{getText('WORD_TIMELINE')}</div>
      <Timeline
        items={itemsLog?.map((tim, index) => {
          return {
            key: tim.idOrdersLogs,
            children: (
              <div
                className={
                  tim.status ? 'timeline-done' : 'timeline-progressing'
                }
                dot={tim.status ? <OrderGreenDone /> : null}
              >
                {Boolean(tim.phoneNumber || tim.note) ? (
                  <RenderCollapseContent itemTim={tim} index={index} />
                ) : (
                  <>
                    <p className="main-info">
                      {Number(tim.actionKey) ===
                      OrderActions.ORDERLOGACTIONKEY.STEP
                        ? tim.name
                        : getText(tim.name)}{' '}
                      {tim.status ? `- ${formatDateTime(tim.date)}` : ''}
                    </p>
                    <div className="timeline-info-items"></div>
                  </>
                )}
              </div>
            ),
          };
        })}
      />
    </div>
  );
};

export default memo(OrderTimeLine);
