import React from 'react';
import { Button } from 'antd';
import MyRow from '../components/MyRow';
import MyBlock from '../components/MyBlock';
import HeaderSpace from '../components/HeaderSpace';
import useNavigateApi from '../AppRoute/useNavigateApi';

const NoAccessPage = (props) => {
  const navigateApi = useNavigateApi();

  return (
    <MyBlock>
      <HeaderSpace />
      <div className="no-access-page-content">
        <img
          src="https://www.orthodoxlistening.com/wp-content/uploads/2012/03/halt-247x300.jpg"
          alt="no-access"
          width={100}
        />
        No Access
      </div>
      <MyRow>
        <Button
          type="primary"
          onClick={() => {
            navigateApi('login');
          }}
        >
          Login
        </Button>
        <Button
          onClick={() => {
            navigateApi(-1);
          }}
        >
          Back
        </Button>
      </MyRow>
    </MyBlock>
  );
};

export default NoAccessPage;
