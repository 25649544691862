import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_GET_USER_LIST = '/dashboards/get_drivers_list';
const URL_GET_DRIVER_LIST = '/dashboards/get_driver_info';
const URL_GET_DRIVERS_LAST_LOCATION = '/dashboards/get_drivers_last_locations';

const fs = {
  getUserList: async (status) => {
    const urlParams = [
      {
        key: 'driver',
        value: status,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(URL_GET_USER_LIST, urlParams);
    return result;
  },
  getDriverList: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_DRIVER_LIST + '/' + idKey,
      urlParams
    );
    return result;
  },
  getDriversCurrentLocation_Dashboard: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_DRIVERS_LAST_LOCATION,
      urlParams
    );
    return result;
  },
};

const loc = {};

const DashboardActions = Object.assign(fs, loc);

export default DashboardActions;
