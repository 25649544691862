import { Empty } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { notifyError } from '../../components/notify';
import DashboardCustomerBlockItem from './DashboardCustomerBlockItem';
import DashboardIcon from '../../icons/svg/DashboardIcon';
import getText from '../../lang';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import UserActions from '../../actions/UserActions';

const DashboardLeft = forwardRef((props, ref) => {
  const [selectedStatus, setSelectedStatus] = useState('online');
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { onClickOpenMap, selectedItemActive, onChange } = props;

  useImperativeHandle(ref, () => ({
    refreshUser(obj) {
      let list;
      if (selectedStatus === 'all') {
        list = listData.map((oo) => {
          if (oo.idUser === obj.idUser) {
            return obj;
          }
          return oo;
        });
        // } else if (selectedStatus === "available") {
        //   if (obj.driverIsOnline && obj.orderInprogressCount === 0) {
        //     list = [...listData];
        //     let index = listData.findIndex((oo) => {
        //       return oo.idUser === obj.idUser;
        //     });
        //     if (index === -1) {
        //       list.push(obj);
        //     }
        //   } else {
        //     list = listData.filter((oo) => {
        //       return oo.idUser !== obj.idUser;
        //     });
        //   }
      } else {
        if (obj.driverIsOnline) {
          list = [...listData];
          let index = listData.findIndex((oo) => {
            return oo.idUser === obj.idUser;
          });
          if (index === -1) {
            list.push(obj);
          }
        } else {
          list = listData.filter((oo) => {
            return oo.idUser !== obj.idUser;
          });
        }
      }
      setListData(list);
    },
  }));

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      let fetchParams = {
        page: 0,
        perPage: 1000,
        sort: {},
        filter: {
          // status: '',
          // idTransportType: '',
        },
        q: '',
      };
      let resultUsers = await UserActions.getDriversListResult(fetchParams);
      if (resultUsers.success) {
        if (selectedStatus === 'online') {
          setListData(resultUsers.data.filter((us) => us.driverIsOnline));
        } else {
          setListData(resultUsers.data);
        }
      } else {
        notifyError(resultUsers.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [selectedStatus]);

  return (
    <div className="dashboard-left-side-wrapper">
      <div className="dashbaord-customer-activity-with-map">
        <div className="dashbaord-customer-activity">
          <div
            className={
              'dashbaord-customer-activity-item ' +
              (selectedStatus === 'online' && 'active')
            }
            onClick={() => {
              setSelectedStatus('online');
              onChange && onChange('');
            }}
          >
            {getText('WORD_DASHBOARD_LEFT_TAB_ONLINE')}
          </div>
          {/* <div
            className={
              "dashbaord-customer-activity-item " +
              (selectedStatus === "available" && "active")
            }
            onClick={() => {
              setSelectedStatus("available");
            }}
          >
            {getText("WORD_DASHBOARD_LEFT_TAB_AVAILABLE")}
          </div> */}
          <div
            className={
              'dashbaord-customer-activity-item ' +
              (selectedStatus === 'all' && 'active')
            }
            onClick={() => {
              setSelectedStatus('all');
              onChange && onChange('');
            }}
          >
            {getText('WORD_DASHBOARD_LEFT_TAB_ALL')}
          </div>
        </div>
        <div className="activity-map-for-responsive" onClick={onClickOpenMap}>
          <DashboardIcon />
        </div>
      </div>
      <div className="my-order-list-panel">
        {loading ? (
          <LoadingOutlinedApp />
        ) : listData?.length ? (
          listData.map((item, key) => {
            return (
              <DashboardCustomerBlockItem
                key={key}
                item={item}
                selectedItemActive={selectedItemActive}
                onClick={(activeItem, item) => {
                  onChange && onChange(activeItem, item);
                }}
              />
            );
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
});

export default DashboardLeft;
