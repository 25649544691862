import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getText from '../../lang';
import OrderReviewActions from '../../actions/OrderReviewActions';
import MyRate from '../../components/antd/MyRate';
import InputFormTextArea from '../../components/antd/InputFormTextArea';
import MyForm from '../../components/MyForm';
import { Button, Form } from 'antd';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import './RatePage.scss';

const RatePage = (props) => {
  const { _IdKeyClient, _idKeyOrrderRate } = useParams();

  const navigateApi = useNavigateApi();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [canReview, setCanReview] = useState(false);

  const [company, setCompany] = useState({});

  useEffect(() => {
    const getOne = async () => {
      if (_IdKeyClient && _idKeyOrrderRate) {
        setLoading(true);
        let resultGetOne = await OrderReviewActions.getOneByidKey(
          _IdKeyClient,
          _idKeyOrrderRate
        );
        setLoading(false);
        if (resultGetOne.success) {
          if (resultGetOne.company) {
            setCompany(resultGetOne.company);
          }
          if (resultGetOne.data.idKey) {
            setCanReview(false);
            form.setFieldsValue(resultGetOne.data);
          } else {
            setCanReview(true);
          }
        } else {
          navigateApi('error_page_401');
        }
      }
    };

    getOne();
  }, [form, _IdKeyClient, _idKeyOrrderRate, navigateApi]);

  const onFinish = async (values) => {
    await OrderReviewActions.save(values, _IdKeyClient, _idKeyOrrderRate);
    window.location.reload();
  };

  return loading ? (
    <LoadingOutlinedApp />
  ) : (
    <div className="rate-page-wrapper">
      {!company.icon ? null : (
        <div className="rate-page-header">
          <div className="header-logo">
            <img src={company?.icon} alt="company-logo" />
          </div>
        </div>
      )}

      <div className="rate-page-body">
        <MyForm
          form={form}
          name="fff"
          onFinish={onFinish}
          initialValues={{
            rating: '',
            message: '',
          }}
        >
          <div className="rate-page-body-block-wrapper">
            <label className="rate-label">
              {getText('WORD_EVALUATE_YOUR_EXPERIENCE')}
            </label>
            <MyRate
              name="rating"
              required
              disabled={!canReview}
              hasFeedback={false}
            />
            <label className="rate-label">
              {getText('WORD_TELL_US_ABOUT_YOUR_EXPERIENCE')}
            </label>
            {!canReview ? (
              <>
                <div className="cant-review">{getText('WORD_REVIEW')}</div>
                <InputFormTextArea
                  name="message"
                  minRows={4}
                  // placeholder={getText("WORD_TELL_US_ABOUT_YOUR_EXPERIENCE")}
                  placeholder={''}
                  hasFeedback={false}
                  readOnly={!canReview}
                />
              </>
            ) : (
              <>
                <InputFormTextArea
                  name="message"
                  minRows={4}
                  // placeholder={getText("WORD_TELL_US_ABOUT_YOUR_EXPERIENCE")}
                  placeholder={''}
                  hasFeedback={false}
                />
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  htmlType="submit"
                >
                  {getText('WORD_POST')}
                </Button>
              </>
            )}
          </div>
        </MyForm>
      </div>
    </div>
  );
};

export default RatePage;
