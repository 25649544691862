import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InputFormPassword from '../../components/antd/InputFormPassword';
import MyForm from '../../components/MyForm';
import { Button } from 'antd';
import MyBlock from '../../components/MyBlock';
import MyRow from '../../components/MyRow.js';
import UserActions from '../../actions/UserActions';
import { notifyError } from '../../components/notify';
import getText from '../../lang';
import InputFormText from '../../components/antd/InputFormText';
import ApiStorage from '../../reducers/ApiStorage';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import MyCaptcha from '../../components/Captcha/MyCaptcha';
import ApiLocalStorage from '../../reducers/ApiLocalStorage';
import md5 from 'md5';
import CheckboxForm from '../../components/antd/CheckboxForm';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import UtilObject from '../../utils/UtilObject';
import './style.scss';

const Login = (props) => {
  const navigateApi = useNavigateApi();

  useEffect(() => {
    ApiStorage.setUser(null);
  }, []);

  const [saving, setSaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [captchaCheck, setcaptchaCheck] = useState(false);

  const handleLoginIn = useCallback(
    async (values) => {
      setSaving(true);
      let result = await UserActions.login(values);
      setSaving(false);
      if (result.success) {
        navigateApi('home');
      } else {
        notifyError(result.errMsg);
        setloading(false);
      }
    },
    [navigateApi]
  );

  useEffect(() => {
    const get = async () => {
      setloading(true);
      let obj = ApiLocalStorage.getItem(ApiLocalStorage.TEMP_REMEMBER_ME);
      if (!UtilObject.isEmpty(obj)) {
        handleLoginIn(obj);
      } else {
        setloading(false);
      }
    };
    get();
  }, [handleLoginIn]);

  const onFinish = async (values) => {
    values.password = md5(values.password);
    values.isGuest = false;
    if (values.remenberMe) {
      ApiLocalStorage.setItem(ApiLocalStorage.TEMP_REMEMBER_ME, values);
    }
    await handleLoginIn(values);
  };

  if (loading) {
    return <LoadingOutlinedApp />;
  }

  return (
    <div className="login-page-wrapper">
      <MyBlock title={getText('WORD_LOGIN')}>
        <MyForm
          name="loginForm"
          onFinish={onFinish}
          initialValues={{
            remenberMe: true,
          }}
        >
          <InputFormText
            name="login"
            placeholder={getText('WORD_USERNAME_OR_EMAIL')}
            message={getText('WORD_PLEASE_INPUT_USERNAME')}
            minLength={3}
            required
          />
          <InputFormPassword
            name="password"
            placeholder={getText('WORD_PASSWORD')}
            message={'WORD_PLEASE_INPUT_PASSWORD'}
          />
          <MyRow>
            <CheckboxForm
              name="remenberMe"
              label={getText('WORD_REMEMBER_ME')}
            />
            <div />
            <Link to="/en/forgot_password/request">
              {getText('WORD_FORGOT_PASSWORD')}
            </Link>
          </MyRow>
          <Button
            type="primary"
            htmlType="submit"
            form={'loginForm'}
            loading={saving}
            disabled={!captchaCheck}
            style={{ width: '100%' }}
          >
            {getText('WORD_LOGIN')}
          </Button>
          <MyRow
            title={getText('WORD_DOWNLOAD_DRIVER_APP')}
            className="mobile-apps-list"
          >
            <a
              href="https://apps.apple.com/app/id1661527215"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={window.location.origin + '/images/app-store-badge.png'}
                alt="App store badge"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.declogi"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={window.location.origin + '/images/play-store-badge.png'}
                alt="Play store badge"
              />
            </a>
          </MyRow>
        </MyForm>
        <MyCaptcha
          onChange={(e) => {
            setcaptchaCheck(Boolean(e));
          }}
        />
      </MyBlock>
    </div>
  );
};

export default Login;
