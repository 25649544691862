import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.188"
    height="15.529"
    viewBox="0 0 13.188 15.529"
  >
    <g
      id="Group_8262"
      data-name="Group 8262"
      transform="translate(1086.85 -2499)"
      opacity="0.8"
    >
      <path
        id="Path_5907"
        data-name="Path 5907"
        d="M4.15,9.887v9.52l3.867,1.646V12.223c-.193-.3-.38-.613-.549-.924Z"
        transform="translate(-1091 2493.476)"
        fill="#fff"
      />
      <path
        id="Path_5908"
        data-name="Path 5908"
        d="M21.923,10.669a12.6,12.6,0,0,1-1.023,1.9v7.185L24.767,21.4V11.879Z"
        transform="translate(-1098.429 2493.129)"
        fill="#fff"
      />
      <path
        id="Path_5909"
        data-name="Path 5909"
        d="M14.459,18.565l-.6-.708c-.215-.252-.746-.889-1.331-1.693v7.673l3.868-1.646V16.165c-.585.8-1.115,1.441-1.33,1.693Z"
        transform="translate(-1094.714 2490.692)"
        fill="#fff"
      />
      <path
        id="Path_5910"
        data-name="Path 5910"
        d="M16.673,5.362a3.315,3.315,0,1,0-6.63,0c0,1.831,3.316,5.72,3.316,5.72S16.673,7.193,16.673,5.362Zm-5.2-.112a1.883,1.883,0,1,1,1.883,1.883A1.884,1.884,0,0,1,11.475,5.25Z"
        transform="translate(-1093.614 2496.953)"
        fill="#fff"
      />
    </g>
  </svg>
);

const DashboardIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default DashboardIcon;
