import React from 'react';
import { Form, InputNumber } from 'antd';
import getText from '../../lang';
import UtilNumber from '../../utils/utilNumber';
import './inputs.scss';

const InputFormNumber = (props) => {
  const parseValue = (value) => (value ? parseFloat(value).toFixed(3) : 0);

  const {
    label,
    name,
    hasFeedback,
    placeholder,
    type,
    hidden,
    step,
    min,
    disabled,
    onChange,
    readOnly,
    className,
    required,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: `${getText('WORD_ERROR_PLEASE_INPUT')} ${
            label ? label : placeholder
          }!`,
          type: type,
        },
      ]}
      layout="vertical"
      hidden={hidden}
    >
      <InputNumber
        placeholder={placeholder ? placeholder : label}
        formatter={(value) => UtilNumber.formatDouble(value)}
        parser={parseValue}
        step={step}
        min={min}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        className={className}
        bordered={false}
      />
    </Form.Item>
  );
};

InputFormNumber.defaultProps = {
  name: 'numberValue',
  type: 'number',
  required: false,
  hasFeedback: true,
  allowClear: true,
  min: 0,
  step: 1,
};

export default InputFormNumber;
