import React from 'react';
import getText from '../../lang';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

const InputFormPasswordConfirm = (props) => {
  const {
    label,
    name,
    hasFeedback,
    passwordToConfirm,
    required,
    message,
    placeholder,
    value,
    onChange,
    allowClear,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      dependencies={[passwordToConfirm]}
      rules={[
        { required: required, message: getText(message) },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue(passwordToConfirm) === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              'The two passwords that you entered do not match!'
            );
          },
        }),
      ]}
    >
      <Input.Password
        placeholder={label ? label : placeholder}
        prefix={<LockOutlined />}
        value={value}
        onChange={onChange}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};

InputFormPasswordConfirm.defaultProps = {
  name: 'confirmPassword',
  placeholder: 'Confirm password',
  message: 'WORD_PLEASE_CONFIRM_YOUR_PASSWORD',
  required: true,
  hasFeedback: true,
  allowClear: true,
  passwordToConfirm: 'password',
};

export default InputFormPasswordConfirm;
