import React, { useState } from 'react';
import { formatDateTime, redirectPathNewTab } from '../../utils';
import getText from '../../lang';
import { Button, Rate } from 'antd';
import MyRow from '../../components/MyRow';

const RatingListCard = (props) => {
  const [readMore, setReadMore] = useState(false);

  const { item } = props;
  const messageLength = item?.message?.length > 40;

  return (
    <MyRow withoutTitle className="rating-view-list-item">
      <div className="list-item order-number">
        <div className="list-item-title">{getText('WORD_ORDER_ID')}</div>
        <div
          className="list-item-info order-number"
          onClick={(e) => {
            e.stopPropagation();
            redirectPathNewTab(`orders/view/${item?.idKeyOrder}`);
          }}
        >
          #{item?.apiOrderKey || item?.orderNumber}
        </div>
      </div>
      <div className="list-item order-customer">
        <div className="list-item-title">{getText('WORD_CUSTOMER')}</div>
        <div className="list-item-info order-customer">
          {item?.customerName}
        </div>
      </div>
      <div className="list-item" style={{ width: '15%' }}>
        <div className="list-item-title">{getText('WORD_DISTRIBUTOR')}</div>
        <div className="list-item-info">{item?.driverName}</div>
      </div>
      <div className="list-item" style={{ width: '10%' }}>
        <div className="list-item-title">{getText('WORD_RATING_TITLE')}</div>
        <div className="rate-with-created-date">
          <Rate value={item?.rating} disabled={true} />
        </div>
      </div>
      <div className="list-item" style={{ width: '9%' }}>
        <div className="list-item-title">{getText('WORD_DATE')}</div>
        <div className="list-item-info">{formatDateTime(item?.createDate)}</div>
      </div>
      <div className="list-item list-item-large message">
        <div className="list-item-title">{getText('WORD_REVIEW')}</div>
        <div className="list-item-info-with-read-more">
          <div className={readMore ? 'list-item-all-text' : 'list-item-info'}>
            {item?.message}
          </div>
          {item?.message && messageLength ? (
            <Button
              className="read-more-link"
              type="link"
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {readMore ? getText('WORD_READ_LESS') : getText('WORD_READ_MORE')}
            </Button>
          ) : null}
        </div>
      </div>
    </MyRow>
  );
};

RatingListCard.defaultProps = {
  item: {},
};

export default RatingListCard;
