import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useNavigateApi = () => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.AuthReducer.lang);

  const navigateApi = useCallback(
    (path, options) => {
      if (path === -1) {
        navigate(path, options);
      } else {
        const pathWithLang = `/${lang}/${path}`;
        navigate(pathWithLang, options);
      }
    },
    [lang, navigate]
  );

  return navigateApi;
};

export default useNavigateApi;
