import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import { SELECT_UNSELECT_NONE } from '../utils';

const URL_LIST = '/currencies/get_all';

const fs = {
  getFullList: async () => {
    const urlParams = [];
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result.success ? result.data : [];
  },
};

const loc = {
  getFullListOptions: async (
    firstOption = { value: SELECT_UNSELECT_NONE, label: 'Select' }
  ) => {
    let items = await fs.getFullList();
    const options = items.map((obj) => {
      return { value: obj.idCurrency, label: obj.currencyName };
    });
    if (firstOption) {
      options.unshift(firstOption);
    }
    return options;
  },
};

const CurrencyActions = Object.assign(fs, loc);

export default CurrencyActions;
