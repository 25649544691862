import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.799"
    height="11.215"
    viewBox="0 0 14.799 11.215"
  >
    <g
      id="Group_8212"
      data-name="Group 8212"
      transform="translate(-8.667 -19.333)"
    >
      <path
        id="Path_5891"
        data-name="Path 5891"
        d="M23.466,20.411a1.078,1.078,0,0,1-1.078,1.078H9.745a1.078,1.078,0,0,1-1.078-1.078h0a1.078,1.078,0,0,1,1.078-1.078H22.388a1.078,1.078,0,0,1,1.078,1.078Z"
        transform="translate(0 0)"
        fill="#474657"
      />
      <path
        id="Path_5892"
        data-name="Path 5892"
        d="M29.886,45.714a1.078,1.078,0,0,1-1.078,1.078H19.745a1.078,1.078,0,0,1-1.078-1.078h0a1.078,1.078,0,0,1,1.078-1.078h9.063a1.078,1.078,0,0,1,1.078,1.078Z"
        transform="translate(-8.21 -20.773)"
        fill="#474657"
      />
      <path
        id="Path_5893"
        data-name="Path 5893"
        d="M39.515,71.016a1.078,1.078,0,0,1-1.078,1.078H34.745a1.078,1.078,0,0,1-1.078-1.078h0a1.078,1.078,0,0,1,1.078-1.078h3.692a1.078,1.078,0,0,1,1.078,1.078Z"
        transform="translate(-20.524 -41.546)"
        fill="#474657"
      />
    </g>
  </svg>
);

const FilterIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default FilterIcon;
