import React, { useCallback, useState, useEffect, forwardRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import history from '../../utils/history';
import UtilNumber from '../../utils/utilNumber';
import getText from '../../lang';
import ManifestActions from '../../actions/ManifestActions';
import OrderActions from '../../actions/OrderActions';
import SelectUserDrivers from '../../components/selects/SelectUserDrivers';
import MyTextWithBadge from '../../components/myTextWithBadge/MyTextWithBadge';
import { notifyError, notifySuccess } from '../../components/notify';
import MyPanelFooter from '../../components/MyPanelFooter';
import MyCard from '../../components/MyCard';
import MyRow from '../../components/MyRow';
import TruckIcon from '../../icons/svg/TruckIcon';
import { Button, Checkbox } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import './style.scss';
import { useSelector } from 'react-redux';
import utilDate from '../../utils/utilDate';

const columnsFromBackend = {
  penddingList: {
    title: 'WORD_PENDING_WAITING',
    totalCount: 0,
    loading: false,
    items: [],
  },
  assignedList: {
    title: 'WORD_FREIGHTS',
    totalCount: 0,
    loading: false,
    items: [],
  },
};

const onDragEnd = (result, columns, setColumns, cb) => {
  if (!result.destination) return;
  const { source, destination } = result;
  let col;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    col = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
        totalCount: sourceItems.length,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
        totalCount: destItems.length,
      },
    };
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    col = {
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
        totalCount: copiedItems.length,
      },
    };
  }
  setColumns(col);
  cb && cb(col);
};

const DraggableOrderList = forwardRef((props, ref) => {
  const navigateApi = useNavigateApi();

  const { onChangeList, _id, isNew } = props;
  const [columns, setColumns] = useState(columnsFromBackend);
  const [penddingRsultList, setpenddingRsultList] = useState([]);

  const [totalKG, settotalKG] = useState(0);
  const [totalM3, settotalM3] = useState(0);

  const [driverKG, setdriverKG] = useState(0);
  const [driverM3, setdriverM3] = useState(0);

  const [selectedDriver, setselectedDriver] = useState();
  const [saving, setSaving] = useState(false);

  const [selectedPenddingListItemsIds, setSelectedPenddingListItemsIds] =
    useState([]);
  const [selectedAssignedListItemsIds, setSelectedAssignedListItemsIds] =
    useState([]);

  const filter = useSelector((state) => {
    const sta = state.AuthReducer.filterAssign;
    if (sta.dateRange) {
      sta.dateRange = [
        utilDate.getDate(sta.dateRange[0]),
        utilDate.getDate(sta.dateRange[1]),
      ];
      if (sta.fromDate) {
        sta.fromDate = utilDate.getDate(sta.fromDate);
      }
      if (sta.toDate) {
        sta.toDate = utilDate.getDate(sta.toDate);
      }
    }
    return sta;
  });

  // useImperativeHandle(ref, () => ({
  //   listFilter: (search, filter) => {
  //     fillList(search);
  //     fill(search, filter);
  //   },
  // }));

  const fillList = useCallback(
    async (search) => {
      const fillBy_idManifest = async (idManifest) => {
        let fetchParams = {
          page: 0,
          perPage: 1000,
          sort: {},
          filter: {
            idManifest: idManifest,
          },
          q: search || '',
        };
        setColumns((oldV) => {
          oldV.assignedList.loading = true;
          return { ...oldV };
        });
        let resultAssignedList = await OrderActions.fillPaginationList(
          fetchParams
        );

        if (resultAssignedList.success) {
          setColumns((oldV) => {
            Object.assign(oldV, {
              assignedList: {
                ...oldV.assignedList,
                items: resultAssignedList.data,
                loading: false,
                totalCount: resultAssignedList.totalRows,
                totals: resultAssignedList.totals,
              },
            });
            return { ...oldV };
          });
        }
      };

      const getObj = async () => {
        if (_id) {
          let resultGet = await ManifestActions.getOneByidKey(_id);
          if (resultGet.success) {
            setselectedDriver({ idUser: resultGet.data.idUserDriver });
            setdriverKG(resultGet.data.transportKg);
            setdriverM3(resultGet.data.transportM3);
            await fillBy_idManifest(resultGet.data.idManifest);
          } else {
            notifyError(resultGet.errMsg);
          }
        }
      };
      getObj();
    },
    [_id]
  );

  useEffect(() => {
    fillList();
  }, [fillList]);

  useEffect(() => {
    const driver = history.location.state && history.location.state.driver;
    if (driver) {
      setselectedDriver(driver);
      setdriverKG(driver.transportKg);
      setdriverM3(driver.transportM3);
    }
  }, []);

  const fill = useCallback(async () => {
    let fetchParams = {
      page: 0,
      perPage: 500,
      sort: {
        'o.deliveryDate': 'ASC',
      },
      filter: {
        ...filter,
        status: OrderActions.STATUS.ACTIVE,
        assignedUnassigned: 'unassigned',
        hasNoManifest: true,
      },
      q: filter.searchName || '',
    };
    setColumns((oldV) => {
      oldV.penddingList.loading = true;
      return { ...oldV };
    });
    let result = await OrderActions.fillPaginationList(fetchParams);
    if (result.success) {
      setpenddingRsultList(result.data);
      setColumns((oldV) => {
        Object.assign(oldV, {
          penddingList: {
            ...oldV.penddingList,
            items: result.data,
            loading: false,
            totalCount: result.totalRows,
            totals: result.totals,
          },
        });
        return { ...oldV };
      });
    } else {
      notifyError(result.errMsg);
    }
  }, [filter]);

  useEffect(() => {
    fill();
  }, [fill]);

  useEffect(() => {
    const calcTotals = () => {
      let totalKG = 0;
      let totalM3 = 0;
      for (const it of columns.assignedList.items) {
        totalKG += it.sizeWeight;
        totalM3 += it.sizeM3;
      }
      settotalKG(totalKG);
      settotalM3(totalM3);
    };
    calcTotals();
  }, [columns]);

  useEffect(() => {
    onChangeList && onChangeList(penddingRsultList);
  }, [penddingRsultList, onChangeList]);

  const redirectToBack = () => {
    navigateApi('manifest/list');
  };

  const getObjectToSend = () => {
    let objToSend = {
      idUserDriver: selectedDriver.idUser,
      sizeM3: totalM3,
      sizeKg: totalKG,
      idOrders: columns.assignedList.items.map((oo) => {
        return { idOrder: oo.idOrder };
      }),
    };
    return objToSend;
  };

  const handleSave = async () => {
    if (!selectedDriver || !selectedDriver.idUser) {
      notifyError(getText('WORD_ERROR_SELECT_DRIVER'));
      return;
    }
    setSaving(true);
    let objToSend = getObjectToSend();
    let resultSave = await OrderActions.assignRoute(objToSend, _id);
    setSaving(false);
    if (resultSave.success) {
      notifySuccess(getText('WORD_ORDERS_ASSIGNED_SUCCESSFULY'));
      if (isNew) {
        if (resultSave.data.idKey) {
          navigateApi(`manifest/edit/${resultSave.data.idKey}`);
        }
      }
    } else {
      notifyError(resultSave.errMsg);
    }
  };

  const handleSaveAndSend = async () => {
    if (!selectedDriver || !selectedDriver.idUser) {
      notifyError(getText('WORD_ERROR_SELECT_DRIVER'));
      return;
    }
    setSaving(true);
    let objToSend = getObjectToSend();
    let resultSave = await OrderActions.assignRouteAndSend(objToSend, _id);
    setSaving(false);
    if (resultSave.success) {
      notifySuccess(getText('WORD_ORDERS_ASSIGNED_AND_SEND_SUCCESSFULY'));
      redirectToBack();
    } else {
      notifyError(resultSave.errMsg);
    }
  };

  const getNegativeOrPositive = () => {
    let number = parseInt(UtilNumber.formatDouble(driverKG - totalKG));
    if (number > 0) {
      return 'positive';
    } else if (number < 0) {
      return 'negative';
    } else {
      return '';
    }
  };

  const handleMyCardClick = (e, it, isAssignList) => {
    e.persist();
    e.stopPropagation();
    if (it.idKey) {
      if (isAssignList) {
        setSelectedPenddingListItemsIds([]);
        setSelectedAssignedListItemsIds((oldV) => {
          if (e.ctrlKey) {
            if (!oldV.includes(it.idKey)) {
              return [...oldV, it.idKey];
            } else {
              return oldV.filter((oo) => {
                return oo !== it.idKey;
              });
            }
          } else {
            if (oldV.includes(it.idKey)) {
              return [];
            }
            return [it.idKey];
          }
        });
      } else {
        setSelectedAssignedListItemsIds([]);
        setSelectedPenddingListItemsIds((oldV) => {
          if (e.ctrlKey) {
            if (!oldV.includes(it.idKey)) {
              return [...oldV, it.idKey];
            } else {
              return oldV.filter((oo) => {
                return oo !== it.idKey;
              });
            }
          } else {
            if (oldV.includes(it.idKey)) {
              return [];
            }
            return [it.idKey];
          }
        });
      }
    }
  };

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <DragDropContext
        onDragStart={(result) => {
          if (result.source.droppableId === 'assignedList') {
            setSelectedPenddingListItemsIds([]);
            setSelectedAssignedListItemsIds([result.draggableId]);
          } else {
            setSelectedPenddingListItemsIds([result.draggableId]);
            setSelectedAssignedListItemsIds([]);
          }
        }}
        onDragEnd={(result) => {
          onDragEnd(result, columns, setColumns, () => {
            if (result.source.droppableId === 'assignedList') {
              setSelectedPenddingListItemsIds([result.draggableId]);
              setSelectedAssignedListItemsIds([]);
            } else {
              setSelectedPenddingListItemsIds([]);
              setSelectedAssignedListItemsIds([result.draggableId]);
            }
          });
        }}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          let isAssignList = Boolean(columnId === 'assignedList');
          return (
            <div key={columnId} className="my-list-panel-wrapper">
              <MyRow
                className={`list-panel-title ${
                  isAssignList ? 'with-select' : ''
                }`}
                withoutTitle
              >
                <div className="list-panel-title-left-content">
                  <div className="title">{getText(column.title)}</div>
                  {!isAssignList && (
                    <div className="header-value-kg">
                      {UtilNumber.formatDouble(
                        column.totals?.sizeWeightTotal || 0
                      )}{' '}
                      {getText('WORD_KG_LC')}
                    </div>
                  )}
                  <div className="count">{column.totalCount}</div>
                </div>
                {isAssignList && (
                  <SelectUserDrivers
                    value={selectedDriver && selectedDriver.idUser}
                    placeholder={getText('WORD_SELECT_DRIVER')}
                    onChangeItem={(driver) => {
                      setselectedDriver(driver);
                      setdriverKG(driver.transportKg);
                      setdriverM3(driver.transportM3);
                    }}
                  />
                )}
              </MyRow>
              <div className="my-list-checked-wrapper">
                <div className="list-checked-item">
                  <Checkbox
                    indeterminate={
                      isAssignList
                        ? selectedAssignedListItemsIds.length > 0 &&
                          selectedAssignedListItemsIds.length !==
                            column.items.length
                        : selectedPenddingListItemsIds.length > 0 &&
                          selectedPenddingListItemsIds.length !==
                            column.items.length
                    }
                    checked={
                      isAssignList
                        ? selectedAssignedListItemsIds.length > 0 &&
                          selectedAssignedListItemsIds.length ===
                            column.items.length
                        : selectedPenddingListItemsIds.length > 0 &&
                          selectedPenddingListItemsIds.length ===
                            column.items.length
                    }
                    onChange={(e) => {
                      let checked = e.target.checked;
                      if (isAssignList) {
                        if (checked) {
                          setSelectedAssignedListItemsIds(
                            column.items.map((item) => {
                              return item.idKey;
                            })
                          );
                        } else {
                          setSelectedAssignedListItemsIds([]);
                        }
                      } else {
                        if (checked) {
                          setSelectedPenddingListItemsIds(
                            column.items.map((item) => {
                              return item.idKey;
                            })
                          );
                        } else {
                          setSelectedPenddingListItemsIds([]);
                        }
                      }
                    }}
                  >
                    {`${getText('WORD_CHOOSEN')}  
                                        ${
                                          isAssignList
                                            ? selectedAssignedListItemsIds.length
                                            : selectedPenddingListItemsIds.length
                                        } `}
                  </Checkbox>
                  <Button
                    className="checked-button"
                    type="primary"
                    disabled={
                      isAssignList
                        ? !selectedAssignedListItemsIds.length
                        : !selectedPenddingListItemsIds.length
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (isAssignList) {
                        setColumns((oldV) => {
                          Object.assign(oldV, {
                            penddingList: {
                              ...oldV.penddingList,
                              items: [
                                ...oldV.penddingList.items,
                                ...oldV.assignedList.items.filter((oo) =>
                                  selectedAssignedListItemsIds.includes(
                                    oo.idKey
                                  )
                                ),
                              ],
                              loading: false,
                            },
                            assignedList: {
                              ...oldV.assignedList,
                              items: oldV.assignedList.items.filter(
                                (oo) =>
                                  !selectedAssignedListItemsIds.includes(
                                    oo.idKey
                                  )
                              ),
                              loading: false,
                            },
                          });
                          return { ...oldV };
                        });
                        setSelectedPenddingListItemsIds(
                          selectedAssignedListItemsIds
                        );
                        setSelectedAssignedListItemsIds([]);
                      } else {
                        setColumns((oldV) => {
                          Object.assign(oldV, {
                            assignedList: {
                              ...oldV.assignedList,
                              items: [
                                ...oldV.assignedList.items,
                                ...oldV.penddingList.items.filter((oo) =>
                                  selectedPenddingListItemsIds.includes(
                                    oo.idKey
                                  )
                                ),
                              ],
                              loading: false,
                            },
                            penddingList: {
                              ...oldV.penddingList,
                              items: oldV.penddingList.items.filter(
                                (oo) =>
                                  !selectedPenddingListItemsIds.includes(
                                    oo.idKey
                                  )
                              ),
                              loading: false,
                            },
                          });
                          return { ...oldV };
                        });
                        setSelectedAssignedListItemsIds(
                          selectedPenddingListItemsIds
                        );
                        setSelectedPenddingListItemsIds([]);
                      }
                      setColumns((oldV) => {
                        oldV.assignedList.totalCount =
                          oldV.assignedList.items.length;
                        oldV.penddingList.totalCount =
                          oldV.penddingList.items.length;
                        return { ...oldV };
                      });
                    }}
                  >
                    {isAssignList ? (
                      <ArrowLeftOutlined />
                    ) : (
                      <ArrowRightOutlined />
                    )}
                  </Button>
                </div>
              </div>
              <Droppable droppableId={columnId} key={columnId}>
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? '#0076ff1a'
                          : 'transparent',
                      }}
                      className={`dropabel-list-item ${
                        isAssignList ? 'with-footer' : ''
                      }`}
                    >
                      {column.loading ? (
                        <LoadingOutlinedApp />
                      ) : (
                        column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.idKey}
                              draggableId={item.idKey}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',
                                      backgroundColor: snapshot.isDragging
                                        ? 'transparent'
                                        : 'transparent',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <MyCard
                                      key={`${index + '_' + item.idKey}`}
                                      className={
                                        Boolean(
                                          selectedAssignedListItemsIds.includes(
                                            item.idKey
                                          ) ||
                                            selectedPenddingListItemsIds.includes(
                                              item.idKey
                                            )
                                        ) && 'active'
                                      }
                                      item={item}
                                      canSplit={!isAssignList}
                                      onSave={() => {
                                        fill();
                                      }}
                                      onClick={(e, it) =>
                                        handleMyCardClick(e, it, isAssignList)
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })
                      )}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
              {isAssignList && (
                <div className="my-list-panel-footer">
                  <MyRow className="my-list-item-product">
                    <MyRow withoutTitle className="list-item">
                      <MyRow withoutTitle className="my-list-item-left-item">
                        <TruckIcon />
                        {UtilNumber.formatDouble(driverKG)}
                        {getText('WORD_KG_LC')}
                      </MyRow>
                      <MyRow withoutTitle className="my-list-item-right-item">
                        {UtilNumber.formatDouble(totalKG)}/
                        <span className={getNegativeOrPositive()}>
                          {UtilNumber.formatDouble(driverKG - totalKG)}
                        </span>
                      </MyRow>
                    </MyRow>
                    <div className="line" />
                    <MyRow withoutTitle className="list-item">
                      <MyRow withoutTitle className="my-list-item-left-item">
                        <TruckIcon />
                        {UtilNumber.formatDouble(driverM3)}
                        <MyTextWithBadge
                          mainText={getText('WORD_M3_LC')}
                          topSymbol={3}
                        />
                      </MyRow>
                      <MyRow withoutTitle className="my-list-item-right-item">
                        {UtilNumber.formatDouble(totalM3)}/
                        <span className={getNegativeOrPositive()}>
                          {UtilNumber.formatDouble(driverM3 - totalM3)}
                        </span>
                      </MyRow>
                    </MyRow>
                  </MyRow>
                  <MyPanelFooter
                    labelPrimary={getText('WORD_SEND')}
                    labelCancel={getText('WORD_SAVE')}
                    onPrimaryClick={handleSaveAndSend}
                    hideSave={isNew}
                    onCancelClick={handleSave}
                    loading={saving}
                    loadingCancel={saving}
                  />
                </div>
              )}
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
});

export default DraggableOrderList;
