import React from 'react';
import MyRow from '../../components/MyRow';
// import EditIcon from '../../icons/svg/EditIcon';
import { formatDateTime } from '../../utils';
import DeleteButton from '../../components/antd/DeleteButton';
import { Switch, Tooltip } from 'antd';
import DeviceActions from '../../actions/DeviceActions';
import { notifyError, notifySuccess } from '../../components/notify';
import getText from '../../lang';
// import useNavigateApi from '../../AppRoute/useNavigateApi';

const DeviceListCard = (props) => {
  // const navigateApi = useNavigateApi();

  const { item, onChange, onDelete } = props;

  return (
    <div className="my-card service-list-card">
      <MyRow className="first-row">
        <div className="with-edit-buttin">
          <p>{item.deviceName ? item.deviceName : item.deviceOs}</p>
          {/* <div
            className="edit-icon"
            onClick={(e) => {
              e.stopPropagation();
              navigateApi('device/edit/' + item.idKey);
            }}
          >
            <EditIcon />
          </div> */}
        </div>
        <Switch
          checked={item.status === DeviceActions.STATUS.ACTIVE}
          onChange={async () => {
            let result;
            if (item.status === DeviceActions.STATUS.INACTIVE) {
              result = await DeviceActions.changeStatusActive(item.idKey);
            } else {
              result = await DeviceActions.changeStatusInActive(item.idKey);
            }
            if (result.success) {
              notifySuccess('Status Changed successfuly ');
              onChange && onChange(result.data);
            } else {
              notifyError(result.errMsg);
            }
          }}
        />
      </MyRow>

      <div className="list-service-card-price-wrapper">
        <div className="divider-line" />
        <MyRow className="second-row">
          <p>{getText('WORD_OS')}</p>
          <MyRow>
            <p>{getText(item.deviceOs)}</p>
            <DeleteButton onConfirm={onDelete} color={'#0076ff'} />
          </MyRow>
        </MyRow>
      </div>
      <div className="list-service-card-price-wrapper">
        <div className="divider-line" />
        <MyRow className="second-row">
          <p>{'ID'}</p>
          <Tooltip title={item.deviceKey}>
            <p>{item.deviceKey}</p>
          </Tooltip>
        </MyRow>
      </div>
      <div className="list-service-card-price-wrapper">
        <div className="divider-line" />
        <MyRow className="second-row">
          <p>{getText('WORD_CREATE_DATE')}</p>
          <p>{formatDateTime(item.createDate)}</p>
        </MyRow>
      </div>
    </div>
  );
};

DeviceListCard.defaultProps = {
  item: {},
};

export default DeviceListCard;
