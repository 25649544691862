import openSocket from 'socket.io-client';
import ApiStorage from '../reducers/ApiStorage';

let socket;

export const mainConnect = async () => {
  if (socket && socket.connected) {
    socket.disconnect();
  }

  let driverPackingMonitoringToken =
    await ApiStorage.getAccessTokenMonitoring();

  let user = await ApiStorage.getUser();
  const idKey = user?.idKey || '';
  const idKeyClient = user?.clientIdKey || '';
  socket = openSocket(process.env.REACT_APP_SOCKET_URL, {
    transports: ['polling'],
    reconnectionDelay: 3000,
    query: {
      name: 'Hi',
      device: 'web',
      idKey: idKey,
    },
  });
  socket.on('connect', function () {
    // console.warn('connect');
  });
  socket.on('event', function (data) {
    // console.warn('event');
  });
  socket.on('disconnect', function () {
    // socket.emit('ssssss', 'asda')
    // console.warn('disconnect');
  });
  if (driverPackingMonitoringToken) {
    socket.on(
      `channel_packing_monitoring_${driverPackingMonitoringToken}`,
      async (message) => {
        let msg = JSON.parse(message);
        switch (msg.type) {
          case 'PACKINGSTATUSCHANGE':
            if (msg.body) {
              let FN = ApiStorage.getPageSocketMonitoring();
              FN && FN(msg.body);
            }
            break;
          default:
            break;
        }
      }
    );
  }
  if (idKeyClient) {
    socket.on(`channel_${idKeyClient}`, async (message) => {
      let msg = JSON.parse(message);
      switch (msg.type) {
        case 'ORDERCOUNT':
          if (msg.body.hasOwnProperty('badgeOrderCount')) {
            await ApiStorage.setTotalActiveOrdersCount(
              msg.body.badgeOrderCount
            );
          }
          if (msg.body.hasOwnProperty('badgeManifestCount')) {
            await ApiStorage.setTotalActiveManifestCount(
              msg.body.badgeManifestCount
            );
          }
          break;
        // case 'DRIVERLOCATION':
        case 'DRIVERINFO':
          // if (msg.body.hasOwnProperty("badgeOrderCount")) {
          //   await ApiStorage.setTotalActiveOrdersCount(msg.body.badgeOrderCount);
          // }
          let fn_DRIVERINFO = ApiStorage.getPageSocket();
          fn_DRIVERINFO && fn_DRIVERINFO(msg);
          break;
        case 'ORDERCHANGE':
          let fn_ORDERCHANGE = ApiStorage.getPageSocket();
          fn_ORDERCHANGE && fn_ORDERCHANGE(msg);
          break;
        default:
          break;
      }
      //     // if (message.userID === userActions.getUser().userID) {
      //     //   userActions.setUnreadMessageTotalCount(message.unreadMessageTotalCount);
      //     // }
    });
  }
};

// if (!socket || !socket.connected) {
//     mainConnect();
// }

export default socket;
