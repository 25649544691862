import React, { memo, useEffect, useState } from 'react';
import ServiceTransportPriceActions from '../../actions/ServiceTransportPriceActions';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import MySelect from '../antd/MySelect';
import getText from '../../lang';

const SelectServiceTransportPrice = (props) => {
  const { withAll } = props;

  const [listData, setListData] = useState([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    let fill = async () => {
      let result =
        await ServiceTransportPriceActions.getFullListOptionsActive();
      if (isMounted()) {
        setListData(result);
      }
    };
    fill();
  }, [isMounted]);

  return (
    <MySelect
      {...props}
      options={listData}
      placeholder={
        withAll
          ? getText('WORD_SELECT_ALL_SERVICES')
          : getText('WORD_SELECT_SERVICE')
      }
    />
  );
};

export default memo(SelectServiceTransportPrice);
