import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="35"
    viewBox="0 0 1317 320"
    fill="none"
  >
    <g clipPath="url(#clip0_6_82)">
      <path
        d="M314.31 61.8101L184.4 108.58C172.038 113.03 158.512 113.03 146.15 108.58L16.24 61.8101C15.037 61.3755 13.9973 60.5806 13.2625 59.5337C12.5276 58.4869 12.1333 57.239 12.1333 55.96C12.1333 54.681 12.5276 53.4331 13.2625 52.3863C13.9973 51.3394 15.037 50.5447 16.24 50.1101L146.15 3.34012C158.512 -1.10947 172.038 -1.10947 184.4 3.34012L314.31 50.1101C315.513 50.5447 316.553 51.3394 317.288 52.3863C318.022 53.4331 318.417 54.681 318.417 55.96C318.417 57.239 318.022 58.4869 317.288 59.5337C316.553 60.5806 315.513 61.3755 314.31 61.8101V61.8101Z"
        fill="url(#paint0_linear_6_82)"
      />
      <path
        d="M314.63 50.23L314.31 50.1101C315.513 50.5447 316.553 51.3394 317.288 52.3862C318.022 53.4331 318.417 54.6809 318.417 55.96C318.417 57.239 318.022 58.4869 317.288 59.5337C316.553 60.5805 315.513 61.3755 314.31 61.8101L184.4 108.58C172.038 113.03 158.512 113.03 146.15 108.58L16.24 61.8101C15.037 61.3755 13.9973 60.5805 13.2625 59.5337C12.5276 58.4869 12.1333 57.239 12.1333 55.96C12.1333 54.6809 12.5276 53.4331 13.2625 52.3862C13.9973 51.3394 15.037 50.5447 16.24 50.1101L15.9199 50.23C11.2569 51.9095 7.2259 54.987 4.37671 59.0425C1.52752 63.0979 -0.000975134 67.9336 4.66736e-07 72.8899V246.58C-0.000975134 251.536 1.52752 256.372 4.37671 260.427C7.2259 264.483 11.2569 267.56 15.9199 269.24L146.15 316.13C158.512 320.58 172.038 320.58 184.4 316.13L314.63 269.24C319.294 267.561 323.327 264.484 326.177 260.428C329.028 256.373 330.559 251.537 330.56 246.58V72.8899C330.559 67.9328 329.028 63.0968 326.177 59.0415C323.327 54.9862 319.294 51.9091 314.63 50.23V50.23Z"
        fill="url(#paint1_linear_6_82)"
      />
      <path
        opacity="0.4"
        d="M185.04 294.59L216.21 283.37V266.64L185.04 277.86V294.59Z"
        fill="url(#paint2_linear_6_82)"
      />
      <path
        opacity="0.4"
        d="M221.14 281.59L230.46 278.24V261.51L221.14 264.86V281.59Z"
        fill="url(#paint3_linear_6_82)"
      />
      <g opacity="0.9">
        <path
          opacity="0.9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.4899 156.91L42.8 181.44C42.7507 181.482 42.7133 181.537 42.6918 181.598C42.6704 181.66 42.6655 181.726 42.6777 181.79C42.6898 181.854 42.7187 181.913 42.7612 181.962C42.8037 182.012 42.8584 182.049 42.9199 182.07L55.7199 186.35V262.11C55.7213 262.301 55.7816 262.487 55.8925 262.643C56.0035 262.799 56.1598 262.916 56.34 262.98L83.4099 272.03C83.4949 272.059 83.5857 272.067 83.6745 272.053C83.7633 272.04 83.8475 272.005 83.9201 271.952C83.9927 271.899 84.0515 271.83 84.0915 271.749C84.1315 271.669 84.1514 271.58 84.1498 271.49V195.86L98.2199 200.57C98.2912 200.593 98.3675 200.593 98.4389 200.571C98.5103 200.549 98.5735 200.506 98.6201 200.448C98.6666 200.389 98.6943 200.318 98.6996 200.244C98.705 200.169 98.6876 200.095 98.6498 200.03L72.7299 157.11C72.6674 157.009 72.5837 156.922 72.4846 156.856C72.3855 156.79 72.2734 156.745 72.1557 156.726C72.0381 156.707 71.9176 156.714 71.8027 156.746C71.6878 156.777 71.5812 156.833 71.4899 156.91V156.91Z"
          fill="url(#paint4_linear_6_82)"
        />
      </g>
      <path
        opacity="0.9"
        d="M280.75 73.89L115.01 14.55L74.75 29.05L240.49 88.39L280.75 73.89Z"
        fill="url(#paint5_linear_6_82)"
      />
      <path
        opacity="0.9"
        d="M274.03 115.83L280.74 121.83V73.9199L240.49 88.4099V136.32L247.2 125.49L253.91 131.49L260.62 120.68L267.32 126.68L274.03 115.83Z"
        fill="url(#paint6_linear_6_82)"
      />
    </g>
    <path
      d="M428.428 69.2444V206.864H400V69.2444H428.428Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M498.744 209.577C483.051 209.577 470.196 204.573 460.177 194.567C450.278 184.56 445.329 172.143 445.329 157.314C445.329 142.485 450.278 130.127 460.177 120.241C470.196 110.114 483.051 105.051 498.744 105.051C514.557 105.051 527.473 110.114 537.492 120.241C547.511 130.248 552.521 142.605 552.521 157.314C552.521 171.902 547.511 184.319 537.492 194.567C527.473 204.573 514.557 209.577 498.744 209.577ZM524.093 157.133C524.093 150.02 521.619 143.992 516.67 139.049C511.72 133.985 505.745 131.454 498.744 131.454C491.743 131.454 485.767 133.985 480.818 139.049C475.99 143.871 473.576 149.899 473.576 157.133C473.576 164.608 475.99 170.817 480.818 175.76C485.767 180.703 491.743 183.174 498.744 183.174C505.745 183.174 511.72 180.703 516.67 175.76C521.619 170.575 524.093 164.367 524.093 157.133Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M644.363 117.529V107.763H670.256V197.099C670.256 213.133 665.005 225.732 654.503 234.894C644.242 244.298 630.964 249 614.668 249C597.286 249 581.835 244.057 568.315 234.171L579.179 213.194C590.526 220.909 601.993 224.767 613.582 224.767C622.756 224.767 629.938 222.356 635.129 217.534C640.44 212.832 643.096 206.744 643.096 199.269V195.471C635.732 203.187 625.955 207.045 613.763 207.045C599.157 207.045 587.267 202.283 578.092 192.759C568.918 183.234 564.331 171.118 564.331 156.41C564.331 141.46 569.16 129.163 578.817 119.518C588.353 109.994 600.364 105.232 614.849 105.232C626.679 105.232 636.517 109.331 644.363 117.529ZM643.458 156.59C643.458 149.236 641.044 143.208 636.215 138.506C631.507 133.684 625.532 131.273 618.289 131.273C611.047 131.273 605.011 133.684 600.183 138.506C595.354 143.208 592.94 149.236 592.94 156.59C592.94 164.065 595.354 170.214 600.183 175.036C605.132 179.979 611.167 182.451 618.289 182.451C625.532 182.451 631.507 180.039 636.215 175.217C641.044 170.395 643.458 164.186 643.458 156.59Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M706.964 97.6364C702.136 97.6364 698.092 96.0691 694.833 92.9345C691.574 89.6794 689.944 85.6406 689.944 80.8182C689.944 75.9958 691.574 72.0173 694.833 68.8827C698.092 65.6276 702.136 64 706.964 64C711.672 64 715.656 65.6276 718.915 68.8827C722.174 72.0173 723.804 75.9958 723.804 80.8182C723.804 85.6406 722.174 89.6794 718.915 92.9345C715.656 96.0691 711.672 97.6364 706.964 97.6364ZM720.907 107.763V206.864H692.479V107.763H720.907Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M777.458 209.577C759.954 209.577 745.831 203.488 735.088 191.312L749.03 172.866C757.6 181.546 767.076 185.887 777.458 185.887C781.079 185.887 783.976 185.163 786.149 183.717C788.442 182.27 789.589 180.341 789.589 177.93C789.589 175.036 787.537 172.625 783.433 170.696C782.105 170.093 778.182 168.646 771.663 166.356C764.662 163.703 760.377 162.016 758.808 161.292C746.978 155.626 741.063 146.885 741.063 135.07C741.063 125.908 744.564 118.554 751.565 113.008C758.687 107.341 768.102 104.508 779.811 104.508C794.176 104.508 806.127 109.09 815.663 118.252L802.445 136.698C794.961 130.549 787.235 127.475 779.268 127.475C776.13 127.475 773.595 128.138 771.663 129.464C769.853 130.79 768.947 132.478 768.947 134.528C768.947 138.145 771.905 140.918 777.82 142.847C788.925 146.463 796.771 149.477 801.358 151.889C812.585 157.796 818.198 166.235 818.198 177.206C818.198 186.489 814.456 194.205 806.972 200.354C799.608 206.502 789.77 209.577 777.458 209.577Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M899.135 178.653L907.102 201.258C898.652 206.683 888.512 209.396 876.682 209.396C853.023 209.396 841.193 196.074 841.193 169.43V131.273H825.44V107.763H841.374V72.6803H869.621V107.763H901.489V131.273H869.621V166.717C869.621 177.93 874.027 183.536 882.839 183.536C887.426 183.536 892.858 181.908 899.135 178.653Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M965.558 209.577C949.866 209.577 937.01 204.573 926.991 194.567C917.093 184.56 912.143 172.143 912.143 157.314C912.143 142.485 917.093 130.127 926.991 120.241C937.01 110.114 949.866 105.051 965.558 105.051C981.371 105.051 994.288 110.114 1004.31 120.241C1014.33 130.248 1019.34 142.605 1019.34 157.314C1019.34 171.902 1014.33 184.319 1004.31 194.567C994.288 204.573 981.371 209.577 965.558 209.577ZM990.908 157.133C990.908 150.02 988.433 143.992 983.484 139.049C978.535 133.985 972.559 131.454 965.558 131.454C958.557 131.454 952.582 133.985 947.633 139.049C942.804 143.871 940.39 149.899 940.39 157.133C940.39 164.608 942.804 170.817 947.633 175.76C952.582 180.703 958.557 183.174 965.558 183.174C972.559 183.174 978.535 180.703 983.484 175.76C988.433 170.575 990.908 164.367 990.908 157.133Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M1157.89 105.051C1169.6 105.051 1179.32 108.668 1187.04 115.901C1194.77 123.135 1198.63 133.383 1198.63 146.644V206.864H1170.21V152.069C1170.21 145.318 1168.52 140.134 1165.14 136.517C1161.88 132.9 1157.47 131.092 1151.92 131.092C1145.88 131.092 1140.99 133.081 1137.25 137.06C1133.51 141.038 1131.64 146.343 1131.64 152.974V206.864H1103.21V152.25C1103.21 145.74 1101.46 140.616 1097.96 136.879C1094.58 133.021 1090.11 131.092 1084.56 131.092C1078.65 131.092 1073.82 133.081 1070.08 137.06C1066.33 141.038 1064.46 146.403 1064.46 153.154V206.864H1036.03V107.763H1063.74V121.869C1066.51 116.564 1070.68 112.405 1076.23 109.391C1081.78 106.377 1087.82 104.93 1094.34 105.051C1100.62 105.051 1106.29 106.498 1111.36 109.391C1116.43 112.164 1120.23 116.323 1122.77 121.869C1126.39 116.564 1131.34 112.465 1137.61 109.572C1143.89 106.558 1150.65 105.051 1157.89 105.051Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M1317 154.963C1317 157.856 1316.7 161.835 1316.09 166.898H1243.12C1246.5 179.316 1255.5 185.525 1270.1 185.525C1281.21 185.525 1291.53 182.33 1301.07 175.94L1312.29 195.29C1300.22 204.453 1285.74 209.034 1268.84 209.034C1251.33 209.034 1237.87 204.031 1228.46 194.024C1219.16 184.018 1214.52 171.721 1214.52 157.133C1214.52 142.304 1219.34 129.947 1229 120.061C1238.54 110.175 1251.15 105.232 1266.84 105.232C1281.33 105.232 1293.34 109.692 1302.88 118.614C1312.29 127.535 1317 139.652 1317 154.963ZM1281.51 133.081C1277.53 129.826 1272.64 128.198 1266.84 128.198C1261.05 128.198 1255.98 129.826 1251.63 133.081C1247.41 136.336 1244.51 140.857 1242.94 146.644H1289.66C1288.33 140.857 1285.61 136.336 1281.51 133.081Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M711.364 247.573H692V220.845H698.019V241.858H711.364V247.573Z"
      fill="white"
    />
    <path
      d="M723.623 248.115C720.48 248.115 717.906 247.115 715.899 245.113C713.917 243.112 712.926 240.629 712.926 237.663C712.926 234.697 713.917 232.225 715.899 230.248C717.906 228.223 720.48 227.21 723.623 227.21C726.79 227.21 729.377 228.223 731.383 230.248C733.39 232.25 734.393 234.721 734.393 237.663C734.393 240.58 733.39 243.064 731.383 245.113C729.377 247.115 726.79 248.115 723.623 248.115ZM728.7 237.627C728.7 236.204 728.204 234.998 727.213 234.01C726.222 232.997 725.025 232.491 723.623 232.491C722.221 232.491 721.024 232.997 720.033 234.01C719.066 234.974 718.583 236.18 718.583 237.627C718.583 239.122 719.066 240.363 720.033 241.352C721.024 242.341 722.221 242.835 723.623 242.835C725.025 242.835 726.222 242.341 727.213 241.352C728.204 240.315 728.7 239.073 728.7 237.627Z"
      fill="white"
    />
    <path
      d="M752.786 229.706V227.753H757.971V245.62C757.971 248.827 756.92 251.346 754.816 253.179C752.762 255.06 750.102 256 746.839 256C743.358 256 740.263 255.011 737.556 253.034L739.732 248.839C742.004 250.382 744.301 251.153 746.621 251.153C748.459 251.153 749.897 250.671 750.936 249.707C752 248.766 752.532 247.549 752.532 246.054V245.294C751.057 246.837 749.099 247.609 746.658 247.609C743.732 247.609 741.351 246.657 739.514 244.752C737.677 242.847 736.758 240.424 736.758 237.482C736.758 234.492 737.725 232.033 739.659 230.104C741.569 228.199 743.974 227.246 746.875 227.246C749.244 227.246 751.214 228.066 752.786 229.706ZM752.604 237.518C752.604 236.047 752.121 234.842 751.154 233.901C750.211 232.937 749.015 232.455 747.564 232.455C746.114 232.455 744.905 232.937 743.938 233.901C742.971 234.842 742.487 236.047 742.487 237.518C742.487 239.013 742.971 240.243 743.938 241.207C744.929 242.196 746.138 242.69 747.564 242.69C749.015 242.69 750.211 242.208 751.154 241.243C752.121 240.279 752.604 239.037 752.604 237.518Z"
      fill="white"
    />
    <path
      d="M765.323 225.727C764.356 225.727 763.546 225.414 762.893 224.787C762.241 224.136 761.914 223.328 761.914 222.364C761.914 221.399 762.241 220.603 762.893 219.977C763.546 219.326 764.356 219 765.323 219C766.266 219 767.063 219.326 767.716 219.977C768.369 220.603 768.695 221.399 768.695 222.364C768.695 223.328 768.369 224.136 767.716 224.787C767.063 225.414 766.266 225.727 765.323 225.727ZM768.115 227.753V247.573H762.422V227.753H768.115Z"
      fill="white"
    />
    <path
      d="M779.44 248.115C775.935 248.115 773.107 246.898 770.955 244.462L773.747 240.773C775.464 242.509 777.361 243.377 779.44 243.377C780.166 243.377 780.746 243.233 781.181 242.943C781.64 242.654 781.87 242.268 781.87 241.786C781.87 241.207 781.459 240.725 780.637 240.339C780.371 240.219 779.585 239.929 778.28 239.471C776.878 238.941 776.02 238.603 775.705 238.458C773.336 237.325 772.152 235.577 772.152 233.214C772.152 231.382 772.853 229.911 774.255 228.802C775.681 227.668 777.567 227.102 779.912 227.102C782.789 227.102 785.182 228.018 787.092 229.85L784.444 233.54C782.946 232.31 781.398 231.695 779.803 231.695C779.174 231.695 778.667 231.828 778.28 232.093C777.917 232.358 777.736 232.696 777.736 233.106C777.736 233.829 778.328 234.384 779.513 234.769C781.737 235.493 783.308 236.095 784.227 236.578C786.475 237.759 787.599 239.447 787.599 241.641C787.599 243.498 786.85 245.041 785.351 246.271C783.876 247.5 781.906 248.115 779.44 248.115Z"
      fill="white"
    />
    <path
      d="M803.808 241.931L805.404 246.452C803.712 247.537 801.681 248.079 799.312 248.079C794.574 248.079 792.205 245.415 792.205 240.086V232.455H789.05V227.753H792.241V220.736H797.898V227.753H804.28V232.455H797.898V239.544C797.898 241.786 798.78 242.907 800.545 242.907C801.463 242.907 802.551 242.582 803.808 241.931Z"
      fill="white"
    />
    <path
      d="M810.686 225.727C809.719 225.727 808.909 225.414 808.256 224.787C807.603 224.136 807.277 223.328 807.277 222.364C807.277 221.399 807.603 220.603 808.256 219.977C808.909 219.326 809.719 219 810.686 219C811.628 219 812.426 219.326 813.079 219.977C813.732 220.603 814.058 221.399 814.058 222.364C814.058 223.328 813.732 224.136 813.079 224.787C812.426 225.414 811.628 225.727 810.686 225.727ZM813.478 227.753V247.573H807.785V227.753H813.478Z"
      fill="white"
    />
    <path
      d="M827.342 248.007C824.247 248.007 821.709 247.042 819.727 245.113C817.768 243.16 816.789 240.665 816.789 237.627C816.789 234.564 817.78 232.081 819.763 230.176C821.745 228.223 824.308 227.246 827.45 227.246C829.529 227.246 831.403 227.741 833.071 228.729C834.763 229.718 836.008 231.104 836.806 232.889L832.273 235.312C831.113 233.431 829.541 232.491 827.559 232.491C826.109 232.491 824.888 232.985 823.897 233.974C822.93 234.938 822.446 236.156 822.446 237.627C822.446 239.146 822.918 240.387 823.86 241.352C824.827 242.316 826.036 242.799 827.487 242.799C828.478 242.799 829.396 242.545 830.242 242.039C831.113 241.509 831.753 240.833 832.164 240.014L836.77 242.726C835.899 244.342 834.618 245.632 832.926 246.596C831.258 247.537 829.396 248.007 827.342 248.007Z"
      fill="white"
    />
    <path
      d="M846.759 248.115C843.253 248.115 840.425 246.898 838.273 244.462L841.066 240.773C842.782 242.509 844.68 243.377 846.759 243.377C847.484 243.377 848.064 243.233 848.499 242.943C848.959 242.654 849.188 242.268 849.188 241.786C849.188 241.207 848.777 240.725 847.955 240.339C847.689 240.219 846.904 239.929 845.598 239.471C844.196 238.941 843.338 238.603 843.024 238.458C840.655 237.325 839.47 235.577 839.47 233.214C839.47 231.382 840.171 229.911 841.573 228.802C843 227.668 844.885 227.102 847.23 227.102C850.107 227.102 852.5 228.018 854.41 229.85L851.763 233.54C850.264 232.31 848.717 231.695 847.121 231.695C846.493 231.695 845.985 231.828 845.598 232.093C845.236 232.358 845.054 232.696 845.054 233.106C845.054 233.829 845.647 234.384 846.831 234.769C849.055 235.493 850.627 236.095 851.545 236.578C853.793 237.759 854.918 239.447 854.918 241.641C854.918 243.498 854.168 245.041 852.669 246.271C851.195 247.5 849.224 248.115 846.759 248.115Z"
      fill="white"
    />
    <path
      d="M873.767 247.573H867.856V220.845H872.063L882.18 235.384L892.224 220.845H896.503V247.573H890.52V233.431L883.231 243.811H881.056L873.767 233.431V247.573Z"
      fill="white"
    />
    <path
      d="M916.139 229.85V227.753H921.506V247.573H916.139V245.547C914.447 247.235 912.404 248.079 910.011 248.079C907.182 248.079 904.837 247.079 902.976 245.077C901.139 243.076 900.22 240.568 900.22 237.554C900.22 234.613 901.151 232.153 903.012 230.176C904.874 228.223 907.207 227.246 910.011 227.246C912.404 227.246 914.447 228.114 916.139 229.85ZM905.913 237.627C905.913 239.097 906.385 240.351 907.327 241.388C908.319 242.401 909.539 242.907 910.99 242.907C912.465 242.907 913.685 242.389 914.652 241.352C915.644 240.291 916.139 239.049 916.139 237.627C916.139 236.204 915.644 234.974 914.652 233.937C913.661 232.925 912.44 232.418 910.99 232.418C909.539 232.418 908.319 232.925 907.327 233.937C906.385 234.95 905.913 236.18 905.913 237.627Z"
      fill="white"
    />
    <path
      d="M931.575 247.573H925.881V227.753H931.429V230.501C931.961 229.537 932.783 228.753 933.895 228.151C935.032 227.524 936.301 227.21 937.703 227.21C939.854 227.21 941.68 227.921 943.178 229.344C944.677 230.767 945.427 232.804 945.427 235.457V247.573H939.733V236.939C939.733 235.517 939.383 234.408 938.682 233.612C937.981 232.816 937.038 232.418 935.853 232.418C934.572 232.418 933.533 232.852 932.735 233.72C931.961 234.564 931.575 235.686 931.575 237.084V247.573Z"
      fill="white"
    />
    <path
      d="M964.477 229.85V227.753H969.843V247.573H964.477V245.547C962.784 247.235 960.742 248.079 958.348 248.079C955.52 248.079 953.175 247.079 951.314 245.077C949.476 243.076 948.558 240.568 948.558 237.554C948.558 234.613 949.488 232.153 951.35 230.176C953.211 228.223 955.544 227.246 958.348 227.246C960.742 227.246 962.784 228.114 964.477 229.85ZM954.251 237.627C954.251 239.097 954.722 240.351 955.665 241.388C956.656 242.401 957.877 242.907 959.327 242.907C960.802 242.907 962.023 242.389 962.99 241.352C963.981 240.291 964.477 239.049 964.477 237.627C964.477 236.204 963.981 234.974 962.99 233.937C961.999 232.925 960.778 232.418 959.327 232.418C957.877 232.418 956.656 232.925 955.665 233.937C954.722 234.95 954.251 236.18 954.251 237.627Z"
      fill="white"
    />
    <path
      d="M989.268 229.706V227.753H994.453V245.62C994.453 248.827 993.401 251.346 991.298 253.179C989.243 255.06 986.584 256 983.321 256C979.839 256 976.745 255.011 974.038 253.034L976.213 248.839C978.486 250.382 980.782 251.153 983.103 251.153C984.94 251.153 986.379 250.671 987.418 249.707C988.482 248.766 989.014 247.549 989.014 246.054V245.294C987.539 246.837 985.581 247.609 983.139 247.609C980.214 247.609 977.833 246.657 975.996 244.752C974.158 242.847 973.24 240.424 973.24 237.482C973.24 234.492 974.207 232.033 976.141 230.104C978.051 228.199 980.456 227.246 983.357 227.246C985.726 227.246 987.696 228.066 989.268 229.706ZM989.086 237.518C989.086 236.047 988.603 234.842 987.636 233.901C986.693 232.937 985.496 232.455 984.046 232.455C982.595 232.455 981.387 232.937 980.42 233.901C979.453 234.842 978.969 236.047 978.969 237.518C978.969 239.013 979.453 240.243 980.42 241.207C981.411 242.196 982.62 242.69 984.046 242.69C985.496 242.69 986.693 242.208 987.636 241.243C988.603 240.279 989.086 239.037 989.086 237.518Z"
      fill="white"
    />
    <path
      d="M1018.38 237.193C1018.38 237.771 1018.32 238.567 1018.2 239.58H1003.58C1004.26 242.063 1006.06 243.305 1008.98 243.305C1011.21 243.305 1013.28 242.666 1015.19 241.388L1017.43 245.258C1015.02 247.091 1012.12 248.007 1008.73 248.007C1005.23 248.007 1002.53 247.006 1000.64 245.005C998.783 243.004 997.852 240.544 997.852 237.627C997.852 234.661 998.819 232.189 1000.75 230.212C1002.66 228.235 1005.19 227.246 1008.33 227.246C1011.23 227.246 1013.64 228.138 1015.55 229.923C1017.43 231.707 1018.38 234.13 1018.38 237.193ZM1011.27 232.816C1010.47 232.165 1009.49 231.84 1008.33 231.84C1007.17 231.84 1006.16 232.165 1005.29 232.816C1004.44 233.467 1003.86 234.371 1003.55 235.529H1012.9C1012.63 234.371 1012.09 233.467 1011.27 232.816Z"
      fill="white"
    />
    <path
      d="M1046.11 227.21C1048.46 227.21 1050.4 227.934 1051.95 229.38C1053.5 230.827 1054.27 232.877 1054.27 235.529V247.573H1048.58V236.614C1048.58 235.264 1048.24 234.227 1047.56 233.503C1046.91 232.78 1046.03 232.418 1044.91 232.418C1043.71 232.418 1042.73 232.816 1041.98 233.612C1041.23 234.408 1040.85 235.469 1040.85 236.795V247.573H1035.16V236.65C1035.16 235.348 1034.81 234.323 1034.11 233.576C1033.43 232.804 1032.54 232.418 1031.42 232.418C1030.24 232.418 1029.27 232.816 1028.52 233.612C1027.77 234.408 1027.4 235.481 1027.4 236.831V247.573H1021.71V227.753H1027.25V230.574C1027.81 229.513 1028.64 228.681 1029.76 228.078C1030.87 227.475 1032.08 227.186 1033.38 227.21C1034.64 227.21 1035.78 227.5 1036.79 228.078C1037.81 228.633 1038.57 229.465 1039.08 230.574C1039.8 229.513 1040.79 228.693 1042.05 228.114C1043.31 227.512 1044.66 227.21 1046.11 227.21Z"
      fill="white"
    />
    <path
      d="M1077.97 237.193C1077.97 237.771 1077.91 238.567 1077.79 239.58H1063.18C1063.86 242.063 1065.66 243.305 1068.58 243.305C1070.81 243.305 1072.87 242.666 1074.78 241.388L1077.03 245.258C1074.61 247.091 1071.71 248.007 1068.33 248.007C1064.82 248.007 1062.13 247.006 1060.24 245.005C1058.38 243.004 1057.45 240.544 1057.45 237.627C1057.45 234.661 1058.42 232.189 1060.35 230.212C1062.26 228.235 1064.79 227.246 1067.93 227.246C1070.83 227.246 1073.24 228.138 1075.15 229.923C1077.03 231.707 1077.97 234.13 1077.97 237.193ZM1070.87 232.816C1070.07 232.165 1069.09 231.84 1067.93 231.84C1066.77 231.84 1065.75 232.165 1064.88 232.816C1064.04 233.467 1063.46 234.371 1063.14 235.529H1072.5C1072.23 234.371 1071.69 233.467 1070.87 232.816Z"
      fill="white"
    />
    <path
      d="M1087 247.573H1081.3V227.753H1086.85V230.501C1087.38 229.537 1088.21 228.753 1089.32 228.151C1090.46 227.524 1091.72 227.21 1093.13 227.21C1095.28 227.21 1097.1 227.921 1098.6 229.344C1100.1 230.767 1100.85 232.804 1100.85 235.457V247.573H1095.16V236.939C1095.16 235.517 1094.81 234.408 1094.11 233.612C1093.4 232.816 1092.46 232.418 1091.28 232.418C1090 232.418 1088.96 232.852 1088.16 233.72C1087.38 234.564 1087 235.686 1087 237.084V247.573Z"
      fill="white"
    />
    <path
      d="M1118.02 241.931L1119.62 246.452C1117.92 247.537 1115.89 248.079 1113.52 248.079C1108.78 248.079 1106.42 245.415 1106.42 240.086V232.455H1103.26V227.753H1106.45V220.736H1112.11V227.753H1118.49V232.455H1112.11V239.544C1112.11 241.786 1112.99 242.907 1114.76 242.907C1115.67 242.907 1116.76 242.582 1118.02 241.931Z"
      fill="white"
    />
    <path
      d="M1139.77 248.043C1137.64 248.043 1135.66 247.633 1133.82 246.813C1132.01 245.969 1130.48 244.764 1129.25 243.196L1132.51 238.929C1134.52 241.292 1136.95 242.473 1139.8 242.473C1140.84 242.473 1141.69 242.256 1142.34 241.822C1142.99 241.364 1143.32 240.773 1143.32 240.05C1143.32 239.061 1142.55 238.229 1141 237.554L1138.61 236.686C1137.13 236.132 1135.98 235.637 1135.16 235.203C1132.28 233.636 1130.85 231.382 1130.85 228.44C1130.85 226.125 1131.73 224.208 1133.49 222.689C1135.26 221.17 1137.55 220.411 1140.38 220.411C1143.94 220.411 1146.97 221.592 1149.49 223.955L1146.51 228.404C1145.74 227.68 1144.78 227.102 1143.65 226.668C1142.53 226.234 1141.47 226.017 1140.46 226.017C1139.46 226.017 1138.68 226.222 1138.1 226.631C1137.54 227.017 1137.26 227.548 1137.26 228.223C1137.26 229.139 1137.99 229.923 1139.44 230.574L1142.05 231.623C1143.67 232.25 1144.7 232.672 1145.13 232.889C1148.35 234.335 1149.96 236.602 1149.96 239.688C1149.96 242.148 1149.01 244.149 1147.13 245.692C1145.27 247.259 1142.81 248.043 1139.77 248.043Z"
      fill="white"
    />
    <path
      d="M1163.09 248.115C1159.94 248.115 1157.37 247.115 1155.36 245.113C1153.38 243.112 1152.39 240.629 1152.39 237.663C1152.39 234.697 1153.38 232.225 1155.36 230.248C1157.37 228.223 1159.94 227.21 1163.09 227.21C1166.25 227.21 1168.84 228.223 1170.85 230.248C1172.85 232.25 1173.86 234.721 1173.86 237.663C1173.86 240.58 1172.85 243.064 1170.85 245.113C1168.84 247.115 1166.25 248.115 1163.09 248.115ZM1168.16 237.627C1168.16 236.204 1167.67 234.998 1166.68 234.01C1165.69 232.997 1164.49 232.491 1163.09 232.491C1161.68 232.491 1160.49 232.997 1159.5 234.01C1158.53 234.974 1158.05 236.18 1158.05 237.627C1158.05 239.122 1158.53 240.363 1159.5 241.352C1160.49 242.341 1161.68 242.835 1163.09 242.835C1164.49 242.835 1165.69 242.341 1166.68 241.352C1167.67 240.315 1168.16 239.073 1168.16 237.627Z"
      fill="white"
    />
    <path
      d="M1187.73 224.027C1186.61 224.027 1185.76 224.305 1185.15 224.859C1184.55 225.414 1184.25 226.21 1184.25 227.246V227.753H1190.37V232.455H1184.25V247.573H1178.59V232.455H1175.65V227.753H1178.59V227.102C1178.59 224.473 1179.35 222.472 1180.87 221.098C1182.44 219.723 1184.44 219.036 1186.86 219.036C1188.89 219.036 1190.91 219.567 1192.91 220.628L1191.06 224.932C1189.76 224.329 1188.65 224.027 1187.73 224.027Z"
      fill="white"
    />
    <path
      d="M1206.23 241.931L1207.83 246.452C1206.13 247.537 1204.1 248.079 1201.73 248.079C1197 248.079 1194.63 245.415 1194.63 240.086V232.455H1191.47V227.753H1194.66V220.736H1200.32V227.753H1206.7V232.455H1200.32V239.544C1200.32 241.786 1201.2 242.907 1202.97 242.907C1203.89 242.907 1204.97 242.582 1206.23 241.931Z"
      fill="white"
    />
    <path
      d="M1219.96 247.537H1214.92L1208.14 227.753H1214.12L1217.86 240.086L1222.03 227.753H1226.05L1230.3 240.086L1233.96 227.753H1239.94L1233.23 247.537H1228.19L1224.06 235.963L1219.96 247.537Z"
      fill="white"
    />
    <path
      d="M1256.63 229.85V227.753H1261.99V247.573H1256.63V245.547C1254.93 247.235 1252.89 248.079 1250.5 248.079C1247.67 248.079 1245.32 247.079 1243.46 245.077C1241.63 243.076 1240.71 240.568 1240.71 237.554C1240.71 234.613 1241.64 232.153 1243.5 230.176C1245.36 228.223 1247.69 227.246 1250.5 227.246C1252.89 227.246 1254.93 228.114 1256.63 229.85ZM1246.4 237.627C1246.4 239.097 1246.87 240.351 1247.81 241.388C1248.81 242.401 1250.03 242.907 1251.48 242.907C1252.95 242.907 1254.17 242.389 1255.14 241.352C1256.13 240.291 1256.63 239.049 1256.63 237.627C1256.63 236.204 1256.13 234.974 1255.14 233.937C1254.15 232.925 1252.93 232.418 1251.48 232.418C1250.03 232.418 1248.81 232.925 1247.81 233.937C1246.87 234.95 1246.4 236.18 1246.4 237.627Z"
      fill="white"
    />
    <path
      d="M1272.06 247.573H1266.37V227.753H1271.81V230.321C1273.09 228.247 1274.89 227.21 1277.21 227.21C1277.98 227.21 1278.81 227.367 1279.68 227.68L1279.21 233.106C1278.38 232.816 1277.55 232.672 1276.7 232.672C1275.25 232.672 1274.12 233.166 1273.29 234.154C1272.47 235.119 1272.06 236.578 1272.06 238.531V247.573Z"
      fill="white"
    />
    <path
      d="M1301 237.193C1301 237.771 1300.94 238.567 1300.82 239.58H1286.21C1286.88 242.063 1288.68 243.305 1291.61 243.305C1293.83 243.305 1295.9 242.666 1297.81 241.388L1300.06 245.258C1297.64 247.091 1294.74 248.007 1291.35 248.007C1287.85 248.007 1285.15 247.006 1283.27 245.005C1281.41 243.004 1280.48 240.544 1280.48 237.627C1280.48 234.661 1281.44 232.189 1283.38 230.212C1285.29 228.235 1287.81 227.246 1290.96 227.246C1293.86 227.246 1296.26 228.138 1298.17 229.923C1300.06 231.707 1301 234.13 1301 237.193ZM1293.89 232.816C1293.09 232.165 1292.12 231.84 1290.96 231.84C1289.8 231.84 1288.78 232.165 1287.91 232.816C1287.06 233.467 1286.48 234.371 1286.17 235.529H1295.52C1295.26 234.371 1294.71 233.467 1293.89 232.816Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6_82"
        x1="318.42"
        y1="55.9601"
        x2="12.13"
        y2="55.9601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.32" stopColor="#F9F9F9" />
        <stop offset="0.77" stopColor="#E7E9E7" />
        <stop offset="1" stopColor="#DBDEDC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6_82"
        x1="4.66764e-07"
        y1="184.79"
        x2="330.56"
        y2="184.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.05" stopColor="#EFEFEF" />
        <stop offset="0.46" stopColor="#EEEFEF" />
        <stop offset="0.52" stopColor="#E1E1E1" />
        <stop offset="1" stopColor="#F9F9FA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6_82"
        x1="195.54"
        y1="271.8"
        x2="205.72"
        y2="289.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66C6E4" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6_82"
        x1="13988.7"
        y1="-41273.5"
        x2="13804.8"
        y2="-41125.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66C6E4" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_6_82"
        x1="41.6498"
        y1="248.41"
        x2="102.86"
        y2="187.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A7E1" />
        <stop offset="1" stopColor="#008AF4" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_6_82"
        x1="115.04"
        y1="-11.24"
        x2="240.46"
        y2="114.18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFE1" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_6_82"
        x1="286.28"
        y1="79.4299"
        x2="234.95"
        y2="130.76"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#74A9C9" />
        <stop offset="0.99" stopColor="#1793FF" />
      </linearGradient>
      <clipPath id="clip0_6_82">
        <rect width="330.56" height="319.47" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const LogoFull = (props) => <Icon component={SVGIcon} {...props} />;

export default LogoFull;
