import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.004"
    height="15.203"
    viewBox="0 0 16.004 15.203"
  >
    <path
      id="Path_13513"
      data-name="Path 13513"
      d="M14.736,11.957a.881.881,0,0,0-.255.776l.711,3.936a.864.864,0,0,1-.36.864.881.881,0,0,1-.936.064L10.352,15.75a.9.9,0,0,0-.4-.1H9.735a.649.649,0,0,0-.216.072L5.975,17.574a.935.935,0,0,1-.568.088.889.889,0,0,1-.712-1.017l.712-3.936a.9.9,0,0,0-.255-.783l-2.889-2.8a.864.864,0,0,1-.215-.9.9.9,0,0,1,.711-.6l3.976-.577a.89.89,0,0,0,.7-.487L9.191,2.964a.833.833,0,0,1,.16-.216l.072-.056a.537.537,0,0,1,.129-.1l.087-.032L9.776,2.5h.337a.9.9,0,0,1,.7.48l1.775,3.576a.889.889,0,0,0,.664.487l3.976.577a.907.907,0,0,1,.728.6.869.869,0,0,1-.232.9Z"
      transform="translate(-2 -2.5)"
      fill="#fff"
      opacity="0.8"
    />
  </svg>
);

const StarIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default StarIcon;
