import React, { useEffect } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import InputFormText from '../../components/antd/InputFormText';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';

import InputFormTextArea from '../../components/antd/InputFormTextArea';
import MyPanelFooter from '../../components/MyPanelFooter';
import './style.scss';

import ServiceActions from '../../actions/ServiceActions';
import { notifySuccess, notifyError } from '../../components/notify';
import { useParams } from 'react-router-dom';
import ServiceEditListItemPrice from './ServiceEditListItemPrice';
import { useForm } from 'antd/lib/form/Form';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';

const ServicesEdit = (props) => {
  let { _id } = useParams();
  const { isMobile } = useWindowSize();

  const { isNew } = props;

  const navigateApi = useNavigateApi();

  const [form] = useForm();

  useEffect(() => {
    let fill = async () => {
      if (!_id) {
        form.setFieldsValue({
          itemsStep: [
            { name: getText('WORD_ORDER_STATUS_START') },
            { name: getText('WORD_ORDER_STATUS_COMPLETE') },
          ],
        });
      }
    };
    fill();
  }, [_id, form]);

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await ServiceActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
          });
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('services/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  const onFinish = async (values) => {
    for (const key in values.itemsStep) {
      if (!values.itemsStep[key].checkPoint) {
        values.itemsStep[key].checkPoint = false;
      }
    }

    let result = await ServiceActions.save(values, _id);
    if (result.success) {
      notifySuccess(
        isNew ? 'Service created successfuly' : 'Service updated successfuly'
      );
      navigateApi('services/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    for (const key in errorInfo.values.itemsStep) {
      if (!errorInfo.values.itemsStep[key].checkPoint) {
        errorInfo.values.itemsStep[key].checkPoint = false;
      }
    }
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock
        {...props}
        title={
          isNew ? getText('WORD_NEW_SERVICE') : getText('WORD_SERVICE_EDIT')
        }
        page
      >
        <MyForm
          name="serviceForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{
            description: '',
          }}
        >
          <InputFormText name="name" label={getText('WORD_NAME')} required />
          <InputFormTextArea
            name="description"
            label={getText('WORD_DESCRIPTION')}
          />
          <ServiceEditListItemPrice form={form} />
          <MyPanelFooter
            onCancelClick={() => {
              navigateApi('services/list');
            }}
            formName={'serviceForm'}
            labelPrimary={
              isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
            }
            labelCancel={getText('WORD_CANCEL')}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default ServicesEdit;
