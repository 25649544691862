import React, { useEffect, useState } from 'react';
import UserActions from '../../actions/UserActions';
import { SELECT_UNSELECT_NONE, TABLE_FETCH_PAGE_SIZE } from '../../utils';
import getText from '../../lang';
import MainPage from '../../components/MainPage';
import MyGrid from '../../components/antd/MyGrid';
import MySelect from '../../components/antd/MySelect';
import HeaderSpace from '../../components/HeaderSpace';
import MyFilterApply from '../../components/MyFilterApply';
import InputSearch from '../../components/antd/InputSearch';
import SelectUserTransportType from '../../components/selects/SelectUserTransportType';
import { notifyError } from '../../components/notify';
import FilterIcon from '../../icons/svg/FilterIcon';
import { Button, Drawer } from 'antd';
import UserListDriverMonitorStatusChangeCard from './UserListDriverMonitorStatusChangeCard';
import useIsMounted from '@utilityjs/use-is-mounted';
import useWindowSize from '../../utils/useWindowSize';
import './style.scss';

const UserListDriverMonitorStatusChange = (props) => {
  const isMounted = useIsMounted();

  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedTransportType, setSelectedTransportType] =
    useState(SELECT_UNSELECT_NONE);
  const [selectedStatus, setSelectedStatus] = useState(SELECT_UNSELECT_NONE);
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [visibleFilter, setVisibleFilter] = useState(false);

  const { isMobile } = useWindowSize();

  useEffect(() => {
    const fillDataFS = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {},
        filter: {
          status: selectedStatus,
          idTransportType: selectedTransportType,
        },
        q: searchName || '',
      };

      let result = await UserActions.fillPaginationListDriverWithManifest(
        fetchParams
      );
      if (isMounted()) {
        if (result.success) {
          setListData(result.data);
          setTotalCount(result.totalRows);
        } else {
          notifyError(result.errMsg);
        }
        setLoadingData(false);
      }
    };
    fillDataFS();
  }, [
    isMounted,
    selectedTransportType,
    selectedStatus,
    page,
    perPage,
    searchName,
  ]);

  const showDrawer = () => {
    setVisibleFilter(true);
  };

  const onClose = () => {
    setVisibleFilter(false);
  };
  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onChange={(v) => setSearchName(v)}
          onClear={() => setSearchName('')}
        />
        {isMobile ? (
          <>
            <Button
              className="filter-button"
              type="filter"
              onClick={showDrawer}
            >
              <FilterIcon />
            </Button>
            <Drawer
              title={
                <MyFilterApply
                  onCancelClick={onClose}
                  onClick={onClose}
                  labelCancel={getText('WORD_CANCEL')}
                  labelPrimary={getText('WORD_FILTER_APPLY')}
                />
              }
              placement={'right'}
              closable={false}
              onClose={onClose}
              open={visibleFilter}
              key={'right'}
              width={300}
            >
              <SelectUserTransportType
                name="selectTransportType"
                value={selectedTransportType}
                withAll
                showSearch={false}
                onChange={(e) => setSelectedTransportType(e)}
              />
              <MySelect
                name="selectStatus"
                defaultValue={SELECT_UNSELECT_NONE}
                options={UserActions.getStatusOptionsAll()}
                value={selectedStatus}
                showSearch={false}
                onChange={(e) => setSelectedStatus(e)}
                placeholder={getText('WORD_ALL_STATUSES')}
              />
            </Drawer>
          </>
        ) : (
          <>
            <SelectUserTransportType
              name="selectTransportType"
              value={selectedTransportType}
              withAll
              showSearch={false}
              onChange={(e) => setSelectedTransportType(e)}
            />
            <MySelect
              name="selectStatus"
              defaultValue={SELECT_UNSELECT_NONE}
              options={UserActions.getStatusOptionsAll()}
              value={selectedStatus}
              showSearch={false}
              onChange={(e) => setSelectedStatus(e)}
              placeholder={getText('WORD_SELECT_ALL_STATUSES')}
            />
          </>
        )}
      </HeaderSpace>
      <MainPage title={getText('WORD_DRIVERS')} topZero>
        <MyGrid
          loading={loadingData}
          data={listData}
          pagination={true}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          renderItem={(item, index) => {
            return (
              <UserListDriverMonitorStatusChangeCard
                key={index}
                item={item}
                {...props}
              />
            );
          }}
        />
      </MainPage>
    </>
  );
};

export default UserListDriverMonitorStatusChange;
