import React from 'react';
import UtilNumber from '../../../utils/utilNumber';
import { InputNumber } from 'antd';
import './InputNumberWithAfterBefore.scss';

const InputNumberWithAfterBefore = (props) => {
  const parseValue = (value) => (value ? parseFloat(value).toFixed(3) : 0);

  const {
    addonBefore,
    placeholder,
    label,
    step,
    min,
    max,
    disabled,
    onChange,
    readOnly,
    value,
    onBlur,
    addonAfter,
  } = props;

  return (
    <div className="input-number-with-btn-wrapper">
      <span className="before-input">{addonBefore}</span>
      <InputNumber
        placeholder={placeholder ? placeholder : label}
        formatter={(value) => UtilNumber.formatDouble(value)}
        parser={parseValue}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        value={value}
        onBlur={onBlur}
      />
      <span className="after-input">{addonAfter}</span>
    </div>
  );
};

InputNumberWithAfterBefore.defaultProps = {
  name: 'numberValue',
  type: 'number',
  required: false,
  hasFeedback: true,
  min: 0,
  step: 1,
};

export default InputNumberWithAfterBefore;
