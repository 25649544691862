import axios from 'axios';
import { redirectPath } from '.';
import ApiStorage from '../reducers/ApiStorage';

let BASE_URL = process.env.REACT_APP_BASE_URL;

export const getBaseURL = () => {
  return `${BASE_URL}/api/v1/`;
};

const adapter = axios.create({
  baseURL: getBaseURL(),
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
  validateStatus: false,
  withCredentials: false,
  timeout: 15000,
});

export async function fetchFromUrlPOSTAsyncWEBAPIT(
  url,
  parameters = [],
  oneTimeToken
) {
  try {
    let dataPost = {
      device: 'web_api',
      offset: new Date().getTimezoneOffset(),
    };

    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    let token = await ApiStorage.getAccessTokenMonitoring();
    if (oneTimeToken) {
      token = oneTimeToken;
    }
    if (token) {
      adapter.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common['Authorization'];
    }
    let result = await adapter.post(url, dataPost);
    if (result.status === 401) {
      redirectPath('error_page_401');
    }
    return result.data;
  } catch (error) {
    return { success: false, errMsg: error.message };
  }
}

export default adapter;
