import md5 from 'md5';

const utilString = {
  getUniqueString: () => {
    return md5(new Date().valueOf().toString() + Math.random().toString());
  },
  MD5: (str) => {
    return md5(str);
  },
  randomNumber: (max) => {
    return Math.floor(Math.random() * max + max)
      .toString()
      .substring(1);
  },
  checkLanguage: (text) => {
    const englishRegex = /^[A-Za-z\s]+$/;
    const armenianRegex = /^[Ա-Ֆա-ֆ\s]+$/;
    if (englishRegex.test(text)) {
      return 'en';
    } else if (armenianRegex.test(text)) {
      return 'hy';
    } else {
      return '';
    }
  },
};

export default utilString;
