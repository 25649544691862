import React, { useEffect, useState } from 'react';
import OrderActions from '../../actions/OrderActions';
import getText from '../../lang';
import { notifyError } from '../../components/notify';
import { Drawer, Timeline } from 'antd';
import OrderViewContactCard from '../orders/OrderViewContactCard';
import OrderProductsSection from '../orders/OrderProductsSection';
import MyRow from '../../components/MyRow';
// import TimelineDotIcon from '../../icons/svg/TimelineDotIcon';
import useIsMounted from '@utilityjs/use-is-mounted';
import LoadingOutlinedApp from '../../components/antd/LoadingOutlinedApp';
import { useSelector } from 'react-redux';

const DrawerOrderView = (props) => {
  const { visible, onClose, idKey } = props;

  const clientCurrencySymbol = useSelector((state) => {
    return state.AuthReducer.currentUser?.clientCurrencySymbol;
  });

  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);

  const isMounted = useIsMounted();

  useEffect(() => {
    const getObj = async () => {
      if (idKey && visible) {
        setLoading(true);
        let resultGet = await OrderActions.getOneByidKey(idKey);
        setLoading(false);
        if (resultGet.success) {
          let orderItemsTotal_delevered = 0;
          let orderItemsTotal_deleveredNot = 0;
          let orderItemsTotal_total = 0;
          resultGet.data.items.forEach((it) => {
            orderItemsTotal_total += it.itemPrice;
            if (it.status === OrderActions.STATUS_ITEMS.DELIVERED) {
              orderItemsTotal_delevered += it.itemPrice;
            } else if (it.status === OrderActions.STATUS_ITEMS.NOTDELIVERED) {
              orderItemsTotal_deleveredNot += it.itemPrice;
            }
          });
          resultGet.data._orderItemsTotal_delevered = orderItemsTotal_delevered;
          resultGet.data._orderItemsTotal_deleveredNot =
            orderItemsTotal_deleveredNot;
          resultGet.data._orderItemsTotal_total = orderItemsTotal_total;
          resultGet.data.statusFinishImages = JSON.parse(
            resultGet.data.statusFinishImages
          );
          setItem(resultGet.data);
        } else {
          notifyError(resultGet.errMsg);
        }
      }
    };
    getObj();
  }, [visible, idKey, isMounted]);

  return (
    <Drawer
      placement={'right'}
      closable={false}
      onClose={onClose}
      open={visible}
      key={'right'}
      width={410}
    >
      {loading ? (
        <LoadingOutlinedApp />
      ) : (
        <>
          <OrderViewContactCard
            type={getText('WORD_CUSTOMER')}
            phoneNumber={
              item.phoneCode &&
              item.phoneNumber &&
              item.phoneCode + '' + item.phoneNumber
            }
            address=""
          />
          <div className="order-view-description">
            <div className="about-order-wrapper">
              <MyRow bottomZero withoutTitle>
                <Timeline
                  items={[
                    { key: 1, children: item && item.addressFrom },
                    { key: 2, children: item && item.addressTo },
                  ]}
                />
              </MyRow>
              <div className="about-order-row">
                <div className="about-order-type">
                  {getText('WORD_SERVICE')}
                </div>
                <div className="about-order-name">{item.serviceName}</div>
              </div>
              <div className="about-order-row">
                <div className="about-order-type">
                  {getText('WORD_CHECK_ID')}
                </div>
                <div className="about-order-name">
                  {item.documentNumber ? item.documentNumber : '---'}
                </div>
              </div>
              <div className="about-order-row">
                <div className="about-order-type">{getText('WORD_BRANCH')}</div>
                <div className="about-order-name">{item.branchName}</div>
              </div>
              <div className="about-order-row">
                <div className="about-order-type">{getText('WORD_NOTES')}</div>
                <div className="about-order-name">{item.description}</div>
              </div>
            </div>
            <div className="order-view-services-prices">
              <div className="serives-row">
                <div className="row-first-item">
                  {getText('WORD_SERVICE_AMOUNT')}
                </div>
                <div className="row-second-item">
                  {item.amountDelivery}
                  <span className="currency-symbol">
                    {clientCurrencySymbol}
                  </span>
                </div>
              </div>
              <div className="divider-line" />
              <div className="serives-row">
                <div className="row-first-item">
                  {getText('WORD_ORDER_AMOUNT')}
                </div>
                <div className="row-second-item">
                  {item.amountOrder}
                  <span className="currency-symbol">
                    {clientCurrencySymbol}
                  </span>
                </div>
              </div>
              <div className="divider-line" />
              <OrderProductsSection
                item={item}
                clientCurrencySymbol={clientCurrencySymbol}
              />

              <div className="serives-row big">
                <div className="row-first-item">{getText('WORD_SUBTOTAL')}</div>
                <div className="row-second-item">
                  {item._orderItemsTotal_total}
                  <span className="currency-symbol">
                    {clientCurrencySymbol}
                  </span>
                </div>
              </div>
              <div className="divider-line" />
              <div className="serives-row big">
                <div className="row-first-item">{getText('WORD_REFUND')}</div>
                <div className="row-second-item">
                  {item._orderItemsTotal_deleveredNot}
                  <span className="currency-symbol">
                    {clientCurrencySymbol}
                  </span>
                </div>
              </div>
              <div className="divider-line" />
              <div className="serives-row big">
                <div className="row-first-item">{getText('WORD_TOTAL')}</div>
                <div className="row-second-item">
                  {(
                    item._orderItemsTotal_total -
                    item._orderItemsTotal_deleveredNot
                  ).toString()}
                  <span className="currency-symbol">
                    {clientCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};

export default DrawerOrderView;
