import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
  >
    <g id="Group_8284" data-name="Group 8284" transform="translate(-100 -440)">
      <circle
        id="Ellipse_657"
        data-name="Ellipse 657"
        cx="5"
        cy="5"
        r="5"
        transform="translate(100 440)"
        fill="#00c97c"
      />
      <path
        id="Path_11409"
        data-name="Path 11409"
        d="M4131.356-1268.2l1.489,1.183,1.874-3.245"
        transform="translate(-4027.989 1713.9)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

const OrderGreenDone = (props) => <Icon component={SVGIcon} {...props} />;

export default OrderGreenDone;
