import axios from 'axios';
import { redirectPath } from '.';

let BASE_URL = process.env.REACT_APP_BASE_URL;

export const getBaseURL = () => {
  return `${BASE_URL}/api/v1/`;
};

const adapter = axios.create({
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
  validateStatus: false,
  withCredentials: false,
  timeout: 15000,
});

export async function fetchFromUrlGETExternal(url) {
  try {
    let result = await adapter.get(url);
    if (result.status === 401) {
      redirectPath('error_page_401');
    }
    return { success: result.status === 200, data: result.data };
  } catch (error) {
    return { success: false, errMsg: error.message };
  }
}

export default adapter;
