import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApicodeActions from '../../actions/ApicodeActions';
import UserActions from '../../actions/UserActions';
import InputFormText from '../../components/antd/InputFormText';
import SelectForm from '../../components/antd/SelectForm';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import MyPanelFooter from '../../components/MyPanelFooter';
import { notifyError, notifySuccess } from '../../components/notify';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';

const ApiEdit = (props) => {
  let { _id } = useParams();

  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const { isMobile } = useWindowSize();

  const [listDrivers, setListDrivers] = useState([]);

  const { isNew } = props;

  useEffect(() => {
    let fill = async () => {
      if (!_id) {
        form.setFieldsValue({
          itemsStep: [
            { name: getText('WORD_ORDER_STATUS_START') },
            { name: getText('WORD_ORDER_STATUS_COMPLETE') },
          ],
        });
      }
      setListDrivers(await UserActions.getFullListDriversOptions());
    };
    fill();
  }, [_id, form]);

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await ApicodeActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
          });
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('apicode/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  const onFinish = async (values) => {
    for (const key in values.itemsStep) {
      if (!values.itemsStep[key].checkPoint) {
        values.itemsStep[key].checkPoint = false;
      }
    }

    let result = await ApicodeActions.save(values, _id);
    if (result.success) {
      notifySuccess(
        isNew
          ? getText('WORD_API_CODE_CREATED_SUCCESSFULY')
          : getText('WORD_API_CODE_UPDATED_SUCCESSFULY')
      );
      navigateApi('apicode/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    for (const key in errorInfo.values.itemsStep) {
      if (!errorInfo.values.itemsStep[key].checkPoint) {
        errorInfo.values.itemsStep[key].checkPoint = false;
      }
    }
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock
        {...props}
        title={
          isNew ? getText('WORD_NEW_API_CODE') : getText('WORD_EDIT_API_CODE')
        }
        page
      >
        <MyForm
          name="apicodeForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <InputFormText name="name" label={getText('WORD_NAME')} required />
          {!isNew ? (
            <InputFormText
              name="token"
              label={getText('WORD_TOKEN')}
              required
              disabled
            />
          ) : null}
          <SelectForm
            name="idDriver"
            label={getText('WORD_DRIVER')}
            options={listDrivers}
            showSearch={true}
          />
          <MyPanelFooter
            onCancelClick={() => {
              navigateApi('apicode/list');
            }}
            formName={'apicodeForm'}
            labelPrimary={
              isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
            }
            labelCancel={getText('WORD_CANCEL')}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default ApiEdit;
