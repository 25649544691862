import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BranchActions from '../../actions/BranchActions';
import InputFormText from '../../components/antd/InputFormText';
import HeaderSpace from '../../components/HeaderSpace';
import MyBlock from '../../components/MyBlock';
import MyForm from '../../components/MyForm';
import MyPanelFooter from '../../components/MyPanelFooter';
import { notifyError, notifySuccess } from '../../components/notify';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';

const BranchEdit = (props) => {
  let { _id } = useParams();
  const [form] = useForm();
  const navigateApi = useNavigateApi();
  const { isMobile } = useWindowSize();
  const { isNew } = props;

  const formName = 'branchEdit';

  useEffect(() => {
    const getObj = async () => {
      if (_id) {
        let resultGet = await BranchActions.getOneByidKey(_id);
        if (resultGet.success) {
          form.setFieldsValue({
            ...resultGet.data,
          });
        } else {
          notifyError(resultGet.errMsg);
          navigateApi('branch/list');
        }
      }
    };
    getObj();
  }, [_id, form, navigateApi]);

  const onFinish = async (values) => {
    let result = await BranchActions.save(values, _id);
    if (result.success) {
      notifySuccess(
        isNew
          ? getText('WORD_BRANCH_CREATED_SUCCESSFULY')
          : getText('WORD_BRANCH_UPDATED_SUCCESSFULY')
      );
      navigateApi('branch/list');
    } else {
      notifyError(result.errMsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields?.length) {
      notifyError(errorInfo.errorFields[0].errors[0]);
    }
    for (const key in errorInfo.values.itemsStep) {
      if (!errorInfo.values.itemsStep[key].checkPoint) {
        errorInfo.values.itemsStep[key].checkPoint = false;
      }
    }
  };

  return (
    <>
      {isMobile ? <HeaderSpace /> : null}
      <MyBlock
        {...props}
        title={
          isNew ? getText('WORD_ADD_NEW_BRANCH') : getText('WORD_EDIT_BRANCH')
        }
        page
      >
        <MyForm
          name={formName}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <InputFormText
            name="branchName"
            label={getText('WORD_NAME')}
            required
          />
          <InputFormText
            name="branchCode"
            label={getText('WORD_CODE')}
            required
          />
          <MyPanelFooter
            onCancelClick={() => {
              navigateApi('branch/list');
            }}
            formName={formName}
            labelPrimary={
              isNew ? getText('WORD_CREATE') : getText('WORD_UPDATE')
            }
            labelCancel={getText('WORD_CANCEL')}
          />
        </MyForm>
      </MyBlock>
    </>
  );
};

export default BranchEdit;
