import React, { memo } from 'react';
import getText from '../../lang';
import MyRow from '../../components/MyRow';
import { formatDateTime } from '../../utils';
import SmsHistoryActions from '../../actions/SmsHistoryActions';
import { MessageOutlined, MailOutlined } from '@ant-design/icons';
import { Collapse, Space } from 'antd';
import TextWithLinks from '../sms/TextWithLinks';

const OrderNotificationSection = (props) => {
  const { items } = props;

  return (
    <div className="order-notification-section-wrapper">
      <div className="timeline-header">
        {items?.length ? getText('WORD_NOTIFICATIONS') : null}
      </div>
      {items?.map((it, index) => {
        return (
          <div className="order-notification-wrapper" key={index}>
            <Collapse
              ghost
              items={[
                {
                  key: index,
                  showArrow: false,
                  label: (
                    <>
                      <MyRow>
                        <Space className="order-notification-first-row">
                          {it.notificationsType ===
                          SmsHistoryActions.NOTIFICATION_CHANNEL.SMS ? (
                            <MessageOutlined style={{ fontSize: 20 }} />
                          ) : (
                            <MailOutlined style={{ fontSize: 20 }} />
                          )}
                          <div>{it.phoneNumber}</div>
                        </Space>
                        <div>{formatDateTime(it.createDate)}</div>
                      </MyRow>
                      <div className="order-notification-second-row">
                        {SmsHistoryActions.getTypeLabel(it.actionType)?.title}
                      </div>
                    </>
                  ),
                  // children: <div>{it.textBody}</div>,
                  children: <TextWithLinks text={it.textBody} />,
                },
              ]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default memo(OrderNotificationSection);
