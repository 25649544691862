import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
  <svg
    id="Group_8217"
    data-name="Group 8217"
    xmlns="http://www.w3.org/2000/svg"
    width="19.115"
    height="19.146"
    viewBox="0 0 19.115 19.146"
  >
    <path
      id="Path_5894"
      data-name="Path 5894"
      d="M18.436,28.409l-2.458-1.934a1.2,1.2,0,0,0-1.652.161c-.443.5-.725.846-.826.927-.745.745-2.3-.342-3.949-1.995s-2.72-3.183-1.974-3.929c.081-.081.423-.383.927-.826a1.2,1.2,0,0,0,.161-1.652L6.731,16.7a1.224,1.224,0,0,0-1.41-.363,10.314,10.314,0,0,0-2.176,1.592c-1.773,1.793.443,5.863,4.311,9.751s7.958,6.1,9.731,4.311a10.314,10.314,0,0,0,1.592-2.176A1.181,1.181,0,0,0,18.436,28.409Z"
      transform="translate(-2.525 -13.48)"
      fill="#0076ff"
    />
    <path
      id="Path_5895"
      data-name="Path 5895"
      d="M47.578,33.615a.8.8,0,0,0-.987.826h0a.84.84,0,0,0,.6.725,2.435,2.435,0,0,1,1.793,1.793.774.774,0,0,0,.725.6h0a.8.8,0,0,0,.826-.987,3.787,3.787,0,0,0-1.048-1.894A3.955,3.955,0,0,0,47.578,33.615Z"
      transform="translate(-37.711 -27.328)"
      fill="#0076ff"
    />
    <path
      id="Path_5896"
      data-name="Path 5896"
      d="M52.1,25.342a.8.8,0,0,0,.846-.866,7.1,7.1,0,0,0-6.487-6.487.8.8,0,0,0-.141,1.592,5.406,5.406,0,0,1,3.445,1.612,5.607,5.607,0,0,1,1.612,3.445A.732.732,0,0,0,52.1,25.342Z"
      transform="translate(-36.911 -14.866)"
      fill="#0076ff"
    />
    <path
      id="Path_5897"
      data-name="Path 5897"
      d="M52.1,5.479A10.27,10.27,0,0,0,45.194,2.5a.783.783,0,0,0-.806.846h0a.8.8,0,0,0,.766.745,8.652,8.652,0,0,1,8.321,8.341.789.789,0,0,0,.745.766h0a.783.783,0,0,0,.846-.806A10.155,10.155,0,0,0,52.1,5.479Z"
      transform="translate(-35.952 -2.496)"
      fill="#0076ff"
    />
  </svg>
);

const PhoneIcon = (props) => <Icon component={SVGIcon} {...props} />;

export default PhoneIcon;
