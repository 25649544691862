import React, { useEffect, useState } from 'react';
import HeaderSpace from '../../components/HeaderSpace';
import InputSearch from '../../components/antd/InputSearch';
import MyGrid from '../../components/antd/MyGrid';
import { Button } from 'antd';
import CustomerListCard from './CustomerListCard';
// import FilterIcon from '../../icons/svg/FilterIcon';
// import MyFilterApply from '../../components/MyFilterApply';
import { TABLE_FETCH_PAGE_SIZE } from '../../utils';
import CustomerActions from '../../actions/CustomerActions';
import MainPage from '../../components/MainPage';
import getText from '../../lang';
import useNavigateApi from '../../AppRoute/useNavigateApi';
import useWindowSize from '../../utils/useWindowSize';
import { PlusOutlined } from '@ant-design/icons';
import './style.scss';

const CustomerList = () => {
  const navigateApi = useNavigateApi();

  const [listData, setListData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_FETCH_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  // const [visibleFilter, setVisibleFilter] = useState(false);

  const { isMobile } = useWindowSize();

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true);
      let fetchParams = {
        page: page,
        perPage: perPage,
        sort: {},
        filter: {},
        q: searchName || '',
      };
      let result = await CustomerActions.fillPaginationList(fetchParams);
      if (result.success) {
        setListData(result.data);
        setTotalCount(result.totalRows);
      }
      setLoadingData(false);
    };
    fill();
  }, [searchName, page, perPage]);

  // const showDrawer = () => {
  //   setVisibleFilter(true);
  // };

  // const onClose = () => {
  //   setVisibleFilter(false);
  // };

  return (
    <>
      <HeaderSpace className="mobile-list-header">
        <InputSearch
          className="search-response-input"
          placeholder={getText('WORD_SEARCH')}
          onChange={(v) => setSearchName(v)}
        />
        {/* <Button
          icon={<FilterIcon />}
          className="filter-button"
          type="filter"
          onClick={showDrawer}
        >
          {getText('WORD_FILTERS')}
        </Button> */}
        <Button
          type="primary"
          onClick={() => {
            navigateApi('customer/create');
          }}
        >
          {isMobile ? (
            <PlusOutlined />
          ) : (
            <span>{getText('WORD_ADD_CUSTOMER')}</span>
          )}
        </Button>
        {/* <Drawer
          title={
            <MyFilterApply
              onCancelClick={onClose}
              onClick={onClose}
              labelCancel={getText('WORD_CANCEL')}
              labelPrimary={getText('WORD_FILTER_APPLY')}
            />
          }
          placement={'right'}
          closable={false}
          onClose={onClose}
          open={visibleFilter}
          key={'right'}
          width={300}
        ></Drawer> */}
      </HeaderSpace>
      <MainPage title={getText('WORD_CUSTOMERS_LIST_TITLE')} topZero>
        <MyGrid
          loading={loadingData}
          data={listData}
          pagination={true}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          renderItem={(item, index) => {
            return <CustomerListCard key={index} item={item} />;
          }}
        />
      </MainPage>
    </>
  );
};

export default CustomerList;
